import { API } from '../api'
import { RootProps } from '../root'
import { Session } from '../session'
import { AppView } from '../state'
import { getEventListProps } from './event-list'
import { getHeaderProps } from './header'
import { getMeetingProps } from './meeting'
import { getStatsProps } from './stats'
import { getStatsTypeChoiceProps } from './stats-type-choice'

export const getRootProps = (view: AppView) => {
    const { route } = view.state
    const [main, ...params] = route

    const props: RootProps = {}

    if (main === 'login') {
        props.login = { view }
    } else if (main === 'select-centre') {
        props.selectCentre = { view }
    } else if (main === 'meeting') {
        props.header = getHeaderProps(view, 'events')
        props.meeting = getMeetingProps(view)
    } else if (main === 'events') {
        const [eventId] = params
        props.header = getHeaderProps(view, 'events')
        props.eventList = getEventListProps(view, eventId)
    } else if (main === 'visitor-search') {
        props.header = getHeaderProps(view, 'visitor-search')
        props.visitorSearch = { view }
    } else if (main === 'cards') {
        setCardsViewProps(view, props, ...params)
    } else if (main === 'stats') {
        const [type, param] = params
        props.header = getHeaderProps(view, 'stats')

        if (!type) {
            props.statsTypeChoice = getStatsTypeChoiceProps(view)
        } else {
            props.stats = getStatsProps(view, type, param)
        }
    } else if (main === 'centre') {
        const [page] = params
        setCentreViewProps(view, props, page)
    } else if (main === 'employees') {
        props.header = getHeaderProps(view, 'employees')
        props.employees = { view }
    } else if (main === 'employee') {
        props.header = getHeaderProps(view)
        props.employee = { view }
    } else if (main === 'transfer') {
        const [visitorId] = params
        props.header = getHeaderProps(view)
        props.transfer = { reactKey: visitorId, view, visitorId }
    } else if (main === 'dev') {
        const [tool] = params
        props.header = getHeaderProps(view, 'dev')
        props.devPanel = { view, tool }
    } else if (main === 'run-tests' || main === 'custom-render') {
        // Dummy routes for empty page, used in UI tests
    } else {
        API.logWarning(view, 'Route not found: ' + route.join('/'), false)

        // Default view, same as /events
        props.header = getHeaderProps(view, 'events')
        props.eventList = getEventListProps(view)
    }

    return props
}

const setCardsViewProps = (
    view: AppView,
    props: RootProps,
    page?: string,
    ...params: string[]
): void => {
    if (!page) {
        const centre = Session.getCentre(view)

        if (centre) {
            props.header = getHeaderProps(view, 'cards')
            props.cardList = { view }
        } else {
            props.header = getHeaderProps(view, 'cards')
            props.cardsAdmin = { view }
        }
    } else if (page === 'new') {
        const [visitorId] = params

        if (visitorId) {
            props.header = getHeaderProps(view, 'cards')
            props.cardView = { view, isNew: true, unlinked: false, visitorId }
        } else {
            props.header = getHeaderProps(view, 'cards')
            props.newCard = { view }
        }
    } else if (page === 'new-unlinked') {
        props.header = getHeaderProps(view, 'cards')
        props.cardView = { view, isNew: true, unlinked: true }
    } else if (page === 'view') {
        const [cardId] = params
        props.header = getHeaderProps(view, 'cards')
        props.cardView = { reactKey: cardId, view, isNew: false, cardId }
    } else if (page === 'link') {
        const [cardId] = params
        props.header = getHeaderProps(view, 'cards')
        props.linkCard = { view, cardId }
    } else if (page === 'new-diary') {
        const [cardId] = params
        props.header = getHeaderProps(view, 'cards')
        props.cardDiary = { reactKey: `new-${cardId}`, view, editMode: true, isNew: true, cardId }
    } else if (page === 'view-diary') {
        const [entryId] = params
        props.header = getHeaderProps(view, 'cards')
        props.cardDiary = {
            reactKey: `view-${entryId}`,
            view,
            editMode: false,
            isNew: false,
            entryId,
        }
    } else if (page === 'edit-diary') {
        const [entryId] = params
        props.header = getHeaderProps(view, 'cards')
        props.cardDiary = {
            reactKey: `edit-${entryId}`,
            view,
            editMode: true,
            isNew: false,
            entryId,
        }
    } else if (page === 'edit-dates') {
        const [cardId] = params
        props.header = getHeaderProps(view, 'cards')
        props.editCardDates = { view, cardId }
    } else if (page === 'transfer') {
        if (params.length === 1 && params[0] === 'success') {
            props.header = getHeaderProps(view, 'cards')
            props.transferCardsSuccess = { view }
        } else if (params.length === 2) {
            const [orig, dest] = params
            props.header = getHeaderProps(view, 'cards')
            props.transferCardsSelection = { view, origin: orig, destination: dest }
        } else {
            props.header = getHeaderProps(view, 'cards')
            props.transferCards = { view }
        }
    } else if (page === 'employee-update') {
        props.header = getHeaderProps(view, 'cards')
        props.cardsEmployeeUpdate = { view }
    } else if (page === 'summary') {
        props.header = getHeaderProps(view, 'cards')
        props.cardsSummary = { view }
    } else if (page === 'esf-report') {
        props.header = getHeaderProps(view, 'cards')
        props.esfReport = { view }
    }
}

const setCentreViewProps = (view: AppView, props: RootProps, page: string): void => {
    if (page === 'general') {
        props.header = getHeaderProps(view, 'centre/general')
        props.manageCentre = { view }
    } else if (page === 'employees') {
        props.header = getHeaderProps(view, 'centre/general')
        props.centreEmployees = { view }
    } else if (page === 'form') {
        props.header = getHeaderProps(view, 'centre/general')
        props.centreForm = { view }
    } else if (page === 'parent-notification') {
        props.header = getHeaderProps(view, 'centre/general')
        props.parentNotification = { view }
    } else if (page === 'visitors') {
        props.header = getHeaderProps(view, 'centre/general')
        props.centreVisitors = { view }
    }
}
