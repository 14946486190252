import type { GetCentreVisitors_ApiVisitor } from '../api'
import { Cache } from '../cache'
import { EventBus } from '../event-bus'
import { getInitialVisitorSearchState } from '../initial-state'
import type { SearchPanelExistingItemProps } from '../search-panel/existing-item'
import type { AppView } from '../state'
import { Utils } from '../utils'
import type { VisitorSearchPanelProps } from '../visitor-search-panel'
import { getUsername, isString, type VisitorMatch } from '../visitor-search-utils'
import { getEditVisitorProps } from './edit-visitor'

export interface VisitorSearchPanelParams {
    primaryVisitors?: Record<string, GetCentreVisitors_ApiVisitor>
    excludedVisitorUsernames?: Set<string>
    includeOtherVisitors?: boolean
    clearAfterSelection?: boolean
    onSelectVisitor: (visitor: VisitorMatch) => void
    getHeader?: (anythingTyped: boolean, anyMatches: boolean) => string
    addNew?: {
        onAddParticipant: (username: string) => Promise<void>
    }
}

interface SearchResponse {
    matches: VisitorMatch[]
    hasMore: boolean
    exactMatch?: VisitorMatch
}

const MAX_VISITOR_RESULTS = 20

export const getVisitorSearchPanelProps = (
    view: AppView,
    params: VisitorSearchPanelParams,
): VisitorSearchPanelProps => {
    const { state, update } = view
    const { visitorSearch } = state
    const { exactMatch, moreMatches } = visitorSearch
    const { includeOtherVisitors, getHeader, addNew } = params

    const props: VisitorSearchPanelProps = {
        effect: () => {
            if (includeOtherVisitors) {
                const init = async () => {
                    visitorSearch.usernames = await Cache.getVisitorUsernames(view)
                    update()
                    await EventBus.fire('usernames-loaded')
                }

                void init()
            }

            return () => {
                state.visitorSearch = getInitialVisitorSearchState()
            }
        },
        isLoading: Boolean(includeOtherVisitors && !visitorSearch.usernames),
        searchBox: {
            id: 'visitor-search',
            onChange: async (evt) => updateMatches(view, params, evt.target.value),
            search: visitorSearch.search,
        },
        matches: visitorSearch.matches.map((match) => getMatchProps(view, params, match)),
    }

    if (getHeader) {
        props.header = getHeader(visitorSearch.search.length > 0, visitorSearch.matches.length > 0)
    }

    if (exactMatch) {
        props.exactMatch = getMatchProps(view, params, exactMatch)
    }

    if (moreMatches) {
        props.moreMatchesNote = [
            `Leidus rohkem kui ${MAX_VISITOR_RESULTS} vastet.`,
            'Proovi trükkida rohkem tähti oma kasutajanimest.',
        ]
    }

    if (addNew) {
        const { editVisitor } = state
        const searchText = visitorSearch.search

        props.newVisitorNote = 'Osaled esimest korda?'

        props.newVisitorItem = {
            id: 'add-new-visitor',
            label: 'Loo uus kasutaja',
            search: searchText.trim(),
            onClick: () => {
                editVisitor.newModalVisible = true
                view.update()
            },
        }

        if (editVisitor.newModalVisible) {
            props.newVisitorModal = getEditVisitorProps(view, {
                close: async () => {
                    editVisitor.newModalVisible = false
                    view.update()
                    await EventBus.fire('modal-closed')
                },
                isNew: true,
                initialName: searchText.trim(),
                afterSave: async (username: string | null) => {
                    await updateMatches(view, params, '')
                    await addNew.onAddParticipant!(username!)
                },
            })
        }
    }

    return props
}

const updateMatches = async (view: AppView, params: VisitorSearchPanelParams, search: string) => {
    const { state, update } = view
    const { visitorSearch } = state

    visitorSearch.search = search
    update()

    const response = getSearchResponse(view, params)

    const { matches, hasMore, exactMatch } = response
    visitorSearch.matches = matches
    visitorSearch.moreMatches = hasMore
    visitorSearch.exactMatch = exactMatch
    update()

    await EventBus.fire('visitor-results-updated')
}

const getSearchResponse = (view: AppView, params: VisitorSearchPanelParams): SearchResponse => {
    const { state } = view
    const { visitorSearch } = state

    if (!visitorSearch.search) {
        return { matches: [], hasMore: false }
    }

    const exactMatch = findExactMatch(view, params)

    const searchLower = visitorSearch.search.toLowerCase()

    const matches: VisitorMatch[] = Utils.filterMap(params.primaryVisitors || {}, (visitor) =>
        filterPrimaryVisitor(params, visitor, searchLower),
    )

    if (params.includeOtherVisitors && matches.length < MAX_VISITOR_RESULTS) {
        // Combine results from current centre with others
        const alreadyMatchedUsernames = new Set(matches.map(getUsername))

        const { usernames } = visitorSearch

        if (!usernames) {
            throw new Error('Usernames have not been loaded')
        }

        for (const username of usernames) {
            if (
                !shouldExcludeUsername(params, username) &&
                !alreadyMatchedUsernames.has(username) &&
                stringMatchesSearch(username, searchLower)
            ) {
                matches.push(username)

                // Allow one more than the max to detect if there are more
                if (matches.length >= MAX_VISITOR_RESULTS + 1) {
                    break
                }
            }
        }
    } else {
        // We have enough results, no need to check other usernames
        Utils.sortAsStrings(matches, (match) => getUsername(match).toLowerCase())
    }

    const hasMore = matches.length > MAX_VISITOR_RESULTS

    if (hasMore) {
        // Truncate list if too long
        matches.length = MAX_VISITOR_RESULTS
    }

    return { matches, hasMore, exactMatch }
}

const findExactMatch = (
    view: AppView,
    params: VisitorSearchPanelParams,
): VisitorMatch | undefined => {
    const { primaryVisitors } = params
    const { state } = view
    const { visitorSearch } = state
    const { usernames, search } = visitorSearch

    if (shouldExcludeUsername(params, search)) {
        return undefined
    }

    if (primaryVisitors) {
        const exactMatch = Utils.findByField<
            GetCentreVisitors_ApiVisitor,
            keyof GetCentreVisitors_ApiVisitor
        >(primaryVisitors, 'username', search, true)

        if (exactMatch) {
            return exactMatch
        }
    }

    if (usernames) {
        return usernames.find((username) => username === search)
    }

    return undefined
}

const filterPrimaryVisitor = (
    params: VisitorSearchPanelParams,
    visitor: GetCentreVisitors_ApiVisitor,
    searchLower: string,
) => {
    // Exclude those already present
    if (shouldExcludeUsername(params, visitor.username)) {
        return false
    }

    const { firstname, lastname, username } = visitor

    return (
        (firstname && stringMatchesSearch(firstname, searchLower)) ||
        (lastname && stringMatchesSearch(lastname, searchLower)) ||
        (firstname && lastname && stringMatchesSearch(firstname + ' ' + lastname, searchLower)) ||
        stringMatchesSearch(username, searchLower)
    )
}

const shouldExcludeUsername = (params: VisitorSearchPanelParams, username: string) => {
    if (!params.excludedVisitorUsernames) {
        return false
    }

    return params.excludedVisitorUsernames.has(username)
}

const stringMatchesSearch = (str: string, searchLower: string) => {
    return str.toLowerCase().indexOf(searchLower) !== -1
}

const getMatchProps = (
    view: AppView,
    params: VisitorSearchPanelParams,
    match: VisitorMatch,
): SearchPanelExistingItemProps => {
    const { state } = view
    const { visitorSearch } = state

    let username: string
    let desaturated: boolean

    if (isString(match)) {
        username = match
        desaturated = true
    } else {
        username = match.username
        desaturated = !match.currentCentre
    }

    return {
        reactKey: username,
        className: 'visitor-result',
        primaryName: username,
        secondaryName: getFullName(match),
        onClick: async () => {
            params.onSelectVisitor(match)

            if (params.clearAfterSelection) {
                await updateMatches(view, params, '')
            }
        },
        search: visitorSearch.search,
        desaturated,
    }
}

const getFullName = (visitor: VisitorMatch) => {
    if (isString(visitor)) {
        return undefined
    }

    if (visitor.firstname && visitor.lastname) {
        return visitor.firstname + ' ' + visitor.lastname
    }

    return undefined
}
