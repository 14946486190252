import moment from 'moment'
import { Moment } from 'moment'

import { xml, XmlBuilder } from '../../util/xml'
import { NS } from './constants'
import { HEADER_STYLE } from './style'
import {
    Column,
    ColumnHeader,
    ExcelNumberFormat,
    ExcelSpec,
    ExcelStyle,
    ExcelValue,
    SimpleExcelValue,
    StyleManager,
    WrappedExcelValue,
} from './types'

interface State {
    rowIndex: number
    colIndex: number
    merges: string[]
    numbersAsText: string[]
    twoDigitTextYears: string[]
}

const EPOCH = moment('1899-12-30T00:00Z').unix()

// Time since Excel epoch, converted from seconds to number of days
const getExcelTimestamp = (date: Moment) => (date.unix() - EPOCH) / (24 * 60 * 60)

// 1 -> A, 2 -> B, ..., 26 -> Z, 27 -> AA, 28 -> AB, ..., 702 -> ZZ
// Since row numbers start with 1 instead of 0, we do the same for columns
const colLetter = (i: number) => {
    if (i < 1 || i > 702) {
        throw new Error('Invalid column: ' + i)
    }

    const div = Math.floor((i - 1) / 26)
    const mod = (i - 1) % 26
    const first = div === 0 ? '' : String.fromCodePoint(64 + div)
    const second = String.fromCodePoint(65 + mod)
    return first + second
}

const getRef = (col: number, row: number) => colLetter(col) + row

const getContent = <Row, Value extends ExcelValue, Totals>(
    column: Column<Row, Value, Totals>,
    row: Row,
): ExcelValue => {
    const value: Value = column.getValue ? column.getValue(row) : (undefined as any)
    return column.renderForExcel ? column.renderForExcel(value, row) : value
}

const writeColumns = <Row, Value, Totals>(
    worksheetBuilder: XmlBuilder,
    columns: Column<Row, Value, Totals>[],
) => {
    const colsBuilder = worksheetBuilder.e('cols')

    for (const [i, column] of columns.entries()) {
        const width = column.excelWidth || 20

        colsBuilder
            .e('col')
            .a('min', i + 1)
            .a('max', i + 1)
            .a('width', width)
            .a('customWidth', 1)
            .done()
    }

    colsBuilder.done()
}

const getHeader = (header: ColumnHeader) => {
    let value: ExcelValue = ''

    if (header.excelContent) {
        if (typeof header.excelContent === 'object' && 'value' in header.excelContent) {
            return header.excelContent
        } else {
            value = header.excelContent
        }
    } else if (typeof header.content === 'string') {
        value = header.content
    }

    return { value, style: HEADER_STYLE }
}

const writeCell = async (
    rowBuilder: XmlBuilder,
    value: ExcelValue,
    styleManager: StyleManager,
    state: State,
) => {
    const ref = getRef(state.colIndex, state.rowIndex)
    const cellBuilder = rowBuilder.e('c').a('r', ref)

    let simpleValue: SimpleExcelValue = ''
    let style: ExcelStyle = {}

    if (typeof value === 'string' || typeof value === 'number') {
        simpleValue = value
    } else if (moment.isMoment(value)) {
        simpleValue = value
        style.numberFormat = ExcelNumberFormat.date
    } else if (value && (typeof value as any) === 'object' && 'value' in value) {
        const wrapped: WrappedExcelValue = value
        simpleValue = wrapped.value

        if (simpleValue === null) {
            simpleValue = ''
        }

        style = wrapped.style

        if (wrapped.formula) {
            // The value in the <v> element isn't required and the spreadsheet will work without it,
            // unless Excel opens it in protected view. In this mode, Excel won't calculate any
            // formulas, but it will show the values from the <v> elements.
            cellBuilder.e('f').t(wrapped.formula).done()
        }
    }

    cellBuilder.a('s', await styleManager.getIndex(style))

    if (moment.isMoment(simpleValue)) {
        simpleValue = getExcelTimestamp(simpleValue)
    }

    if (typeof simpleValue === 'number') {
        // Avoid floating point inaccuracy
        const rounded = Math.round(simpleValue * 100000) / 100000
        cellBuilder.e('v').t(rounded).done()
    } else if ((typeof simpleValue as any) === 'string') {
        if (String(Number(simpleValue)) === simpleValue) {
            state.numbersAsText.push(ref)
        } else if (simpleValue.match(/^\d{1,2}\.\d{1,2}\.\d{1,2}$/)) {
            state.twoDigitTextYears.push(ref)
        }

        cellBuilder.a('t', 'inlineStr').e('is').e('t').u(simpleValue).done().done()
    } else {
        throw new Error('Invalid cell value type: ' + typeof simpleValue)
    }

    cellBuilder.done()
}

const writeHeaderRows = async <Row, Value, Totals>(
    state: State,
    sheetDataBuilder: XmlBuilder,
    styleManager: StyleManager,
    columns: ReadonlyArray<Column<Row, Value, Totals>>,
    { hasSecondHeader }: ExcelSpec<Row, Value, Totals>,
) => {
    state.rowIndex += 1
    state.colIndex = 0

    let rowBuilder = sheetDataBuilder.e('row').a('r', state.rowIndex)
    let toSkip = 0

    for (const column of columns) {
        state.colIndex += 1

        if (toSkip > 0) {
            toSkip -= 1
            continue
        }

        const ref = getRef(state.colIndex, state.rowIndex)
        const header: ColumnHeader = column.header || {}
        await writeCell(rowBuilder, getHeader(header), styleManager, state)
        const { span: headerSpan } = header

        if (headerSpan) {
            if (headerSpan < 2) {
                throw new Error('Header span must be at least 2')
            }

            state.merges.push(ref + ':' + getRef(state.colIndex + headerSpan - 1, state.rowIndex))
            toSkip = headerSpan - 1
        }

        if (hasSecondHeader && !column.secondHeader) {
            if (headerSpan) {
                throw new Error('Mixing row and column spans is currently not supported')
            }

            state.merges.push(ref + ':' + getRef(state.colIndex, state.rowIndex + 1))
        }
    }

    rowBuilder.done()

    if (hasSecondHeader) {
        state.rowIndex += 1
        state.colIndex = 0
        rowBuilder = sheetDataBuilder.e('row').a('r', state.rowIndex)

        for (const mainColumn of columns) {
            state.colIndex += 1
            const column = mainColumn.secondHeader

            if (column) {
                if (column.span) {
                    // Add support if needed
                    throw new Error('Span is currently not supported on second header')
                }

                await writeCell(rowBuilder, getHeader(column), styleManager, state)
            }
        }

        rowBuilder.done()
    }
}

const writeBodyRow = async <Row, Value extends ExcelValue, Totals>(
    row: Row,
    state: State,
    sheetDataBuilder: XmlBuilder,
    styleManager: StyleManager,
    columns: Column<Row, Value, Totals>[],
) => {
    state.rowIndex += 1
    state.colIndex = 0
    const rowBuilder = sheetDataBuilder.e('row').a('r', state.rowIndex)

    for (const column of columns) {
        state.colIndex += 1
        const content = getContent(column, row)
        await writeCell(rowBuilder, content, styleManager, state)
    }

    rowBuilder.done()
}

const writeTotalsRow = async <Row, Value, Totals>(
    state: State,
    sheetDataBuilder: XmlBuilder,
    styleManager: StyleManager,
    columns: Column<Row, Value, Totals>[],
    spec: ExcelSpec<Row, Value, Totals>,
) => {
    const totals = spec.totals!
    state.rowIndex += 1
    state.colIndex = 0
    const rowBuilder = sheetDataBuilder.e('row').a('r', state.rowIndex)

    for (const column of columns) {
        state.colIndex += 1
        let content: ExcelValue = ''

        if (state.colIndex === 1) {
            if (column.getExcelTotal) {
                throw new Error('First column should not have a getExcelTotal method')
            }

            if (column.getTotal) {
                throw new Error('First column should not have a getTotal method')
            }

            content = 'Kokku'
        } else if (column.getExcelTotal) {
            const excelTotal = column.getExcelTotal(totals)

            if (excelTotal.type === 'sum-money') {
                content = {
                    value: excelTotal.value,
                    style: { numberFormat: ExcelNumberFormat.money },
                    formula:
                        'SUM(' +
                        getRef(state.colIndex, spec.hasSecondHeader ? 3 : 2) +
                        ':' +
                        getRef(state.colIndex, state.rowIndex - 1) +
                        ')',
                }
            } else {
                throw new Error('Unexpected ExcelTotal type: ' + excelTotal.type)
            }
        } else if (column.getTotal) {
            content = column.getTotal(totals)
        }

        await writeCell(rowBuilder, content, styleManager, state)
    }

    rowBuilder.done()
}

const writeMeta = (worksheetBuilder: XmlBuilder, state: State) => {
    if (state.merges.length) {
        const mergeBuilder = worksheetBuilder.e('mergeCells')

        for (const merge of state.merges) {
            mergeBuilder.e('mergeCell').a('ref', merge).done()
        }

        mergeBuilder.done()
    }

    if (state.numbersAsText.length || state.twoDigitTextYears.length) {
        const ignBuilder = worksheetBuilder.e('ignoredErrors')

        for (const ref of state.numbersAsText) {
            ignBuilder.e('ignoredError').a('sqref', ref).a('numberStoredAsText', 1).done()
        }

        for (const ref of state.twoDigitTextYears) {
            ignBuilder.e('ignoredError').a('sqref', ref).a('twoDigitTextYear', 1).done()
        }

        ignBuilder.done()
    }
}

export const getWorksheetXml = async <Row, Value extends ExcelValue, Totals>(
    spec: ExcelSpec<Row, Value, Totals>,
    styleManager: StyleManager,
) => {
    const { rows, totals, noHeader } = spec
    const columns = spec.columns.filter((column) => !column.hideInExcel)

    const worksheetBuilder = xml('worksheet').a('xmlns', NS.main)
    writeColumns(worksheetBuilder, columns)

    const state: State = {
        rowIndex: 0,
        colIndex: 0,
        merges: [],
        numbersAsText: [],
        twoDigitTextYears: [],
    }

    const sheetDataBuilder = worksheetBuilder.e('sheetData')

    if (!noHeader) {
        await writeHeaderRows(state, sheetDataBuilder, styleManager, columns, spec)
    }

    for (const row of rows) {
        await writeBodyRow(row, state, sheetDataBuilder, styleManager, columns)
    }

    if (totals) {
        await writeTotalsRow(state, sheetDataBuilder, styleManager, columns, spec)
    }

    sheetDataBuilder.done()
    writeMeta(worksheetBuilder, state)
    return worksheetBuilder.done()
}
