import { assert } from '../../util/assert'
import { canSeeCards, canSeeEvents, hasManagePermission, isAdmin, isDev } from '../access'
import { HeaderProps } from '../header'
import { AppView } from '../state'
import { TabMenuProps, TabProps } from '../tab-menu'
import { getEmployeeInfoProps } from './employee-info'

export const getHeaderProps = (view: AppView, activeTab?: string): HeaderProps | undefined => {
    const { session } = view.state

    if (!session.loggedIn || !session.employeeMode) {
        return undefined
    }

    const props: HeaderProps = {
        tabMenu: getTabMenuProps(view, activeTab),
        employeeInfo: getEmployeeInfoProps(view),
    }

    return props
}

const getTabMenuProps = (view: AppView, activeTab?: string): TabMenuProps => {
    const { session } = view.state
    assert(session.loggedIn)

    const { employee, centre } = session

    const centreId = centre ? centre._id : null
    const userIsAdmin = isAdmin(employee)
    const tabs: TabProps[] = []

    if (!centreId && !userIsAdmin) {
        tabs.push(getTabProps('select-centre', 'Vali keskus', activeTab))
    }

    if (canSeeEvents(employee, centreId)) {
        tabs.push(getTabProps('events', 'Info kogumine', activeTab))
        tabs.push(getTabProps('visitor-search', 'Külastaja otsing', activeTab))
    }

    if (canSeeCards(employee, centreId)) {
        tabs.push(getTabProps('cards', 'Juhtumikaardid', activeTab))
    }

    if (centreId || userIsAdmin) {
        tabs.push(getTabProps('stats', 'Statistika', activeTab))
    }

    if (hasManagePermission(employee, centreId) || (userIsAdmin && centreId)) {
        tabs.push(getTabProps('centre/general', 'Keskuse haldamine', activeTab))
    }

    if (userIsAdmin && !centreId) {
        tabs.push(getTabProps('employees', 'Töötajad', activeTab))
        tabs.push(getTabProps('cards', 'Juhtumikaardid', activeTab))
    }

    if (isDev(employee) && !centreId) {
        tabs.push(getTabProps('dev', 'Dev', activeTab))
    }

    return { tabs }
}

const getTabProps = (id: string, name: string, activeTab: string | undefined): TabProps => ({
    reactKey: id,
    className: id === activeTab ? 'active' : '',
    link: {
        url: `#/${id}`,
        text: name,
    },
})
