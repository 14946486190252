import { FC } from 'react'

import { renderButtonGroup } from './button-group'
import { AppView } from './state'

interface Props {
    view: AppView
    active: string
}

export const ManageCentreMenu: FC<Props> = ({ view, active = 'general' }) => (
    <div className="submenu">
        {renderButtonGroup({
            id: 'manage-centre-menu',
            buttons: [
                { value: 'general', label: 'Üldinfo' },
                { value: 'employees', label: 'Töötajad' },
                { value: 'form', label: 'Keskuse ankeet' },
                { value: 'parent-notification', label: 'Lapsevanemale saadetav kiri' },
                { value: 'visitors', label: 'Külastajate nimekiri' },
            ],
            active,
            onClick: (value) => view.navigate(['centre', value]),
        })}
    </div>
)
