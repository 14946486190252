export const download = (url: string, filename: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename

    const { body } = document

    // In Firefox, we need to add the link to the DOM. (In Chrome, we don't need it)
    body.appendChild(link)
    link.click()
    body.removeChild(link)
}
