import { type FC, useEffect } from 'react'

import { EditVisitor, type EditVisitorProps } from './edit-visitor'
import { LoadingIcon } from './loading-icon'
import { SearchBox, type SearchBoxProps } from './search-box'
import {
    SearchPanelExistingItem,
    type SearchPanelExistingItemProps,
} from './search-panel/existing-item'
import { SearchPanelNewItem, type SearchPanelNewItemProps } from './search-panel/new-item'

export interface VisitorSearchPanelProps {
    effect: () => void // TODO refactor
    isLoading: boolean
    searchBox: SearchBoxProps
    header?: string
    exactMatch?: SearchPanelExistingItemProps
    matches: SearchPanelExistingItemProps[]
    moreMatchesNote?: string[]
    newVisitorNote?: string
    newVisitorItem?: SearchPanelNewItemProps
    newVisitorModal?: EditVisitorProps
}

export const VisitorSearchPanel: FC<VisitorSearchPanelProps> = (props) => {
    useEffect(props.effect, [])

    if (props.isLoading) {
        return <LoadingIcon />
    }

    return (
        <div>
            <div style={{ marginBottom: 10 }}>
                <SearchBox {...props.searchBox} />
            </div>
            {props.header && <div style={{ marginTop: 10, marginBottom: 10 }}>{props.header}</div>}
            {props.exactMatch && (
                <>
                    <div className="top-margin bottom-margin">Täpne vaste:</div>
                    <SearchPanelExistingItem {...props.exactMatch} />
                    <div className="top-margin bottom-margin">Kõik vasted:</div>
                </>
            )}
            {props.matches.map((match) => (
                <SearchPanelExistingItem key={match.reactKey} {...match} />
            ))}
            {props.moreMatchesNote && (
                <div className="top-margin bottom-margin">
                    {props.moreMatchesNote.map((note, index) => (
                        <div key={index}>{note}</div>
                    ))}
                </div>
            )}
            {props.newVisitorNote && (
                <div style={{ marginTop: 10, marginBottom: 10 }}>{props.newVisitorNote}</div>
            )}
            {props.newVisitorItem && <SearchPanelNewItem {...props.newVisitorItem} />}
            {props.newVisitorModal && <EditVisitor {...props.newVisitorModal} />}
        </div>
    )
}
