import { type FC } from 'react'

import { Loading } from './loading'
import { VisitorSearchPanel, type VisitorSearchPanelProps } from './visitor-search-panel'
import { VisitorStatsResults, type VisitorStatsResultsProps } from './visitor-stats-results'

export interface VisitorStatsProps {
    isLoading: boolean
    visitorSearchPanel: VisitorSearchPanelProps
    note?: string
    dataLoading?: boolean
    results?: VisitorStatsResultsProps
}

export const VisitorStats: FC<VisitorStatsProps> = (props) => {
    if (props.isLoading) {
        return <Loading />
    }

    return (
        <table className="vtop">
            <tbody>
                <tr>
                    <td className="left-panel">
                        <h2>Vali külastaja</h2>
                        <VisitorSearchPanel {...props.visitorSearchPanel} />
                    </td>
                    <td className="right-panel">
                        {props.note && (
                            <div>
                                <p>{props.note}</p>
                            </div>
                        )}
                        {props.dataLoading && <Loading />}
                        {props.results && <VisitorStatsResults {...props.results} />}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
