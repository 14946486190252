import { FC } from 'react'

import { AgeAnonKey, GenderKey } from '../server/types'
import { LoadingButton } from './loading-button'
import { Table, TableProps } from './table'
import { TextInput, TextInputProps } from './text-input'
import { ValErr, ValErrProps } from './validation'

export interface MeetingAnonymousProps {
    table?: TableProps
    nameInput: TextInputProps
    addRow: () => void
    total: number | null
    onSave: () => Promise<void>
}

export interface AnonymousGroupRow {
    id: string
    nameCell: CellProps
    totalCell: CellProps
    optionCells: Record<GenderKey | AgeAnonKey, CellProps>
    remaining: { gender: number; age: number }
    deleteRow: () => void
}

export interface CellProps {
    input?: CellInputProps
    error?: ValErrProps
}

interface CellInputProps {
    value: string
    onChange: (newValue: string) => void
    isNumeric?: boolean
}

interface AddRowProps {
    withMargin?: boolean
    nameInput: TextInputProps
    addRow: () => void
}

export const MeetingAnonymous: FC<MeetingAnonymousProps> = (props) => (
    <div>
        <h2>Anonüümsed osalejad:</h2>
        {props.table && (
            <div
                style={{
                    border: '1px solid black',
                    padding: 4,
                    display: 'inline-block',
                }}
            >
                <Table {...props.table} />
                <AddRow withMargin nameInput={props.nameInput} addRow={props.addRow} />
            </div>
        )}
        {!props.table && <AddRow nameInput={props.nameInput} addRow={props.addRow} />}
        {props.total && (
            <div style={{ marginTop: 5 }}>
                {'Anonüümseid osalejaid kokku: '}
                {props.total}
            </div>
        )}
        <div style={{ marginTop: 10 }}>
            <LoadingButton text="Salvesta" getPromise={props.onSave} />
        </div>
    </div>
)

export const meetingAnonymousCells = {
    regular: (props: CellProps) => (
        <>
            {props.input && <CellInput {...props.input} />}
            {props.error && <ValErr {...props.error} />}
        </>
    ),
    actions: (row: AnonymousGroupRow) => <button onClick={row.deleteRow}>Kustuta</button>,
}

const CellInput: FC<CellInputProps> = (props) => (
    <input
        value={props.value}
        onChange={(evt) => props.onChange(evt.target.value)}
        style={props.isNumeric ? { width: 50, textAlign: 'center' } : undefined}
    />
)

const AddRow: FC<AddRowProps> = (props) => (
    <div style={props.withMargin ? { margin: 5 } : undefined}>
        <TextInput {...props.nameInput} />
        <button onClick={props.addRow} style={{ marginLeft: 10 }}>
            Lisa uus rida
        </button>
    </div>
)
