// TODO rename file
import * as Sentry from '@sentry/react'
import toastr from 'toastr'

import { AppView } from './state'

const showErrorToast = (_view: AppView | undefined, error: Error, message: string) => {
    let messageToShow = message

    if (error && typeof error === 'object' && 'message' in error) {
        // TODO
        messageToShow = error.message
    }

    // Show uncaught errors on UI
    toastr.error(messageToShow)
    // TODO use view for notifications
}

const handleError = async (view: AppView | undefined, error: Error, message: string) => {
    if (toastr) {
        showErrorToast(view, error, message)
    }

    Sentry.captureException(error)
}

export const handlePromiseRejection = async (
    view: AppView | undefined,
    error: Error,
    preventDefault?: () => void,
) => {
    const message = error.message

    if (
        (typeof error === 'object' && (error as { alreadyHandled?: unknown }).alreadyHandled) ||
        message === 'Error: Handled error - can be ignored' ||
        message === 'Uncaught Error: Handled error - can be ignored'
    ) {
        // We can ignore errors that have already been handled.
        // See error-handler for more information.
        preventDefault?.()
    } else {
        await handleError(view, error, message)
    }
}

export const configureErrorHandling = (view: AppView | undefined) => {
    window.addEventListener('error', async (e) => {
        await handleError(view, e.error, e.message)
    })

    window.addEventListener('unhandledrejection', async (e) => {
        await handlePromiseRejection(view, e.reason, () => e.preventDefault())
    })
}
