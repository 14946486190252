import { FC, useEffect, useRef, useState } from 'react'

import { DatePicker } from './date-picker'

export interface DateFieldProps {
    value: string
    onChange: (value: string) => void
}

const isChildOf = (childNode: Node, possibleParent: Node | null): boolean => {
    if (!possibleParent) {
        return false
    }

    const actualParent = childNode.parentNode

    if (!actualParent) {
        return false
    }

    return actualParent === possibleParent || isChildOf(actualParent, possibleParent)
}

const renderPicker = (isVisible: boolean, value: string, selectDate: (ymd: string) => void) => {
    if (!isVisible) {
        return null
    }

    return <DatePicker value={value} selectDate={selectDate} />
}

export const DateField: FC<DateFieldProps> = (props) => {
    const [pickerIsVisible, setPickerVisible] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const listener = (evt: MouseEvent) => {
            if (!isChildOf(evt.target as any, wrapperRef.current)) {
                setPickerVisible(false)
            }
        }

        document.addEventListener('mousedown', listener)
        return () => document.removeEventListener('mousedown', listener)
    }, [])

    const value = props.value || ''

    const selectDate = async (ymd: string) => {
        props.onChange(ymd)
        setPickerVisible(false)
    }

    return (
        <div className="date" ref={wrapperRef}>
            <input
                value={value}
                onFocus={() => setPickerVisible(true)}
                onChange={async (evt) => selectDate(evt.currentTarget.value)}
                className="form-control date__input"
            />
            {renderPicker(pickerIsVisible, value, selectDate)}
        </div>
    )
}
