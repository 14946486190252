import { AgeAnonKey, County, GenderKey, VisitorLang } from '../server/types'
import { CardAction } from '../util/card-action'
import { CardPhase } from '../util/card-phase'

export type Enum<T extends string> = Record<T, string> & { _order: T[] }
type AgeEnum<T extends string> = Enum<T> & { _minAges: Record<T, number> }

type AgeKey = '0-6' | '7-11' | '12-13' | '14-16' | '17-19' | '20-24' | '25-26' | '27+'

export const cardPhases: Enum<CardPhase> = {
    started2: 'started2',
    ended2: 'ended2',
    started3: 'started3',
    ended3: 'ended3',
    started4: 'started4',
    cancelled: 'cancelled',
    _order: [
        CardPhase.STARTED2,
        CardPhase.ENDED2,
        CardPhase.STARTED3,
        CardPhase.ENDED3,
        CardPhase.STARTED4,
        CardPhase.CANCELLED,
    ],
}

export const visitorLanguages: Enum<VisitorLang> = {
    est: 'eesti',
    rus: 'vene',
    'est-rus': 'eesti ja vene',
    eng: 'inglise',
    ukr: 'ukraina',
    'ukr-rus': 'ukraina ja vene',
    _order: ['est', 'rus', 'est-rus', 'eng', 'ukr', 'ukr-rus'],
}

export const genderOptions: Enum<GenderKey> = {
    m: 'M',
    n: 'N',
    _order: ['m', 'n'],
}

export const ageOptions: AgeEnum<AgeKey> = {
    '0-6': '0-6',
    '7-11': '7-11',
    '12-13': '12-13',
    '14-16': '14-16',
    '17-19': '17-19',
    '20-24': '20-24',
    '25-26': '25-26',
    '27+': '27+',
    _order: ['0-6', '7-11', '12-13', '14-16', '17-19', '20-24', '25-26', '27+'],
    _minAges: {
        '0-6': 0,
        '7-11': 7,
        '12-13': 12,
        '14-16': 14,
        '17-19': 17,
        '20-24': 20,
        '25-26': 25,
        '27+': 27, // Must match Utils.maxAge
    },
}

// The main age options have changed over time, but the options for
// anonymous participants can't be changed as the old data can't be converted.
// Therefore we have two different sets of age options.
export const ageOptionsAnon: AgeEnum<AgeAnonKey> = {
    '0-6': '0-6',
    '7-11': '7-11',
    '12-16': '12-16',
    '17-19': '17-19',
    '20-26': '20-26',
    '27+': '27+',
    _order: ['0-6', '7-11', '12-16', '17-19', '20-26', '27+'],
    _minAges: {
        '0-6': 0,
        '7-11': 7,
        '12-16': 12,
        '17-19': 17,
        '20-26': 20,
        '27+': 27, // Must match Utils.maxAge
    },
}

// TODO share code between client and server
export const counties: Record<County, string> = {
    harju: 'Harjumaa',
    hiiu: 'Hiiumaa',
    'ida-viru': 'Ida-Virumaa',
    jogeva: 'Jõgevamaa',
    jarva: 'Järvamaa',
    laane: 'Läänemaa',
    'laane-viru': 'Lääne-Virumaa',
    polva: 'Põlvamaa',
    parnu: 'Pärnumaa',
    rapla: 'Raplamaa',
    saare: 'Saaremaa',
    tartu: 'Tartumaa',
    valga: 'Valgamaa',
    viljandi: 'Viljandimaa',
    voru: 'Võrumaa',

    yle: 'Üle-eestiline',
    test: '(Test)',
}

export const partitions = {
    tln: 'Osa 1: Tallinna SNA',
    other: 'Osa 2: Ülejäänud Eesti',
}

export const cardPhaseDescriptions: Record<CardPhase, string> = {
    started2: '2. faasis',
    ended2: '2. ja 3. faasi vahel',
    started3: '3. faasis',
    ended3: '3. ja 4. faasi vahel',
    started4: '4. faasis',
    cancelled: 'Katkestanud',
}

export const cardLogMessages: Record<CardAction, string> = {
    start2: 'Alustas 2. faasi',
    end2: 'Lõpetas 2. faasi',
    start3: 'Alustas 3. faasi',
    end3: 'Lõpetas 3. faasi',
    start4: 'Alustas 4. faasi',
    cancel: 'Katkestas programmi',
    resume: 'Jätkas programmi',
}

export const cardActions: Record<CardAction, string> = {
    start2: 'start2',
    end2: 'end2',
    start3: 'start3',
    end3: 'end3',
    start4: 'start4',
    cancel: 'cancel',
    resume: 'resume',
}

export const cardActionToPhase: Record<CardAction, CardPhase> = {
    start2: CardPhase.STARTED2,
    end2: CardPhase.ENDED2,
    start3: CardPhase.STARTED3,
    end3: CardPhase.ENDED3,
    start4: CardPhase.STARTED4,
    cancel: CardPhase.CANCELLED,
    resume: CardPhase.STARTED2,
}

// TODO remove
export const Enums = {
    visitorLanguages,
    genderOptions,
    ageOptions,
    ageOptionsAnon,
    counties,
    partitions,
    cardPhases,
    cardPhaseDescriptions,
    cardLogMessages,
    cardActions,
    cardActionToPhase,
}
