import { CSSProperties, FC } from 'react'

import { VisitorMerge_Meeting, VisitorMergeInfo } from '../server/commands/get-visitor-merge-info'
import { Utils } from './utils'

interface Props {
    label: string
    info: VisitorMergeInfo | null
    overlap: Record<string, true>
}

export const VisitorInfo: FC<Props> = (props) => {
    const renderHasCard = () => {
        if (props.info!.hasCard) {
            return <span style={{ color: 'red', fontWeight: 'bold' }}>Yes</span>
        } else {
            return 'No'
        }
    }

    const renderInfo = () => {
        const info = props.info

        if (!info) {
            return <div style={{ marginTop: 10 }}>(not selected)</div>
        }

        const visitor = info.visitor

        return (
            <div style={{ marginTop: 10 }}>
                <div>
                    <b>{'ID: '}</b>
                    {visitor._id}
                </div>
                <div>
                    <b>{'Username: '}</b>
                    {visitor.username}
                </div>
                <div>
                    <b>{'Name: '}</b>
                    {visitor.firstname} {visitor.lastname}
                </div>
                <div>
                    <b>{'Birth date: '}</b>
                    {Utils.formatPartialDate(visitor.birthDate)}
                </div>
                <div>
                    <b>{'Has card: '}</b>
                    {renderHasCard()}
                </div>
                {renderMeetings()}
            </div>
        )
    }

    const renderMeetings = () => {
        const meetingsMap = props.info!.meetings
        const eventsMap = props.info!.events

        const byCentre: Record<string, Record<string, VisitorMerge_Meeting[]>> = {}

        Utils.iterMap(meetingsMap, (meeting) => {
            const evt = eventsMap[meeting.event]
            const byEvent = Utils.member<Record<string, VisitorMerge_Meeting[]>>(
                byCentre,
                evt.centre,
                {},
            )
            const eventMeetings = Utils.member(byEvent, evt._id, [])
            eventMeetings.push(meeting)
        })

        const centreIds = Object.keys(byCentre)
        centreIds.sort()

        return centreIds.map((centreId) => {
            const byEvent = byCentre[centreId]
            const eventIds = Object.keys(byEvent)

            Utils.sortAsStrings(eventIds, (eventId) => {
                const evt = eventsMap[eventId]
                return evt.name
            })

            return (
                <div key={centreId} style={{ marginTop: 10 }}>
                    <b>{centreId}</b>
                    <ul>
                        {eventIds.map((eventId) => {
                            const eventMeetings = byEvent[eventId]
                            const evt = eventsMap[eventId]
                            Utils.sortAsStrings(
                                eventMeetings,
                                (meeting) => meeting.startDate + '#' + meeting._id,
                            )

                            return eventMeetings.map((meeting) => {
                                const style: CSSProperties = {}

                                if (meeting._id in props.overlap) {
                                    style.color = 'red'
                                }

                                return (
                                    <li key={meeting._id} style={style}>
                                        {evt.name} {meeting.startDate}
                                    </li>
                                )
                            })
                        })}
                    </ul>
                </div>
            )
        })
    }

    const style = {
        display: 'inline-block',
        verticalAlign: 'top',
        width: 'calc(50% - 3px)',
        maxWidth: 400,
        padding: 5,
        border: '1px solid #ddd',
    }

    return (
        <div style={style}>
            <div style={{ fontWeight: 'bold' }}>{props.label}</div>
            {renderInfo()}
        </div>
    )
}
