import { Component, ReactNode } from 'react'

import { LimitedEmployee } from '../server/remove-employee-fields'
import { isAdmin } from './access'
import { API } from './api'
import { bindProps } from './bind-props'
import { EventBus } from './event-bus'
import { LoadingIcon } from './loading-icon'
import { useCustomHandlerDuring } from './request-failure-handler'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'
import {
    checkValidationErrors,
    clearValidationErrors,
    CustomErrorMessage,
    renderValidationError,
    ValidationError,
} from './validation'

export interface EditEmployeeProps {
    view: AppView // TODO refactor props
    isNew?: boolean
    addExisting?: boolean
    employee?: LimitedEmployee // Required if !isNew and !addExisting
    afterSave: (employeeId: string) => Promise<void>
}

interface State {
    processing: boolean
    name?: string
    username?: string
    manage: boolean
    allEvents: boolean
    cards: boolean
    password?: string
    canEditPassword?: boolean
    changingPassword?: boolean
    validationErrors?: Record<string, ValidationError>
}

export class EditEmployee extends Component<EditEmployeeProps, State> {
    unmounted = false

    constructor(props: EditEmployeeProps) {
        super(props)

        if (this.props.isNew) {
            this.state = {
                processing: false,
                name: '',
                username: '',
                manage: false,
                allEvents: true,
                cards: false,
                password: '',
            }
        } else if (this.props.addExisting) {
            this.state = {
                processing: false,
                username: '',
                manage: false,
                allEvents: true,
                cards: false,
            }
        } else {
            const employee = this.props.employee!
            const centreId = Session.getCentre(props.view)!._id
            const permissions = employee.centrePermissions[centreId]

            this.state = {
                processing: false,
                manage: permissions.manage!,
                allEvents: permissions.allEvents!,
                cards: permissions.cards || false,
                canEditPassword: permissions.password,
                changingPassword: false,
                password: '',
            }
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    toggleManage(manage: boolean) {
        if (manage) {
            // allEvents permissions is implied by the 'manage' permission,
            // would not make sense if it was false now.
            this.setState({ manage, allEvents: true })
        } else {
            this.setState({ manage: false })
        }
    }

    async onSave() {
        const { view } = this.props
        this.setState({ processing: true })

        try {
            await clearValidationErrors(view, this)

            const currentEmployee = Session.getEmployee(view)
            const canGrantCardAccess = isAdmin(currentEmployee)

            const manage = this.state.manage
            const allEvents = this.state.allEvents
            const cards = canGrantCardAccess ? this.state.cards : undefined

            let employeeId: string

            if (this.props.isNew) {
                const username = this.state.username!.trim()
                const fullName = this.state.name!.trim()
                const password = this.state.password!.trim()

                const response = await API.addEmployee(
                    view,
                    username,
                    fullName,
                    password,
                    manage,
                    allEvents,
                    cards,
                )

                employeeId = response.id
            } else if (this.props.addExisting) {
                const username = this.state.username!.trim()
                const response = await API.addEmployeeToCentre(
                    view,
                    username,
                    manage,
                    allEvents,
                    cards,
                )
                employeeId = response.id
            } else {
                employeeId = this.props.employee!._id

                const hasCardMessage =
                    'Enne sellelt töötajalt ligipääsu eemaldamist tuleb' +
                    ' tema juhtumikaartidele määrata uued vastutajad.'

                await useCustomHandlerDuring(
                    Utils.getErrorHandler('has-cards', hasCardMessage),
                    async () => {
                        await API.updatePermissions(view, employeeId, manage, allEvents, cards)
                    },
                )

                if (this.state.changingPassword) {
                    const password = this.state.password!.trim()
                    await API.updatePassword(view, employeeId, password)
                }
            }

            await this.props.afterSave(employeeId)
        } catch (error) {
            await checkValidationErrors(view, this, error)
        } finally {
            await Utils.setState(this, { processing: false })
            await EventBus.fire('employee-save-done')
        }
    }

    getManageCustomErrors(): Record<string, CustomErrorMessage> | undefined {
        if (this.props.addExisting) {
            return {
                invalid: (error) =>
                    error.admin ? 'Admin kasutaja peab saama keskust hallata' : null,
            }
        }

        return undefined
    }

    isEditingAdmin() {
        if (this.props.isNew || this.props.addExisting) {
            return false
        } else {
            return isAdmin({ role: this.props.employee!.role! })
        }
    }

    renderPasswordRow(): ReactNode {
        if (this.props.addExisting) {
            return null
        }

        let cellContents: ReactNode

        if (this.props.isNew || this.state.changingPassword) {
            const validationField = this.props.isNew ? 'password' : 'newPassword'

            cellContents = (
                <div>
                    <input {...bindProps(this, 'password', { type: 'password' })} />
                    {renderValidationError(this.state.validationErrors, validationField, {
                        'over-max': 'Parool on liiga pikk',
                    })}
                </div>
            )
        } else if (this.state.canEditPassword) {
            cellContents = (
                <button onClick={() => this.setState({ changingPassword: true })}>
                    Muuda parooli
                </button>
            )
        } else {
            cellContents =
                'See töötaja pole andnud selle keskuse haldajatele õigust oma parooli muuta.'
        }

        return (
            <tr>
                <td style={{ fontWeight: 'bold' }}>Parool</td>
                <td>{cellContents}</td>
            </tr>
        )
    }

    renderNameRow(): ReactNode {
        if (this.props.addExisting) {
            return null
        }

        let nameCell: ReactNode

        if (this.props.isNew) {
            nameCell = (
                <td>
                    <input
                        {...bindProps(this, 'name', {
                            type: 'text',
                            className: 'form-control',
                        })}
                    />
                    {renderValidationError(this.state.validationErrors, 'name')}
                </td>
            )
        } else {
            nameCell = <td>{this.props.employee!.name}</td>
        }

        return (
            <tr>
                <td style={{ fontWeight: 'bold' }}>Nimi</td>
                {nameCell}
            </tr>
        )
    }

    renderUsernameCell(): ReactNode {
        if (this.props.isNew || this.props.addExisting) {
            let customErrorMessages: Record<string, CustomErrorMessage> | undefined
            let placeholder: string | undefined

            if (this.props.isNew) {
                customErrorMessages = {
                    unique: 'See kasutajanimi on juba kasutusel. Palun vali uus.',
                }
            }

            if (this.props.addExisting) {
                placeholder = 'Olemasoleva töötaja kasutajanimi'
                customErrorMessages = {
                    unique: 'See töötaja on juba siin keskuses olemas.',
                }
            }

            return (
                <td>
                    <input
                        {...bindProps(this, 'username', {
                            type: 'text',
                            className: 'form-control',
                            placeholder,
                        })}
                    />
                    {renderValidationError(
                        this.state.validationErrors,
                        'username',
                        customErrorMessages,
                    )}
                </td>
            )
        } else {
            return <td>{this.props.employee!.username}</td>
        }
    }

    renderCardsCheckbox() {
        const { view } = this.props
        const currentEmployee = Session.getEmployee(view)

        if (!isAdmin(currentEmployee)) {
            return null
        }

        return (
            <div>
                <input
                    {...bindProps(this, 'cards', {
                        type: 'checkbox',
                        id: 'chk-cards',
                    })}
                />
                {' Omab ligipääsu juhtumikaartidele'}
                {renderValidationError(this.state.validationErrors, 'cards')}
            </div>
        )
    }

    renderSaveButton() {
        if (this.state.processing) {
            return <LoadingIcon />
        } else {
            return (
                <button id="btn-save-employee" onClick={async () => this.onSave()}>
                    Salvesta
                </button>
            )
        }
    }

    render(): ReactNode {
        return (
            <table className="vtop" style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                <tbody>
                    {this.renderNameRow()}
                    <tr>
                        <td style={{ fontWeight: 'bold' }}>Kasutajanimi</td>
                        {this.renderUsernameCell()}
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold' }}>Ligipääs</td>
                        <td style={{ paddingBottom: 8 }}>
                            <div>
                                <input
                                    type="checkbox"
                                    id="chk-manage"
                                    onChange={(evt) => this.toggleManage(evt.currentTarget.checked)}
                                    checked={this.state.manage}
                                    disabled={this.isEditingAdmin()}
                                />
                                {' Saab hallata keskust'}
                                {renderValidationError(
                                    this.state.validationErrors,
                                    'manage',
                                    this.getManageCustomErrors(),
                                )}
                            </div>
                            <div>
                                <input
                                    {...bindProps(this, 'allEvents', {
                                        type: 'checkbox',
                                        id: 'chk-all-events',
                                        disabled: this.state.manage,
                                    })}
                                />
                                {' Omab ligipääsu kõigile sündmustele'}
                                <div className="input-note">
                                    Kui seda õigust pole, on sellel töötajal ligipääs ainult neile
                                    sündmustele,
                                    {' kus ta on sündmuse detailide all töötajate seas kirjas.'}
                                </div>
                                {renderValidationError(this.state.validationErrors, 'allEvents')}
                            </div>
                            {this.renderCardsCheckbox()}
                        </td>
                    </tr>
                    {this.renderPasswordRow()}
                    <tr>
                        <td />
                        <td style={{ paddingTop: 5 }}>{this.renderSaveButton()}</td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
