import { FC, KeyboardEvent, ReactNode, useEffect } from 'react'
import toastr from 'toastr'

import { API } from './api'
import { handlePromiseRejection } from './common-conf'
import { EventBus } from './event-bus'
import { getInitialLoginState } from './initial-state'
import { LoadingIcon } from './loading-icon'
import { AppView } from './state'
import { TextInput } from './text-input'
import { checkValidationErrors, renderValidationError } from './validation'

interface LoginProps {
    view: AppView // TODO refactor props
}

export const Login: FC<LoginProps> = (props) => {
    const { view } = props
    const { state } = view
    const { login } = state

    useEffect(() => {
        EventBus.fire('login-rendered').catch((err) => handlePromiseRejection(view, err))

        return () => {
            state.login = getInitialLoginState()
            view.update()
        }
    }, [])

    const onKeyUp = (evt: KeyboardEvent) => {
        if (evt.key === 'Enter' || evt.keyCode === 13) {
            void onLogin()
        }
    }

    const onLogin = async () => {
        login.processing = true
        state.validationErrors = {}
        view.update()

        try {
            const { username, password } = state.login
            const response = await API.login(view, username.trim(), password.trim())

            if (response.success) {
                // TODO: if user was redirected to login page from a 401 error, return to that page.
                view.navigate([])
            } else {
                toastr.error('Vale parool või kasutajanimi')
            }
        } catch (error) {
            await checkValidationErrors(view, null, error)
        } finally {
            login.processing = false
            view.update()
        }
    }

    const togglePasswordInfo = () => {
        login.passwordInfoVisible = !login.passwordInfoVisible
        view.update()
    }

    const renderPasswordInfo = () => {
        if (!state.login.passwordInfoVisible) {
            return null
        }

        return (
            <div style={{ margin: 20, fontSize: '90%', textAlign: 'center' }}>
                Kui olete oma parooli kaotanud, palun pöörduge oma noortekeskuse
                <br />
                Logiraamatu haldaja(te) poole, et oma parool ära muuta.
                <br />
                Kui koos keskuse haldajaga ei õnnestu parooli muuta, palun pöörduge Eesti ANK poole.
            </div>
        )
    }

    let buttonOrLoading: ReactNode

    if (state.login.processing) {
        buttonOrLoading = <LoadingIcon />
    } else {
        buttonOrLoading = (
            <button id="login" onClick={async () => onLogin()}>
                Logi sisse
            </button>
        )
    }

    return (
        <div
            style={{
                maxWidth: 700,
                margin: '150px auto',
                border: '2px solid black',
                padding: 10,
            }}
        >
            <div
                style={{
                    fontSize: '140%',
                    textAlign: 'center',
                    marginTop: 30,
                    marginBottom: 20,
                }}
            >
                Teretulemast Eesti noortekeskuste logiraamatusse!
            </div>
            <div
                onKeyUp={onKeyUp}
                className="container"
                style={{ width: 300, marginTop: 30, marginBottom: 10 }}
            >
                <div className="row" style={{ margin: '10px 0' }}>
                    <div className="col-xs-4">Kasutajanimi</div>
                    <div className="col-xs-8">
                        <TextInput
                            value={login.username}
                            onChange={(value) => {
                                login.username = value
                                view.update()
                            }}
                            focusOnMount
                            additional={{
                                id: 'username',
                                autoCapitalize: 'off',
                                style: { border: '2px solid black', padding: '1px 3px' },
                            }}
                        />
                        {renderValidationError(state.validationErrors, 'username')}
                    </div>
                </div>
                <div className="row" style={{ margin: '10px 0' }}>
                    <div className="col-xs-4">Parool</div>
                    <div className="col-xs-8">
                        <TextInput
                            value={login.password}
                            onChange={(value) => {
                                login.password = value
                                view.update()
                            }}
                            type="password"
                            additional={{
                                id: 'password',
                                style: { border: '2px solid black', padding: '1px 3px' },
                            }}
                        />
                        {renderValidationError(state.validationErrors, 'password')}
                    </div>
                </div>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-xs-12 text-center">{buttonOrLoading}</div>
                </div>
            </div>
            <div style={{ marginTop: 30, fontSize: '80%', textAlign: 'center' }}>
                <a onClick={() => togglePasswordInfo()} style={{ cursor: 'pointer' }}>
                    Parool kadunud?
                </a>
            </div>
            {renderPasswordInfo()}
        </div>
    )
}
