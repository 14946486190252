import sha1 from 'js-sha1'
import toastr from 'toastr'

import { AgeAnonKey, AnonymousCountKey, AnonymousGroup, GenderKey } from '../../server/types'
import { filterMapToMap } from '../../util/filter-map-to-map'
import { API } from '../api'
import { Enum, Enums } from '../enums'
import { EventBus } from '../event-bus'
import {
    AnonymousGroupRow,
    CellProps,
    meetingAnonymousCells,
    MeetingAnonymousProps,
} from '../meeting-anonymous'
import { useCustomHandlerDuring } from '../request-failure-handler'
import { AppState, AppView } from '../state'
import { Utils } from '../utils'
import { checkValidationErrors, getValErrProps } from '../validation'
import { reloadMeeting, setMode } from './meeting'
import { Column, getTableProps } from './table'

export const getMeetingAnonymousProps = (view: AppView): MeetingAnonymousProps => {
    const { state } = view
    const { meeting } = state
    const { anonymous } = meeting
    const { data } = anonymous
    const meetingId = state.route[1]

    const props: MeetingAnonymousProps = {
        nameInput: {
            value: anonymous.newGroupName,
            onChange: (newValue) => {
                anonymous.newGroupName = newValue
                view.update()
            },
            focusEventName: 'focus-anonymous-group-name',
            additional: { placeholder: 'Grupi nimetus' },
        },
        addRow: async () => {
            const groupName = anonymous.newGroupName

            if (groupName) {
                const cleanName = Utils.cleanName(groupName)
                const existingIds = data.map((group) => group.id)
                const id = Utils.uniqueId(cleanName, existingIds)
                data.push({ id, name: groupName, total: 0 })
                meeting.anonymous.newGroupName = ''
                view.update()
            } else {
                toastr.warning('Palun sisestage grupi nimetus')
                await EventBus.fire('focus-anonymous-group-name')
            }
        },
        total: null,
        onSave: async () => saveAnonymous(view, meetingId),
    }

    if (data.length) {
        props.total = 0

        for (const group of data) {
            props.total += group.total
        }

        props.table = getTableProps({
            columns: getColumns(),
            rows: data.map((group, index) => getAnonymousGroupRow(view, group, index)),
            className: 'anonymous-participants table table-condensed',
        })
    }

    return props
}

const getAnonymousGroupRow = (
    view: AppView,
    group: AnonymousGroup,
    index: number,
): AnonymousGroupRow => {
    const { state } = view
    const { meeting } = state

    const onChange = (option: AnonymousCountKey, newValue: string) => {
        // TODO: if changes from number to NaN, revert to prev number instead of clearing all
        const { data } = meeting.anonymous
        const groupInData = Utils.findById(data, group.id)!
        groupInData[option] = Number(newValue)
        view.update()
    }

    const getOptionCell = (option: GenderKey | AgeAnonKey): CellProps => ({
        input: {
            value: group[option] ? String(group[option]) : '',
            onChange: (newValue) => onChange(option, newValue),
            isNumeric: true,
        },
    })

    const getRemaining = (options: Array<GenderKey | AgeAnonKey>): number => {
        let remaining = group.total

        for (const option of options) {
            remaining -= group[option] || 0
        }

        return remaining
    }

    return {
        id: group.id,
        nameCell: {
            input: {
                value: group.name,
                onChange: (newValue) => {
                    const { data } = meeting.anonymous
                    const groupInData = Utils.findById(data, group.id)!
                    groupInData.name = newValue
                    view.update()
                },
            },
            error: getValErrProps(state.validationErrors, 'newData.' + index + '.name'),
        },
        totalCell: {
            input: {
                value: group.total ? String(group.total) : '',
                onChange: (newValue) => onChange('total', newValue),
                isNumeric: true,
            },
            error: getValErrProps(state.validationErrors, 'newData.' + index + '.total'),
        },
        optionCells: {
            m: getOptionCell('m'),
            n: getOptionCell('n'),
            '0-6': getOptionCell('0-6'),
            '7-11': getOptionCell('7-11'),
            '12-16': getOptionCell('12-16'),
            '17-19': getOptionCell('17-19'),
            '20-26': getOptionCell('20-26'),
            '27+': getOptionCell('27+'),
        },
        remaining: {
            gender: getRemaining(Enums.genderOptions._order),
            age: getRemaining(Enums.ageOptionsAnon._order),
        },
        deleteRow: () => {
            const { data } = meeting.anonymous
            const ix = Utils.findIndexByField(data, 'id', group.id)
            data.splice(ix, 1)

            // Validation errors are keyed by the positional index, so we clear
            // them when deleting rows to avoid matching errors to wrong rows.
            state.validationErrors = {}

            view.update()
        },
    }
}

const getColumns = (): Column<AnonymousGroupRow>[] => {
    const columns: Column<AnonymousGroupRow>[] = [
        {
            id: 'name',
            header: 'Grupp',
            editHeaderProps: (props) => (props.style = { fontWeight: 'bold' }),
            getContents: (row) => meetingAnonymousCells.regular(row.nameCell),
        },
        {
            id: 'total',
            header: 'Kokku',
            editHeaderProps: (props) => {
                props.colSpan = 2
                props.style = { fontWeight: 'bold' }
            },
            getContents: (row) => meetingAnonymousCells.regular(row.totalCell),
        },
    ]

    const addEnumColumns = <T extends GenderKey | AgeAnonKey>(
        enumId: keyof AnonymousGroupRow['remaining'],
        header: string,
        optionEnum: Enum<T>,
    ) => {
        columns.push({
            id: 'spacer-' + enumId,
            getContents: () => '',
            editCellProps: (props) => (props.style = { width: 20 }),
        })

        for (const [index, option] of optionEnum._order.entries()) {
            const column: Column<AnonymousGroupRow> = {
                id: option,
                secondHeader: optionEnum[option],
                editSecondHeaderProps: (props) => (props.style = { textAlign: 'center' }),
            }

            if (index === 0) {
                column.header = header

                column.editHeaderProps = (props) => {
                    props.colSpan = optionEnum._order.length + 2
                    props.style = { fontWeight: 'bold' }
                }
            }

            column.getContents = (row) => meetingAnonymousCells.regular(row.optionCells[option])
            columns.push(column)
        }

        columns.push({
            id: 'remaining-' + enumId,
            secondHeader: '?',
            editSecondHeaderProps: (props) => (props.style = { textAlign: 'center', width: 40 }),
            getContents: (row) => String(row.remaining[enumId]),
            editCellProps: (props, row) => {
                props.style = { textAlign: 'center' }

                if (row.remaining[enumId] < 0) {
                    props.style.color = 'red'
                }
            },
        })
    }

    addEnumColumns('gender', 'Sooline jaotus', Enums.genderOptions)
    addEnumColumns('age', 'Vanuseline jaotus', Enums.ageOptionsAnon)

    columns.push({
        id: 'actions',
        getContents: meetingAnonymousCells.actions,
    })

    return columns
}

const saveAnonymous = async (view: AppView, meetingId: string) => {
    const { state } = view

    // Filter out zeroes and NaN-s
    const newData = state.meeting.anonymous.data.map((group) => {
        return filterMapToMap(group, (value) => {
            if (typeof value === 'number') {
                return !isNaN(value) && value !== 0
            } else {
                return typeof value === 'string'
            }
        })
    })

    const meeting = state.meeting.meeting!
    const oldHash = getAnonHash(state)

    state.validationErrors = {}
    view.update()

    return useCustomHandlerDuring(
        Utils.getConcurrentEditErrorHandler('kohtumise anonüümsete osalejate'),
        async () => {
            try {
                await API.updateMeetingAnonymous(view, meeting._id, newData, oldHash, meeting.rev)
                await reloadMeeting(view, meetingId, { meeting: true })
                setMode(view, 'regular')
            } catch (error) {
                await checkValidationErrors(view, null, error)
            }
        },
    )
}

const getAnonHash = (state: AppState) => {
    // TODO unduplicate with server
    const data = state.meeting.meeting!.anonymous || []
    const values: Array<string | number> = []

    for (const group of data) {
        if (group.total > 0) {
            values.push(group.id)
            values.push(group.name)
            values.push(group.total)

            const optionArrays = [Enums.genderOptions, Enums.ageOptionsAnon]

            for (const options of optionArrays) {
                for (const option of options._order) {
                    values.push(group[option] || 0)
                }
            }
        }
    }

    const json = JSON.stringify(values)
    return sha1(json)
}
