import { Component } from 'react'
import toastr from 'toastr'

import { VisitorMergeInfo } from '../server/commands/get-visitor-merge-info'
import { API } from './api'
import { getVisitorSearchPanelProps } from './props/visitor-search-panel'
import { AppView } from './state'
import { setState } from './utils'
import { VisitorInfo } from './visitor-info'
import { VisitorSearchPanel } from './visitor-search-panel'
import { getUsername, VisitorMatch } from './visitor-search-utils'

interface VisitorMergeProps {
    view: AppView // TODO refactor props
}

interface State {
    oldInfo: VisitorMergeInfo | null
    newInfo: VisitorMergeInfo | null
}

export class VisitorMerge extends Component<VisitorMergeProps, State> {
    state: State = { oldInfo: null, newInfo: null }
    unmounted = false

    componentWillUnmount() {
        this.unmounted = true
    }

    async selectVisitor(visitor: VisitorMatch) {
        const { view } = this.props
        const username = getUsername(visitor)

        if (!this.state.oldInfo) {
            await setState(this, { oldInfo: await API.getVisitorMergeInfo(view, username) })
        } else if (!this.state.newInfo) {
            await setState(this, { newInfo: await API.getVisitorMergeInfo(view, username) })
        } else {
            toastr.error('Both visitors are set')
        }
    }

    getOverlap() {
        const oldInfo = this.state.oldInfo
        const newInfo = this.state.newInfo
        const overlap: Record<string, true> = {} // TODO Set<string>

        if (oldInfo && newInfo) {
            for (const meetingId of Object.keys(newInfo.meetings)) {
                if (meetingId in oldInfo.meetings) {
                    overlap[meetingId] = true
                }
            }
        }

        return overlap
    }

    hasOverlap() {
        return Object.keys(this.getOverlap()).length > 0
    }

    clear() {
        this.setState({ oldInfo: null, newInfo: null })
    }

    async reload() {
        const { view } = this.props

        const [oldInfo, newInfo] = await Promise.all([
            API.getVisitorMergeInfo(view, this.state.oldInfo!.visitor.username),
            API.getVisitorMergeInfo(view, this.state.newInfo!.visitor.username),
        ])

        this.setState({ oldInfo, newInfo })
    }

    swap() {
        this.setState({ oldInfo: this.state.newInfo, newInfo: this.state.oldInfo })
    }

    async replace() {
        const { view } = this.props

        if (confirm('Confirm')) {
            const oldVisitorId = this.state.oldInfo!.visitor._id
            const newVisitorId = this.state.newInfo!.visitor._id
            await API.replaceVisitorOnMeetings(view, oldVisitorId, newVisitorId)
            await this.reload()
        }
    }

    async delete() {
        const { view } = this.props

        if (confirm('Confirm')) {
            await API.deleteVisitor(view, this.state.oldInfo!.visitor._id)
            this.setState({ oldInfo: null })
        }
    }

    renderClearButton() {
        return (
            <button onClick={() => this.clear()} style={{ marginRight: 5 }}>
                Clear
            </button>
        )
    }

    renderReloadButton() {
        if (this.state.oldInfo && this.state.newInfo) {
            return (
                <button onClick={async () => this.reload()} style={{ marginRight: 5 }}>
                    Reload
                </button>
            )
        } else {
            return null
        }
    }

    renderSwapButton() {
        if (this.state.oldInfo && this.state.newInfo) {
            return (
                <button onClick={() => this.swap()} style={{ marginRight: 5 }}>
                    Swap
                </button>
            )
        } else {
            return null
        }
    }

    renderReplaceButton() {
        const oldInfo = this.state.oldInfo
        const newInfo = this.state.newInfo

        if (
            oldInfo &&
            !oldInfo.hasCard &&
            Object.keys(oldInfo.meetings).length > 0 &&
            newInfo &&
            !this.hasOverlap()
        ) {
            return (
                <button onClick={async () => this.replace()} style={{ marginRight: 5 }}>
                    {'Replace '}
                    {oldInfo.visitor._id}
                    {' with '}
                    {newInfo.visitor._id}
                    {' on meetings'}
                </button>
            )
        } else {
            return null
        }
    }

    renderDeleteButton() {
        const oldInfo = this.state.oldInfo

        if (oldInfo && !oldInfo.hasCard && Object.keys(oldInfo.meetings).length === 0) {
            return (
                <button onClick={async () => this.delete()} style={{ marginRight: 5 }}>
                    {'Delete '}
                    {oldInfo.visitor._id}
                </button>
            )
        } else {
            return null
        }
    }

    renderButtons() {
        return (
            <div style={{ marginBottom: 10 }}>
                {this.renderClearButton()}
                {this.renderReloadButton()}
                {this.renderSwapButton()}
                {this.renderReplaceButton()}
                {this.renderDeleteButton()}
            </div>
        )
    }

    render() {
        const { view } = this.props

        const oldInfo = this.state.oldInfo
        const newInfo = this.state.newInfo
        const overlap = this.getOverlap()

        const visitorSearchPanelProps = getVisitorSearchPanelProps(view, {
            onSelectVisitor: async (visitor) => this.selectVisitor(visitor),
            includeOtherVisitors: true,
        })

        return (
            <table className="vtop">
                <tbody>
                    <tr>
                        <td className="left-panel">
                            <VisitorSearchPanel {...visitorSearchPanelProps} />
                        </td>
                        <td className="right-panel">
                            {this.renderButtons()}
                            <VisitorInfo label="Old" info={oldInfo} overlap={overlap} />{' '}
                            <VisitorInfo label="New" info={newInfo} overlap={overlap} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
