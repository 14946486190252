import {
    CSSProperties,
    FC,
    InputHTMLAttributes,
    OptionHTMLAttributes,
    SelectHTMLAttributes,
    TextareaHTMLAttributes,
} from 'react'

import { ActivityConfig, ActivityConfigProps } from './activity-config'
import { EmployeeInput, EmployeeInputProps } from './employee-input'
import { RadioButton, RadioButtonProps } from './radio-button'
import { TextInput, TextInputProps } from './text-input'
import { ValErr, ValErrProps } from './validation'

export interface EditEventFormProps {
    fields: EventFieldsProps
    activityConfig: ActivityConfigProps
    inUse?: boolean
}

interface Option extends OptionHTMLAttributes<HTMLOptionElement> {
    reactKey: string
}

export interface EventFieldsProps {
    eventName: {
        label: string
        input: TextInputProps
        error?: ValErrProps
    }
    centreName: {
        label: string
        value: string
    }
    category: {
        label: string
        input: SelectHTMLAttributes<HTMLSelectElement>
        options: Option[]
        error?: ValErrProps
    }
    type: {
        label: string
        buttons: RadioButtonProps[]
        error?: ValErrProps
    }
    employees: {
        label: string
        input: EmployeeInputProps
        values: EmployeeValue[]
        error?: ValErrProps
    }
    description: {
        label: string
        input: TextareaHTMLAttributes<HTMLTextAreaElement>
        error?: ValErrProps
    }
    timesNeeded: {
        input: InputHTMLAttributes<HTMLInputElement>
        error?: ValErrProps
    }
}

export interface EmployeeValue {
    reactKey: string
    name: string
    remove: () => void
    isFaded?: boolean
}

export const EditEventForm: FC<EditEventFormProps> = (props) => (
    <>
        <table id="event-form" className="vtop">
            <tbody>
                <tr>
                    <td style={{ paddingRight: 10, borderRight: '1px solid black' }}>
                        <EventFields {...props.fields} />
                    </td>
                    <td style={{ paddingLeft: 10 }}>
                        <ActivityConfig {...props.activityConfig} />
                    </td>
                </tr>
            </tbody>
        </table>
        {props.inUse && (
            <div className="event-locked-note">
                Mõned seaded on lukus, kuna selle sündmuse alla on juba kohtumisi lisatud
            </div>
        )}
    </>
)

const EventFields: FC<EventFieldsProps> = (props) => (
    <table
        className="vtop"
        style={{
            width: 460,
            borderCollapse: 'separate',
            borderSpacing: 5,
        }}
    >
        <tbody>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.eventName.label}</td>
                <td>
                    <TextInput className="form-control" {...props.eventName.input} />
                    {props.eventName.error && <ValErr {...props.eventName.error} />}
                </td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.centreName.label}</td>
                <td>{props.centreName.value}</td>
            </tr>
            <tr>
                <td className="bold">{props.category.label}</td>
                <td>
                    <select className="form-control" {...props.category.input}>
                        {props.category.options.map(({ reactKey, ...option }) => (
                            <option key={reactKey} {...option} />
                        ))}
                    </select>
                    {props.category.error && <ValErr {...props.category.error} />}
                </td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.type.label}</td>
                <td>
                    {props.type.buttons.map((button) => (
                        <RadioButton
                            key={button.reactKey}
                            style={{ display: 'block' }}
                            {...button}
                        />
                    ))}
                    {props.type.error && <ValErr {...props.type.error} />}
                </td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.employees.label}</td>
                <td>
                    <EmployeeInput {...props.employees.input} />
                    <div className="input-note">
                        Uue töötaja lisamiseks leia tema nimi trükkides ja vajuta Enter
                    </div>
                    {props.employees.values.map((value) => {
                        const style: CSSProperties = {}

                        if (value.isFaded) {
                            style.color = '#b0b0b0'
                        }

                        return (
                            <div key={value.reactKey}>
                                <button
                                    className="delete"
                                    onClick={value.remove}
                                    title="Eemalda see töötaja sellelt sündmuselt"
                                />{' '}
                                <span style={style}>{value.name}</span>
                            </div>
                        )
                    })}
                    {props.employees.error && <ValErr {...props.employees.error} />}
                </td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.description.label}</td>
                <td>
                    <textarea className="form-control" {...props.description.input} />
                    {props.description.error && <ValErr {...props.description.error} />}
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <input type="checkbox" {...props.timesNeeded.input} />
                    {' Selle sündmuse puhul on vajalik iga osaleja'}
                    {' saabumis- ja lahkumiskellaaegade märkimine.'}
                    {props.timesNeeded.error && <ValErr {...props.timesNeeded.error} />}
                </td>
            </tr>
        </tbody>
    </table>
)
