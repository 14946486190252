import { type FC } from 'react'

import { Link, type LinkProps } from './link'

export interface StatsTypeChoiceProps {
    links: LinkProps[]
}

export const StatsTypeChoice: FC<StatsTypeChoiceProps> = (props) => (
    <div className="main-panel">
        <div className="bottom-margin">Vali statistika vaade:</div>
        {props.links.map((link) => (
            <div key={link.url}>
                <Link {...link} />
            </div>
        ))}
    </div>
)
