import { download } from './download'

export interface Zipper {
    addFile: (filename: string, data: ArrayBuffer) => void
    generate: () => Promise<void>
}

export type ZipperFactory = (outputFilename: string) => Zipper

const defaultZipperFactory: ZipperFactory = (outputFilename) => {
    const worker = new Worker('js/workers/zip.js')
    let resolvePromise: ((value?: undefined) => void) | null = null

    // Must be called on both success and failure
    const markDone = () => {
        if (resolvePromise) {
            resolvePromise()
            resolvePromise = null
        }

        worker.terminate()
    }

    let anyErrors = false

    worker.onerror = (errEvent) => {
        anyErrors = true
        markDone()

        const error = new Error(errEvent.message)

        // A bit hacky - overwrite the stack to include info from the event
        error.stack =
            'Error: ' +
            errEvent.message +
            '\n' +
            '  at (' +
            errEvent.filename +
            ':' +
            errEvent.lineno +
            ':' +
            errEvent.colno +
            ')'

        throw error
    }

    worker.onmessage = (evt: MessageEvent) => {
        const data = evt.data

        if (data.type === 'error') {
            anyErrors = true
            const error = new Error(data.message)
            error.stack = data.stack
            throw error
        } else if (data.type === 'done') {
            if (anyErrors) {
                return
            }

            const { url } = data
            download(url, outputFilename)
            markDone()
        } else {
            throw new Error('Unrecognized message type: ' + data.type)
        }
    }

    return {
        addFile: (filename, data) => {
            if (!(data instanceof ArrayBuffer)) {
                throw new Error('data must be an ArrayBuffer')
            }

            worker.postMessage({ type: 'addFile', filename, contents: data }, [data])
        },
        generate: async () => {
            if (anyErrors) {
                return
            }

            worker.postMessage({ type: 'generate' })
            return new Promise<undefined>((resolve) => {
                resolvePromise = resolve
            })
        },
    }
}

let zipperFactory: ZipperFactory = defaultZipperFactory

// Export in a wrapper so we can swap out the implementation
export const createZipper: ZipperFactory = (outputFilename) => {
    return zipperFactory(outputFilename)
}

// For tests
export const setZipperFactory = (
    factory: ZipperFactory,
    onReset: (callback: () => void) => void,
) => {
    zipperFactory = factory
    onReset(() => {
        zipperFactory = defaultZipperFactory
    })
}
