import { FC } from 'react'

import { CardsMenu } from './cards-menu'
import { AppView } from './state'

export interface TransferCardsSuccessProps {
    view: AppView // TODO refactor props
}

export const TransferCardsSuccess: FC<TransferCardsSuccessProps> = ({ view }) => (
    <div>
        <CardsMenu view={view} page="transfer" />
        <div className="main-panel">
            <div className="alert alert-success">Kaartide üle tõstmine õnnestus.</div>
            <div>
                <a href="#/cards/transfer">Tagasi</a>
            </div>
        </div>
    </div>
)
