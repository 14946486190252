import { Moment } from 'moment'
import { ReactNode, TdHTMLAttributes, ThHTMLAttributes } from 'react'

import { XmlBuilder } from '../../util/xml'

type HeaderCellProps = ThHTMLAttributes<HTMLTableHeaderCellElement>
type BodyCellProps = TdHTMLAttributes<HTMLTableCellElement>

export type SimpleExcelValue = string | number | Moment | null

export interface WrappedExcelValue {
    value: SimpleExcelValue
    style: ExcelStyle
    formula?: string
}

export type ExcelValue = SimpleExcelValue | WrappedExcelValue
interface ExcelTotal {
    type: 'sum-money'
    value: number
} // More types may be added later

export interface ColumnHeader {
    content?: ReactNode
    excelContent?: ExcelValue
    span?: number
    getProps?: () => HeaderCellProps | null
}

export interface ColumnInput<Row, Value, Totals> {
    header?: ColumnHeader
    secondHeader?: ColumnHeader
    getProps?: (row: Row) => BodyCellProps | null
    renderForBrowser?: (value: Value, row: Row) => ReactNode
    renderForExcel?: (value: Value, row: Row) => ExcelValue
    getTotalProps?: () => BodyCellProps | null
    getTotal?: (totals: Totals) => string | number
    getExcelTotal?: (totals: Totals) => ExcelTotal
    hideInBrowser?: boolean
    hideInExcel?: boolean
    excelWidth?: number
}

export interface Column<Row, Value, Totals = undefined> extends ColumnInput<Row, Value, Totals> {
    getValue: (row: Row) => Value
}

export enum ExcelNumberFormat {
    // Built-in formats
    percentage = 10,
    date = 14,

    // Custom number format ids must be within specific ranges, one of them is 164-382:
    // https://msdn.microsoft.com/en-us/library/dd944946(v=office.12).aspx
    // We use 200+n for ours.

    month = 201,
    money,
    /** Same as money, but uses dash instead of zero */
    moneyDash,
}

// TODO replace hardcoded choices with customizable objects like ExcelStyle?
export enum ExcelFont {
    regular = 0,
    bold = 1,
}

export interface ExcelStyle {
    alignment?: { horizontal?: 'center' | 'right'; vertical?: 'center' }
    font?: ExcelFont
    numberFormat?: ExcelNumberFormat
}

export interface StyleManager {
    getIndex: (style: ExcelStyle) => Promise<number>
    writeXml: (builder: XmlBuilder) => void
}

export interface ExcelSpec<Row, Value = unknown, Totals = any> {
    outputName: string
    columns: Column<Row, Value, Totals>[]
    rows: Row[]
    totals?: Totals
    noHeader?: boolean
    hasSecondHeader?: true
}
