import moment from 'moment'
import { Component } from 'react'

import { EmployeeForCards } from '../server/commands/get-employees-for-cards'
import { VisitorForCards } from '../server/commands/get-visitors-for-cards'
import { BirthDate, Card, CardLogEntry, Centre, County, Field } from '../server/types'
import { CardAction } from '../util/card-action'
import { CardPhase } from '../util/card-phase'
import { API } from './api'
import { CardFields, CentreFormParams } from './card-fields'
import { getCentreDistTypes } from './centre-dist-types'
import { DistType } from './dist-type'
import { Enums } from './enums'
import { EventBus } from './event-bus'
import { expandValue } from './form'
import { Loading } from './loading'
import { RegionId } from './regions'
import { AppView } from './state'
import { DataCollector, renderStatsTable } from './stats-table'
import { getPercentageOption } from './stats-utils'
import { setState, Utils } from './utils'

export interface CardStatsProps {
    view: AppView // TODO refactor props
    dateFrom?: string
    dateTo?: string
    multiCentre: boolean
    centreIds: string[] | null
    centreSelectionDesc?: string // Required if multiCentre = true,
}

type FieldCategory = 'phase2' | 'phase3' | 'phase4'

type CardFields =
    | '_id'
    | 'phase'
    | 'general'
    | FieldCategory
    | 'log'
    | 'unlinked'
    | 'visitorId'
    | 'employeeId'

interface CardStats_Card extends Pick<Card, CardFields> {
    centreId?: string
}

interface Data {
    centre: Centre | null
    card: CardStats_Card
    dateTo: string
}

interface CardStatsDistTypes {
    phase: DistType<Data, CardPhase>
    age: DistType<Data>
    gender: DistType<Data>
    employee: DistType<Data>
    path: DistType<Data>
    program: DistType<Data>
    background: DistType<Data, string, string[]>
    obstacle: DistType<Data, string, string[]>
    goal: DistType<Data>
    resources: DistType<Data, string, string[]>
    prognosis: DistType<Data>
    continue4: DistType<Data, string, string[]>
    continue6: DistType<Data, string, string[]>
    support: DistType<Data, string, string[]>
    collaborators: DistType<Data, string, string[]>
    activity: DistType<Data, string, string[]>
    competences: DistType<Data, string, string[]>
    result: DistType<Data, string, string[]>
    centre?: DistType<Data>
    county?: DistType<Data, County>
    region?: DistType<Data, RegionId>
    duration: DistType<Data>
}

interface State {
    loaded: boolean
    showPercentages: boolean
    cards?: Record<string, CardStats_Card>
    centres?: Record<string, Centre>
    distTypes?: CardStatsDistTypes
}

type Fields = { [C in FieldCategory]: Field[] }

const calculateCardDuration = (log: CardLogEntry[]): number => {
    const logCopy = Utils.clone(log)
    let isoEndTime: string | undefined
    let phase: CardPhase

    while (logCopy.length) {
        const entry = logCopy.shift()

        switch (entry!.action) {
            case CardAction.START2:
                phase = CardPhase.STARTED2
                break
            case CardAction.END2:
                phase = CardPhase.ENDED2
                break
            case CardAction.START3:
                phase = CardPhase.STARTED3
                break
            case CardAction.END3:
                phase = CardPhase.ENDED3
                isoEndTime = entry!.time
                break
            case CardAction.START4:
                phase = CardPhase.STARTED4
                break
            case CardAction.CANCEL:
                if (
                    phase! === CardPhase.STARTED2 ||
                    phase! === CardPhase.ENDED2 ||
                    phase! === CardPhase.STARTED3
                ) {
                    isoEndTime = entry!.time
                }

                phase = CardPhase.CANCELLED
                break
            case CardAction.RESUME:
                isoEndTime = undefined
                phase = CardPhase.STARTED2
                break
            default:
                throw new Error('Invalid card action: ' + entry!.action)
        }
    }

    const startTime = moment(log[0].time)
    const endTime = moment(isoEndTime)
    const duration = moment.duration(endTime.diff(startTime))
    return duration.months()
}

const getSingleChoiceDistType = (
    fields: Fields,
    fieldCategory: FieldCategory,
    fieldId: string,
): DistType<Data> => {
    const field = Utils.findById(fields[fieldCategory], fieldId)!

    const labels: Record<string, string> = {}
    const orderedKeys: string[] = []

    for (const option of field.options!) {
        const key = 'key#' + option.id
        labels[key] = option.label || ''
        orderedKeys.push(key)
    }

    if (field.other) {
        labels['other'] = field.other.label || ''
        orderedKeys.push('other')
    }

    return {
        name: field.label!,
        getKey(data) {
            const catFields = data.card[fieldCategory]

            if (!catFields) {
                return null
            }

            const value = (catFields as any)[fieldId] // TODO

            if (!value) {
                return null
            }

            const expanded = expandValue(field, value)

            if (expanded.key) {
                return 'key#' + expanded.key
            } else if (expanded.other) {
                return 'other'
            } else {
                throw new Error('Neither choice nor other')
            }
        },
        getKeyName(key) {
            if (key && key in labels) {
                return labels[key]
            } else {
                throw new Error('Invalid key: ' + key)
            }
        },
        orderedKeys,
        excelWidth: 40,
    }
}

const getMultipleChoiceDistType = (
    fields: Fields,
    fieldCategory: FieldCategory,
    fieldId: string,
    isConditional: boolean,
): DistType<Data, string, string[]> => {
    const field = Utils.findById(fields[fieldCategory], fieldId)!

    let sortKey = 0
    const labels: Record<string, string> = {}
    const sortKeys: Record<string, number> = {}

    if (isConditional) {
        labels['none'] = field.checkboxLabel || ''
        sortKey += 1
        sortKeys['none'] = sortKey
    }

    const options = isConditional ? field.valueField!.options! : field.options!

    for (const option of options) {
        const key = 'key#' + option.id
        labels[key] = option.label || ''
        sortKey += 1
        sortKeys[key] = sortKey
    }

    return {
        name: field.label!,
        getKey(data) {
            const catFields = data.card[fieldCategory]

            if (!catFields) {
                return []
            }

            const expanded = expandValue(field, (catFields as any)[fieldId]) // TODO

            if (expanded.bool) {
                return ['none']
            } else {
                const choices = isConditional ? expanded.value : expanded
                return choices.map((key: string) => 'key#' + key)
            }
        },
        keyFormat: 'array',
        getKeyName(key) {
            return labels[key]
        },
        getSortKey(key) {
            return sortKeys[key]
        },
        excelWidth: 40,
        hideUnknown: true,
        hideTotal: true,
    }
}

const getMultipleChoiceCheckboxDistType = (
    fields: Fields,
    fieldCategory: FieldCategory,
    fieldId: string,
): DistType<Data, string, string[]> => {
    const field = Utils.findById(fields[fieldCategory], fieldId)!

    let sortKey = 0
    const groupLabels: Record<string, string> = {}
    const labels: Record<string, string> = {}
    const sortKeys: Record<string, number> = {}

    for (const group of field.groups!) {
        groupLabels[group.id] = group.label
    }

    for (const option of field.options!) {
        const groupLabel = groupLabels[option.group!]
        labels[option.id] = groupLabel + ': ' + option.label
        sortKey += 1
        sortKeys[option.id] = sortKey
    }

    return {
        name: field.label!,
        getKey(data) {
            const catFields = data.card[fieldCategory]

            if (!catFields) {
                return []
            }

            const expanded = expandValue(field, (catFields as any)[fieldId]) // TODO
            return expanded.choices.map((choice: any) => choice.key) // TODO
        },
        keyFormat: 'array',
        getKeyName(key) {
            return labels[key]
        },
        getSortKey(key) {
            return sortKeys[key]
        },
        excelWidth: 50,
        hideUnknown: true,
        hideTotal: true,
    }
}

const hasReachedPhase4 = (card: CardStats_Card) => card.phase === Enums.cardPhases.started4

const hasReachedPhase3 = (card: CardStats_Card) =>
    card.phase === Enums.cardPhases.started3 ||
    card.phase === Enums.cardPhases.ended3 ||
    hasReachedPhase4(card)

const getTwoLevelDistType = (
    fields: Fields,
    fieldCategory: FieldCategory,
    fieldId: string,
    isConditional: boolean,
): DistType<Data, string, string[]> => {
    const field = Utils.findById(fields[fieldCategory], fieldId)!

    let sortKey = 0
    const labels: Record<string, string> = {}
    const sortKeys: Record<string, number> = {}

    if (isConditional) {
        labels['none'] = field.checkboxLabel || ''
        sortKey += 1
        sortKeys['none'] = sortKey
    }

    const effectiveField = isConditional ? field.valueField! : field

    for (const option of effectiveField.options!) {
        const mainKey = 'main#' + option.id
        labels[mainKey] = option.label || ''
        sortKey += 1
        sortKeys[mainKey] = sortKey

        if (option.details && option.details.type === 'single-choice') {
            for (const subOption of option.details.options!) {
                const key = 'sub#' + option.id + '#' + subOption.id
                labels[key] = option.label + ': ' + subOption.label
                sortKey += 1
                sortKeys[key] = sortKey
            }
        } else if (
            option.details &&
            (option.details.type === 'multiple-choice' ||
                option.details.type === 'multiple-choice-checkbox')
        ) {
            for (const subOption of option.details.options!) {
                const key = 'sub#' + option.id + '#' + subOption.id
                labels[key] = option.label + ': ' + subOption.label
                sortKey += 1
                sortKeys[key] = sortKey
            }
        }

        if (option.details && option.details.other) {
            const key = 'sub-other#' + option.id
            labels[key] = option.label + ': ' + option.details!.other.label
            sortKey += 1
            sortKeys[key] = sortKey
        }
    }

    if (effectiveField.other) {
        labels['other'] = effectiveField.other.label || ''
        sortKey += 1
        sortKeys['other'] = sortKey
    }

    return {
        name: field.labelForStats || field.label!,
        getKey(data) {
            const catFields = data.card[fieldCategory]

            if (
                !catFields ||
                (fieldCategory === 'phase3' && !hasReachedPhase3(data.card)) ||
                (fieldCategory === 'phase4' && !hasReachedPhase4(data.card))
            ) {
                return []
            }

            const expanded = expandValue(field, (catFields as any)[fieldId]) // TODO

            if (isConditional && expanded.bool) {
                return ['none']
            } else {
                const keys: string[] = []
                const value = isConditional ? expanded.value : expanded

                for (const choice of value.choices) {
                    keys.push('main#' + choice.key)
                    const option = Utils.findById(effectiveField.options!, choice.key)!

                    if (option.details && option.details.type === 'single-choice') {
                        if (choice.details.key) {
                            keys.push('sub#' + choice.key + '#' + choice.details.key)
                        }
                    } else if (option.details && option.details.type === 'multiple-choice') {
                        for (const subChoiceKey of choice.details) {
                            keys.push('sub#' + choice.key + '#' + subChoiceKey)
                        }
                    } else if (
                        option.details &&
                        option.details.type === 'multiple-choice-checkbox'
                    ) {
                        for (const subChoice of choice.details.choices) {
                            keys.push('sub#' + choice.key + '#' + subChoice.key)
                        }
                    }

                    if (choice.details && choice.details.other) {
                        keys.push('sub-other#' + choice.key)
                    }
                }

                if (value.other) {
                    keys.push('other')
                }

                return keys
            }
        },
        keyFormat: 'array',
        getKeyName(key) {
            return labels[key]
        },
        getSortKey(key) {
            return sortKeys[key]
        },
        excelWidth: 60,
        hideUnknown: true,
        hideTotal: true,
    }
}

const getPathDistType = (fields: Fields): DistType<Data> => {
    const field = Utils.findById(fields.phase2, 'teekond')!

    const labels: Record<string, string> = {}
    const orderedKeys: string[] = []

    for (const option of field.options!) {
        if (option.details) {
            if (option.details.type !== 'single-choice') {
                throw new Error('Unexpected detail type')
            }

            for (const subOption of option.details.options!) {
                const key = 'sub#' + option.id + '#' + subOption.id
                labels[key] = option.label + ' (' + subOption.label + ')'
                orderedKeys.push(key)
            }
        } else {
            const key = 'key#' + option.id
            labels[key] = option.label || ''
            orderedKeys.push(key)
        }
    }

    if (field.other) {
        labels['other'] = field.other.label || ''
        orderedKeys.push('other')
    }

    return {
        name: field.label!,
        getKey(data) {
            if (!data.card.phase2) {
                return null
            }

            const value = data.card.phase2.teekond

            if (!value) {
                return null
            }

            const expanded = expandValue(field, value)

            if (expanded.key) {
                const option = Utils.findById(field.options!, expanded.key)!

                if (option.details) {
                    return 'sub#' + expanded.key + '#' + expanded.details.key
                } else {
                    return 'key#' + expanded.key
                }
            } else if (expanded.other) {
                return 'other'
            } else {
                throw new Error('Neither choice nor other')
            }
        },
        getKeyName(key) {
            if (key && key in labels) {
                return labels[key]
            } else {
                throw new Error('Invalid key: ' + key)
            }
        },
        orderedKeys,
        excelWidth: 60,
    }
}

const getSupportDistType = (fields: Fields): DistType<Data, string, string[]> => {
    const field1 = Utils.findById(fields.phase3, 'toetus')!
    const field2 = Utils.findById(fields.phase3, 'toetus-lisa')!

    let sortKey = 0
    const labels: Record<string, string> = {}
    const sortKeys: Record<string, number> = {}

    for (const option of field1.options!) {
        labels[option.id] = option.label || ''
        sortKey += 1
        sortKeys[option.id] = sortKey
    }

    for (const option of field2.options!) {
        labels[option.id] = option.label || ''
        sortKey += 1
        sortKeys[option.id] = sortKey
    }

    return {
        name: field1.label!,
        getKey(data) {
            if (!data.card.phase3 || !hasReachedPhase3(data.card)) {
                return []
            }

            const expanded1 = expandValue(field1, data.card.phase3['toetus'])
            const expanded2 = expandValue(field2, data.card.phase3['toetus-lisa'])
            return expanded1.concat(expanded2)
        },
        keyFormat: 'array',
        getKeyName(key) {
            return labels[key]
        },
        getSortKey(key) {
            return sortKeys[key]
        },
        excelWidth: 40,
        hideUnknown: true,
        hideTotal: true,
    }
}

const getActivityDistType = (fields: Fields): DistType<Data, string, string[]> => {
    const programField = Utils.findById(fields.phase3, 'programm')!
    const labels: Record<string, string> = {}

    for (const option of programField.options!) {
        if (option.group === 'tegevused') {
            labels[option.id] = option.label || ''
        }
    }

    return {
        name: 'Tegevused',
        getKey(data) {
            if (!data.card.phase3 || !hasReachedPhase3(data.card)) {
                return []
            }

            const expanded = expandValue(programField, data.card.phase3['programm'])

            return expanded.choices
                .map((choice: any) => choice.key) // TODO
                .filter((key: string) => key in labels)
        },
        keyFormat: 'array',
        getKeyName(key) {
            return labels[key]
        },
        excelWidth: 40,
        hideUnknown: true,
        hideTotal: true,
    }
}

const getDistTypes = (
    view: AppView,
    visitors: Record<string, VisitorForCards>,
    employees: Record<string, EmployeeForCards>,
    multiCentre: boolean,
    centres: Record<string, Centre>,
) => {
    const centreFormParams: CentreFormParams = { mode: 'all-options' }

    const fields: Fields = {
        phase2: CardFields.getPhase2Fields(centreFormParams),
        phase3: CardFields.getPhase3Fields(centreFormParams),
        phase4: CardFields.getPhase4Fields(),
    }

    const getEmployeeName = (key: string) => {
        const employee = employees[key]

        if (employee) {
            return employee.name
        } else {
            API.logWarning(view, 'Employee not found: ' + key, false)
            return key
        }
    }

    const distTypes: CardStatsDistTypes = {
        phase: {
            name: 'Faas',
            getKey(data) {
                if (data.dateTo) {
                    // Find last action before or on dateTo
                    let lastAction = CardAction.START2

                    for (const entry of data.card.log) {
                        const date = new Date(entry.time)
                        const dateStr = Utils.formatDateYmd(date)

                        if (dateStr <= data.dateTo) {
                            lastAction = entry.action
                        }
                    }

                    return Enums.cardActionToPhase[lastAction]
                } else {
                    return data.card.phase
                }
            },
            getKeyName(phase) {
                return Enums.cardPhaseDescriptions[phase]
            },
            excelWidth: 17,
            orderedKeys: Enums.cardPhases._order,
            hideUnknown: true,
        },
        age: {
            name: 'Vanus',
            getKey(data) {
                let birthDate: BirthDate

                if (data.card.unlinked) {
                    birthDate = data.card.general['syn']!

                    if (!birthDate) {
                        return null
                    }
                } else {
                    const visitor = visitors[data.card.visitorId!]
                    birthDate = visitor.birthDate
                }

                const refDate = data.dateTo ? new Date(data.dateTo) : Utils.getNow()
                const ageObj = Utils.getAgeObj(birthDate, refDate)
                return Utils.getAgeKey(ageObj, false)
            },
            excelWidth: 11,
            orderedKeys: Enums.ageOptions._order,
        },
        gender: {
            name: 'Sugu',
            getKey(data) {
                if (data.card.unlinked) {
                    const gender = data.card.general['sugu']
                    return gender ? gender.key : ''
                } else {
                    const visitor = visitors[data.card.visitorId!]
                    return visitor.gender.toLowerCase()
                }
            },
            getKeyName: (key) => key.toUpperCase(),
            excelWidth: 11,
            orderedKeys: Enums.genderOptions._order,
        },
        employee: {
            name: 'Vastutaja',
            getKey: (data) => data.card.employeeId,
            getKeyName: getEmployeeName,
            getSortKey: getEmployeeName,
            excelWidth: 25,
            hideUnknown: true,
        },
        path: getPathDistType(fields),
        program: getSingleChoiceDistType(fields, 'phase2', 'pohiprogramm'),
        background: getTwoLevelDistType(fields, 'phase2', 'taust', false),
        obstacle: getTwoLevelDistType(fields, 'phase2', 'tunnus', false),
        goal: getSingleChoiceDistType(fields, 'phase2', 'eesmark'),
        resources: getMultipleChoiceDistType(fields, 'phase3', 'ressursid', false),
        prognosis: getSingleChoiceDistType(fields, 'phase2', 'prognoos'),
        continue4: getTwoLevelDistType(fields, 'phase2', '4-kuu-jatk', false),
        continue6: getTwoLevelDistType(fields, 'phase2', '6-kuu-jatk', false),
        support: getSupportDistType(fields),
        collaborators: getMultipleChoiceDistType(fields, 'phase3', 'koostoos', true),
        activity: getActivityDistType(fields),
        competences: getMultipleChoiceCheckboxDistType(fields, 'phase3', 'arend'),
        result: getTwoLevelDistType(fields, 'phase4', 'faas4', true),
        duration: {
            name: 'Teenuses osalemise kestus (kuudes)',
            getKey: ({ card }) => {
                const duration = calculateCardDuration(card.log)
                if (duration < 1) {
                    return '< 1'
                } else if (duration > 6) {
                    return '> 6'
                }

                return duration.toString()
            },
            excelWidth: 10,
            orderedKeys: ['< 1', '1', '2', '3', '4', '5', '6', '> 6'],
            hideTotal: false,
        },
    }

    if (multiCentre) {
        const rowDistTypes = getCentreDistTypes<Data>(centres, false)
        distTypes.centre = rowDistTypes.centre
        distTypes.county = rowDistTypes.county
        distTypes.region = rowDistTypes.region
    }

    return distTypes
}

export class CardStats extends Component<CardStatsProps, State> {
    state: State = { loaded: false, showPercentages: false }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const multiCentre = this.props.multiCentre
        let centres: Record<string, Centre> = {}
        let visitors: Record<string, VisitorForCards>
        let employees: Record<string, EmployeeForCards>
        let cards: Record<string, CardStats_Card>

        if (multiCentre) {
            const cardsResponse = await API.getAllCards(view)
            visitors = cardsResponse.visitors
            employees = cardsResponse.employees
            centres = cardsResponse.centres
            cards = cardsResponse.cards
        } else {
            const cardsResponse = await API.getCentreCards(view, true)
            visitors = cardsResponse.visitors
            employees = cardsResponse.employees
            cards = cardsResponse.cards
        }

        const distTypes = getDistTypes(view, visitors, employees, multiCentre, centres)
        await setState(this, { loaded: true, cards, centres, distTypes })
        await EventBus.fire('card-stats-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    getStartedDate(card: CardStats_Card) {
        let dateStr = ''

        for (const entry of card.log) {
            if (entry.action === 'start2' || entry.action === 'resume') {
                dateStr = entry.time
            }
        }

        return Utils.formatDateYmd(new Date(dateStr))
    }

    collectData(dataCollector: DataCollector<Data>) {
        const multiCentre = this.props.multiCentre

        Utils.filterMap(this.state.cards!, (card) => {
            if (multiCentre && !Utils.arrayContains(this.props.centreIds!, card.centreId)) {
                return false
            }

            if (this.props.dateFrom || this.props.dateTo) {
                const date = this.getStartedDate(card)

                if (this.props.dateFrom && date < this.props.dateFrom) {
                    return false
                }

                if (this.props.dateTo && date > this.props.dateTo) {
                    return false
                }
            }

            return true
        }).forEach((card) => {
            const centre = multiCentre ? this.state.centres![card.centreId || ''] : null
            dataCollector.process({ centre, card, dateTo: this.props.dateTo || '' })
        })
    }

    getExcelFileNamePart() {
        return this.props.centreSelectionDesc!
    }

    renderDateFilterInfo() {
        if (this.props.dateFrom || this.props.dateTo) {
            return (
                <div style={{ marginTop: 10 }}>
                    Sisse on arvestatud juhtumikaardid, mille 2. faasi alguskuupäev (või viimane
                    {' programmi jätkamise kuupäev) jääb valitud vahemikku.'}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const percentageOption = getPercentageOption(this.state.showPercentages, (value) =>
            this.setState({ showPercentages: value }),
        )

        return (
            <div className="main-panel">
                {renderStatsTable<Data, boolean>({
                    distTypes: { ...this.state.distTypes! },
                    initialColDist: 'phase',
                    initialRowDist: this.props.multiCentre ? 'county' : 'age',
                    dropdownForCol: true,
                    dropdownForRow: true,
                    collectData: (dataCollector: DataCollector<Data>) =>
                        this.collectData(dataCollector),
                    getCustomOptions: () => [percentageOption],
                    getExcelFileNamePart: () => this.getExcelFileNamePart(),
                    showPercentages: this.state.showPercentages,
                    showFilters: true,
                })}
                {this.renderDateFilterInfo()}
            </div>
        )
    }
}
