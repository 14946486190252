import { canSeeCards, canSeeEvents, isAdmin } from '../access'
import { EventBus } from '../event-bus'
import type { LinkProps } from '../link'
import { Session } from '../session'
import type { AppView } from '../state'
import type { StatsTypeChoiceProps } from '../stats-type-choice'
import { initIfNeeded } from './init-if-needed'

export const getStatsTypeChoiceProps = (view: AppView): StatsTypeChoiceProps => {
    initIfNeeded(view, 'statsTypeChoice', async () => {
        await EventBus.fire('stats-type-choice-rendered')
    })

    const links: LinkProps[] = []

    const addLink = (value: string, label: string) => {
        links.push({
            text: label,
            url: '#/stats/' + value,
            className: 'stats-link',
        })
    }

    addLink('event-visits', 'Sündmuste külastatavus')
    addLink('event-durations', 'Sündmuste kestus')

    const employee = Session.getEmployee(view)
    const centre = Session.getCentre(view)
    const centreId = centre ? centre._id : null

    if (canSeeEvents(employee, centreId)) {
        addLink('event-categories', 'Sündmuste kestus kategooriate kaupa')
    }

    if (!centreId || canSeeCards(employee, centreId)) {
        addLink('cards', 'Juhtumikaardid')
    }

    if (canSeeEvents(employee, centreId)) {
        addLink('visitor-activity', 'Külastajate aktiivsus')
        addLink('new-visitors', 'Uued külastajad')
    }

    if (isAdmin(employee) && !centreId) {
        addLink('centre-forms', 'Keskuste ankeedid')
        addLink('card-results', 'Juhtumikaartide tulemused')
        addLink('collaborators', 'Koostöövõrgustik')
        addLink('feedback', 'Juhtumikaartide tagasiside')
    }

    return { links }
}
