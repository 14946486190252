import { FC, Ref } from 'react'

import { Button, ButtonProps } from './button'
import { EditEvent, EditEventProps } from './edit-event'
import { EditMeeting, EditMeetingProps } from './edit-meeting'
import { Loading } from './loading'
import { LoadingButton, LoadingButtonProps } from './loading-button'
import { MeetingList, MeetingListProps } from './meeting-list'

export interface EventViewProps {
    reactKey: string
    isLoading: boolean
    eventName: string
    nameRef: Ref<HTMLHeadingElement>
    detailRows: DetailRowProps[]
    archiveButton: ButtonProps
    deleteButton: ButtonProps
    addMeeting: EditMeetingProps
    earlierMeetings?: EarlierMeetingsProps
}

interface DetailRowProps {
    reactKey: string
    label: string
    value: string
    isMultiline?: boolean
    editCell?: EditCellProps
}

export interface EditCellProps {
    rowSpan: number
    openEventModal: () => void
    editEvent?: EditEventProps
}

interface EarlierMeetingsProps {
    list: MeetingListProps
    loadMore?: LoadMoreProps
}

interface LoadMoreProps {
    text: string
    button: LoadingButtonProps
}

export const EventView: FC<EventViewProps> = (props) => {
    if (props.isLoading) {
        return <Loading />
    }

    return (
        <div>
            <h2 ref={props.nameRef}>{'Sündmus: ' + props.eventName}</h2>
            <table className="vtop" style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                <tbody>
                    {props.detailRows.map((row) => (
                        <DetailRow key={row.reactKey} {...row} />
                    ))}
                </tbody>
            </table>
            <div style={{ marginTop: 5 }}>
                <Button {...props.archiveButton} /> <Button {...props.deleteButton} />
            </div>
            <h2>Kohtumised</h2>
            <EditMeeting {...props.addMeeting} />
            {props.earlierMeetings && <EarlierMeetings {...props.earlierMeetings} />}
        </div>
    )
}

const DetailRow: FC<DetailRowProps> = (props) => (
    <tr>
        <td style={{ fontWeight: 'bold' }}>{props.label}</td>
        <td style={{ whiteSpace: props.isMultiline ? 'pre-wrap' : undefined }}>{props.value}</td>
        {props.editCell && <EditCell {...props.editCell} />}
    </tr>
)

const EditCell: FC<EditCellProps> = (props) => (
    <td rowSpan={props.rowSpan} style={{ verticalAlign: 'middle', paddingLeft: 20 }}>
        <button onClick={props.openEventModal} style={{ width: 135, height: 50 }}>
            Muuda sündmuse detaile
        </button>
        {props.editEvent && <EditEvent {...props.editEvent} />}
    </td>
)

const EarlierMeetings: FC<EarlierMeetingsProps> = (props) => (
    <div>
        <div style={{ marginBottom: 5 }}>Varasemad kohtumised:</div>
        <MeetingList {...props.list} />
        {props.loadMore && <LoadMore {...props.loadMore} />}
    </div>
)

const LoadMore: FC<LoadMoreProps> = (props) => (
    <div style={{ marginTop: 5 }}>
        {props.text} <LoadingButton {...props.button} />
    </div>
)
