import { GetCentreVisitors_ApiVisitor, ManagedVisitorExt } from './api'

export type VisitorMatch = GetCentreVisitors_ApiVisitor | string

export const isString = (match: VisitorMatch): match is string => {
    return typeof match === 'string'
}

export const isApiVisitor = (match: VisitorMatch): match is GetCentreVisitors_ApiVisitor => {
    return typeof match === 'object'
}

export const isManagedVisitorExt = (match: VisitorMatch): match is ManagedVisitorExt => {
    return isApiVisitor(match) && 'firstname' in match && 'lastname' in match && match.currentCentre
}

export const getUsername = (match: VisitorMatch) => (isString(match) ? match : match.username)
