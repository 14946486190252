import { Component } from 'react'

import {
    CurrentCentreInfo,
    OtherCentreInfo,
    VisitorRequestInfo,
} from '../server/commands/get-visitor-request-info'
import { API } from './api'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { LoadingButton } from './loading-button'
import { AppView } from './state'
import { Utils } from './utils'

export interface TransferProps {
    view: AppView // TODO refactor props
    reactKey?: string
    visitorId: string
}

interface State {
    loaded: boolean
    info?: VisitorRequestInfo
    requestSaved?: boolean
}

export class Transfer extends Component<TransferProps, State> {
    state: State = { loaded: false }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const info = await API.getVisitorRequestInfo(view, this.props.visitorId)
        await Utils.setState(this, { loaded: true, info })
        await EventBus.fire('transfer-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async requestVisitor() {
        const { view } = this.props
        await Utils.setState(this, { loaded: false })
        await API.requestVisitor(view, this.props.visitorId)
        await Utils.setState(this, { loaded: true, requestSaved: true })
        await EventBus.fire('visitor-requested')
    }

    async transferVisitor(centreId: string) {
        const { view } = this.props
        await Utils.setState(this, { loaded: false })
        await API.transferVisitor(view, this.props.visitorId, centreId)
        const info = await API.getVisitorRequestInfo(view, this.props.visitorId)
        await Utils.setState(this, { loaded: true, info })
        await EventBus.fire('visitor-transferred')
    }

    renderLink() {
        const url = 'https://logiraamat.ank.ee/#/transfer/' + this.props.visitorId
        return <a href={url}>{url}</a>
    }

    renderRespondView(info: CurrentCentreInfo) {
        return (
            <div className="main-panel">
                <p>
                    {'Hetkel haldab teie keskus külastaja kontot kasutajanimega '}
                    <b>{info.username}</b>.
                </p>
                <p>
                    See annab teie keskuse töötajatele ainuõiguse näha sellel kontol olevaid
                    isikuandmeid ning siduda seda kontot juhtumikaardiga.
                </p>
                {this.renderRespondOptions(info)}
            </div>
        )
    }

    renderRespondOptions(info: CurrentCentreInfo) {
        if (info.hasCard) {
            return (
                <p>
                    Kuna see külastaja konto on seotud juhtumikaardiga, pole selle haldamist
                    võimalik teistele keskustele üle anda.
                </p>
            )
        }

        const count = info.requests.length

        if (count < 1) {
            return (
                <div>
                    <p>Ükski teine keskus pole avaldanud soovi selle konto haldamist üle võtta.</p>
                    <p>
                        Kui soovite konto haldamist mõnele teisele keskusele üle anda, peab teine
                        keskus esmalt vastavat soovi avaldama.
                    </p>
                    <p>
                        Selleks peab mõni teise keskuse töötaja Logiraamatusse sisse logima ning
                        avama selle lingi: {this.renderLink()}
                    </p>
                </div>
            )
        }

        return (
            <div>
                <p>
                    {count === 1 ? 'Alloleva nupuga' : 'Allolevate nuppudega'}
                    {' saate selle konto haldamise üle anda teisele keskusele,'}
                    {' mille töötajad on avaldanud vastavat soovi.'}
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    NB! Igal külastaja kontol saab olla ainult üks haldav keskus.
                    <br />
                    Pärast haldamise üle andmist pole teie keskus enam selle konto haldaja.
                </p>
                {info.requests.map((request) => (
                    <p key={request.centreId}>
                        {
                            // TODO: option for denying request?
                            <LoadingButton
                                text={'Anna konto haldamine üle keskusele ' + request.centreName}
                                getPromise={async () => this.transferVisitor(request.centreId)}
                            />
                        }
                    </p>
                ))}
            </div>
        )
    }

    renderRequestView(info: OtherCentreInfo) {
        return (
            <div className="main-panel">
                <p>
                    {'Külastaja kontot kasutajanimega '}
                    <b>{info.username}</b>
                    {' haldab hetkel keskus '}
                    <b>{info.centreName}</b>.
                </p>
                <p>
                    Kuna teie keskus pole selle konto haldaja, pole teil õigust näha sellel kontol
                    olevaid isikuandmeid ega siduda seda kontot juhtumikaardiga.
                </p>
                {this.renderRequestOptions(info)}
            </div>
        )
    }

    renderRequestOptions(info: OtherCentreInfo) {
        if (info.hasCard) {
            return (
                <p>
                    Kuna see külastaja konto on seotud juhtumikaardiga, pole selle haldamist
                    võimalik teistelt keskustelt üle võtta.
                </p>
            )
        } else if (info.alreadyRequested) {
            return (
                <div>
                    <p>
                        Teie keskus on teisele keskusele juba avaldanud soovi selle konto haldamine
                        üle võtta, kuid ükski teise keskuse töötaja pole seda soovi veel täitnud.
                    </p>
                    {this.renderTransferInfo()}
                </div>
            )
        } else if (this.state.requestSaved) {
            return (
                <div>
                    <p>Haldamise soov on nüüd avaldatud.</p>
                    {this.renderTransferInfo()}
                </div>
            )
        } else {
            return (
                <div>
                    <a onClick={async () => this.requestVisitor()} style={{ cursor: 'pointer' }}>
                        Klikkige siia, et avaldada soovi selle konto haldamine üle võtta.
                    </a>
                </div>
            )
        }
    }

    renderTransferInfo() {
        return (
            <div>
                <p>
                    Teie soovi täitmiseks peab mõni teise keskuse töötaja Logiraamatusse sisse
                    logima ning avama selle lingi: {this.renderLink()}
                </p>
                <p style={{ fontWeight: 'bold' }}>
                    NB! Teise keskuse töötajaid ei teavitata teie soovist automaatselt.
                    <br />
                    Peate nendega ise Logiraamatu väliselt ühendust võtma, et nad teaksid teie
                    sooviga tegeleda.
                </p>
            </div>
        )
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const info = this.state.info!

        if (info.managedByCurrentCentre) {
            return this.renderRespondView(info)
        } else {
            return this.renderRequestView(info)
        }
    }
}
