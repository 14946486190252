import { SearchPanelNewItemProps } from '../search-panel/new-item'
import { AppView } from '../state'

// TODO derive other params from view?
export const getAddEventButtonProps = (view: AppView, search?: string, categoryId?: string) => {
    const { state, update } = view

    const props: SearchPanelNewItemProps = {
        label: 'Lisa uus sündmus',
        search,
        onClick: async () => {
            state.event.visibleModalId = 'new-event'
            state.event.initialCategoryId = categoryId
            update()
        },
    }

    return props
}
