import classnames from 'classnames'
import { Component } from 'react'

import { GetAvailableCentres_Centre } from '../server/commands/get-available-centres'
import { API } from './api'
import { CardsMenu } from './cards-menu'
import { renderDropdown } from './dropdown'
import { Loading } from './loading'
import { AppView } from './state'
import { Utils } from './utils'

interface TransferCardsProps {
    view: AppView
}

interface State {
    loaded: boolean
    origin: string
    destination: string
    centres?: GetAvailableCentres_Centre[]
}

export class TransferCards extends Component<TransferCardsProps, State> {
    state: State = { loaded: false, origin: '', destination: '' }

    async componentDidMount() {
        const { view } = this.props
        const result = await API.getAvailableCentres(view)
        Utils.sortAsStrings(result, (centre) => centre.name.toLowerCase())
        this.setState({ loaded: true, centres: result })
    }

    areValidCentres() {
        const orig = this.state.origin
        const destination = this.state.destination
        return orig && destination && orig !== destination
    }

    getMessage() {
        const orig = this.state.origin
        const destination = this.state.destination

        if (!orig && !destination) {
            return 'Keskused on valimata'
        } else if (!orig) {
            return 'Algkeskus on valimata'
        } else if (!destination) {
            return 'Lõppkeskus on valimata'
        } else if (orig === destination) {
            return 'Alg- ja lõppkeskus ei tohi olla samad'
        } else {
            return undefined
        }
    }

    renderOriginSelect() {
        const { state } = this

        return renderDropdown({
            options: [
                { id: '', label: 'Algkeskus', additional: { className: 'hint' } },
                ...state.centres!.map((centre) => {
                    const disabled = centre._id === state.destination
                    return {
                        id: centre._id,
                        label: centre.name,
                        additional: { disabled },
                    }
                }),
            ],
            value: state.origin,
            onChange: (origin) => this.setState({ origin }),
            additional: { className: 'form-control bottom-margin' },
        })
    }

    renderDestinationSelect() {
        const { state } = this

        return renderDropdown({
            options: [
                { id: '', label: 'Lõppkeskus', additional: { className: 'hint' } },
                ...state.centres!.map((centre) => {
                    const disabled = centre._id === state.origin
                    return {
                        id: centre._id,
                        label: centre.name,
                        additional: { disabled },
                    }
                }),
            ],
            value: state.destination,
            onChange: (destination) => this.setState({ destination }),
            additional: { className: 'form-control bottom-margin' },
        })
    }

    routeToNextPage() {
        const { view } = this.props
        const state = this.state
        view.navigate(['cards', 'transfer', state.origin, state.destination])
    }

    renderButton() {
        const disabled = !this.areValidCentres()
        const className = classnames({ disabled: disabled })

        return (
            <button
                className={className}
                disabled={!this.areValidCentres()}
                onClick={() => this.routeToNextPage()}
                title={this.getMessage()}
            >
                Edasi
            </button>
        )
    }

    renderMain() {
        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div className="main-panel">
                <h2>Juhtumikaartide üle tõstmine</h2>
                {this.renderOriginSelect()}
                {this.renderDestinationSelect()}
                {this.renderButton()}
            </div>
        )
    }

    render() {
        const { view } = this.props

        return (
            <div>
                <CardsMenu view={view} page="transfer" />
                {this.renderMain()}
            </div>
        )
    }
}
