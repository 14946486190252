import { CSSProperties, FC, ReactNode } from 'react'

export interface RadioButtonProps {
    reactKey?: string
    checked: boolean
    onSelect: () => void
    label: string
    className?: string
    style?: CSSProperties
    children?: ReactNode
    inputId?: string
}

export const RadioButton: FC<RadioButtonProps> = (props) => (
    <label className={props.className} style={props.style}>
        <input type="radio" checked={props.checked} onChange={props.onSelect} id={props.inputId} />{' '}
        {props.label}
        {props.children && <> {props.children}</>}
    </label>
)
