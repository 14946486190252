import { FC, ReactNode } from 'react'

import {
    EventDurationStats_Event,
    EventDurationStats_Meeting,
} from '../server/commands/get-event-duration-stats-data'
import { EventCategory } from '../server/types'
import { useCentreEventCategories, useEventDurationStatsData } from './hooks/event-hooks'
import { LoadingIcon } from './loading-icon'
import { AppView } from './state'
import { Utils } from './utils'

export interface EventCategoryStatsProps {
    view: AppView // TODO refactor props
    dateFrom?: string
    dateTo?: string
    includeArchived: boolean
}

interface RowData {
    categoryName: string
    duration: number
    unknownCount: number
}

type Rows = Map<string | undefined, RowData>

const initRowData = (categoryName: string) => ({
    categoryName,
    duration: 0,
    unknownCount: 0,
})

const calculateDuration = (meeting: EventDurationStats_Meeting): number | undefined => {
    const sameDay = meeting.endDate === '' || meeting.endDate === meeting.startDate

    if (!meeting.startTime || !meeting.endTime) {
        return undefined
    }

    if (sameDay && meeting.startTime > meeting.endTime) {
        throw new Error(
            'Start time cannot be after end time: ' +
                meeting.startTime +
                '-' +
                meeting.endTime +
                ' on ' +
                meeting._id,
        )
    }

    if (!sameDay) {
        if (meeting.startDate > meeting.endDate!) {
            throw new Error(
                'Start date cannot be after end time: ' +
                    meeting.startDate +
                    ' - ' +
                    meeting.endDate +
                    ' on ' +
                    meeting._id,
            )
        }

        const start = new Date(meeting.startDate).getTime()
        const end = new Date(meeting.endDate!).getTime()

        // Divide milliseconds by 60000 to get minutes
        return (end - start) / 60000
    }

    const startMin = Utils.minutesSinceMidnight(meeting.startTime)
    const endMin = Utils.minutesSinceMidnight(meeting.endTime)
    return endMin - startMin
}

const buildRows = (
    categories: EventCategory[],
    events: Record<string, EventDurationStats_Event>,
    meetings: EventDurationStats_Meeting[],
    includeArchived: boolean,
) => {
    const rows: Rows = new Map()
    rows.set(undefined, initRowData('Kategooriata'))
    categories.forEach((cat) => rows.set(cat._id, initRowData(cat.name)))

    meetings.forEach((meeting) => {
        const eventId = meeting.event
        const event = events[eventId]

        if (event.archived && !includeArchived) {
            return
        }

        const duration = calculateDuration(meeting)
        const categoryId = event.category
        const rowData = rows.get(categoryId)

        if (!rowData) {
            throw new Error('No category found with id ' + categoryId)
        }

        if (duration !== undefined) {
            rowData.duration += duration
        } else {
            rowData.unknownCount += 1
        }
    })

    return rows
}

const renderDuration = (durationMinutes: number) => {
    const fullHours = Math.floor(durationMinutes / 60)
    const remainingMinutes = durationMinutes % 60

    let minutesText = ''

    if (remainingMinutes) {
        minutesText = ' ja ' + Utils.pluralize(remainingMinutes, 'minut', 'minutit')
    }

    return Utils.pluralize(fullHours, 'tund', 'tundi') + minutesText
}

const renderUnknownCount = (count: number) => {
    if (count <= 0) {
        return null
    }

    return Utils.pluralize(count, 'määramata kestusega kohtumine', 'määramata kestusega kohtumist')
}

const renderRow = (
    { categoryName, duration, unknownCount }: RowData,
    categoryId: string | undefined,
) => {
    const nameElement = categoryId ? categoryName : <i>{categoryName}</i>

    return (
        <tr key={categoryId || ''}>
            <td>{nameElement}</td>
            <td className="text-right">{renderDuration(duration)}</td>
            <td>{renderUnknownCount(unknownCount)}</td>
        </tr>
    )
}

const renderRows = (rows: Rows) => {
    const tableRows: ReactNode[] = []

    rows.forEach((row, catId) => {
        tableRows.push(renderRow(row, catId))
    })

    return tableRows
}

export const EventCategoryStats: FC<EventCategoryStatsProps> = ({
    view,
    dateFrom,
    dateTo,
    includeArchived,
}) => {
    const [data] = useEventDurationStatsData(view)
    const [cats] = useCentreEventCategories(view)

    if (!data || !cats) {
        return <LoadingIcon />
    }

    const meetings = Utils.filterMeetingsByDate(data.meetings, dateFrom, dateTo)

    if (!meetings.length) {
        return <div className="main-panel">Valitud perioodil pole toimunud ühtegi kohtumist.</div>
    }

    const rows = buildRows(cats, data.events, meetings, includeArchived)

    return (
        <div className="main-panel">
            <div className="bottom-margin">
                {
                    'Kokku on arvestatud ainult nende kohtumiste kestused, millel on määratud nii algus- kui '
                }
                lõpukellaajad.
                <br />
                Ülejäänud kohtumiste arv on näidatud "Lisainfo" tulbas.
            </div>
            <table className="bordered">
                <thead>
                    <tr>
                        <th>Kategooria</th>
                        <th>Kestus</th>
                        <th>Lisainfo</th>
                    </tr>
                </thead>
                <tbody>{renderRows(rows)}</tbody>
            </table>
        </div>
    )
}
