import { API } from './api'
import { AppView } from './state'

interface Data {
    visitorUsernames: string[] | null
}

const timeOut = 3600000 // 1 hour

const data: Data = {
    visitorUsernames: null,
}

let promise: Promise<void> | null = null

let testMode = false
const interval = setInterval(() => (data.visitorUsernames = null), timeOut)

export const setCacheTestMode = () => {
    clearInterval(interval)
    testMode = true
}

const loadVisitorUsernames = async (view: AppView) => {
    data.visitorUsernames = await API.getVisitorUsernames(view)
}

export const Cache = {
    async getVisitorUsernames(view: AppView) {
        if (promise) {
            // Avoid starting a new request if one is already in progress
            await promise
        }

        if (!data.visitorUsernames || testMode) {
            promise = loadVisitorUsernames(view)
            await promise
            promise = null
        }

        return data.visitorUsernames!
    },
}
