import { FC, useState } from 'react'
import toastr from 'toastr'

import { MeetingVisitor } from '../server/commands/get-meeting-visitors'
import { API } from './api'
import { EditVisitor } from './edit-visitor'
import { useManagedVisitors } from './hooks/visitor-hooks'
import { LoadingIcon } from './loading-icon'
import { getEditVisitorProps } from './props/edit-visitor'
import { getVisitorSearchPanelProps } from './props/visitor-search-panel'
import { AppView } from './state'
import { VisitorSearchPanel } from './visitor-search-panel'
import { isApiVisitor, VisitorMatch } from './visitor-search-utils'

interface Props {
    view: AppView // TODO refactor props
}

export const VisitorSearch: FC<Props> = ({ view }) => {
    const { state } = view
    const { editVisitor } = state

    const [visitors, loadVisitors] = useManagedVisitors(view)
    const [visitor, setVisitor] = useState<MeetingVisitor>()

    const selectVisitor = async (selectedVisitor: VisitorMatch) => {
        if (!isApiVisitor(selectedVisitor)) {
            throw new Error(
                'Invalid selected visitor type. Only visitors with type GetCentreVisitors_ApiVisitor are ' +
                    'allowed.',
            )
        }

        const { _id, username } = selectedVisitor

        const {
            rev,
            birthDate,
            firstname,
            lastname,
            gender,
            lang,
            langOther,
            schoolType,
            school,
            working,
            email,
            parentEmail,
            ownPhone,
            country,
            area,
            settlement,
            notes,
            managingCentreId,
            createdTime,
        } = await API.getVisitor(view, _id)

        const meetingVisitor: MeetingVisitor = {
            _id,
            rev,
            username,
            birthDate,
            firstname,
            lastname,
            gender,
            lang,
            langOther,
            schoolType,
            school,
            working,
            email,
            parentEmail,
            ownPhone,
            country,
            area,
            settlement,
            notes,
            managingCentreId,
            createdTime,
        }

        editVisitor.editModalVisible = true
        view.update()
        setVisitor(meetingVisitor)
    }

    const renderSearchPanel = () => {
        if (!visitors) {
            return <LoadingIcon />
        }

        const visitorSearchPanelProps = getVisitorSearchPanelProps(view, {
            primaryVisitors: visitors,
            includeOtherVisitors: false,
            clearAfterSelection: true,
            onSelectVisitor: selectVisitor,
            getHeader: () => 'Alusta kasutajanime trükkimist, et leida külastaja.',
        })

        return <VisitorSearchPanel {...visitorSearchPanelProps} />
    }

    const renderEditVisitorModal = () => {
        if (!visitor || !state.editVisitor.editModalVisible) {
            return null
        }

        return (
            <EditVisitor
                {...getEditVisitorProps(view, {
                    close: async () => {
                        editVisitor.editModalVisible = false
                        view.update()
                        setVisitor(undefined)
                    },
                    isNew: false,
                    visitor,
                    afterSave: async () => {
                        toastr.success('Kasutaja salvestatud')
                        await loadVisitors()
                    },
                })}
            />
        )
    }

    return (
        <table className="vtop">
            <tbody>
                <tr>
                    <td className="left-panel">
                        <h2>Otsi külastajat</h2>
                        {renderSearchPanel()}
                    </td>
                    <td className="right-panel">
                        Sisesta vasakule otsingukasti külastaja ees- perekonna- või kasutajanimi.
                        Tulemustena kuvatakse ainult selle keskuse hallatavad külastajad.
                        {renderEditVisitorModal()}
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
