import sha1 from 'js-sha1'
import toastr from 'toastr'

import { MeetingVisitor } from '../../server/commands/get-meeting-visitors'
import {
    Activity,
    AgeAnonKey,
    AnonymousGroup,
    GenderKey,
    Meeting as TMeeting,
} from '../../server/types'
import { assert } from '../../util/assert'
import { assertNever } from '../../util/assert-never'
import { clone } from '../../util/clone'
import { API } from '../api'
import { ButtonProps } from '../button'
import { AnonDistProps, AnonSummaryProps, EmployeeModeMeetingProps } from '../employee-mode-meeting'
import { Enum, Enums } from '../enums'
import { EventBus } from '../event-bus'
import { getConfirmation } from '../get-confirmation'
import { getInitialMeetingState } from '../initial-state'
import { MainPanelProps, MeetingProps, RegularProps } from '../meeting'
import { MeetingDetailsProps } from '../meeting-details'
import { ParticipantModeMeetingProps } from '../participant-mode-meeting'
import { queuePromise } from '../promise-queue'
import { useCustomHandlerDuring } from '../request-failure-handler'
import { Session } from '../session'
import { AppView, MeetingState } from '../state'
import { Utils } from '../utils'
import { checkValidationErrors, clearValidationErrors, getValErrProps } from '../validation'
import { getAddParticipantPanelProps } from './add-participant-panel'
import { getEditActivityProps } from './edit-activity'
import { getEditMeetingModalProps } from './edit-meeting-modal'
import { getEmployeeInfoProps } from './employee-info'
import { initIfNeeded } from './init-if-needed'
import { getMeetingAnonymousProps } from './meeting-anonymous'
import { getParticipantTableProps } from './participant-table'

// TODO refactor
export interface Reloaded {
    loaded: boolean
    meeting?: MeetingState['meeting']
    visitors?: MeetingState['visitors']
    centreVisitors?: MeetingState['centreVisitors']
    logEntries?: MeetingState['logEntries']
    employees?: MeetingState['employees']
    extendedEmployees?: MeetingState['extendedEmployees']
}

interface ReloadTypes {
    meeting?: true
    logEntries?: true
    employees?: true
    skipStateUpdate?: true
}

export const getMeetingProps = (view: AppView) => {
    const { state } = view
    const { meeting } = state
    const meetingId = state.route[1]

    initIfNeeded(view, `meetingView:${meetingId}`, async ({ isMounted, setCleanup }) => {
        const reload = async () => fullReload(view, meetingId, isMounted)
        const subscription = EventBus.on('user-context-updated', reload)

        setCleanup(() => {
            subscription.remove()
            state.meeting = getInitialMeetingState()
        })

        await reload() // Initial load
    })

    const props: MeetingProps = {}

    if (meeting.meetingNotFound) {
        props.mainPanel = {
            notFound: {
                backUrl: '#/events',
            },
        }
    }

    if (!meeting.loaded || !meeting.event) {
        props.isLoading = true
        return props
    }

    const { mode } = meeting

    if (mode === 'regular') {
        props.regular = getRegularProps(view, meetingId)
    } else {
        const mainPanel: MainPanelProps = {
            onClickBack: () => setMode(view, 'regular'),
        }

        if (mode === 'anon') {
            mainPanel.anonymous = getMeetingAnonymousProps(view)
        } else if (mode === 'notes') {
            mainPanel.notes = {
                meeting: meeting.meeting!,
                onSave: async (newNotes) => saveNotes(view, meetingId, newNotes),
            }
        } else {
            throw assertNever(mode, 'meeting mode')
        }

        props.mainPanel = mainPanel
    }

    return props
}

export const fullReload = async (view: AppView, meetingId: string, isMounted: () => boolean) => {
    const { meeting } = view.state

    const reloaded = await reloadMeeting(view, meetingId, {
        meeting: true,
        logEntries: true,
        employees: true,
        skipStateUpdate: true,
    })

    const eventId = reloaded.meeting!.event

    const [evt, centreVisitors] = await Promise.all([
        API.getEvent(view, eventId),
        API.getCentreVisitors(view),
    ])

    if (!isMounted()) {
        return
    }

    applyReloaded(meeting, reloaded)
    meeting.event = evt
    meeting.centreVisitors = centreVisitors
    view.update()

    await EventBus.fire('meeting-rendered')
}

export const getRegularProps = (view: AppView, meetingId: string) => {
    const { state } = view
    const { meeting: meetingState } = state

    const employeeMode = Session.isEmployeeMode(view)

    const props: RegularProps = {
        hasTopBorder: !employeeMode,
        visitorSearchPanel: getAddParticipantPanelProps(view, meetingId),
    }

    if (employeeMode) {
        props.employeeModeMeeting = getEmployeeModeMeetingProps(view, meetingId)
    } else {
        props.participantModeMeeting = getParticipantModeMeetingProps(view, meetingId)
    }

    if (meetingState.activityModalConf) {
        props.activityModal = getEditActivityProps(view)
    }

    return props
}

export const saveNotes = async (view: AppView, meetingId: string, newNotes: string) => {
    const { state } = view

    const meeting = state.meeting.meeting!
    const oldHash = sha1(meeting.notes || '')

    return useCustomHandlerDuring(
        Utils.getConcurrentEditErrorHandler('kohtumise märkmete'),
        async () => {
            await API.updateMeetingNotes(view, meeting._id, newNotes, oldHash, meeting.rev)
            await reloadMeeting(view, meetingId, { meeting: true })
            setMode(view, 'regular')
        },
    )
}

const getEmployeeModeMeetingProps = (
    view: AppView,
    meetingId: string,
): EmployeeModeMeetingProps => {
    const { state, update } = view
    const { meeting: meetingState } = state

    const meeting = meetingState.meeting
    const evt = meetingState.event
    assert(meeting && evt)

    const deleteButton: ButtonProps = {
        text: 'Kustuta kohtumine',
        className: 'delete-meeting-button',
    }

    if (meeting.participations.length) {
        deleteButton.className += ' disabled'

        deleteButton.additional = {
            disabled: true,
            title: 'Kohtumise kustutamiseks tuleb kõigepealt eemaldada selle alt kõik osalejad',
        }
    } else {
        deleteButton.isLoading = meetingState.isDeleting

        deleteButton.onClick = async () => {
            if (
                !(await getConfirmation('Kas olete kindel, et soovite seda kohtumist kustutada?'))
            ) {
                return
            }

            meetingState.isDeleting = true
            update()

            try {
                await API.deleteMeeting(view, meetingId)
                view.navigate(['events', evt._id])
            } finally {
                meetingState.isDeleting = false
                update()
            }
        }
    }

    // Normalize data
    const data = (meeting.anonymous || []).filter((group) => group.total > 0)
    const hasAnon = data.length > 0

    const notes = meeting.notes
    const hasNotes = typeof notes === 'string' && notes !== ''

    const props: EmployeeModeMeetingProps = {
        modeButton: {
            isLoading: meetingState.isChangingMode,
            text: 'Mine osaleja režiimi',
            id: 'participant-mode', // TODO remove?
            onClick: async () => {
                meetingState.isChangingMode = true
                update()

                try {
                    await API.enterParticipantMode(view, meetingId)
                    meetingState.showFullDetails = false
                } finally {
                    meetingState.isChangingMode = false
                    update()
                }
            },
        },
        deleteButton,
        participantTable: getParticipantTableProps(view, {
            onRemoveParticipation: async (participationId) => {
                return onRemoveParticipation(view, meetingId, participationId)
            },
            reloadMeeting: async () => {
                await reloadMeeting(view, meetingId, { meeting: true })
            },
            // Reloading meeting also triggers visitor reload
            reloadVisitors: async () => {
                await reloadMeeting(view, meetingId, { meeting: true })
            },
        }),
        endMeeting: {
            event: evt,
            participations: meeting.participations,
            onEndMeeting: async () => onEndMeeting(view, meetingId),
        },
        anonButton: {
            text: hasAnon ? 'Muuda andmeid' : 'Lisa anonüümsete osalejate info',
            onClick: async () => setMode(view, 'anon'),
        },
        notes,
        notesButton: {
            text: hasNotes
                ? 'Muuda märkmeid ja/või refleksiooni'
                : 'Lisa kohtumisele märkmed ja refleksioon',
            onClick: async () => setMode(view, 'notes'),
        },
        meetingLog: {
            view,
            entries: meetingState.logEntries,
            employees: meetingState.employees!,
            extendedEmployees: meetingState.extendedEmployees!,
        },
    }

    if (meeting) {
        props.meetingDetails = getMeetingDetailsProps(view, meetingId)
    }

    if (hasAnon) {
        const sumProps: AnonSummaryProps = {
            groups: [],
            total: 0,
        }

        for (const group of data) {
            sumProps.total += group.total

            const dists: AnonDistProps[] = []

            const genderDist = getAnonDistProps(group, Enums.genderOptions, 'Sooline')

            if (genderDist) {
                dists.push(genderDist)
            }

            const ageDist = getAnonDistProps(group, Enums.ageOptionsAnon, 'Vanuseline')

            if (ageDist) {
                dists.push(ageDist)
            }

            sumProps.groups.push({
                reactKey: group.id,
                header: `${group.name} (${group.total})`,
                dists,
            })
        }
    }

    return props
}

const getParticipantModeMeetingProps = (
    view: AppView,
    meetingId: string,
): ParticipantModeMeetingProps => {
    const { state, update } = view
    const { meeting: meetingState } = state

    const enterEmployeeMode = async () => {
        meetingState.isChangingMode = true
        update()

        await clearValidationErrors(view)

        try {
            const result = await API.enterEmployeeMode(view, meetingState.password.trim())

            if (result.success) {
                meetingState.password = ''
            } else {
                toastr.error('Vale parool')
            }
        } catch (error) {
            await checkValidationErrors(view, null, error)
        } finally {
            meetingState.isChangingMode = false
            update()
        }
    }

    const props: ParticipantModeMeetingProps = {
        employeeInfo: getEmployeeInfoProps(view),
        isChangingMode: Boolean(meetingState.isChangingMode),
        password: {
            label: 'Parool',
            input: {
                value: meetingState.password,
                onChange: (value) => {
                    state.meeting.password = value
                    update()
                },
                onEnter: enterEmployeeMode,
                id: 'password',
                type: 'password',
                style: { width: 120 },
            },
            error: getValErrProps(state.validationErrors, 'password'),
        },
        modeButton: {
            text: 'Mine töötaja režiimi',
            onClick: enterEmployeeMode,
            id: 'employee-mode',
            additional: {
                disabled: state.meeting.isChangingMode,
            },
            className: state.meeting.isChangingMode ? 'disabled' : '',
        },
        participantTable: getParticipantTableProps(view, {
            onLeave: async (participationId) => onLeave(view, meetingId, participationId),
        }),
    }

    if (meetingState.meeting) {
        props.meetingDetails = getMeetingDetailsProps(view, meetingId)
    }

    return props
}

const getMeetingDetailsProps = (view: AppView, meetingId: string): MeetingDetailsProps => {
    const { state, update } = view
    const { meeting: meetingState } = state

    const evt = meetingState.event

    if (!evt) {
        throw new Error('Event not provided for meeting ' + meetingId)
    }

    const meeting = meetingState.meeting!
    const centre = Session.getCentre(view)!

    const props: MeetingDetailsProps = {
        location: meeting.externalLocation || centre.name,
        meetingName: meeting.name,
        time: Utils.formatTimeRange(
            meeting.startDate,
            meeting.startTime,
            meeting.endDate,
            meeting.endTime,
        ),
    }

    const employeeMode = Session.isEmployeeMode(view)

    if (employeeMode) {
        props.eventLink = {
            text: evt.name,
            url: `#/events/${evt._id}`,
        }

        props.editMeeting = {
            button: {
                children: 'Muuda kohtumise detaile',
                style: { width: 135, height: 50 },
                onClick: () => {
                    state.meeting.modalVisible = true

                    state.meetingForm = {
                        loaded: false,
                        startDate: meeting.startDate,
                        startTime: meeting.startTime || '',
                        endDate: meeting.endDate || '',
                        endTime: meeting.endTime || '',
                        name: meeting.name || '',
                        locationType: meeting.externalLocation ? 'external' : 'centre',
                        externalLocation: meeting.externalLocation || '',
                        activityConf: Utils.clone(meeting.activityConf) ?? { mode: 'none' },
                        program: meeting.program || '',
                        collaborator: meeting.collaborator || [],
                        openMode: 'employee',
                        activityChoiceText: '',
                    }

                    update()
                },
            },
        }

        if (state.meeting.modalVisible) {
            props.editMeeting.modal = getEditMeetingModalProps(view, evt, 'Kohtumine', {
                meeting,
                event: evt,
                afterUpdate: async () => reloadMeeting(view, meetingId, { meeting: true }),
            })
        }
    } else {
        props.eventName = evt.name
    }

    return props
}

export const setMode = (view: AppView, mode: MeetingState['mode']) => {
    const { state } = view
    const { meeting } = state

    meeting.mode = mode
    state.validationErrors = {}

    if (mode === 'anon') {
        meeting.anonymous.data = clone(meeting.meeting!.anonymous) || []
    }

    view.update()
}

const onRemoveParticipation = async (view: AppView, meetingId: string, participationId: number) => {
    const { state } = view
    state.meeting.queriesRunning += 1

    try {
        await API.removeParticipation(view, meetingId, participationId)
    } finally {
        await reloadAfterLastQuery(view, meetingId)
    }
}

const onEndMeeting = async (view: AppView, meetingId: string) => {
    await API.endMeeting(view, meetingId)
    await reloadMeeting(view, meetingId, { meeting: true, logEntries: true })
}

export const setActivity = async (
    view: AppView,
    meetingId: string,
    participationId: number,
    activity: Activity,
) => {
    await API.setActivity(view, meetingId, participationId, activity)
    await reloadMeeting(view, meetingId, { meeting: true, logEntries: true })
}

const getAnonDistProps = <T extends GenderKey | AgeAnonKey>(
    group: AnonymousGroup,
    options: Enum<T>,
    label: string,
) => {
    let unknown = group.total

    const distProps: AnonDistProps = {
        reactKey: label,
        prefix: `${label} jaotus - `,
        items: [],
    }

    for (const option of options._order) {
        const count = group[option]

        if (typeof count === 'number' && count > 0) {
            distProps.items.push({
                reactKey: option,
                hasSeparator: distProps.items.length > 0,
                label: options[option],
                value: count,
            })

            unknown -= count
        }
    }

    if (!distProps.items.length) {
        return null
    }

    if (unknown > 0) {
        distProps.items.push({
            reactKey: 'unknown',
            hasSeparator: true,
            label: 'Teadmata',
            value: unknown,
        })
    }

    return distProps
}

const onLeave = async (view: AppView, meetingId: string, participationId: number) => {
    const { state } = view
    state.meeting.queriesRunning += 1

    try {
        await queuePromise(async () => API.leaveMeeting(view, meetingId, participationId))
    } finally {
        await reloadAfterLastQuery(view, meetingId)
    }
}

export const reloadMeeting = async (view: AppView, meetingId: string, types: ReloadTypes) => {
    let meetingPromise: Promise<TMeeting | undefined> | undefined

    if (types.meeting) {
        meetingPromise = loadMeeting(view, meetingId)
    }

    const employeeMode = Session.isEmployeeMode(view)

    let visitorsPromise: Promise<Record<string, MeetingVisitor>> | undefined

    if (types.meeting) {
        if (employeeMode) {
            visitorsPromise = API.getMeetingVisitors(view, meetingId)
        } else {
            visitorsPromise = API.getMeetingVisitors(view)
        }
    }

    const centreVisitorsPromise = types.meeting ? API.getCentreVisitors(view) : null

    const logEntriesPromise =
        types.logEntries && employeeMode ? API.getLogEntries(view, meetingId) : undefined

    const employeesPromise = types.employees && employeeMode ? API.getEmployees(view) : undefined

    const extendedEmployeesPromise =
        types.employees && employeeMode ? API.getExtendedEmployees(view, meetingId) : undefined

    const reloaded: Reloaded = { loaded: true }

    if (types.meeting) {
        reloaded.meeting = await meetingPromise
        reloaded.visitors = await visitorsPromise

        const centreVisitors = await centreVisitorsPromise

        if (centreVisitors) {
            reloaded.centreVisitors = centreVisitors
        }
    }

    if (types.logEntries) {
        reloaded.logEntries = await logEntriesPromise
    }

    if (types.employees) {
        reloaded.employees = await employeesPromise
        reloaded.extendedEmployees = await extendedEmployeesPromise
    }

    if (!types.skipStateUpdate) {
        applyReloaded(view.state.meeting, reloaded)
        view.update()
    }

    await EventBus.fire('meeting-reloaded')
    return reloaded
}

const applyReloaded = (state: MeetingState, reloaded: Reloaded) => {
    state.loaded = reloaded.loaded

    if (reloaded.meeting) {
        state.meeting = reloaded.meeting
    }

    if (reloaded.visitors) {
        state.visitors = reloaded.visitors
    }

    if (reloaded.centreVisitors) {
        state.centreVisitors = reloaded.centreVisitors
    }

    if (reloaded.logEntries) {
        state.logEntries = reloaded.logEntries
    }

    if (reloaded.employees) {
        state.employees = reloaded.employees
    }

    if (reloaded.extendedEmployees) {
        state.extendedEmployees = reloaded.extendedEmployees
    }
}

const reloadAfterLastQuery = async (view: AppView, meetingId: string) => {
    const { meeting } = view.state
    meeting.queriesRunning -= 1

    // With multiple concurrent queries, only reload after the last one
    if (meeting.queriesRunning < 1) {
        return reloadMeeting(view, meetingId, { meeting: true, logEntries: true })
    } else {
        return null
    }
}

const loadMeeting = async (view: AppView, meetingId: string) => {
    const { meeting } = view.state

    try {
        return API.getMeeting(view, meetingId)
    } catch (error) {
        if (error.status === 404) {
            // This occasionally happens - users return to an old tab after a meeting is deleted.
            // Show a more user-friendly error.
            meeting.meetingNotFound = true
            view.update()
            return undefined
        } else {
            throw error
        }
    }
}
