import { isValid, parse } from 'date-fns'
import { et } from 'date-fns/locale'
import { FC, useState } from 'react'
import { DayPicker } from 'react-day-picker'

import { Utils } from './utils'

interface Props {
    value: string
    selectDate: (ymd: string) => void
}

export const DatePicker: FC<Props> = ({ value, selectDate }) => {
    // TODO keyboard navigation?

    const selectedDate = parse(value, 'yyyy-MM-dd', new Date())

    const [shownMonth, setShownMonth] = useState(() => {
        return isValid(selectedDate) ? selectedDate : Utils.getNow()
    })

    return (
        <div className="date__picker">
            <DayPicker
                selected={isValid(selectedDate) ? selectedDate : []}
                month={shownMonth}
                onMonthChange={(month: Date) => setShownMonth(month)}
                onDayClick={(value: Date) => selectDate(Utils.formatDateYmd(value))}
                locale={et}
                captionLayout="dropdown-buttons"
                fromYear={shownMonth.getFullYear() - 25}
                toYear={shownMonth.getFullYear() + 25}
                showOutsideDays
            />
        </div>
    )
}
