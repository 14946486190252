import { CSSProperties, FC, TextareaHTMLAttributes } from 'react'

export interface TextAreaProps {
    value: string
    onChange: (value: string) => void
    style?: CSSProperties
    additional?: TextareaHTMLAttributes<HTMLTextAreaElement>
}

export const TextArea: FC<TextAreaProps> = (props) => {
    return (
        <textarea
            value={props.value}
            onChange={(evt) => props.onChange(evt.currentTarget.value)}
            style={props.style}
            {...props.additional}
        />
    )
}
