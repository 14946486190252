import { ButtonHTMLAttributes, CSSProperties, FC } from 'react'

import { LoadingIcon } from './loading-icon'

export interface ButtonProps {
    isLoading?: boolean
    text: string
    onClick?: () => Promise<void> | void
    id?: string
    className?: string
    style?: CSSProperties
    additional?: ButtonHTMLAttributes<HTMLButtonElement>
}

export const Button: FC<ButtonProps> = (props) => {
    if (props.isLoading) {
        return <LoadingIcon />
    }

    return (
        <button
            id={props.id}
            onClick={props.onClick}
            className={props.className}
            style={props.style}
            {...props.additional}
        >
            {props.text}
        </button>
    )
}
