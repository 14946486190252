import classnames from 'classnames'
import { FC } from 'react'

import { Event } from '../../server/types'
import { IndeterminateCheckbox, IndeterminateCheckboxState } from '../indeterminate-checkbox'
import { Utils } from '../utils'

interface Props {
    categoryName?: string
    events: Event[]
    searchString: string
    selectedEventIds: string[]
    onSelectionChange: (eventIds: string[], shouldReload: boolean) => void
}

const toggleEventSelected = (
    id: string,
    isSelected: boolean,
    selectedEventIds: string[],
    onSelectionChange: (ids: string[], shouldReload: boolean) => void,
) => {
    const newSelectedEventIds = Utils.clone(selectedEventIds)

    if (isSelected) {
        newSelectedEventIds.push(id)
    } else {
        const idx = newSelectedEventIds.indexOf(id)
        newSelectedEventIds.splice(idx, 1)
    }

    onSelectionChange(newSelectedEventIds, newSelectedEventIds.length < 2)
}

const determineCategoryCheckboxState = (
    events: Event[],
    selectedEventIds: string[],
): IndeterminateCheckboxState => {
    const allSelected = events.every((evt) => selectedEventIds.some((id) => id === evt._id))

    if (allSelected) {
        return IndeterminateCheckboxState.checked
    }

    const someSelected = events.some((evt) => selectedEventIds.some((id) => id === evt._id))

    if (someSelected) {
        return IndeterminateCheckboxState.indeterminate
    }

    return IndeterminateCheckboxState.unchecked
}

export const EventStatsCategorySelection: FC<Props> = (props) => {
    const { categoryName, events, searchString, selectedEventIds, onSelectionChange } = props
    const categoryCheckboxState = determineCategoryCheckboxState(events, selectedEventIds)
    const categoryCheckboxClassName = classnames('bold', {
        italic: !categoryName,
    })

    return (
        <div>
            <IndeterminateCheckbox
                checked={categoryCheckboxState}
                onClick={() => {
                    const newSelectedEventIds = Utils.clone(selectedEventIds)

                    if (categoryCheckboxState === IndeterminateCheckboxState.unchecked) {
                        events.forEach((evt) => newSelectedEventIds.push(evt._id))
                    } else if (categoryCheckboxState === IndeterminateCheckboxState.indeterminate) {
                        events.forEach((evt) => {
                            if (!newSelectedEventIds.find((id) => id === evt._id)) {
                                newSelectedEventIds.push(evt._id)
                            }
                        })
                    } else if (categoryCheckboxState === IndeterminateCheckboxState.checked) {
                        events.forEach((evt) => {
                            const idx = newSelectedEventIds.indexOf(evt._id)
                            newSelectedEventIds.splice(idx, 1)
                        })
                    } else {
                        throw new Error(
                            'Invalid indeterminate checkbox state: ' + categoryCheckboxState,
                        )
                    }

                    onSelectionChange(newSelectedEventIds, newSelectedEventIds.length < 2)
                }}
                className={categoryCheckboxClassName}
            >
                {categoryName || 'Kategooriata'}
            </IndeterminateCheckbox>
            {events.map((evt) => {
                const evtCheckboxId = 'checkbox-evt-' + evt._id
                const checked = Utils.arrayContains(selectedEventIds, evt._id)

                return (
                    <div key={evt._id} className="left-margin">
                        <input
                            id={evtCheckboxId}
                            type="checkbox"
                            checked={checked}
                            onChange={(e) =>
                                toggleEventSelected(
                                    evt._id,
                                    e.target.checked,
                                    selectedEventIds,
                                    onSelectionChange,
                                )
                            }
                        />{' '}
                        <label htmlFor={evtCheckboxId}>
                            {Utils.highlight(evt.name, searchString)}
                        </label>
                    </div>
                )
            })}
        </div>
    )
}
