import { FC } from 'react'

import { Meeting } from '../server/types'
import { ActivityConfig, ActivityConfigProps } from './activity-config'
import { DateField, DateFieldProps } from './date-field'
import { Dropdown, DropdownProps } from './dropdown'
import { MultiCheckbox, MultiCheckboxProps } from './multi-checkbox'
import { RadioButton, RadioButtonProps } from './radio-button'
import { TextInput, TextInputProps } from './text-input'
import { ValErr, ValErrProps } from './validation'

export interface EditMeetingFormProps {
    fields: MeetingFieldsProps
    activityConfig?: ActivityConfigProps
}

export interface MeetingFieldsProps {
    eventName: string
    locationType: {
        label: string
        buttons: {
            centre: RadioButtonProps
            external: RadioButtonProps
        }
        other: TextInputProps
        error?: ValErrProps
    }
    name: {
        label: string
        input: TextInputProps
    }
    startDate: {
        input: DateFieldProps
        error?: ValErrProps
    }
    startTime: {
        input: TextInputProps
        error?: ValErrProps
    }
    endDate: {
        input: DateFieldProps
        error?: ValErrProps
    }
    endTime: {
        input: TextInputProps
        error?: ValErrProps
    }
    program?: {
        label: string
        input: DropdownProps<Meeting['program']>
        error?: ValErrProps
    }
    collaborator?: {
        label: string
        input: MultiCheckboxProps
        error?: ValErrProps
    }
    mode?: {
        label: string
        participant: {
            button: RadioButtonProps
            note: string
        }
        employee: {
            button: RadioButtonProps
            note: string
        }
    }
}

export const EditMeetingForm: FC<EditMeetingFormProps> = (props) => {
    const fields = <MeetingFields {...props.fields} />

    if (props.activityConfig) {
        return (
            <table className="vtop">
                <tbody>
                    <tr>
                        <td style={{ paddingRight: 10, borderRight: '1px solid black' }}>
                            {fields}
                        </td>
                        <td style={{ paddingLeft: 10 }}>
                            <ActivityConfig {...props.activityConfig} />
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    } else {
        return fields
    }
}

const MeetingFields: FC<MeetingFieldsProps> = (props) => (
    <table className="vtop meeting-left" style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
        <tbody>
            <tr>
                <td style={{ fontWeight: 'bold', width: 90 }}>Sündmus</td>
                <td>{props.eventName}</td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.locationType.label}</td>
                <td>
                    <RadioButton
                        style={{ display: 'block' }}
                        {...props.locationType.buttons.centre}
                    />
                    <RadioButton
                        className="form-inline"
                        style={{ display: 'block' }}
                        {...props.locationType.buttons.external}
                    >
                        <TextInput
                            className="form-control"
                            style={{ width: 250 }}
                            {...props.locationType.other}
                        />
                    </RadioButton>
                    {props.locationType.error && <ValErr {...props.locationType.error} />}
                </td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>{props.name.label}</td>
                <td>
                    <TextInput className="form-control" {...props.name.input} />
                    <div className="input-note">Võib jätta tühjaks</div>
                </td>
            </tr>
            {
                // TODO: more convenient way of entering times?
                <tr>
                    <td style={{ fontWeight: 'bold' }}>Algus</td>
                    <td className="form-inline">
                        <div
                            id="start-date"
                            style={{ display: 'inline-block', verticalAlign: 'top' }}
                        >
                            <DateField {...props.startDate.input} />
                            {props.startDate.error && <ValErr {...props.startDate.error} />}
                        </div>{' '}
                        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                            <TextInput
                                className="form-control"
                                style={{ width: 60 }}
                                {...props.startTime.input}
                            />
                            {props.startTime.error && <ValErr {...props.startTime.error} />}
                        </div>
                    </td>
                </tr>
            }
            <tr>
                <td style={{ fontWeight: 'bold' }}>Lõpp</td>
                <td className="form-inline">
                    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                        <DateField {...props.endDate.input} />
                        {props.endDate.error && <ValErr {...props.endDate.error} />}
                    </div>{' '}
                    <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                        <TextInput
                            className="form-control"
                            style={{ width: 60 }}
                            {...props.endTime.input}
                        />
                        {props.endTime.error && <ValErr {...props.endTime.error} />}
                    </div>
                </td>
            </tr>
            {props.program && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.program.label}</td>
                    <td>
                        <Dropdown {...props.program.input} />
                        {props.program.error && <ValErr {...props.program.error} />}
                        <div className="input-note">Võib jätta tühjaks</div>
                    </td>
                </tr>
            )}
            {props.collaborator && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>Koostööd tehti</td>
                    <td>
                        <MultiCheckbox {...props.collaborator.input} />
                        {props.collaborator.error && <ValErr {...props.collaborator.error} />}
                        <div className="input-note" style={{ marginTop: 5 }}>
                            Võib valida mitu või jätta tühjaks
                        </div>
                    </td>
                </tr>
            )}
            {props.mode && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.mode.label}</td>
                    <td>
                        <RadioButton
                            style={{ display: 'block' }}
                            {...props.mode.participant.button}
                        >
                            <div className="input-note-medium">{props.mode.participant.note}</div>
                        </RadioButton>
                        <RadioButton style={{ display: 'block' }} {...props.mode.employee.button}>
                            <div className="input-note-medium">{props.mode.employee.note}</div>
                        </RadioButton>
                    </td>
                </tr>
            )}
        </tbody>
    </table>
)
