import { Component, ReactNode } from 'react'

import { GetAllCards_Card } from '../server/commands/get-all-cards'
import { Field, MultiCheckboxValue } from '../server/types'
import { API } from './api'
import { CardFields } from './card-fields'
import { Collapsible } from './collapsible'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { AppView } from './state'
import { setState, Utils } from './utils'

export interface FeedbackStatsProps {
    view: AppView // TODO refactor props
    centreIds: string[]
}

interface State {
    loaded: boolean
    cards?: Record<string, GetAllCards_Card>
}

type ResponseCounts = Record<string, Record<string, number>>

const getLabels = (field: Field) => {
    const labels: Record<string, string> = {}

    for (const option of field.options!) {
        labels[option.id] = option.label!
    }

    labels.other = field.other!.label!
    return labels
}

enum FeedbackKey {
    importantChange = 'oluline-muutus',
    programValue = 'vaartus',
    need = 'vajadus',
    notes = 'markused',
}

const feedbackFields = CardFields.getFeedbackFields()
const importantChangeField = Utils.findById(feedbackFields, FeedbackKey.importantChange)!
const programValueField = Utils.findById(feedbackFields, FeedbackKey.programValue)!
const needField = Utils.findById(feedbackFields, FeedbackKey.need)!
const notesField = Utils.findById(feedbackFields, FeedbackKey.notes)!

const responseLabels = Object.freeze({
    [FeedbackKey.importantChange]: getLabels(importantChangeField),
    [FeedbackKey.programValue]: getLabels(programValueField),
})

export class FeedbackStats extends Component<FeedbackStatsProps, State> {
    state: State = { loaded: false }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const response = await API.getAllCards(view)
        await setState(this, { loaded: true, cards: response.cards })
        await EventBus.fire('feedback-stats-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    initResponseCounts(labels: Record<string, string>) {
        const counts: Record<string, number> = {}

        for (const key of Object.keys(labels)) {
            counts[key] = 0
        }

        return counts
    }

    filterCard(card: GetAllCards_Card) {
        return Boolean(Utils.arrayContains(this.props.centreIds, card.centreId) && card.feedback)
    }

    countResponseChoices(value: MultiCheckboxValue, counts: Record<string, number>) {
        if (value.other) {
            counts.other += 1
        }

        return value.choices.reduce((accumulator, choice) => {
            accumulator[choice.key] += 1
            return accumulator
        }, counts)
    }

    getResponseCounts() {
        const importantChangeLabels = responseLabels[FeedbackKey.importantChange]
        const importantChangeCounts = this.initResponseCounts(importantChangeLabels)
        const programValueLabels = responseLabels[FeedbackKey.programValue]
        const programValueCounts = this.initResponseCounts(programValueLabels)

        for (const card of Utils.filterMap(this.state.cards!, (c) => this.filterCard(c))) {
            const importantChange = card.feedback![FeedbackKey.importantChange]

            if (importantChange) {
                this.countResponseChoices(importantChange, importantChangeCounts)
            }

            const programValue = card.feedback![FeedbackKey.programValue]

            if (programValue) {
                this.countResponseChoices(programValue, programValueCounts)
            }
        }

        const counts: ResponseCounts = {
            [FeedbackKey.importantChange]: importantChangeCounts,
            [FeedbackKey.programValue]: programValueCounts,
        }

        return counts
    }

    renderCountRow(key: string, label: string, count: number) {
        return (
            <tr key={key}>
                <td>{label}</td>
                <td className="text-right">{count}</td>
            </tr>
        )
    }

    renderOtherResponses(feedbackKey: FeedbackKey.importantChange | FeedbackKey.programValue) {
        const cards = Utils.filterMap(this.state.cards!, (card) => {
            if (!this.filterCard(card)) {
                return false
            }

            const value = card.feedback![feedbackKey]
            return typeof value === 'object' && Boolean(value.other)
        })

        if (!cards.length) {
            return null
        }

        return (
            <Collapsible
                header="Muud vastused"
                className="feedback__response-panel"
                bodyClassName="feedback__responses"
            >
                {cards.map((card) => {
                    const value = card.feedback![feedbackKey]

                    if (typeof value !== 'object') {
                        throw new Error('Invalid type for ' + feedbackKey)
                    }

                    return this.renderTextResponse(card._id, value.other!)
                })}
            </Collapsible>
        )
    }

    renderMultipleChoiceResponses(
        feedbackKey: FeedbackKey.importantChange | FeedbackKey.programValue,
        header: string,
        counts: ResponseCounts,
    ) {
        const renderRow = (count: number, key: string): ReactNode => {
            return this.renderCountRow(key, responseLabels[feedbackKey][key], count)
        }

        return (
            <div>
                <p>{header}</p>
                <table id={'feedback-' + feedbackKey} className="bordered bottom-margin">
                    <tbody>{Utils.mapMap(counts[feedbackKey], renderRow)}</tbody>
                </table>
                {this.renderOtherResponses(feedbackKey)}
            </div>
        )
    }

    renderTextResponse(key: string, text: string) {
        return (
            <div key={key} className="text-response">
                {text}
            </div>
        )
    }

    renderTextResponses(header: string, feedbackKey: FeedbackKey) {
        const cards = Utils.filterMap(this.state.cards!, (card) => {
            return this.filterCard(card) && Boolean(card.feedback![feedbackKey])
        })

        if (!cards.length) {
            return (
                <div>
                    <p>{header}</p>
                    <div className="panel panel-default">
                        <div className="panel-body">
                            Valitud keskus(t)es pole ühelgi juhtumikaardil siia midagi sisestatud
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <p>{header}</p>
                <Collapsible
                    header="Kliki, et avada/sulgeda"
                    className="feedback__response-panel"
                    bodyClassName="feedback__responses"
                >
                    {cards.map((card) => {
                        const text = card.feedback![feedbackKey]

                        if (typeof text !== 'string') {
                            throw new Error('Invalid type for ' + feedbackKey)
                        }

                        return this.renderTextResponse(card._id, text)
                    })}
                </Collapsible>
            </div>
        )
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const responseCounts = this.getResponseCounts()

        return (
            <div className="main-panel">
                {this.renderMultipleChoiceResponses(
                    FeedbackKey.importantChange,
                    'Vastused küsimusele "' + importantChangeField.label + '"',
                    responseCounts,
                )}
                {this.renderMultipleChoiceResponses(
                    FeedbackKey.programValue,
                    'Vastused küsimusele "' + programValueField.label + '"',
                    responseCounts,
                )}
                {this.renderTextResponses(
                    'Vastused küsimusele "' + needField.label + '"',
                    FeedbackKey.need,
                )}
                {this.renderTextResponses(notesField.label!, FeedbackKey.notes)}
            </div>
        )
    }
}
