import { FC } from 'react'

import { Checkbox, CheckboxProps } from './checkbox'
import { TextInput, TextInputProps } from './text-input'

export interface EditActivityFormProps {
    options?: OptionProps[]
    textInputLabel?: string
    textInput?: TextInputProps
}

export interface OptionProps {
    reactKey: string
    checkbox: CheckboxProps
    label: string
}

export const EditActivityForm: FC<EditActivityFormProps> = (props) => (
    <div>
        {props.options?.map((option) => <Option key={option.reactKey} {...option} />)}
        {props.textInputLabel && (
            <>
                {props.textInputLabel}
                {': '}
            </>
        )}
        {props.textInput && <TextInput {...props.textInput} />}
    </div>
)

export const Option: FC<OptionProps> = (props) => (
    <label style={{ display: 'block' }}>
        <Checkbox {...props.checkbox} /> {props.label}
    </label>
)
