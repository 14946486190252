import { Component, ReactNode } from 'react'

import { Area } from '../server/areas'
import { EventCategoryHierarchy } from '../server/commands/get-event-category-hierarchy'
import { EventStatsData } from '../server/commands/get-event-stats-data'
import { Country } from '../server/countries'
import { SchoolWithId } from '../server/schools'
import { Centre, Event } from '../server/types'
import { isAdmin } from './access'
import { API } from './api'
import { EventBus } from './event-bus'
import { EventStatsTable } from './event-stats-table'
import { CentreCount } from './event-stats/centre-count'
import { Loading } from './loading'
import { SelectStatsEventPanel } from './select-stats-event-panel'
import { AppView } from './state'
import { Utils } from './utils'

export interface EventStatsProps {
    view: AppView // TODO refactor props
    dateFrom?: string
    dateTo?: string
    includeArchived: boolean
    centreIds?: string[] | null
    allCentres?: Record<string, Centre>
}

interface State {
    loaded: boolean
    dataLoading: boolean
    reloadNeeded: boolean
    selectedEventIds: string[]
    statsData: EventStatsData | null
    events?: Record<string, Event>
    eventCategoryHierarchy?: EventCategoryHierarchy
    schools?: Record<string, SchoolWithId>
    countries?: Record<string, Country>
    areas?: Record<string, Area>
}

export class EventStats extends Component<EventStatsProps, State> {
    state: State = {
        loaded: false,
        dataLoading: false,
        reloadNeeded: false,
        selectedEventIds: [],
        statsData: null,
    }

    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        let eventsPromise: Promise<Record<string, Event>>
        let hierarchyPromise: Promise<EventCategoryHierarchy>

        if (this.props.centreIds) {
            hierarchyPromise = API.getEventCategoryHierarchy(
                view,
                this.props.includeArchived,
                this.props.centreIds,
            )

            eventsPromise = API.getEvents(view, this.props.centreIds)
        } else {
            hierarchyPromise = API.getEventCategoryHierarchy(view, this.props.includeArchived)
            eventsPromise = API.getEvents(view)
        }

        const [hierarchy, events, schools, countries, areas] = await Promise.all([
            hierarchyPromise,
            eventsPromise,
            API.getSchools(),
            API.getCountries(),
            API.getAreas(),
        ])

        await Utils.setState(this, {
            loaded: true,
            eventCategoryHierarchy: hierarchy,
            events,
            schools,
            countries,
            areas,
        })

        await EventBus.fire('event-stats-rendered')
    }

    async componentDidUpdate(prevProps: EventStatsProps) {
        const { view } = this.props

        if (this.isAdminMode()) {
            if (
                !Utils.arraysEqual(this.props.centreIds!, prevProps.centreIds!) ||
                this.props.includeArchived !== prevProps.includeArchived
            ) {
                await Utils.setState(this, { loaded: false })

                const [hierarchy, events] = await Promise.all([
                    API.getEventCategoryHierarchy(
                        view,
                        this.props.includeArchived,
                        this.props.centreIds!,
                    ),
                    API.getEvents(view, this.props.centreIds!),
                ])

                await Utils.setState(this, {
                    loaded: true,
                    eventCategoryHierarchy: hierarchy,
                    events,
                })
            }
        } else if (this.props.includeArchived !== prevProps.includeArchived) {
            await Utils.setState(this, { loaded: false })
            const hierarchy = await API.getEventCategoryHierarchy(view, this.props.includeArchived)
            await Utils.setState(this, {
                loaded: true,
                eventCategoryHierarchy: hierarchy,
            })
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    isAdminMode() {
        const { view } = this.props
        const { session } = view.state
        return session.loggedIn && isAdmin(session.employee) && !session.centre
    }

    async onSelectionChange(eventIds: string[], reload: boolean) {
        if (reload) {
            // Need to set dataLoading already here, because there will be a render before reloadData
            await Utils.setState(this, {
                selectedEventIds: eventIds,
                dataLoading: true,
            })
            await this.reloadData()
        } else {
            await Utils.setState(this, {
                selectedEventIds: eventIds,
                reloadNeeded: true,
            })
        }

        await EventBus.fire('event-stats-selection-changed')
    }

    async reloadData() {
        const { view } = this.props
        const eventIds = this.state.selectedEventIds

        if (eventIds.length) {
            this.setState({ dataLoading: true, reloadNeeded: false })

            // TODO: filter by date? Would then need to reload data on date range changes, though.

            const statsData = await API.getEventStatsData(view, eventIds)
            await Utils.setState(this, { dataLoading: false, statsData })
        } else {
            return Utils.setState(this, {
                dataLoading: false,
                statsData: { meetings: [], visitors: {} },
            })
        }
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        const events = Utils.filterEventsByArchived(this.state.events!, this.props.includeArchived)
        const eventIds = this.state.selectedEventIds.filter((eventId) => eventId in events)
        const eventCount = eventIds.length

        let eventStats: ReactNode = (
            <div>
                <p>Palun vali vasakult sündmus, mille statistikat soovid vaadata.</p>
                <p>Alusta sündmuse nime trükkimist, et see hõlpsamini leida.</p>
            </div>
        )

        if (this.state.dataLoading) {
            eventStats = <Loading />
        } else if (this.state.reloadNeeded) {
            eventStats = (
                <div>
                    <div id="event-selection-count">
                        {Utils.pluralize(eventCount, 'sündmus', 'sündmust')}
                        {' valitud'}
                    </div>
                    <button
                        id="calculate"
                        onClick={async () => this.reloadData()}
                        style={{ marginTop: 10 }}
                    >
                        Arvuta statistika
                    </button>
                </div>
            )
        } else if (eventCount) {
            let singleEventName: string | undefined

            if (eventCount === 1) {
                const evt = Utils.findByField(this.state.events!, '_id', eventIds[0])!
                singleEventName = evt.name
            }

            const meetings = Utils.filterMeetingsByDate(
                this.state.statsData!.meetings,
                this.props.dateFrom,
                this.props.dateTo,
            )

            eventStats = (
                <EventStatsTable
                    meetings={meetings}
                    visitors={this.state.statsData!.visitors}
                    schools={this.state.schools!}
                    countries={this.state.countries!}
                    areas={this.state.areas!}
                    eventCount={eventCount}
                    singleEventName={singleEventName}
                    endDate={this.props.dateTo || Utils.formatDateYmd(Utils.getNow())}
                />
            )
        }

        return (
            <table className="vtop">
                <tbody>
                    <tr>
                        <td className="left-panel">
                            <SelectStatsEventPanel
                                eventCategoryHierarchy={this.state.eventCategoryHierarchy!}
                                selectedIds={eventIds}
                                onSelectionChange={async (evtIds, reload) =>
                                    this.onSelectionChange(evtIds, reload)
                                }
                                isAdminMode={this.isAdminMode()}
                            />
                        </td>
                        <td className="right-panel">
                            {eventStats}
                            {this.props.centreIds && (
                                <CentreCount
                                    view={view}
                                    eventIds={eventIds}
                                    startDate={this.props.dateFrom}
                                    endDate={this.props.dateTo}
                                />
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
