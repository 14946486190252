import { FC } from 'react'

import { EditActivity, EditActivityProps } from './edit-activity'
import { EditVisitor, EditVisitorProps } from './edit-visitor'
import { Link, LinkProps } from './link'
import { Loading } from './loading'
import { LoadingButton } from './loading-button'
import { ParticipationTime } from './participation-time'
import { Table, TableProps } from './table'

export interface ParticipantTableProps {
    isLoading: boolean
    noParticipations?: boolean
    toggleDetailsLink?: LinkProps
    table: TableProps
    countInfo?: string
    editVisitor?: EditVisitorProps
    editActivity?: EditActivityProps
}

export const ParticipantTable: FC<ParticipantTableProps> = (props) => {
    if (props.isLoading) {
        return <Loading />
    }

    if (props.noParticipations) {
        // TODO
        return <div id="participations">Hetkel pole kohal ühtegi osalejat.</div>
    }

    return (
        <div>
            {props.toggleDetailsLink && (
                <div style={{ marginBottom: 5 }}>
                    <Link {...props.toggleDetailsLink} />
                </div>
            )}
            <Table {...props.table} className="bordered" />
            {props.countInfo && <div style={{ marginTop: 5 }}>{props.countInfo}</div>}
            {props.editVisitor && <EditVisitor {...props.editVisitor} />}
            {props.editActivity && <EditActivity {...props.editActivity} />}
        </div>
    )
}

export const participantTableCells = {
    link: Link,
    loadingButton: LoadingButton, // TODO Button
    time: ParticipationTime,
}
