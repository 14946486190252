import { ChangeEvent, OptionHTMLAttributes, SelectHTMLAttributes } from 'react'

export interface DropdownOption<S = string> {
    id: S
    label: string
    additional?: OptionHTMLAttributes<HTMLOptionElement>
}

export interface DropdownProps<S extends string> {
    options: DropdownOption<S>[]
    value: string
    onChange: (value: S) => void
    additional?: SelectHTMLAttributes<HTMLSelectElement>
}

export const Dropdown = <S extends string>({
    options,
    value,
    onChange,
    additional,
}: DropdownProps<S>) => (
    <select
        value={value}
        onChange={(evt: ChangeEvent<HTMLSelectElement>) => {
            onChange(evt.target.value as S)
        }}
        {...additional}
    >
        {options.map((option) => (
            <option key={option.id} value={option.id} {...option.additional}>
                {option.label}
            </option>
        ))}
    </select>
)

// TODO remove?
export const renderDropdown = <S extends string>(props: DropdownProps<S>) => <Dropdown {...props} />
