import type { VisitorStatsData } from '../../server/commands/get-visitor-stats-data'
import { API } from '../api'
import { EventBus } from '../event-bus'
import { getInitialVisitorStatsState } from '../initial-state'
import type { AppView } from '../state'
import { Utils } from '../utils'
import { isString, type VisitorMatch } from '../visitor-search-utils'
import type { VisitorStatsProps } from '../visitor-stats'
import { initIfNeeded } from './init-if-needed'
import { getVisitorSearchPanelProps } from './visitor-search-panel'

export const getVisitorStatsProps = (view: AppView, visitorId: string | undefined) => {
    const { state, update } = view
    const { stats, visitorStats } = state

    initIfNeeded(view, 'visitorStats', async ({ setCleanup, isMounted }) => {
        setCleanup(() => {
            state.visitorStats = getInitialVisitorStatsState()
        })

        const visitorsPromise = API.getCentreVisitors(view)
        let statsData: VisitorStatsData | null = null

        if (visitorId) {
            statsData = await API.getVisitorStatsData(view, visitorId)
        }

        const centreVisitors = await visitorsPromise

        if (isMounted()) {
            visitorStats.loaded = true
            visitorStats.centreVisitors = centreVisitors
            visitorStats.statsData = statsData
            update()

            await EventBus.fire('visitor-stats-rendered')
        }
    })

    const props: VisitorStatsProps = {
        isLoading: !visitorStats.loaded,
        visitorSearchPanel: getVisitorSearchPanelProps(view, {
            primaryVisitors: visitorStats.centreVisitors,
            onSelectVisitor: async (visitor) => onSelectVisitor(view, visitor),
            clearAfterSelection: true,
            getHeader: () => 'Alusta kasutajanime trükkimist, et leida külastaja.',
        }),
    }

    if (visitorStats.dataLoading) {
        props.dataLoading = true
    } else if (visitorStats.statsData) {
        const events = Utils.filterEventsByArchived(
            visitorStats.statsData.events,
            stats.includeArchived,
        )

        const meetings = Utils.filterMeetingsByDate(
            visitorStats.statsData.meetings,
            stats.dateFrom,
            stats.dateTo,
        ).filter((meeting) => meeting.event in events)

        props.results = {
            view,
            visitor: visitorStats.statsData.visitor,
            meetings,
            events,
            onVisitorEdited: async () => onVisitorEdited(view),
            includeArchived: stats.includeArchived,
        }
    } else {
        props.note = 'Palun vali vasakult külastaja, kelle statistikat soovid vaadata.'
    }

    return props
}

const onSelectVisitor = async (view: AppView, visitor: VisitorMatch) => {
    const { state, update } = view
    const { visitorStats } = state

    if (isString(visitor)) {
        throw new Error('Visitor id not found. Username: ' + visitor)
    }

    view.navigate(['stats', 'visitor-activity', visitor._id])
    visitorStats.dataLoading = true
    update()

    const statsData = await API.getVisitorStatsData(view, visitor._id)

    // TODO check if mounted?

    visitorStats.dataLoading = false
    visitorStats.statsData = statsData
    update()
    await EventBus.fire('visitor-selected')
}

const onVisitorEdited = async (view: AppView) => {
    const { state, update } = view
    const { visitorStats } = state

    visitorStats.dataLoading = true
    update()

    const visitorId = visitorStats.statsData!.visitor._id

    // This could be optimized if needed by loading only the modified visitor,
    // but for now we reload full data as it keeps the code simpler.

    const [centreVisitors, statsData] = await Promise.all([
        API.getCentreVisitors(view),
        API.getVisitorStatsData(view, visitorId),
    ])

    // TODO check if mounted?

    visitorStats.dataLoading = false
    visitorStats.centreVisitors = centreVisitors
    visitorStats.statsData = statsData
    update()
}
