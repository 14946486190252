import { CardPhase } from './card-phase'

export enum CardAction {
    START2 = 'start2',
    END2 = 'end2',
    START3 = 'start3',
    END3 = 'end3',
    START4 = 'start4',
    CANCEL = 'cancel',
    RESUME = 'resume',
}

export const phases = {
    [CardAction.START2]: CardPhase.STARTED2,
    [CardAction.END2]: CardPhase.ENDED2,
    [CardAction.START3]: CardPhase.STARTED3,
    [CardAction.END3]: CardPhase.ENDED3,
    [CardAction.START4]: CardPhase.STARTED4,
    [CardAction.CANCEL]: CardPhase.CANCELLED,
    [CardAction.RESUME]: CardPhase.STARTED2,
}
