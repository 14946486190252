import { ChangeEvent, FC } from 'react'

export interface SearchBoxProps {
    id?: string
    onChange: (evt: ChangeEvent<HTMLInputElement>) => Promise<void>
    search?: string
}

export const SearchBox: FC<SearchBoxProps> = (props) => (
    <input
        type="search"
        id={props.id}
        placeholder="Otsi"
        onChange={props.onChange}
        value={props.search}
    />
)
