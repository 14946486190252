import {
    CentreFormContents,
    Event,
    MeetingCommon,
    MeetingDetailUpdate,
    MeetingInput,
} from '../../server/types'
import { API } from '../api'
import { Cache } from '../cache'
import { EditMeetingModalProps } from '../edit-meeting'
import { EventBus } from '../event-bus'
import { getInitialMeetingFormState } from '../initial-state'
import { useCustomHandlerDuring } from '../request-failure-handler'
import { AppView } from '../state'
import { Utils } from '../utils'
import { checkValidationErrors, clearValidationErrors } from '../validation'
import { EditMeetingFormParams, getEditMeetingFormProps } from './edit-meeting-form'
import { initIfNeeded } from './init-if-needed'

export const getEditMeetingModalProps = (
    view: AppView,
    evt: Event,
    title: string,
    form: EditMeetingFormParams, // TODO rename?
): EditMeetingModalProps => {
    const { state, update } = view

    initIfNeeded(view, 'editMeetingModal', async ({ setCleanup }) => {
        setCleanup(() => {
            state.meetingForm = getInitialMeetingFormState()
            state.validationErrors = {}
        })

        if (form.isNew) {
            // This is a good time to preload the visitor usernames as we'll probably need them soon.
            Cache.getVisitorUsernames(view)
        }

        const formData = await API.getCentreForm(view)
        let formValues: CentreFormContents = {}

        if (formData) {
            const activeRevision = Utils.getLatestRevNumber(formData)
            formValues = Utils.getRevision(formData.revisions, activeRevision)
        }

        state.meetingForm.loaded = true
        state.meetingForm.formValues = formValues
        update()

        await EventBus.fire('meeting-details-rendered')
    })

    const wide = evt.activityConf.mode === 'meeting'
    const className = wide ? 'edit-meeting-wide' : 'edit-meeting'
    const { isSaving } = state.meeting

    const closeModal = async () => {
        state.meeting.modalVisible = false
        update()
        await EventBus.fire('modal-closed')
    }

    const save = async () => {
        try {
            state.meeting.isSaving = true
            update()
            await saveMeeting(view, form)
            await closeModal()
        } finally {
            state.meeting.isSaving = false
            update()
        }
    }

    const props: EditMeetingModalProps = {
        outer: {
            title,
            closeModal,
            buttons: [
                {
                    isLoading: isSaving,
                    id: 'save',
                    text: 'Salvesta',
                    onClick: save,
                    className: isSaving ? 'disabled' : undefined,
                    style: { marginRight: 5 },
                },
                {
                    text: 'Tühista',
                    onClick: closeModal,
                },
            ],
            dialogClassName: className,
        },
    }

    if (state.meetingForm.loaded) {
        props.form = getEditMeetingFormProps(view, form)
    }

    return props
}

const saveMeeting = async (view: AppView, form: EditMeetingFormParams) => {
    const { state } = view
    const { meetingForm } = state

    const common: MeetingCommon = {
        startDate: meetingForm.startDate,
        startTime: meetingForm.startTime.trim().replace('.', ':'), // Allow both HH.MM and HH:MM
        endDate: meetingForm.endDate,
        endTime: meetingForm.endTime.trim().replace('.', ':'), // Allow both HH.MM and HH:MM
        name: meetingForm.name.trim(),
        externalLocation:
            meetingForm.locationType === 'centre' ? null : meetingForm.externalLocation.trim(),
        program: meetingForm.program,
        collaborator: meetingForm.collaborator.slice(),
    }

    if (form.event.activityConf.mode === 'meeting') {
        common.activityConf = meetingForm.activityConf
    }

    try {
        await clearValidationErrors(view)

        if (form.isNew) {
            const meetingInput: MeetingInput = {
                ...common,
                event: form.event._id,
            }
            const response = await API.addMeeting(view, meetingInput)
            const meetingId = response.id

            if (meetingForm.openMode === 'participant') {
                await API.enterParticipantMode(view, meetingId)
            }

            view.navigate(['meeting', meetingId])
        } else {
            const update: MeetingDetailUpdate = {
                ...common,
                rev: form.meeting!.rev,
            }

            await useCustomHandlerDuring(
                Utils.getConcurrentEditErrorHandler('kohtumise'),
                async () => {
                    await API.updateMeetingDetails(view, form.meeting!._id, update)
                    form.afterUpdate?.()
                },
            )
        }
    } catch (error) {
        await checkValidationErrors(view, null, error)
    }
}
