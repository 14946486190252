import { Cleaner } from '../util/cleaner'
import { member } from '../util/member'

type Listener<T> = (payload: T) => Promise<void>

export interface Subscription {
    remove: () => void
    track: (cleaner: Cleaner) => void
}

const listeners: Record<string, Listener<unknown>[]> = {}

const off = <T>(eventName: string, listener: Listener<T>) => {
    const array = member(listeners, eventName, [])
    const index = array.indexOf(listener as Listener<unknown>)

    if (index === -1) {
        throw new Error('Listener not registered')
    } else {
        array.splice(index, 1)
    }
}

const createSubscription = <T>(eventName: string, listener: Listener<T>): Subscription => {
    const remove = () => off(eventName, listener)
    const track = (cleaner: Cleaner) => cleaner.add(remove)
    return { remove, track }
}

export const EventBus = {
    on: <T>(eventName: string, listener: Listener<T>): Subscription => {
        member(listeners, eventName, []).push(listener as Listener<unknown>)
        return createSubscription(eventName, listener)
    },

    once: <T>(eventName: string, listener: Listener<T>): Subscription => {
        const wrapped = async (payload: T) => {
            off(eventName, wrapped)
            await listener(payload)
        }

        member(listeners, eventName, []).push(wrapped as Listener<unknown>)
        return createSubscription(eventName, wrapped)
    },

    off,

    async fire(eventName: string, payload?: unknown) {
        for (const listener of member(listeners, eventName, [])) {
            await listener(payload)
        }
    },
}
