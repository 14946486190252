import { County } from '../server/types'
import { CommonUtils } from './common-utils'

export type RegionId = 'pohja' | 'ida' | 'louna' | 'laane' | 'other'

export interface Region {
    id: RegionId
    label: string
    counties: County[]
}

const regions: Region[] = [
    {
        id: 'pohja',
        label: 'Põhja-Eesti',
        counties: ['harju', 'rapla', 'jarva'],
    },
    {
        id: 'ida',
        label: 'Ida-Eesti',
        counties: ['laane-viru', 'ida-viru'],
    },
    {
        id: 'louna',
        label: 'Lõuna-Eesti',
        counties: ['jogeva', 'viljandi', 'tartu', 'polva', 'valga', 'voru'],
    },
    {
        id: 'laane',
        label: 'Lääne-Eesti',
        counties: ['laane', 'parnu', 'hiiu', 'saare'],
    },
]

export const array = regions

export const getById = (regionId: RegionId) => CommonUtils.findById(regions, regionId)

export const getByCounty = (countyId: County) => {
    const filtered = regions.filter((region) => region.counties.indexOf(countyId) !== -1)

    if (filtered.length !== 1) {
        throw new Error('Invalid county: ' + countyId)
    }

    return filtered[0]
}

// TODO inline
export const Regions = { array, getById, getByCounty }
