import { FC } from 'react'

import { CustomModal, CustomModalProps } from './custom-modal'
import { EditActivityForm, EditActivityFormProps } from './edit-activity-form'

export interface EditActivityProps {
    modal: CustomModalProps
    form: EditActivityFormProps
}

export const EditActivity: FC<EditActivityProps> = (props) => (
    <CustomModal {...props.modal}>
        <EditActivityForm {...props.form} />
    </CustomModal>
)
