let automated = false

export const getConfirmation = async (message: string) => {
    if (automated) {
        // TODO track confirmation messages in test snapshots?
        return true
    } else {
        return confirm(message)
    }
}

export const automateConfirmations = () => (automated = true)
