import { API, RequestHandler, RequestLogger } from './api'
import { AppView } from './state'

const delay = async (ms: number) => new Promise<void>((resolve) => setTimeout(resolve, ms))

const logger: RequestLogger = {
    warn(message) {
        console.warn(message)
    },

    error(message) {
        console.error(message)
    },
}

export const getDefaultRequestHandler = (view: AppView): RequestHandler => ({
    execute: async (command, params, handleFailure) => {
        // The command in the URI parameter is only a debugging aid.
        // It is ignored by the server and can be safely removed if needed.
        const uri = (location.origin || '') + '/api?' + command

        let failureCount = 0

        const tryRequest = async (): Promise<Response> => {
            try {
                return fetch(uri, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'same-origin',
                    body: JSON.stringify({ command, params }),
                })
            } catch (error) {
                if (
                    (error.message === 'Failed to fetch' ||
                        error.message === 'Network request failed' ||
                        error.message === 'NetworkError when attempting to fetch resource.') &&
                    failureCount < 10
                ) {
                    // These failures are somewhat common.
                    // Retry the request up to 10 times with short delays.
                    failureCount += 1
                    await delay(200)
                    return tryRequest()
                } else {
                    throw error
                }
            }
        }

        const response = await tryRequest()

        if (failureCount > 0) {
            API.logWarning(view, 'Failed ' + failureCount + ' time(s) when fetching ' + uri, true)
        }

        if (response.headers.get('Content-Type') === 'application/json') {
            const json = await response.json()

            if (response.status === 200) {
                return json
            } else {
                handleFailure(response.status, json, logger)
            }
        } else {
            // Most likely the server responded with a non-JSON formatted error
            const text = await response.text()
            throw new Error('Error from server: ' + text)
        }
    },
})
