import { ReactNode } from 'react'

import { GetVisitor_Visitor } from '../server/commands/get-visitor'
import { Card, CentreFormContents, CollabKey, Field, Option } from '../server/types'
import { assertNever } from '../util/assert-never'
import { centreFormFields } from '../util/centre-form-fields'
import { CommonUtils } from './common-utils'

// Keep the fields in sync with the server

const getCentreFormBasedOptions = (
    values: CentreFormContents,
    fieldId: keyof CentreFormContents,
    otherKey: string | null,
    existingValues: string[],
) => {
    const field = CommonUtils.findById(centreFormFields, fieldId)!
    const combinedValue = CommonUtils.clone(values[fieldId] || {}) as any // TODO

    existingValues.forEach((key) => {
        if (!(key in combinedValue)) {
            combinedValue[key] = true
        }
    })

    const options: Option[] = []

    field.keys!.forEach((key) => {
        if (key.id in combinedValue) {
            let label = key.label

            if (key.id === otherKey) {
                label = (values[fieldId]! as Record<string, string>)[otherKey!] || key.label

                if (!label) {
                    return
                }
            }

            options.push({ id: key.id, label })
        }
    })

    return options
}

const getCentreFormBasedField = (
    id: string,
    label: string,
    options: Option[],
    multiple: boolean,
    conditional: boolean,
    checkboxLabel: string | null,
) => {
    const type: Field['type'] = multiple ? 'multiple-choice' : 'single-choice'
    let field: Field = { id, type, options, vertical: true }

    if (conditional) {
        field = { id, type: 'conditional', checkboxLabel, valueField: field }
    }

    field.label = label
    return field
}

const getProgramField = (centreFormParams: CentreFormParams) => {
    const id = 'pohiprogramm'
    const label = 'Seotud põhiprogramm'

    let programOptions: Option[] = []

    if (centreFormParams.mode === 'centre-specific') {
        const existingValue = centreFormParams.card ? centreFormParams.card.phase2[id] : null
        const existingValues = existingValue ? [existingValue] : []

        if (centreFormParams.values) {
            programOptions = getCentreFormBasedOptions(
                centreFormParams.values,
                'seotud-programmid',
                null,
                existingValues.map((value) => value.key),
            )
        }
    } else if (centreFormParams.mode === 'all-options') {
        const field = CommonUtils.findById(centreFormFields, 'seotud-programmid')!
        programOptions = field.keys!
    } else {
        throw assertNever(
            centreFormParams,
            'centre form params mode',
            (centreFormParams as { mode?: string }).mode,
        )
    }

    if (programOptions.length) {
        return getCentreFormBasedField(id, label, programOptions, false, false, null)
    } else {
        const value = 'Keskuse ankeedil pole valitud ühtegi seotud programmi.'
        return { id, label, readOnly: true, value }
    }
}

interface CentreSpecificParams {
    mode: 'centre-specific'
    card?: Card
    values?: CentreFormContents
}

interface AllOptionParams {
    mode: 'all-options'
}

export type CentreFormParams = CentreSpecificParams | AllOptionParams

const getCollaboratorField = (centreFormParams: CentreFormParams) => {
    let collaboratorOptions: Option[] = []

    if (centreFormParams.mode === 'centre-specific') {
        let existingValues: CollabKey[] = []

        if (centreFormParams.card && centreFormParams.card.phase3) {
            const wrappedValue = centreFormParams.card.phase3['koostoos']

            if (wrappedValue && !wrappedValue.bool) {
                existingValues = wrappedValue.value!
            }
        }

        if (centreFormParams.values) {
            collaboratorOptions = getCentreFormBasedOptions(
                centreFormParams.values,
                'koostoo-vorgustik',
                'muu',
                existingValues,
            )
        }
    } else if (centreFormParams.mode === 'all-options') {
        const field = CommonUtils.findById<Field>(centreFormFields, 'koostoo-vorgustik')!
        collaboratorOptions = field.keys!
    } else {
        throw assertNever(centreFormParams, 'centre form params')
    }

    return getCentreFormBasedField(
        'koostoos',
        'Koostöös',
        collaboratorOptions,
        true,
        true,
        'Töö noorega toimub noortekeskuse siseselt',
    )
}

const getEmailAndPhone = (visitor: GetVisitor_Visitor) => {
    const parts: string[] = []

    if (visitor.email) {
        parts.push(visitor.email)
    }

    if (visitor.ownPhone) {
        parts.push(visitor.ownPhone)
    }

    return parts.join(', ')
}

export const CardFields = {
    getGeneralFields(
        unlinked: boolean,
        visitor: GetVisitor_Visitor | null,
        renderCheckDuplicatesButton?: () => ReactNode,
    ) {
        const nameField: Field = {
            id: 'nimi',
            label: 'Nimi',
            renderExtra: renderCheckDuplicatesButton,
        }
        const birthDateField: Field = { id: 'syn', label: 'Sünnipäev' }
        const emailPhoneField: Field = {
            id: 'email-telefon',
            label: 'E-post/telefon',
        }
        const genderField: Field = { id: 'sugu', label: 'Sugu' }

        const fields: Field[] = [nameField, birthDateField]

        if (unlinked) {
            nameField.type = 'text'
            birthDateField.type = 'birth-date'
            birthDateField.hint = 'Lisada vähemalt sünniaasta'

            fields.push({
                id: 'syn-allikas',
                type: 'single-choice',
                indent: true,
                label: 'Info saamise allikas',
                options: [
                    { id: 'noor', label: 'noore andmetel' },
                    {
                        id: 'ametlik',
                        label: 'ametlike andmete kaudu (nt KOV, Eesti Töötukassa jms)',
                    },
                    { id: 'arvatav', label: 'noore arvatav vanus' },
                ],
            })

            emailPhoneField.type = 'text'

            genderField.type = 'single-choice'
            genderField.options = [
                { id: 'm', label: 'M' },
                { id: 'n', label: 'N' },
            ]
        } else {
            nameField.readOnly = true
            nameField.value = visitor!.firstname + ' ' + visitor!.lastname

            birthDateField.readOnly = true
            birthDateField.value = CommonUtils.formatPartialDate(visitor!.birthDate!)

            emailPhoneField.readOnly = true
            emailPhoneField.value = getEmailAndPhone(visitor!)

            genderField.readOnly = true
            genderField.value = visitor!.gender
        }

        fields.push(
            {
                id: 'isikukood',
                label: 'Isikukood',
                type: 'conditional',
                checkboxLabel: 'Keeldub avalikustamast',
                valueField: { type: 'number', width: '8em', saveAsString: true },
            },
            {
                id: 'elukoht',
                label: 'Elukoht',
                type: 'text',
                hint: 'Noore elukohana märkida kohalik omavalitsus',
            },
            emailPhoneField,
            genderField,
        )

        return fields
    },

    getPhase2Fields(centreFormParams: CentreFormParams): Field[] {
        const continueOptions: Option[] = [
            {
                id: 'sots',
                label: 'Sotsiaalsed põhjused',
                details: {
                    type: 'multiple-choice-checkbox',
                    hint: 'Muu valiku korral lisada märkuste lahtrisse täpsustus.',
                    options: [
                        { id: 'toim', label: 'Toimetulek igapäevaeluga' },
                        { id: 'teenus', label: 'Sobiva teenuse kättesaamatus' },
                        { id: 'suht', label: 'Suhted lähedastega' },
                        { id: 'koh', label: 'Kohustused lähedastega seoses' },
                    ],
                    other: { required: true, label: 'Muu' },
                    required: true,
                },
            },
            {
                id: 'haridus',
                label: 'Hariduslikud põhjused',
                details: {
                    type: 'multiple-choice-checkbox',
                    hint: 'Muu valiku korral lisada märkuste lahtrisse täpsustus.',
                    options: [
                        { id: 'rask', label: 'Õpiraskused' },
                        { id: 'erivaj', label: 'Hariduslik erivajadus' },
                        { id: 'sisseast', label: 'Kooli sisseastumise ajakava' },
                        { id: 'mot', label: 'Motivatsioon' },
                    ],
                    other: { required: true, label: 'Muu' },
                    required: true,
                },
            },
            {
                id: 'maj',
                label: 'Majanduslikud põhjused',
                details: {
                    type: 'multiple-choice-checkbox',
                    hint: 'Muu valiku korral lisada märkuste lahtrisse täpsustus.',
                    options: [
                        { id: 'trans', label: 'Transpordi puudumine' },
                        { id: 'fin', label: 'Finantsprobleemid' },
                        { id: 'too', label: 'Sobiva töökoha puudumine' },
                        { id: 'pind', label: 'Elamispinna puudumine' },
                    ],
                    other: { required: true, label: 'Muu' },
                    required: true,
                },
            },
            {
                id: 'tervisl',
                label: 'Tervislikud põhjused',
                details: {
                    type: 'multiple-choice-checkbox',
                    hint: 'Muu valiku korral lisada märkuste lahtrisse täpsustus.',
                    options: [
                        { id: 'vaim', label: 'Vaimse tervise seisund' },
                        { id: 'erivaj', label: 'Erivajadus' },
                        { id: 'haig', label: 'Haigestumine' },
                        { id: 'pere', label: 'Pereliikme haigestumine' },
                        { id: 'solt', label: 'Sõltuvushäire' },
                    ],
                    other: { required: true, label: 'Muu' },
                    required: true,
                },
            },
            {
                id: 'oig',
                label: 'Õiguslikud põhjused',
                details: {
                    type: 'multiple-choice-checkbox',
                    hint: 'Muu valiku korral lisada märkuste lahtrisse täpsustus.',
                    options: [
                        { id: 'dok', label: 'Isikut tõendavad dokumendid' },
                        { id: 'karis', label: 'Karistusest tulenevad piirangud' },
                        {
                            id: 'prots',
                            label: 'Pooleliolevad menetlusprotsessid (nt kohtuprotsess)',
                        },
                    ],
                    other: { required: true, label: 'Muu' },
                    required: true,
                },
            },
        ]

        return [
            {
                id: 'teekond',
                label: 'Noore leidmise teekond',
                type: 'single-choice',
                options: [
                    { id: 'mono', label: 'Mobiilne noorsootöö' },
                    { id: 'vorg', label: 'Võrgustikutöö' },
                    { id: 'teavitus', label: 'Teavituskampaania' },
                    { id: 'ise', label: 'Võttis ise ühendust' },
                    { id: 'teised', label: 'Noorsootöö teised toetavad tegevused' },
                    {
                        id: 'koostoo',
                        label: 'Koostööpartneri kaudu',
                        details: {
                            type: 'single-choice',
                            options: [
                                { id: 'yhis', label: 'Ühistegevused koostööpartneriga' },
                                {
                                    id: 'andmed',
                                    label: 'Isikuandmed on saadud koostööpartnerilt',
                                },
                                { id: 'star', label: 'STAR programmi kaudu' },
                                { id: 'noust', label: 'Koostööpartneri nõustamine' },
                            ],
                            required: true,
                        },
                    },
                ],
                vertical: true,
                other: { label: 'Muu' },
            },
            getProgramField(centreFormParams),
            {
                id: 'taust',
                label: 'Noore taust',
                type: 'multiple-choice-checkbox',
                options: [
                    { id: 'alg', label: 'Alg- või põhiharidus' },
                    {
                        id: 'lopetamata',
                        label: 'Lõpetamata haridus',
                        details: {
                            type: 'single-choice',
                            options: [
                                {
                                    id: 'pohi',
                                    label: 'põhiharidus',
                                    details: {
                                        type: 'number',
                                        unit: 'klassi lõpetatud',
                                        width: '3em',
                                    },
                                },
                                { id: 'yld', label: 'üldharidus' },
                                { id: 'kutse', label: 'kutseharidus' },
                                { id: 'korg', label: 'kõrgharidus' },
                            ],
                            vertical: true,
                        },
                    },
                    { id: 'yld', label: 'Üldharidus (keskharidus)' },
                    { id: 'kutse', label: 'Kutseharidus' },
                    { id: 'korg', label: 'Kõrgharidus' },
                    {
                        id: 'nimek',
                        label: 'Haridusasutuse nimekirjas, kuid ei võta osa õppetööst',
                    },
                    { id: 'too-puudub', label: 'Töökogemus puudub' },
                    { id: 'tootav', label: 'Töötav (vabatahtlik)' },
                    { id: 'tootu', label: 'Töötu' },
                    { id: 'kassa', label: 'On registreeritud Töötukassas' },
                    {
                        id: 'mitteakt',
                        label: 'Mitteaktiivne',
                        details: {
                            type: 'single-choice',
                            options: [
                                { id: 'aja', label: 'ajateenija' },
                                {
                                    id: 'noor',
                                    label: 'noor, kes ei õpi kuigi on koolikohuslane',
                                },
                                { id: 'vanema-alla', label: 'vanemapuhkusel - laps alla 1,5a' },
                                { id: 'vanema-yle', label: 'vanemapuhkusel - laps üle 1,5a' },
                            ],
                            vertical: true,
                        },
                    },
                    {
                        id: 'ebasoodsas',
                        label: 'Ebasoodsas olukorras',
                        details: {
                            type: 'multiple-choice',
                            options: [
                                { id: 'tootu', label: 'elab töötute peres' },
                                { id: 'tootu-lastega', label: 'töötute peres koos lastega' },
                                {
                                    id: 'yhe-tool',
                                    label: 'ühe tööl käiva täiskasvanu ja lastega peres',
                                },
                            ],
                        },
                    },
                    {
                        id: 'osaleb',
                        label: 'Osaleb hariduses',
                        details: {
                            type: 'single-choice',
                            options: [
                                { id: 'pohi', label: 'Põhiharidus' },
                                { id: 'yld', label: 'Üldharidus' },
                                { id: 'kutse', label: 'Kutseharidus' },
                                { id: 'korg', label: 'Kõrgharidus' },
                            ],
                        },
                    },
                    {
                        id: 'garantii',
                        label: 'Olemas varasem noortegarantii kogemus',
                        details: {
                            type: 'text',
                            multiline: true,
                            hint: 'Nt minu esimene töökoht, meede vms',
                        },
                    },
                ],
            },
            {
                id: 'taust-allikas',
                label: 'Info saamise allikas',
                type: 'text',
                indent: true,
                multiline: true,
                hint: 'Kirjelda noore kohta saadud taustinformatsiooni protsessi.',
            },
            {
                id: 'varasem-taust',
                label: 'Noore varasem taust',
                type: 'text',
                multiline: true,
                hint: 'Info noore varasema tegevuse kohta',
            },
            {
                id: 'tunnus',
                label: 'Tunnus (takistus)',
                type: 'multiple-choice-checkbox',
                options: [
                    { id: 'maa', label: 'Maapiirkond' },
                    { id: 'lopetamata', label: 'Lõpetamata haridus' },
                    { id: 'sisse', label: 'Sisserännanu, rahvusvähemus' },
                    { id: 'puue', label: 'Erivajadus' },
                    {
                        id: 'eba',
                        label: 'Ebasoodsas olukorras',
                        details: {
                            type: 'multiple-choice',
                            options: [
                                { id: 'hoold', label: 'hooldajatoetuse saaja' },
                                { id: 'kodutu', label: 'kodutu või eluasemeturult tõrjutu' },
                                { id: 'krimi', label: 'kriminaalkorras karistatud' },
                                { id: 'pohi', label: 'puudub põhiharidus' },
                                { id: 'solt', label: 'sõltlane' },
                                { id: 'toime', label: 'toimetulekutoetuse saaja' },
                                { id: 'vaja', label: 'vajaduspõhise peretoetuse saaja' },
                            ],
                            vertical: true,
                        },
                    },
                    {
                        id: 'perek',
                        label: 'Perekondlik',
                        details: {
                            type: 'multiple-choice',
                            options: [
                                { id: 'lapsega', label: 'lapsega kodus' },
                                { id: 'hooldaja', label: 'pereliikme hooldaja' },
                                { id: 'yksik', label: 'üksikvanem' },
                            ],
                        },
                    },
                ],
                other: { label: 'Muu' },
            },
            {
                id: 'tunnus-allikas',
                label: 'Info saamise allikas',
                type: 'text',
                indent: true,
                multiline: true,
                hint: 'Kirjelda noore kohta saadud taustinformatsiooni protsessi.',
            },
            {
                id: 'eesmark',
                label: 'Eesmärk',
                type: 'single-choice',
                options: [
                    { id: 'haridus', label: 'Hariduse jätkamine' },
                    { id: 'too', label: 'Töö leidmine' },
                    { id: 'eba', label: 'Ebasoodsast olukorrast väljumine' },
                    { id: 'taps', label: 'Eesmärgi täpsustamine' },
                ],
                other: { label: 'Muu' },
            },
            {
                id: 'eesmark-kirjeldus',
                type: 'text',
                indent: true,
                multiline: true,
                hint: 'Kirjelda, mille põhjal selline eesmärk püstitatakse.',
            },
            {
                id: 'prognoos',
                label: 'Eesmärgi täitmise prognoos',
                type: 'single-choice',
                options: [
                    { id: '1k', label: '1 kuu' },
                    { id: '2-3k', label: '2-3 kuud' },
                    { id: '4-6k', label: '4-6 kuud' },
                ],
                other: { label: 'Muu' },
            },
            {
                id: '4-kuu-jatk',
                label: '4 kuu jätkamise vajadus',
                type: 'multiple-choice-checkbox',
                hint: 'Valiku korral tuleb lisada täpsem lisainfo',
                options: continueOptions,
            },
            {
                id: '6-kuu-jatk',
                label: '6 kuu jätkamise vajadus',
                type: 'multiple-choice-checkbox',
                hint: 'Valiku korral tuleb lisada täpsem lisainfo',
                options: continueOptions,
            },
        ]
    },

    getPhase3Fields(centreFormParams: CentreFormParams): Field[] {
        return [
            {
                id: 'ressursid',
                label: 'Ressursside vajadus',
                type: 'multiple-choice',
                options: [
                    { id: 'ind', label: 'Individuaalne lähenemine' },
                    { id: 'grupiteg', label: 'Grupitegevus' },
                    { id: 'grupinou', label: 'Grupinõustamine' },
                    { id: 'suun', label: 'Suunamine partnerite juurde' },
                    { id: 'tugi', label: 'Mentor' },
                ],
            },
            {
                id: 'toetus',
                label: 'Toetuse märksõna/tegevuskaart',
                type: 'multiple-choice',
                options: [
                    { id: 'haridus', label: 'Haridus' },
                    { id: 'too', label: 'Tööturg' },
                    { id: 'ettev', label: 'Ettevõtlus' },
                    { id: 'isiklik', label: 'Isiklik' },
                    { id: 'mfo', label: 'MFÕ' },
                ],
            },
            {
                id: 'toetus-lisa',
                label: 'Lisavalikud',
                type: 'multiple-choice',
                indent: true,
                options: [
                    { id: 'asja', label: 'Asjaajamisoskused' },
                    { id: 'eluviis', label: 'Eluviis' },
                    { id: 'isemaj', label: 'Isemajandamine' },
                    { id: 'koolitus', label: 'Koolitus' },
                    { id: 'pos-kog', label: 'Positiivsed kogemused' },
                    { id: 'soov', label: 'Soovide teadvustamine' },
                    { id: 'taristu', label: 'Taristu' },
                    { id: 'toetusv', label: 'Toetusvõrgustik' },
                    { id: 'too-harj', label: 'Tööharjumus' },
                    { id: 'too-voim', label: 'Töövõimalused' },
                    { id: 'voimekus', label: 'Võimekus' },
                    { id: 'op-voim', label: 'Õppimisvõimalused' },
                ],
                vertical: true,
            },
            getCollaboratorField(centreFormParams),
            {
                id: 'programm',
                label: 'Programmi valik',
                type: 'multiple-choice-checkbox',
                groups: [
                    { id: 'teenused', label: 'Teenused' },
                    { id: 'tegevused', label: 'Tegevused' },
                ],
                options: [
                    { group: 'teenused', id: 'internet', label: 'Avalik internet' },
                    { group: 'teenused', id: 'elamisp', label: 'Elamispinna toetus' },
                    { group: 'teenused', id: 'eriprog', label: 'Eriprogramm' },
                    { group: 'teenused', id: 'ettev', label: 'Ettevõtlustoetus' },
                    { group: 'teenused', id: 'hingehoid', label: 'Hingehoid' },
                    { group: 'teenused', id: 'info', label: 'Infoüritus/messid' },
                    { group: 'teenused', id: 'karj', label: 'Karjäärinõustaja' },
                    { group: 'teenused', id: 'laste', label: 'Lastehoid' },
                    { group: 'teenused', id: 'noorteinfo', label: 'Noorteinfo' },
                    { group: 'teenused', id: 'psyh', label: 'Psühholoog' },
                    { group: 'teenused', id: 'sotsabi', label: 'Sotsiaalabi' },
                    { group: 'teenused', id: 'stardiabi', label: 'Stardiabi' },

                    { group: 'tegevused', id: '3sekt', label: '3 sektori tegevused' },
                    { group: 'tegevused', id: 'avnst', label: 'Avatud noorsootöö' },
                    { group: 'tegevused', id: 'grup', label: 'Grupitegevused' },
                    { group: 'tegevused', id: 'hooaj', label: 'Hooajaline töö' },
                    { group: 'tegevused', id: 'huvi', label: 'Huvitegevus' },
                    { group: 'tegevused', id: 'koolitus', label: 'Koolitus, kursused' },
                    { group: 'tegevused', id: 'laager', label: 'Laager' },
                    {
                        group: 'tegevused',
                        id: 'lyhi-too',
                        label: 'Lühiajaline töökogemus',
                    },
                    {
                        group: 'tegevused',
                        id: 'minu-esim',
                        label: 'Minu esimene töökoht (Töötukassa meede)',
                    },
                    { group: 'tegevused', id: 'noorteyhing', label: 'Noorteühing' },
                    { group: 'tegevused', id: 'noust', label: 'Nõustamine' },
                    {
                        group: 'tegevused',
                        id: 'osalusk',
                        label: 'Osaluskogud, aktiivgrupp',
                    },
                    { group: 'tegevused', id: 'pyhap', label: 'Pühapäevakool' },
                    {
                        group: 'tegevused',
                        id: 'rahv',
                        label: 'Rahvusvahelise noorsootöö võimalused',
                    },
                    {
                        group: 'tegevused',
                        id: 'rehab',
                        label: 'Rehabilitatsiooniprogramm',
                    },
                    { group: 'tegevused', id: 'sport', label: 'Sportimisvõimalused' },
                    { group: 'tegevused', id: 'tugi', label: 'Tugigrupp' },
                    { group: 'tegevused', id: 'taiend', label: 'Täiendõpe' },
                    { group: 'tegevused', id: 'tooj', label: 'Tööjälgimine' },
                    { group: 'tegevused', id: 'tooklubi', label: 'Tööklubi' },
                    { group: 'tegevused', id: 'toopr', label: 'Tööpraktika' },
                    { group: 'tegevused', id: 'toosuvi', label: 'Töösuve programm' },
                    { group: 'tegevused', id: 'vabat', label: 'Vabatahtlik tegevus' },
                    { group: 'tegevused', id: 'vestlus', label: 'Vestlusõhtu' },
                    { group: 'tegevused', id: 'opilasf', label: 'Õpilasfirma' },
                    { group: 'tegevused', id: 'opip', label: 'Õpipoisi programm' },
                    { group: 'tegevused', id: 'oppekaik', label: 'Õppekäik' },
                    { group: 'tegevused', id: 'yhis', label: 'Ühistegevused' },
                ],
                other: { label: 'Muu' },
                vertical: true,
                columns: 3,
            },
            {
                id: 'programm-pohjendus',
                label: 'Programmi valiku põhjendus',
                type: 'text',
                indent: true,
                multiline: true,
            },
            {
                id: 'arend',
                label: 'Arendavate pädevuste valik',
                type: 'multiple-choice-checkbox',
                groups: [
                    { id: 'andmine', label: 'Andmine' },
                    { id: 'tootamine', label: 'Töötamine' },
                    { id: 'olemine', label: 'Olemine' },
                    { id: 'elamine', label: 'Elamine' },
                    { id: 'motlemine', label: 'Mõtlemine' },
                    { id: 'juhtimine', label: 'Juhtimine' },
                    { id: 'suhtlus', label: 'Suhtlus' },
                    { id: 'hoolimine', label: 'Hoolimine' },
                ],
                options: [
                    { group: 'andmine', id: 'vabataht', label: 'Vabatahtlikkus' },
                    { group: 'andmine', id: 'juht', label: 'Juhtimine' },
                    { group: 'andmine', id: 'vastut', label: 'Vastutustundlik kodanik' },
                    { group: 'andmine', id: 'panust', label: 'Panustamine gruppi' },

                    { group: 'tootamine', id: 'turund', label: 'Turundusoskused' },
                    { group: 'tootamine', id: 'meesk', label: 'Meeskonnatöö' },
                    { group: 'tootamine', id: 'enesem', label: 'Enesemotivatsioon' },
                    { group: 'tootamine', id: 'tooh', label: 'Tööharjumus' },

                    { group: 'olemine', id: 'eneseh', label: 'Enesehinnang' },
                    { group: 'olemine', id: 'endav', label: 'Enda eest vastutamine' },
                    { group: 'olemine', id: 'iseloom', label: 'Iseloom/olemus' },
                    { group: 'olemine', id: 'enesed', label: 'Enesedistsipliin' },

                    {
                        group: 'elamine',
                        id: 'tervisl',
                        label: 'Tervisliku eluviisi valikud',
                    },
                    { group: 'elamine', id: 'stress', label: 'Stressijuhtimine' },
                    { group: 'elamine', id: 'haig', label: 'Haiguste ennetus' },
                    { group: 'elamine', id: 'isikl', label: 'Isiklik turvalisus' },

                    { group: 'motlemine', id: 'opp', label: 'Õppima õppimine' },
                    { group: 'motlemine', id: 'otsuste', label: 'Otsuste tegemine' },
                    { group: 'motlemine', id: 'probl', label: 'Probleemide lahendamine' },
                    { group: 'motlemine', id: 'kriit', label: 'Kriitiline mõtlemine' },
                    { group: 'motlemine', id: 'tegev', label: 'Tegevuste mõju' },

                    { group: 'juhtimine', id: 'eesm', label: 'Eesmärgi seadmine' },
                    {
                        group: 'juhtimine',
                        id: 'plan',
                        label: 'Planeerimine/organiseerimine',
                    },
                    { group: 'juhtimine', id: 'tark', label: 'Tark ressursside kasutus' },
                    {
                        group: 'juhtimine',
                        id: 'info',
                        label: 'Informatsiooni haldamine/salvestamine',
                    },
                    { group: 'juhtimine', id: 'elast', label: 'Elastsus/kohanemine' },

                    { group: 'suhtlus', id: 'komm', label: 'Kommunikatsioon' },
                    { group: 'suhtlus', id: 'koostoo', label: 'Koostöö' },
                    { group: 'suhtlus', id: 'sots', label: 'Sotsiaalsed oskused' },
                    { group: 'suhtlus', id: 'konfl', label: 'Konflikti lahendamine' },
                    { group: 'suhtlus', id: 'erin', label: 'Erinevuste aktsepteerimine' },

                    { group: 'hoolimine', id: 'arvest', label: 'Teistega arvestamine' },
                    { group: 'hoolimine', id: 'emp', label: 'Empaatia' },
                    { group: 'hoolimine', id: 'jag', label: 'Jagamine' },
                    {
                        group: 'hoolimine',
                        id: 'heaolu',
                        label: 'Teiste heaolusse panustamine',
                    },
                ],
            },
            {
                id: 'tegevus',
                label: 'Valitud tegevuse kirjeldus',
                type: 'text',
                multiline: true,
            },
            {
                id: 'erikulud',
                label: 'Erikulud kokku',
                type: 'number',
                unit: '\u20ac',
            },
            {
                id: 'erikulud-kirjeldus',
                label: 'Erikulude kirjeldus',
                type: 'text',
                multiline: true,
            },
            {
                id: 'markused',
                label: 'Märkused, näpunäited',
                type: 'text',
                multiline: true,
            },
        ]
    },

    getFeedbackFields(): Field[] {
        return [
            {
                id: 'oluline-muutus',
                label:
                    'Milline on kõige olulisem või olulisemad muutused sinu elus, mis on ' +
                    'toimunud programmis osalemise tõttu? Kuidas?',
                type: 'multiple-choice-checkbox',
                hint: 'Soovitame valida peamised',
                options: [
                    { id: 'leid-too', label: 'Leidsin töö' },
                    { id: 'sob-too', label: 'Sain teada, milline töö mulle sobiks' },
                    { id: 'huvi', label: 'Sain teada, mis mind huvitab' },
                    {
                        id: 'kool-huvi',
                        label: 'Sain teada, mis koolid pakuvad minu huvidele vastavat õpet',
                    },
                    { id: 'tag-kool', label: 'Läksin tagasi kooli' },
                    { id: 'mure', label: 'Leidsin lahenduse oma igapäevaelu muredele' },
                    { id: 'elu', label: 'Sain aru, et pean oma eluga midagi ette võtma' },
                ],
                other: { required: true, label: 'Muu' },
            },
            {
                id: 'vaartus',
                label: 'Mida pead programmis osalemise juures kõige olulisemaks ja väärtuslikumaks?',
                type: 'multiple-choice-checkbox',
                hint: 'Soovitame valida peamised',
                options: [
                    { id: 'aeg', label: 'Noorsootöötajal oli minu jaoks aega' },
                    { id: 'huvi', label: 'Aidati leida minu enda sisemine huvi' },
                    { id: 'vaba', label: 'Kohtumised olid vabas õhkkonnas' },
                    { id: 'koht', label: 'Sain kohtuda omale sobivas kohas' },
                    { id: 'hinnang', label: 'Noorsootöötaja oli hinnangutevaba' },
                    { id: 'toetav', label: 'Leidsin enda jaoks toetava täiskasvanu' },
                    { id: 'opp', label: 'Õppisin midagi uut' },
                ],
                other: { required: true, label: 'Muu' },
            },
            {
                id: 'vajadus',
                label: 'Kas oli midagi, mida oleksid veel programmis osaledes vajanud?',
                type: 'text',
                multiline: true,
                hint:
                    'Oletame, et spetsialisti juurde tuleb noor, kes on olukorras, kus olid sina. ' +
                    'Mis nõu spetsialistile annaksid, et seda noort võimalikult hästi toetada?',
            },
            {
                id: 'markused',
                label: 'Märkused',
                type: 'text',
                hint:
                    'Noorsootöötaja saab ise tagasisidestada, kui midagi ei saa eelnevatesse ' +
                    'lahtritesse lisada.',
                multiline: true,
            },
        ]
    },

    getPhase4Fields(): Field[] {
        return [
            {
                id: 'faas4',
                label: '6 kuud hiljem (4 faas)',
                labelForStats: 'Tulemused',
                type: 'conditional',
                checkboxLabel: 'Keeldub vastusest',
                valueField: {
                    type: 'multiple-choice-checkbox',
                    options: [
                        {
                            id: 'kontakt',
                            label: 'Noorega ei saadud kontakti',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'otsib',
                            label: 'Asunud tööd otsima',
                            details: {
                                type: 'multiple-choice-checkbox',
                                options: [
                                    { id: 'reg', label: 'registreeritud Töötukassas' },
                                    { id: 'sotsm', label: 'otsib tööd sotsiaalmeedia kaudu' },
                                    { id: 'sugul', label: 'otsib tööd sugulaste kaudu' },
                                    { id: 'tooandja', label: 'on pöördunud tööandja poole' },
                                    { id: 'kuulutus', label: 'on lisanud tööotsimiskuulutuse' },
                                    {
                                        id: 'vahendus',
                                        label: 'on pöördunud töövahendusfirma poole',
                                    },
                                ],
                                vertical: true,
                            },
                        },
                        {
                            id: 'tool',
                            label: 'Asunud tööle',
                            details: {
                                type: 'multiple-choice-checkbox',
                                options: [
                                    { id: 'fie', label: 'on FIE' },
                                    { id: 'oy', label: 'on loonud OÜ' },
                                    { id: 'too', label: 'asunud tööle tööandja juures' },
                                ],
                            },
                        },
                        {
                            id: 'ebas',
                            label: 'Ebasoodsa olukorra mõjud on muutunud',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'pole-saav',
                            label: 'Eesmärk ei ole veel saavutatud',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'kool-vastus-pole',
                            label: 'Esitas avalduse kooli, kuid vastus ei ole veel saabunud',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'kool-ootab',
                            label: 'Esitatud avaldus kooli (ootab kooli algusaega)',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'ei',
                            label: 'Infot ei ole võimalik leida',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'too-vastus-pole',
                            label: 'Kandideeris tööle ja vastus ei ole veel saabunud',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'too-algus-pole',
                            label: 'Läbitud konkurss tööle saamiseks, algusaeg ei ole veel saabunud',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'valjasp',
                            label: 'Osaleb erajuhendamisel väljaspool haridussüsteemi',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'eratund',
                            label: 'Osaleb eratundides haridussüsteemi sees',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'huvihar',
                            label: 'Osaleb huvihariduses',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'kursustel',
                            label: 'Osaleb kursustel',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'noorsootoo',
                            label: 'Osaleb noorsootöö võimalustes',
                            details: {
                                type: 'multiple-choice-checkbox',
                                options: [
                                    {
                                        id: 'ava',
                                        label: 'avatud noorsootöö (avatud ruumi teenus)',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'vabat-est',
                                        label: 'vabatahtlik tegevus Eestis',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'solid-est',
                                        label: 'Solidaarkorpuse toel vabatahtlik teenistus (EVT) Eestis',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'rahvusv',
                                        label: 'rahvusvaheline noorsootöö (vabatahtlik tegevus välismaal)',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'solid-vaba-valis',
                                        label: 'Solidaarkorpuse toel vabatahtlik teenistus (EVT) välismaal',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'pikk-huviteg',
                                        label: 'pikaajaline huvitegevus',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'pikk-huvihar',
                                        label: 'pikaajaline huviharidus',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'pikk-proj',
                                        label: 'pikaajaline noorteprojekt',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'osalusk',
                                        label: 'osaluskogu',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'solid-prakt-valis',
                                        label: 'Solidaarkorpuse toel tööjälgimine ja/või praktika välismaal',
                                        details: { type: 'text', multiline: true },
                                    },
                                    {
                                        id: 'solid-too-valis',
                                        label: 'Solidaarkorpuse toel töötamine välismaal',
                                        details: { type: 'text', multiline: true },
                                    },
                                ],
                            },
                        },
                        {
                            id: 'praktika',
                            label: 'Osaleb tööpraktikal',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'star',
                            label: 'Registreeritud KOV juhtumikorraldaja (STAR) juures',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'sojav',
                            label: 'Ajateenistuses',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'syn',
                            label: 'Sünnitus- või vanemapuhkusel',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'ettev',
                            label: 'Tegeleb ettevõtlusega',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'too-katk',
                            label: 'Töötas, kuid katkestas',
                            details: { type: 'text', multiline: true },
                        },
                        {
                            id: 'taseme',
                            label: 'Õpib tasemeõppes',
                            details: { type: 'text', multiline: true },
                        },
                        { id: 'op-katk', label: 'Õppis, kuid katkestas' },
                    ],
                    vertical: true,
                },
            },
        ]
    },
}
