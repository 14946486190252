import { FC } from 'react'

import { Link, LinkProps } from './link'

export interface EmployeeInfoProps {
    employee?: LinkOrTextProps
    centre?: LinkOrTextProps
    notLoggedInText?: string
    logoutLink: LinkProps
}

interface LinkOrTextProps {
    text: string
    url?: string
    id?: string
}

export const EmployeeInfo: FC<EmployeeInfoProps> = (props) => (
    <div style={{ textAlign: 'right' }}>
        {props.employee && (
            <span id="employee-details">
                <LinkOrText {...props.employee} />
                {props.centre && (
                    <span>
                        {' ('}
                        <LinkOrText {...props.centre} />
                        {')'}
                    </span>
                )}
            </span>
        )}
        {props.notLoggedInText}
        <div>
            <Link {...props.logoutLink} />
        </div>
    </div>
)

const LinkOrText: FC<LinkOrTextProps> = (props) => {
    return props.url ? (
        <a id={props.id} href={props.url}>
            {props.text}
        </a>
    ) : (
        props.text
    )
}
