import classnames from 'classnames'
import { FC } from 'react'

export enum IndeterminateCheckboxState {
    checked = 'checked',
    unchecked = 'unchecked',
    indeterminate = 'indeterminate',
}

interface Props {
    checked: IndeterminateCheckboxState
    onClick: () => void
    className?: string
    children?: string
}

const iconNames = {
    checked: 'check',
    unchecked: 'unchecked',
    indeterminate: 'minus',
}

export const IndeterminateCheckbox: FC<Props> = ({ checked, onClick, children, className }) => {
    const outerClassName = classnames('clickable', className)
    const glyphClassName = classnames('glyphicon', 'glyphicon-' + iconNames[checked])
    const label = children ? ' ' + children : null

    // Nested spans to avoid glyphicons default font on the label
    return (
        <span className={outerClassName} onClick={onClick}>
            <span className={glyphClassName} />
            {label}
        </span>
    )
}
