import type { Activity } from '../../server/types'
import { API } from '../api'
import { queuePromise } from '../promise-queue'
import type { AppState, AppView } from '../state'
import { Utils } from '../utils'
import type { VisitorSearchPanelProps } from '../visitor-search-panel'
import { getUsername } from '../visitor-search-utils'
import { reloadMeeting } from './meeting'
import { getVisitorSearchPanelProps } from './visitor-search-panel'

export const getAddParticipantPanelProps = (
    view: AppView,
    meetingId: string,
): VisitorSearchPanelProps => {
    const { state } = view
    const { meeting: meetingState } = state

    const props: VisitorSearchPanelProps = getVisitorSearchPanelProps(view, {
        primaryVisitors: meetingState.centreVisitors!,
        excludedVisitorUsernames: getPresentVisitorUsernames(state),
        includeOtherVisitors: true,
        clearAfterSelection: true,
        onSelectVisitor: async (visitor) => {
            const username = getUsername(visitor)
            return onAddParticipant(view, meetingId, username)
        },
        getHeader: (anythingTyped, anyMatches) => {
            if (!anythingTyped) {
                return 'Alusta kasutajanime trükkimist, et leida oma kasutaja.'
            } else if (anyMatches) {
                return 'Kui leiad oma kasutaja, kliki selle peale:'
            } else {
                return ''
            }
        },
        addNew: {
            onAddParticipant: async (username) => {
                await onAddParticipant(view, meetingId, username)
            },
        },
    })

    return props
}

const getPresentVisitorUsernames = (state: AppState) => {
    const usernames = new Set<string>()

    for (const participation of state.meeting.meeting!.participations) {
        if (!participation.departed) {
            const visitor = state.meeting.visitors![participation.visitorId]
            usernames.add(visitor.username)
        }
    }

    return usernames
}

const onAddParticipant = async (view: AppView, meetingId: string, username: string) => {
    const { state } = view
    const { meeting } = state

    const activityConf = Utils.getActivityConf(meeting.event!, meeting.meeting!)

    if (activityConf.mode === 'none') {
        await addParticipation(view, meetingId, username, null)
    } else {
        meeting.activityModalConf = {
            action: { type: 'add', username },
        }

        view.update()
    }
}

export const addParticipation = async (
    view: AppView,
    meetingId: string,
    username: string,
    activity: Activity,
) => {
    await queuePromise(async () => API.addParticipation(view, meetingId, username, activity))
    return reloadMeeting(view, meetingId, { meeting: true, logEntries: true })
}
