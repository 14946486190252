import { ChangeEvent, Component } from 'react'

import { Meeting } from '../server/types'
import { LoadingButton } from './loading-button'

export interface MeetingNotesProps {
    meeting: Meeting
    onSave: (notes: string) => Promise<void>
}

interface State {
    notes: string
}

export class MeetingNotes extends Component<MeetingNotesProps, State> {
    state: State = { notes: this.props.meeting.notes || '' }

    render() {
        return (
            <div>
                <h2>Kohtumise märkmed:</h2>
                <textarea
                    style={{ width: '100%', maxWidth: 800, height: 300 }}
                    value={this.state.notes}
                    onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
                        this.setState({ notes: evt.currentTarget.value })
                    }}
                />
                <div>
                    <LoadingButton
                        text="Salvesta"
                        getPromise={async () => this.props.onSave(this.state.notes)}
                    />
                </div>
            </div>
        )
    }
}
