import { Component } from 'react'
import toastr from 'toastr'

import { API } from './api'
import { getInitialParentNotificationState } from './initial-state'
import { Loading } from './loading'
import { LoadingButton } from './loading-button'
import { ManageCentreMenu } from './manage-centre-menu'
import { Attachments } from './parent-notification/attachments'
import { getCentre } from './session'
import { AppView } from './state'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

interface ParentNotificationProps {
    view: AppView // TODO refactor props
}

interface State {
    loaded: boolean
    validationErrors?: Record<string, ValidationError>
    fileOperationInProgress: boolean
}

export class ParentNotification extends Component<ParentNotificationProps, State> {
    unmounted = false

    state: State = { loaded: false, fileOperationInProgress: false }

    componentDidMount() {
        this.load()
    }

    load() {
        const { view } = this.props
        const { parentNotificationState } = view.state
        const { parentNotification } = getCentre(view)!

        if (parentNotification) {
            const { subject, textTemplate } = parentNotification
            parentNotificationState.subject = subject
            parentNotificationState.textTemplate = textTemplate
            view.update()
        }

        this.setState({ loaded: true })
    }

    componentWillUnmount() {
        const { view } = this.props
        const { state } = view
        state.parentNotificationState = getInitialParentNotificationState()
        view.update()
        this.unmounted = true
    }

    updateFileOperationInProgress(fileOperationInProgress: boolean) {
        if (fileOperationInProgress !== this.state.fileOperationInProgress) {
            this.setState({ fileOperationInProgress })
        }
    }

    renderSubjectInput() {
        const { view } = this.props
        const { state } = view
        const { parentNotificationState } = state

        return (
            <tr>
                <td className="form-label">Pealkiri</td>
                <td>
                    <input
                        style={{ width: '30em' }}
                        type="text"
                        value={state.parentNotificationState.subject}
                        onChange={(evt) => {
                            parentNotificationState.subject = evt.target.value
                            view.update()
                        }}
                    />
                    {renderValidationError(this.state.validationErrors, 'subject')}
                </td>
            </tr>
        )
    }

    renderTextTemplateInput() {
        const { view } = this.props
        const { state } = view
        const { parentNotificationState } = state

        return (
            <tr>
                <td className="form-label">
                    Sisu
                    <div className="form-hint">
                        {
                            '_külastaja_ asendatakse külastaja nimega, _keskus_ asendatakse keskuse nimega, '
                        }
                        <br />
                        {'_keskus-email_ asendatakse keskuse e-maili aadressiga juhul kui see on keskuse ' +
                            'ankeedis määratud'}
                    </div>
                </td>
                <td>
                    <textarea
                        rows={10}
                        cols={50}
                        onChange={(evt) => {
                            parentNotificationState.textTemplate = evt.target.value
                            view.update()
                        }}
                        value={state.parentNotificationState.textTemplate}
                    />
                    {renderValidationError(this.state.validationErrors, 'textTemplate')}
                </td>
            </tr>
        )
    }

    async save() {
        const { view } = this.props
        const { state } = view
        const { subject, textTemplate } = state.parentNotificationState

        try {
            await clearValidationErrors(view, this)
            await API.updateParentNotification(view, subject, textTemplate)
            toastr.info('Muudatused salvestatud')
            this.load()
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    renderSaveButton() {
        return (
            <tr>
                <td>
                    <LoadingButton text="Salvesta" getPromise={async () => this.save()} />
                </td>
            </tr>
        )
    }

    renderForm() {
        return (
            <table className="extra-padded vtop">
                <tbody>
                    {this.renderSubjectInput()}
                    {this.renderTextTemplateInput()}
                    {this.renderSaveButton()}
                </tbody>
            </table>
        )
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div>
                <ManageCentreMenu view={view} active="parent-notification" />
                <div className="main-panel">
                    <h2>Külastaja registreerimisel lapsevanemale saadetav kiri</h2>
                    {this.renderForm()}
                    <Attachments
                        view={view}
                        updateFileOperationInProgress={(inProgress) =>
                            this.updateFileOperationInProgress(inProgress)
                        }
                    />
                </div>
            </div>
        )
    }
}
