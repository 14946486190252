import { Component } from 'react'

import { API, ManagedVisitorExt } from './api'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { getVisitorSearchPanelProps } from './props/visitor-search-panel'
import { AppView } from './state'
import { Utils } from './utils'
import { VisitorSearchPanel } from './visitor-search-panel'
import { isApiVisitor, VisitorMatch } from './visitor-search-utils'

interface NewCardProps {
    view: AppView // TODO refactor props
}

interface State {
    loaded: boolean
    managedVisitors?: Record<string, ManagedVisitorExt>
}

export class NewCard extends Component<NewCardProps, State> {
    state: State = { loaded: false }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const managedVisitors = await API.getManagedVisitors(view)

        if (!this.unmounted) {
            await Utils.setState(this, { loaded: true, managedVisitors })
            await EventBus.fire('card-creator-rendered')
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async onSelectVisitor(visitor: VisitorMatch) {
        const { view } = this.props
        let visitorId: string

        if (isApiVisitor(visitor)) {
            visitorId = visitor._id
        } else {
            const foreignVisitor = await API.getVisitorId(view, visitor)
            visitorId = foreignVisitor._id
        }

        view.navigate(['cards', 'new', visitorId])
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        const rightPanel = (
            <div>
                <h2>Uue juhtumikaardi loomine</h2>
                <p>
                    Juhtumikaardi loomiseks leia vasakul olemasolev külastaja ning vajuta tema
                    nimele.
                </p>
                <p>
                    Kui noor pole veel keskust külastanud ning oma andmeid sisestanud, on võimalik
                    juhtumikaarti luua ka ilma külastaja profiilita.
                </p>
                <p>
                    {'Selleks '}
                    <a href="#/cards/new-unlinked">vajutage siia</a>.
                </p>
                <p>
                    Kui noor loob hiljem endale tavapärase külastaja profiili, on teil võimalik
                    juhtumikaarti tagantjärgi tema uue profiiliga siduda.
                </p>
            </div>
        )

        const visitorSearchPanelProps = getVisitorSearchPanelProps(view, {
            primaryVisitors: this.state.managedVisitors,
            includeOtherVisitors: true,
            clearAfterSelection: true,
            onSelectVisitor: async (visitor) => this.onSelectVisitor(visitor),
            getHeader: () => 'Alusta kasutajanime trükkimist, et leida külastaja.',
        })

        return (
            <table className="vtop">
                <tbody>
                    <tr>
                        <td className="left-panel">
                            <h2>Vali külastaja</h2>
                            <VisitorSearchPanel {...visitorSearchPanelProps} />
                        </td>
                        <td className="right-panel">{rightPanel}</td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
