import classnames from 'classnames'
import { FC, HTMLAttributes } from 'react'

interface Props {
    onClick: () => void
    title?: string
    className?: string
    disabled?: boolean
}

interface ActionButtonProps extends Props {
    iconName: string
    actionType?: 'default' | 'info' | 'success' | 'danger'
}

export const ActionButton: FC<ActionButtonProps> = (props) => {
    const { iconName, onClick, disabled, title } = props
    const actionType = props.actionType || 'default'

    const className = classnames(
        'action-' + actionType,
        'action-button',
        { 'action-disabled': disabled },
        'glyphicon',
        'glyphicon-' + iconName,
        props.className,
    )

    const spanProps: HTMLAttributes<HTMLSpanElement> = disabled
        ? { className, title }
        : { className, onClick, title }

    return <span {...spanProps} />
}

export const BackButton: FC<Props> = (props) => {
    const { onClick, title } = props

    const text = title || 'Tagasi'

    const className = classnames('action-default', 'action-button', props.className)

    return (
        <span className={className} onClick={onClick} title={text}>
            {text}
        </span>
    )
}

export const EditButton: FC<Props> = ({ title, onClick, className, disabled }) => (
    <ActionButton
        onClick={onClick}
        title={title || 'Muuda'}
        iconName="pencil"
        actionType="info"
        className={className}
        disabled={disabled}
    />
)

export const DeleteButton: FC<Props> = ({ title, onClick, className, disabled }) => (
    <ActionButton
        onClick={onClick}
        title={title || 'Kustuta'}
        iconName="trash"
        actionType="danger"
        className={className}
        disabled={disabled}
    />
)

export const ConfirmButton: FC<Props> = ({ title, onClick, className, disabled }) => (
    <ActionButton
        onClick={onClick}
        title={title || 'Kinnita'}
        iconName="ok"
        actionType="success"
        className={className}
        disabled={disabled}
    />
)

export const DiscardButton: FC<Props> = ({ title, onClick, className, disabled }) => (
    <ActionButton
        onClick={onClick}
        title={title || 'Tühista'}
        iconName="remove"
        actionType="default"
        className={className}
        disabled={disabled}
    />
)
