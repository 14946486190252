import {
    ChangeEvent,
    ClassAttributes,
    Component,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
} from 'react'

export const bindProps = <P, S>(
    component: Component<P, S>,
    fieldName: keyof S,
    otherProps?: ClassAttributes<HTMLInputElement> &
        InputHTMLAttributes<HTMLInputElement> &
        ClassAttributes<HTMLTextAreaElement> &
        TextareaHTMLAttributes<HTMLTextAreaElement>,
) => {
    const props = otherProps || {}
    const isCheckbox = props.type === 'checkbox'
    const checkedOrValue = isCheckbox ? 'checked' : 'value'

    props.onChange = (evt: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
        component.setState({
            [fieldName]: evt.currentTarget[checkedOrValue],
        } as unknown as Pick<S, keyof S>)
    }

    const value = fieldName in component.state ? component.state[fieldName] : ''
    props[checkedOrValue] = value as any // TODO
    return props
}
