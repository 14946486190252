import { MissingFeatures } from '../server/types'

let testMode = false

export const setMissingFeaturesTestMode = () => (testMode = true)

export const getMissingFeatures = () => {
    if (testMode) {
        return undefined // TODO return object with empty arrays?
    }

    // 'critical' is currently unused, but features can be moved there from 'optional' as needed
    const missingFeatures: MissingFeatures = { critical: [], optional: [] }

    try {
        window.eval('({ ...{} })')
    } catch (e) {
        missingFeatures.optional.push('object-spread')
    }

    try {
        window.eval('let { ...a } = {}')
    } catch (e) {
        missingFeatures.optional.push('object-rest')
    }

    if (!Array.prototype.includes) {
        missingFeatures.optional.push('Array.includes')
    }

    if (!Array.prototype.flat) {
        missingFeatures.optional.push('Array.flat')
    }

    if (!Array.prototype.flatMap) {
        missingFeatures.optional.push('Array.flatMap')
    }

    // @ts-expect-error Waiting for support in newer TypeScript version
    if (!Map.prototype.groupBy) {
        missingFeatures.optional.push('Map.groupBy')
    }

    if (!Promise.prototype.finally) {
        missingFeatures.optional.push('Promise.finally')
    }

    // @ts-expect-error Waiting for support in newer TypeScript version
    if (!Set.prototype.addAll) {
        missingFeatures.optional.push('Set.addAll')
    }

    // @ts-expect-error Waiting for support in newer TypeScript version
    if (!Set.prototype.intersection) {
        missingFeatures.optional.push('Set.intersection')
    }

    if (!String.prototype.padStart) {
        missingFeatures.optional.push('String.padStart')
    }

    if (!String.prototype.replaceAll) {
        missingFeatures.optional.push('String.replaceAll')
    }

    if (!String.prototype.trimLeft) {
        missingFeatures.optional.push('String.trimLeft')
    }

    return missingFeatures
}
