import classnames from 'classnames'

export interface Button<T> {
    value: T
    label: string
    /** DOM id */
    id?: string
    /** DOM class */
    className?: string
}

export interface ButtonGroupProps<T> {
    buttons: Button<T>[]
    onClick: (value: T) => void
    active: T | undefined
    vertical?: boolean
    disabled?: boolean
    /** DOM id */
    id?: string
    /** DOM class */
    className?: string
}

const isActive = <T,>(props: ButtonGroupProps<T>, button: Button<T>) => {
    return props.active === button.value
}

export const ButtonGroup = <T,>(props: ButtonGroupProps<T>) => {
    const groupClassName = classnames(
        props.vertical ? 'btn-group-vertical' : 'btn-group',
        props.className,
    )

    return (
        <div id={props.id} className={groupClassName} data-toggle="buttons">
            {props.buttons.map((button) => {
                const className = classnames(
                    'btn btn-default',
                    isActive(props, button) && 'active',
                    props.disabled && 'disabled',
                    button.className,
                )

                return (
                    <label key={String(button.value)} id={button.id} className={className}>
                        <input type="radio" onClick={() => props.onClick!(button.value)} />
                        {button.label}
                    </label>
                )
            })}
        </div>
    )
}

export const renderButtonGroup = <T,>(props: ButtonGroupProps<T>) => <ButtonGroup {...props} />
