import { Component, ReactNode } from 'react'

import {
    EventDurationStats_Meeting,
    EventDurationStatsData,
} from '../server/commands/get-event-duration-stats-data'
import { Centre } from '../server/types'
import { centreFormFields } from '../util/centre-form-fields'
import { isAdmin } from './access'
import { API } from './api'
import { renderButtonGroup } from './button-group'
import { Loading } from './loading'
import { AppView } from './state'
import { Utils } from './utils'

export interface EventDurationStatsProps {
    view: AppView // TODO refactor props
    dateFrom?: string
    dateTo?: string
    includeArchived: boolean
    centreIds: string[] | null
    allCentres?: Record<string, Centre>
}

interface State {
    loaded: boolean
    program: string
    data?: EventDurationStatsData
}

export class EventDurationStats extends Component<EventDurationStatsProps, State> {
    state: State = { loaded: false, program: '_' }
    unmounted = false

    async componentDidMount() {
        await this.loadData(this.props.centreIds)
    }

    async componentDidUpdate(prevProps: EventDurationStatsProps) {
        if (this.isAdminMode() && !Utils.arraysEqual(this.props.centreIds!, prevProps.centreIds!)) {
            this.setState({ loaded: false })
            await this.loadData(this.props.centreIds)
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async loadData(centreIds: string[] | null) {
        const { view } = this.props
        const statsData = await API.getEventDurationStatsData(view, centreIds)

        if (!this.unmounted) {
            this.setState({ loaded: true, data: statsData })
        }
    }

    isAdminMode() {
        const { view } = this.props
        const { session } = view.state
        return session.loggedIn && isAdmin(session.employee) && !session.centre
    }

    isFiltered() {
        return this.state.program !== '_'
    }

    calculateTime(meetings: EventDurationStats_Meeting[]) {
        const program = this.state.program === '_' ? null : this.state.program
        const result = { totalMinutes: 0, unknownCount: 0 }

        meetings.forEach((meeting) => {
            if (program && meeting.program !== program) {
                return
            }

            const sameDay = meeting.endDate === '' || meeting.endDate === meeting.startDate

            if (!meeting.startTime || !meeting.endTime) {
                result.unknownCount += 1
            } else if (sameDay && meeting.startTime > meeting.endTime) {
                throw new Error(
                    'Start time cannot be after end time: ' +
                        meeting.startTime +
                        '-' +
                        meeting.endTime +
                        ' on ' +
                        meeting._id,
                )
            } else {
                if (!sameDay) {
                    if (meeting.startDate > meeting.endDate!) {
                        throw new Error(
                            'Start date cannot be after end time: ' +
                                meeting.startDate +
                                ' - ' +
                                meeting.endDate +
                                ' on ' +
                                meeting._id,
                        )
                    }

                    const start = new Date(meeting.startDate).getTime()
                    const end = new Date(meeting.endDate!).getTime()

                    // Divide milliseconds by 60000 to get minutes
                    result.totalMinutes += (end - start) / 60000
                }

                const startMin = Utils.minutesSinceMidnight(meeting.startTime)
                const endMin = Utils.minutesSinceMidnight(meeting.endTime)
                result.totalMinutes += endMin - startMin
            }
        })

        return result
    }

    renderProgramFilter() {
        const programField = Utils.findById(centreFormFields, 'seotud-programmid')!
        const programButtons = [{ value: '_', label: 'Ära filtreeri' }]

        // TODO: hide filter if no programs selected or no centre form submitted?
        // (When adding advanced features, unduplicate with event-stats)
        for (const key of programField.keys!) {
            if (!key.deprecated) {
                programButtons.push({ value: key.id, label: key.label! })
            }
        }

        return (
            <div style={{ marginBottom: 10 }}>
                {'Programm: '}
                {renderButtonGroup({
                    buttons: programButtons,
                    active: this.state.program,
                    onClick: (value) => this.setState({ program: value }),
                })}
            </div>
        )
    }

    renderRows(eventIds: string[], byEvent: Record<string, EventDurationStats_Meeting[]>) {
        const rows: ReactNode[] = []
        const centreIds: Record<string, true> = {}

        eventIds.forEach((eventId) => {
            const timeInfo = this.calculateTime(byEvent[eventId])

            if (timeInfo.totalMinutes === 0 && timeInfo.unknownCount === 0) {
                return
            }

            const fullHours = Math.floor(timeInfo.totalMinutes / 60)
            const remainingMinutes = timeInfo.totalMinutes % 60

            let minutesText: string | null = null

            if (remainingMinutes) {
                minutesText = ' ja ' + Utils.pluralize(remainingMinutes, 'minut', 'minutit')
            }

            let info: string | null = null

            if (timeInfo.unknownCount > 0) {
                info = Utils.pluralize(
                    timeInfo.unknownCount,
                    'määramata kestusega kohtumine',
                    'määramata kestusega kohtumist',
                )
            }

            let centreCell: ReactNode = null
            let eventElement: ReactNode
            const evt = this.state.data!.events[eventId]

            if (this.isAdminMode()) {
                centreIds[evt.centre!] = true
                const centre = this.props.allCentres![evt.centre!]
                centreCell = <td>{centre.name}</td>
                eventElement = evt.name
            } else {
                eventElement = <a href={'#/events/' + evt._id}>{evt.name}</a>
            }

            const row = (
                <tr key={eventId}>
                    {centreCell}
                    <td>{eventElement}</td>
                    <td style={{ textAlign: 'right' }}>
                        {Utils.pluralize(fullHours, 'tund', 'tundi')}
                        {minutesText}
                    </td>
                    <td>{info}</td>
                </tr>
            )

            rows.push(row)
        })

        if (this.isAdminMode()) {
            const centreCount = Object.keys(centreIds).length

            const row = (
                <tr key="num_centres">
                    <td colSpan={4}>
                        {`Statistika arvutatud ${Number(centreCount)} keskuse andmete põhjal`}
                    </td>
                </tr>
            )

            rows.push(row)
        }

        return rows
    }

    renderTable(eventIds: string[], byEvent: Record<string, EventDurationStats_Meeting[]>) {
        const rows = this.renderRows(eventIds, byEvent)

        if (rows.length > 0) {
            return (
                <div>
                    <div style={{ marginBottom: 10 }}>
                        Kokku on arvestatud ainult nende kohtumiste kestused, millel on määratud nii
                        {' algus- kui lõpukellaajad.'}
                        <br />
                        Ülejäänud kohtumiste arv on näidatud "Lisainfo" tulbas.
                    </div>
                    <table className="bordered">
                        <thead>
                            <tr>
                                {this.isAdminMode() ? <th>Keskus</th> : null}
                                <th>Sündmus</th>
                                <th>Kestus</th>
                                <th>Lisainfo</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div>
            )
        } else {
            const message = this.isFiltered()
                ? 'Praegustele filtritele vastavaid andmeid ei leidunud.'
                : 'Andmeid ei leidunud.'

            return <div>{message}</div>
        }
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const meetings = Utils.filterMeetingsByDate(
            this.state.data!.meetings,
            this.props.dateFrom,
            this.props.dateTo,
        )

        if (!meetings.length) {
            return (
                <div className="main-panel">Valitud perioodil pole toimunud ühtegi kohtumist.</div>
            )
        }

        const byEvent: Record<string, EventDurationStats_Meeting[]> = {}

        for (const meeting of meetings) {
            const evt = this.state.data!.events[meeting.event]

            if (!evt.archived || this.props.includeArchived) {
                const meetingsOfEvent = Utils.member(byEvent, meeting.event, [])
                meetingsOfEvent.push(meeting)
            }
        }

        const eventIds = Object.keys(byEvent)

        Utils.sortAsStrings(eventIds, (eventId) => {
            const evt = this.state.data!.events[eventId]
            const eventName = evt.name.toLowerCase()

            if (this.isAdminMode()) {
                const centre = this.props.allCentres![evt.centre!]
                return centre.name.toLowerCase() + '-' + eventName
            } else {
                return eventName
            }
        })

        return (
            <div className="main-panel">
                {this.renderProgramFilter()}
                {this.renderTable(eventIds, byEvent)}
            </div>
        )
    }
}
