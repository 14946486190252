import { Component, ReactNode } from 'react'
import toastr from 'toastr'

import { API } from './api'
import { bindProps } from './bind-props'
import { renderButtonGroup } from './button-group'
import { Enums } from './enums'
import { EventBus } from './event-bus'
import { LoadingIcon } from './loading-icon'
import { ManageCentreMenu } from './manage-centre-menu'
import { Session } from './session'
import { AppView } from './state'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

interface ManageCentreProps {
    view: AppView // TODO refactor props
}

interface State {
    settingsProcessing: boolean
    name: string
    noteInfo: string
    visitorAdditionRestricted: boolean
    validationErrors?: Record<string, ValidationError>
}

export class ManageCentre extends Component<ManageCentreProps, State> {
    unmounted = false

    constructor(props: ManageCentreProps) {
        super(props)

        const centre = Session.getCentre(props.view)!

        this.state = {
            settingsProcessing: false,
            name: centre.name,
            noteInfo: centre.noteInfo || '',
            visitorAdditionRestricted: centre.visitorAdditionRestricted || false,
        }
    }

    async componentDidMount() {
        await EventBus.fire('centre-manager-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async save() {
        const { view } = this.props
        this.setState({ settingsProcessing: true })
        await clearValidationErrors(view, this)

        try {
            const centreName = this.state.name.trim()
            const noteInfo = this.state.noteInfo.trim()
            await API.updateCentre(view, centreName, noteInfo, this.state.visitorAdditionRestricted)
            toastr.info('Muudatused salvestatud')
        } catch (error) {
            await checkValidationErrors(view, this, error)
        } finally {
            this.setState({ settingsProcessing: false })
        }
    }

    renderSaveButton() {
        if (this.state.settingsProcessing) {
            return <LoadingIcon />
        } else {
            return <button onClick={async () => this.save()}>Salvesta</button>
        }
    }

    renderSettings(): ReactNode {
        const { view } = this.props
        const centre = Session.getCentre(view)!

        return (
            <table className="extra-padded vtop">
                <tbody>
                    <tr>
                        <td>Keskuse nimi:</td>
                        <td>
                            <input {...bindProps(this, 'name', { style: { width: 400 } })} />
                            {renderValidationError(this.state.validationErrors, 'name')}
                        </td>
                    </tr>
                    <tr>
                        <td>Maakond:</td>
                        <td>{Enums.counties[centre.county]}</td>
                    </tr>
                    <tr>
                        <td>Lisainfo osaleja vormil märkmete juures:</td>
                        <td>
                            <input {...bindProps(this, 'noteInfo', { style: { width: 400 } })} />
                            {renderValidationError(this.state.validationErrors, 'noteInfo')}
                            <div className="input-note">
                                Kui jätta tühjaks, kasutatakse vaikimisi teksti "Võib jätta
                                tühjaks".
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Uute külastaja kontode loomine:</td>
                        <td>
                            {renderButtonGroup({
                                buttons: [
                                    {
                                        value: false,
                                        label: 'Lubatud nii osaleja kui töötaja režiimis',
                                    },
                                    {
                                        value: true,
                                        label: 'Osaleja režiimis keelatud, töötaja režiimis lubatud',
                                    },
                                ],
                                active: this.state.visitorAdditionRestricted,
                                onClick: (value) =>
                                    this.setState({ visitorAdditionRestricted: value }),
                                vertical: true,
                            })}
                        </td>
                    </tr>
                    <tr>
                        <td />
                        <td>{this.renderSaveButton()}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render() {
        const { view } = this.props
        return (
            <div>
                <ManageCentreMenu view={view} active="general" />
                <div className="main-panel">{this.renderSettings()}</div>
            </div>
        )
    }
}
