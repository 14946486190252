import * as Sentry from '@sentry/react'

import { Area } from '../server/areas'
import { AddCard_Response, CardInput } from '../server/commands/add-card'
import { AddCardDiaryEntry_Response } from '../server/commands/add-card-diary-entry'
import { AddCardDiaryFile_Response } from '../server/commands/add-card-diary-file'
import { AddEmployee_Response, AddEmployeeParams } from '../server/commands/add-employee'
import {
    AddEmployeeToCentre_Response,
    AddEmployeeToCentreParams,
} from '../server/commands/add-employee-to-centre'
import { AddEvent_Response } from '../server/commands/add-event'
import { AddMeeting_Response } from '../server/commands/add-meeting'
import { AddParentNotificationAttachment_Response } from '../server/commands/add-parent-notification-attachment'
import { AddVisitor_Response } from '../server/commands/add-visitor'
import { ExistingCardInfo } from '../server/commands/check-existing-card'
import { EnterEmployeeMode_Response } from '../server/commands/enter-employee-mode'
import { EnterParticipantMode_Response } from '../server/commands/enter-participant-mode'
import { CardNameInfo, FindCardsByName_Params } from '../server/commands/find-cards-by-name'
import { GetActivityOptionUsage_Result } from '../server/commands/get-activity-option-usage'
import { GetAllCards_Card, GetAllCards_Result } from '../server/commands/get-all-cards'
import { GetAvailableCentres_Centre } from '../server/commands/get-available-centres'
import { GetCardAndEmployees_Result } from '../server/commands/get-card-and-employees'
import { GetCardDiaryEntry_Response } from '../server/commands/get-card-diary-entry'
import { GetCardDiaryFiles_Response } from '../server/commands/get-card-diary-files'
import { GetCentreCards_Response } from '../server/commands/get-centre-cards'
import {
    GetCentreVisitors_Response,
    GetCentreVisitors_Visitor,
} from '../server/commands/get-centre-visitors'
import { CollabStatsCentre } from '../server/commands/get-collaborator-stats-data'
import { EmployeeForCards } from '../server/commands/get-employees-for-cards'
import { EventCategoryHierarchy } from '../server/commands/get-event-category-hierarchy'
import { EventDurationStatsData } from '../server/commands/get-event-duration-stats-data'
import { EventStatsData } from '../server/commands/get-event-stats-data'
import { GetFileStorageInfo_Response } from '../server/commands/get-file-storage-info'
import { GetLogEntries_Response } from '../server/commands/get-log-entries'
import { ManagedVisitor } from '../server/commands/get-managed-visitors'
import { MeetingVisitor } from '../server/commands/get-meeting-visitors'
import { GetMeetings_Response } from '../server/commands/get-meetings'
import { GetVisitor_Visitor } from '../server/commands/get-visitor'
import { VisitorAddition } from '../server/commands/get-visitor-additions'
import { VisitorMergeInfo } from '../server/commands/get-visitor-merge-info'
import { VisitorRequestInfo } from '../server/commands/get-visitor-request-info'
import { VisitorStatsData } from '../server/commands/get-visitor-stats-data'
import { VisitorForCards } from '../server/commands/get-visitors-for-cards'
import { GivePasswordAccess_Response } from '../server/commands/give-password-access'
import { Init_Response } from '../server/commands/init'
import { LinkCard_Response } from '../server/commands/link-card'
import { Login_Response } from '../server/commands/login'
import { Logout_Response } from '../server/commands/logout'
import { RemoveCardDiaryFile_Response } from '../server/commands/remove-card-diary-file'
import { RemoveEmployeeFromCentre_Response } from '../server/commands/remove-employee-from-centre'
import { RemoveParentNotificationAttachment_Response } from '../server/commands/remove-parent-notification-attachment'
import { RemovePasswordAccess_Response } from '../server/commands/remove-password-access'
import { SelectAdminMode_Response } from '../server/commands/select-admin-mode'
import { SelectCentre_Response } from '../server/commands/select-centre'
import { UnlinkCard_Response } from '../server/commands/unlink-card'
import { CardUpdate } from '../server/commands/update-card'
import { UpdateCardEmployee_Response } from '../server/commands/update-card-employee'
import { UpdateCardPhase_Params } from '../server/commands/update-card-phase'
import { UpdateCentre_Response } from '../server/commands/update-centre'
import { UpdateParentNotification_Response } from '../server/commands/update-parent-notification'
import {
    UpdatePermissions_Response,
    UpdatePermissionsParams,
} from '../server/commands/update-permissions'
import { UpdateSelf_Response } from '../server/commands/update-self'
import { Country } from '../server/countries'
import { LimitedEmployee } from '../server/remove-employee-fields'
import { School, SchoolWithId } from '../server/schools'
import {
    Activity,
    AnonymousGroup,
    CardDiaryEntry,
    CardDiaryEntryInput,
    Centre,
    CentreForm,
    CentreFormContents,
    County,
    Event,
    EventCategory,
    EventInput,
    EventUpdate,
    Meeting,
    MeetingDetailUpdate,
    MeetingInput,
    UserContext,
    VisitorInput,
    VisitorUpdate,
} from '../server/types'
import { CardAction } from '../util/card-action'
import { CommonUtils } from './common-utils'
import { EventBus } from './event-bus'
import { getMissingFeatures } from './missing-features'
import { handleRequestFailure } from './request-failure-handler'
import { AppView } from './state'
import { Utils } from './utils'

export interface RequestLogger {
    warn: (message: unknown) => void
    error: (message: unknown) => void
}

export interface FailureJson {
    error: string
    errorType: string
}

export type RequestFailureHandler = (
    statusCode: number,
    json: FailureJson,
    logger: RequestLogger,
) => never

export interface RequestHandler {
    execute: <Params, Response>(
        command: string,
        params: Params,
        handleFailure: RequestFailureHandler,
    ) => Promise<Response>
}

// TODO rename
export interface ManagedVisitorExt extends ManagedVisitor {
    currentCentre: true
    managingCentreId?: string
}

export interface GetCentreVisitors_ApiVisitor extends GetCentreVisitors_Visitor {
    currentCentre: boolean
}

interface ResponseWithContext {
    success: boolean
    context?: UserContext
}

let requestHandler: RequestHandler

// Some commands have a non-void response (usually { success: true }), but are defaulting
// to void as the response type here because the response is not used on client side.
const post = async <Response = void>(
    view: AppView,
    command: string,
    params?: unknown,
): Promise<Response> => {
    // TODO add generic type for params
    return requestHandler.execute(command, params, (statusCode, json, logger) => {
        return handleRequestFailure(view, statusCode, json, logger)
    })
}

const updateSession = async <T extends ResponseWithContext>(view: AppView, response: T) => {
    if (response.success && response.context) {
        await setContext(view, response.context)
    }

    return response
}

const setContext = async (view: AppView, context: UserContext) => {
    view.state.session = context
    view.update()

    await EventBus.fire('user-context-updated')
    Sentry.setUser(getSentryUser(context))

    Sentry.addBreadcrumb({
        type: 'user',
        category: 'auth',
        message: 'User context updated',
        data: getBreadcrumbData(context),
    })
}

const getSentryUser = (context: UserContext): Sentry.User | null => {
    if (!context.loggedIn) {
        return null
    }

    const { employee } = context

    return {
        id: employee._id,
        email: employee.email,
        username: employee.username,
        centreId: context.centre?._id,
        employeeMode: context.employeeMode,
        meetingId: context.meetingId,
    }
}

const getBreadcrumbData = (context: UserContext) => {
    if (!context.loggedIn) {
        return { loggedIn: false }
    }

    return {
        username: context.employee.username,
        centreId: context.centre?._id,
        employeeMode: context.employeeMode,
        meetingId: context.meetingId,
    }
}

// TODO export members
export const API = {
    // Utilities

    setRequestHandler: (newHandler: RequestHandler) => (requestHandler = newHandler),

    // Commands

    async enterEmployeeMode(view: AppView, password: string) {
        const response = await post<EnterEmployeeMode_Response>(view, 'enterEmployeeMode', {
            password,
        })
        return updateSession(view, response)
    },

    async enterParticipantMode(view: AppView, meetingId: string) {
        const response = await post<EnterParticipantMode_Response>(view, 'enterParticipantMode', {
            meetingId,
        })

        return updateSession(view, response)
    },

    async getAvailableCentres(view: AppView) {
        return post<GetAvailableCentres_Centre[]>(view, 'getAvailableCentres')
    },

    async selectCentre(view: AppView, centreId: string) {
        const response = await post<SelectCentre_Response>(view, 'selectCentre', {
            centreId,
            missingFeatures: getMissingFeatures(),
        })

        return updateSession(view, response)
    },

    async archiveCentre(view: AppView, centreId: string) {
        return post<{ success: boolean }>(view, 'archiveCentre', { centreId })
    },

    async selectAdminMode(view: AppView) {
        const response = await post<SelectAdminMode_Response>(view, 'selectAdminMode')
        return updateSession(view, response)
    },

    async init(view: AppView) {
        const response = await post<Init_Response>(view, 'init')
        return updateSession(view, response)
    },

    async login(view: AppView, username: string, password: string) {
        const missingFeatures = getMissingFeatures()
        const response = await post<Login_Response>(view, 'login', {
            username,
            password,
            missingFeatures,
        })
        return updateSession(view, response)
    },

    async logout(view: AppView) {
        const response = await post<Logout_Response>(view, 'logout')
        return updateSession(view, response)
    },

    async getFileStorageInfo(view: AppView) {
        return post<GetFileStorageInfo_Response>(view, 'getFileStorageInfo')
    },

    async getCentres(view: AppView) {
        return post<Record<string, Centre>>(view, 'getCentres')
    },

    async addCentre(view: AppView, centreName: string, county: County) {
        return post(view, 'addCentre', { name: centreName, county })
    },

    async updateCentre(
        view: AppView,
        centreName: string,
        noteInfo: string,
        visitorAdditionRestricted: boolean,
    ) {
        const response = await post<UpdateCentre_Response>(view, 'updateCentre', {
            name: centreName,
            noteInfo,
            visitorAdditionRestricted,
        })

        return updateSession(view, response)
    },

    async updateParentNotification(view: AppView, subject: string, textTemplate: string) {
        const response = await post<UpdateParentNotification_Response>(
            view,
            'updateParentNotification',
            {
                subject,
                textTemplate,
            },
        )

        return updateSession(view, response)
    },

    async addParentNotificationAttachment(view: AppView, filename: string, content: string) {
        const response = await post<AddParentNotificationAttachment_Response>(
            view,
            'addParentNotificationAttachment',
            { filename, content },
        )

        return updateSession(view, response)
    },

    async removeParentNotificationAttachment(view: AppView, relativePath: string) {
        const response = await post<RemoveParentNotificationAttachment_Response>(
            view,
            'removeParentNotificationAttachment',
            { relativePath },
        )

        return updateSession(view, response)
    },

    async getCentreForms(view: AppView) {
        return post<Record<string, CentreForm>>(view, 'getCentreForms')
    },

    async getCentreForm(view: AppView) {
        return post<CentreForm | undefined>(view, 'getCentreForm')
    },

    async saveCentreForm(view: AppView, rev: number, values: CentreFormContents) {
        return post(view, 'saveCentreForm', { rev, values })
    },

    async getEmployees(view: AppView) {
        return post<Record<string, LimitedEmployee>>(view, 'getEmployees')
    },

    async getCentreEmployees(view: AppView, centreId: string) {
        return post<Record<string, LimitedEmployee>>(view, 'getCentreEmployees', {
            centreId,
        })
    },

    async getExtendedEmployees(view: AppView, meetingId?: string) {
        return post<Record<string, LimitedEmployee>>(view, 'getExtendedEmployees', {
            meetingId,
        })
    },

    async addEmployee(
        view: AppView,
        username: string,
        employeeName: string,
        password: string,
        manage: boolean,
        allEvents: boolean,
        cards?: boolean,
    ) {
        const params: AddEmployeeParams = {
            username,
            name: employeeName,
            password,
            manage,
            allEvents,
        }

        if (cards !== undefined) {
            params.cards = cards
        }

        return post<AddEmployee_Response>(view, 'addEmployee', params)
    },

    async updateSelf(view: AppView, email: string) {
        const response = await post<UpdateSelf_Response>(view, 'updateSelf', { email })
        return updateSession(view, response)
    },

    async updatePermissions(
        view: AppView,
        employeeId: string,
        manage: boolean,
        allEvents: boolean,
        cards?: boolean,
    ) {
        const params: UpdatePermissionsParams = { employeeId, manage, allEvents }

        if (cards !== undefined) {
            params.cards = cards
        }

        const response = await post<UpdatePermissions_Response>(view, 'updatePermissions', params)

        return updateSession(view, response)
    },

    async updatePassword(view: AppView, employeeId: string, newPassword: string) {
        return post(view, 'updatePassword', { employeeId, newPassword })
    },

    async updatePasswordByUsername(view: AppView, username: string, newPassword: string) {
        return post(view, 'updatePasswordByUsername', { username, newPassword })
    },

    async addEmployeeToCentre(
        view: AppView,
        username: string,
        manage: boolean,
        allEvents: boolean,
        cards?: boolean,
    ) {
        const params: AddEmployeeToCentreParams = {
            username,
            manage,
            allEvents,
        }

        if (cards !== undefined) {
            params.cards = cards
        }

        const response = await post<AddEmployeeToCentre_Response>(
            view,
            'addEmployeeToCentre',
            params,
        )

        return updateSession(view, response)
    },

    async removeEmployeeFromCentre(view: AppView, employeeId: string) {
        const response = await post<RemoveEmployeeFromCentre_Response>(
            view,
            'removeEmployeeFromCentre',
            {
                employeeId,
            },
        )

        return updateSession(view, response)
    },

    async givePasswordAccess(view: AppView, centreId: string) {
        const response = await post<GivePasswordAccess_Response>(view, 'givePasswordAccess', {
            centreId,
        })
        return updateSession(view, response)
    },

    async removePasswordAccess(view: AppView, centreId: string) {
        const response = await post<RemovePasswordAccess_Response>(view, 'removePasswordAccess', {
            centreId,
        })
        return updateSession(view, response)
    },

    async getLogEntries(view: AppView, meetingId: string) {
        return post<GetLogEntries_Response>(view, 'getLogEntries', { meetingId })
    },

    getCentreVisitors: async (view: AppView) => {
        const visitors = await post<GetCentreVisitors_Response>(view, 'getCentreVisitors')

        return CommonUtils.mapMapToMap(
            visitors,
            (visitor): GetCentreVisitors_ApiVisitor => ({
                ...visitor,
                currentCentre: true,
            }),
        )
    },

    getCentreVisitorsForPeriod: async (view: AppView, dateFrom?: string, dateTo?: string) => {
        interface Params {
            dateFrom?: string
            dateTo?: string
        }

        const params: Params = {}

        if (dateFrom) {
            params.dateFrom = dateFrom
        }

        if (dateTo) {
            params.dateTo = dateTo
        }

        return post<GetCentreVisitors_Response>(view, 'getCentreVisitorsForPeriod', params)
    },

    getManagedVisitors: async (view: AppView) => {
        const visitors = await post<Record<string, ManagedVisitor>>(view, 'getManagedVisitors')

        return CommonUtils.mapMapToMap(
            visitors,
            (visitor): ManagedVisitorExt => ({ ...visitor, currentCentre: true }),
        )
    },

    getAllCards: async (view: AppView) => {
        const cards: Record<string, GetAllCards_Card> = {}

        // Load data in batches to reduce server memory usage
        const loadCards = async (cursor: string): Promise<void> => {
            const data = await post<GetAllCards_Result>(view, 'getAllCards', { cursor })
            const keys = Object.keys(data.cards)

            for (const key of keys) {
                cards[key] = data.cards[key]
            }

            if (data.hasMore) {
                const newCursor = keys[keys.length - 1]
                return loadCards(newCursor)
            }
        }

        const [centres] = await Promise.all([
            post<Record<string, Centre>>(view, 'getCentres'),
            loadCards(''),
        ])

        const visitorIds: string[] = []
        const employeeIdsByKey: Record<string, true> = {} // TODO Set<string>

        for (const key of Object.keys(cards)) {
            const card = cards[key]

            if (card.visitorId) {
                visitorIds.push(card.visitorId)
            }

            employeeIdsByKey[card.employeeId] = true

            for (const entry of card.log) {
                employeeIdsByKey[entry.employeeId] = true
            }
        }

        const employeeIds = Object.keys(employeeIdsByKey)

        const [visitors, employees] = await Promise.all([
            post<Record<string, VisitorForCards>>(view, 'getVisitorsForCards', {
                visitorIds,
            }),
            post<Record<string, EmployeeForCards>>(view, 'getEmployeesForCards', {
                employeeIds,
            }),
        ])

        return { cards, centres, visitors, employees }
    },

    async findCardsByName(view: AppView, cardName: string, excludeCardId: string) {
        const params: FindCardsByName_Params = { name: cardName }

        if (excludeCardId) {
            params.excludeCardId = excludeCardId
        }

        return post<CardNameInfo[]>(view, 'findCardsByName', params)
    },

    async getVisitorUsernames(view: AppView) {
        return post<string[]>(view, 'getVisitorUsernames')
    },

    async getMeetingVisitors(view: AppView, meetingId?: string) {
        return post<Record<string, MeetingVisitor>>(view, 'getMeetingVisitors', {
            meetingId,
        })
    },

    async updateVisitor(view: AppView, visitorId: string, visitor: VisitorUpdate) {
        return post(view, 'updateVisitor', { visitorId, visitor })
    },

    async addVisitor(view: AppView, visitor: VisitorInput) {
        return post<AddVisitor_Response>(view, 'addVisitor', { visitor })
    },

    async getVisitor(view: AppView, visitorId: string) {
        return post<GetVisitor_Visitor>(view, 'getVisitor', { visitorId })
    },

    async getVisitorId(view: AppView, username: string) {
        return post<{ _id: string }>(view, 'getVisitorId', { username })
    },

    async requestVisitor(view: AppView, visitorId: string) {
        return post(view, 'requestVisitor', { visitorId })
    },

    async transferVisitor(view: AppView, visitorId: string, centreId: string) {
        return post(view, 'transferVisitor', { visitorId, centreId })
    },

    async getVisitorRequestInfo(view: AppView, visitorId: string) {
        return post<VisitorRequestInfo>(view, 'getVisitorRequestInfo', { visitorId })
    },

    async getVisitorMergeInfo(view: AppView, visitorUsername: string) {
        return post<VisitorMergeInfo>(view, 'getVisitorMergeInfo', { visitorUsername })
    },

    async replaceVisitorOnMeetings(view: AppView, oldVisitorId: string, newVisitorId: string) {
        return post(view, 'replaceVisitorOnMeetings', { oldVisitorId, newVisitorId })
    },

    async deleteVisitor(view: AppView, visitorId: string) {
        return post(view, 'deleteVisitor', { visitorId })
    },

    async addCard(view: AppView, card: CardInput) {
        return post<AddCard_Response>(view, 'addCard', { card })
    },

    async updateCard(view: AppView, cardId: string, rev: number, card: CardUpdate) {
        return post(view, 'updateCard', { cardId, rev, card })
    },

    async updateCardPhase(
        view: AppView,
        cardId: string,
        action: CardAction,
        additional: UpdateCardPhase_Params['additional'],
    ) {
        return post(view, 'updateCardPhase', { cardId, action, additional })
    },

    async updateCardEmployee(view: AppView, cardId: string, employeeId: string) {
        return post<UpdateCardEmployee_Response>(view, 'updateCardEmployee', {
            cardId,
            employeeId,
        })
    },

    async updateCardDates(view: AppView, cardId: string, dates: Array<string | null>) {
        return post(view, 'updateCardDates', { cardId, dates })
    },

    async getCardAndEmployees(view: AppView, cardId: string) {
        return post<GetCardAndEmployees_Result>(view, 'getCardAndEmployees', { cardId })
    },

    async getCentreCards(view: AppView, fullCards: boolean) {
        return post<GetCentreCards_Response>(view, 'getCentreCards', { fullCards })
    },

    async checkExistingCard(view: AppView, visitorId: string) {
        return post<ExistingCardInfo>(view, 'checkExistingCard', { visitorId })
    },

    async linkCard(view: AppView, cardId: string, visitorId: string) {
        return post<LinkCard_Response>(view, 'linkCard', { cardId, visitorId })
    },

    async unlinkCard(view: AppView, cardId: string) {
        return post<UnlinkCard_Response>(view, 'unlinkCard', { cardId })
    },

    async transferCards(
        view: AppView,
        fromCentreId: string,
        toCentreId: string,
        newEmployeeId: string,
        cardIds: string[],
    ) {
        return post(view, 'transferCards', {
            fromCentreId,
            toCentreId,
            newEmployeeId,
            cardIds,
        })
    },

    async deleteCard(view: AppView, cardId: string) {
        return post(view, 'deleteCard', { cardId })
    },

    async addCardDiaryEntry(view: AppView, cardId: string, entry: CardDiaryEntryInput) {
        return post<AddCardDiaryEntry_Response>(view, 'addCardDiaryEntry', {
            cardId,
            entry,
        })
    },

    async addCardDiaryFile(view: AppView, entryId: string, filename: string, content: string) {
        return post<AddCardDiaryFile_Response>(view, 'addCardDiaryFile', {
            entryId,
            filename,
            content,
        })
    },

    async updateCardDiaryEntry(view: AppView, entryId: string, newFields: CardDiaryEntryInput) {
        return post(view, 'updateCardDiaryEntry', { entryId, newFields })
    },

    async getCardDiaryEntry(view: AppView, entryId: string) {
        return post<GetCardDiaryEntry_Response>(view, 'getCardDiaryEntry', { entryId })
    },

    async getCardDiaryFiles(view: AppView, entryId: string) {
        return post<GetCardDiaryFiles_Response>(view, 'getCardDiaryFiles', { entryId })
    },

    async removeCardDiaryFile(view: AppView, entryId: string, relativePath: string) {
        return post<RemoveCardDiaryFile_Response>(view, 'removeCardDiaryFile', {
            entryId,
            relativePath,
        })
    },

    async getCardDiaryEntries(view: AppView, cardId: string) {
        return post<Record<string, CardDiaryEntry>>(view, 'getCardDiaryEntries', {
            cardId,
        })
    },

    getSchools: async () => {
        const schools = await Utils.getJSON<Record<string, School>>('data/schools.json')

        // ids are omitted in the file to make it smaller while still keeping it human-readable
        return CommonUtils.mapMapToMap(
            schools,
            (school, id): SchoolWithId => ({ ...school, _id: id }),
        )
    },

    getAreas: async () => {
        return Utils.getJSON<Record<string, Area>>('data/areas.json')
    },

    getCountries: async () => {
        return Utils.getJSON<Record<string, Country>>('data/countries.json')
    },

    async getCentreEventCategories(view: AppView) {
        return post<EventCategory[]>(view, 'getCentreEventCategories')
    },

    async getEventCategoryHierarchy(view: AppView, includeArchived: boolean, centreIds?: string[]) {
        return post<EventCategoryHierarchy>(view, 'getEventCategoryHierarchy', {
            includeArchived,
            centreIds: centreIds || undefined,
        })
    },

    async addEventCategory(view: AppView, name: string) {
        return post<{ id: string }>(view, 'addEventCategory', { name })
    },

    async updateEventCategory(view: AppView, id: string, name: string) {
        return post<{ success: boolean }>(view, 'updateEventCategory', { id, name })
    },

    async deleteEventCategory(view: AppView, id: string) {
        return post<{ success: boolean }>(view, 'deleteEventCategory', { id })
    },

    async getEvents(view: AppView, centreIds?: string[]) {
        return post<Record<string, Event>>(view, 'getEvents', {
            centreIds: centreIds || undefined,
        })
    },

    async countVisitedCentres(
        view: AppView,
        eventIds: string[],
        startDate?: string,
        endDate?: string,
    ) {
        return post<number>(view, 'countVisitedCentres', { eventIds, startDate, endDate })
    },

    async getEvent(view: AppView, eventId: string) {
        return post<Event>(view, 'getEvent', { eventId })
    },

    async addEvent(view: AppView, evt: EventInput) {
        return post<AddEvent_Response>(view, 'addEvent', { event: evt })
    },

    async updateEvent(view: AppView, eventId: string, newFields: EventUpdate) {
        return post(view, 'updateEvent', { eventId, newFields })
    },

    async deleteEvent(view: AppView, eventId: string) {
        return post(view, 'deleteEvent', { eventId })
    },

    async archiveEvent(view: AppView, eventId: string) {
        return post(view, 'archiveEvent', { eventId })
    },

    async unarchiveEvent(view: AppView, eventId: string) {
        return post(view, 'unarchiveEvent', { eventId })
    },

    async getActivityOptionUsage(view: AppView, eventId: string) {
        return post<GetActivityOptionUsage_Result>(view, 'getActivityOptionUsage', {
            eventId,
        })
    },

    async getMeetings(view: AppView, eventId: string, full: boolean) {
        return post<GetMeetings_Response>(view, 'getMeetings', { eventId, full })
    },

    async getMeeting(view: AppView, meetingId: string) {
        return post<Meeting>(view, 'getMeeting', { meetingId })
    },

    async updateMeetingDetails(view: AppView, meetingId: string, newFields: MeetingDetailUpdate) {
        return post(view, 'updateMeetingDetails', { meetingId, newFields })
    },

    async updateMeetingNotes(
        view: AppView,
        meetingId: string,
        newNotes: string,
        oldHash: string,
        oldRev: number,
    ) {
        return post(view, 'updateMeetingNotes', { meetingId, newNotes, oldHash, oldRev })
    },

    async updateMeetingAnonymous(
        view: AppView,
        meetingId: string,
        newData: AnonymousGroup[],
        oldHash: string,
        oldRev: number,
    ) {
        return post(view, 'updateMeetingAnonymous', {
            meetingId,
            newData,
            oldHash,
            oldRev,
        })
    },

    async updateParticipationTime(
        view: AppView,
        meetingId: string,
        participationId: number,
        fieldName: 'arrived' | 'departed',
        newValue: string, // TODO empty string vs undefined
        oldValue: string,
        oldRev: number,
    ) {
        return post(view, 'updateParticipationTime', {
            meetingId,
            participationId,
            fieldName,
            newValue,
            oldValue,
            oldRev,
        })
    },

    async addMeeting(view: AppView, meeting: MeetingInput) {
        return post<AddMeeting_Response>(view, 'addMeeting', { meeting })
    },

    async deleteMeeting(view: AppView, meetingId: string) {
        return post(view, 'deleteMeeting', { meetingId })
    },

    async addParticipation(
        view: AppView,
        meetingId: string,
        visitorUsername: string,
        activity: Activity,
    ) {
        return post(view, 'addParticipation', { meetingId, visitorUsername, activity })
    },

    async removeParticipation(view: AppView, meetingId: string, participationId: number) {
        return post(view, 'removeParticipation', { meetingId, participationId })
    },

    async leaveMeeting(view: AppView, meetingId: string, participationId: number) {
        return post(view, 'leaveMeeting', { meetingId, participationId })
    },

    async endMeeting(view: AppView, meetingId: string) {
        return post(view, 'endMeeting', { meetingId })
    },

    async setActivity(
        view: AppView,
        meetingId: string,
        participationId: number,
        activity: Activity,
    ) {
        return post(view, 'setActivity', { meetingId, participationId, activity })
    },

    async logWarning(view: AppView, message: string, skipSlack: boolean) {
        const error = new Error(message)
        console.warn(error)

        return post(view, 'logWarning', {
            message,
            stack: error.stack,
            route: view.state.route.join('/'),
            skipSlack,
        })
    },

    async getEventStatsData(view: AppView, eventIds: string[]) {
        return post<EventStatsData>(view, 'getEventStatsData', { eventIds })
    },

    async getEventDurationStatsData(view: AppView, centreIds: string[] | null) {
        return post<EventDurationStatsData>(view, 'getEventDurationStatsData', {
            centreIds: centreIds || undefined,
        })
    },

    async getCollaboratorStatsData(
        view: AppView,
        centreIds: string[],
        dateFrom: string | undefined,
        dateTo: string | undefined,
        includeArchived: boolean,
        program: string | null,
    ) {
        return post<Record<string, CollabStatsCentre>>(view, 'getCollaboratorStatsData', {
            centreIds: centreIds || undefined,
            dateFrom,
            dateTo,
            includeArchived: Boolean(includeArchived),
            program,
        })
    },

    async getVisitorStatsData(view: AppView, visitorId: string) {
        return post<VisitorStatsData>(view, 'getVisitorStatsData', { visitorId })
    },

    async getVisitorAdditions(view: AppView) {
        return post<Record<string, VisitorAddition>>(view, 'getVisitorAdditions')
    },
}
