import { Component, Fragment } from 'react'
import toastr from 'toastr'

import { Event, EventCategory } from '../../server/types'
import { BackButton } from '../action-button'
import { AddEventButton } from '../add-event-button'
import { API } from '../api'
import { SearchPanelExistingItem } from '../search-panel/existing-item'
import { AppView } from '../state'
import { EventCategoryTitle } from './title'

export interface EventCategoryContainerProps {
    view: AppView
    eventCategory: EventCategory
    events: Event[]
    search: string
    onBack: () => void
    afterSave: () => Promise<void>
}

interface State {
    isEditing: boolean
    editedName?: string
}

export class EventCategoryContainer extends Component<EventCategoryContainerProps, State> {
    state: State = { isEditing: false }

    constructor(props: EventCategoryContainerProps) {
        super(props)
        this.state.editedName = props.eventCategory.name
    }

    private async save() {
        const { view } = this.props
        this.setState({ isEditing: false })

        const result = await API.updateEventCategory(
            view,
            this.props.eventCategory._id,
            this.state.editedName!,
        )

        await this.props.afterSave()

        if (result.success) {
            toastr.success('Kategooria nimi muudetud')
        }
    }

    private renderNameEmptyMessage() {
        const { isEditing, editedName } = this.state

        if (!isEditing || editedName) {
            return null
        }

        return <span className="validation-error">Kategooria nimi ei tohi olla tühi</span>
    }

    private async delete() {
        const { view } = this.props
        if (!confirm('Kas olete kindel, et soovite seda kategooriat kustutada?')) {
            return
        }

        const result = await API.deleteEventCategory(view, this.props.eventCategory._id)

        if (result.success) {
            toastr.success('Kategooria kustutatud')
        }

        this.props.onBack()
        await this.props.afterSave()
    }

    private renderEvents() {
        const { view, search } = this.props
        const searchLower = search.toLowerCase()

        return this.props.events
            .filter((evt) => evt.name.toLowerCase().indexOf(searchLower) !== -1)
            .map(({ _id, name, archived }) => (
                <SearchPanelExistingItem
                    key={_id}
                    primaryName={name}
                    desaturated={archived}
                    onClick={() => view.navigate(['events', _id])}
                    search={search}
                />
            ))
    }

    render() {
        const { isEditing, editedName } = this.state
        const { view, eventCategory, events, onBack, search } = this.props

        return (
            <Fragment>
                <div className="top-margin bottom-margin">
                    <BackButton onClick={onBack} />
                </div>
                <div className="event-category-container">
                    <EventCategoryTitle
                        isEditing={isEditing}
                        text={editedName!}
                        onActivate={() => this.setState({ isEditing: true })}
                        onChange={(newEditedName) => this.setState({ editedName: newEditedName })}
                        onCommit={() => this.save()}
                        onDiscard={() =>
                            this.setState({
                                isEditing: false,
                                editedName: eventCategory.name,
                            })
                        }
                        onDelete={() => this.delete()}
                        commitDisabled={!editedName}
                        canDelete={events.length === 0}
                    />
                    {this.renderNameEmptyMessage()}
                    {this.renderEvents()}
                    <AddEventButton view={view} search={search} categoryId={eventCategory._id} />
                </div>
            </Fragment>
        )
    }
}
