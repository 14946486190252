import { FC } from 'react'

import { Event, Participation } from '../server/types'
import { LoadingButton } from './loading-button'

export interface EndMeetingProps {
    event: Event
    participations: Participation[]
    onEndMeeting: () => Promise<void>
}

export const EndMeeting: FC<EndMeetingProps> = ({ event, participations, onEndMeeting }) => {
    if (event.timesNeeded && participations.length) {
        const allDeparted = participations.every((participation) => Boolean(participation.departed))

        if (!allDeparted) {
            return (
                <div style={{ marginTop: 10 }}>
                    <LoadingButton text="Lõpeta kohtumine" getPromise={onEndMeeting} />
                </div>
            )
        }
    }

    return <span />
}
