import { FC } from 'react'

import { Button, ButtonProps } from './button'
import { EndMeeting, EndMeetingProps } from './end-meeting'
import { MeetingDetails, MeetingDetailsProps } from './meeting-details'
import { MeetingLog, MeetingLogProps } from './meeting-log'
import { ParticipantTable, ParticipantTableProps } from './participant-table'

export interface EmployeeModeMeetingProps {
    modeButton: ButtonProps
    meetingDetails?: MeetingDetailsProps
    deleteButton: ButtonProps
    participantTable: ParticipantTableProps
    endMeeting: EndMeetingProps
    anonSummary?: AnonSummaryProps
    anonButton: ButtonProps
    notes?: string
    notesButton: ButtonProps
    meetingLog: MeetingLogProps
}

export interface AnonSummaryProps {
    groups: AnonSummaryGroupProps[]
    total: number
}

interface AnonSummaryGroupProps {
    reactKey: string
    header: string
    dists: AnonDistProps[]
}

export interface AnonDistProps {
    reactKey: string
    prefix: string
    items: {
        reactKey: string
        hasSeparator: boolean
        label: string
        value: number
    }[]
}

export const EmployeeModeMeeting: FC<EmployeeModeMeetingProps> = (props) => (
    <div>
        <div style={{ float: 'right' }}>
            <Button {...props.modeButton} />
        </div>
        <h2 className="topmost">Kohtumine</h2>
        {props.meetingDetails ? (
            <MeetingDetails {...props.meetingDetails} />
        ) : (
            <div>Laen kohtumise infot...</div>
        )}
        <div style={{ marginTop: 5 }}>
            <Button {...props.deleteButton} />
        </div>
        <h2 style={{ marginBottom: 20 }}>Osalejad</h2>
        <ParticipantTable {...props.participantTable} />
        <EndMeeting {...props.endMeeting} />
        <div>
            <h2 className="non-topmost">Anonüümsed osalejad</h2>
            {props.anonSummary && <AnonSummary {...props.anonSummary} />}
            <Button {...props.anonButton} />
        </div>
        <h2>Kohtumise märkmed</h2>
        <div>
            {props.notes && (
                <div style={{ marginBottom: 10, whiteSpace: 'pre-wrap' }}>{props.notes}</div>
            )}
            <Button {...props.notesButton} />
        </div>
        <h2>Logi</h2>
        <MeetingLog {...props.meetingLog} />
    </div>
)

const AnonSummaryGroup: FC<AnonSummaryGroupProps> = (props) => (
    <div>
        <div style={{ fontWeight: 'bold' }}>{props.header}</div>
        {props.dists.map((dist) => (
            <AnonDist key={dist.reactKey} {...dist} />
        ))}
    </div>
)

const AnonSummary: FC<AnonSummaryProps> = (props) => (
    <div style={{ marginBottom: 10 }}>
        {props.groups.map((group) => (
            <AnonSummaryGroup key={group.reactKey} {...group} />
        ))}
        <div style={{ marginTop: 5 }}>
            {'Anonüümseid osalejaid kokku: '}
            <b>{props.total}</b>
        </div>
    </div>
)

const AnonDist: FC<AnonDistProps> = (props) => (
    <div style={{ marginLeft: 20 }}>
        {props.prefix}
        {props.items.map((item) => (
            <span key={item.reactKey}>
                {item.hasSeparator && ', '}
                {item.label}
                {': '}
                <b>{item.value}</b>
            </span>
        ))}
    </div>
)
