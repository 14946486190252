import { ChangeEvent, Component, ReactNode } from 'react'

import { SchoolWithId } from '../server/schools'
import { API } from './api'
import { EventBus } from './event-bus'
import { LoadingIcon } from './loading-icon'
import { Utils } from './utils'

export interface SchoolSelectionProps {
    selectedSchool: string | null
    onSelectSchool: (schoolId: string) => void
    onDeselectSchool: () => void
    afterChange: (school: string) => void
}

interface State {
    loaded: boolean
    query: string
    selectedIndex: number
    showDropdown: boolean
    schools?: Record<string, SchoolWithId>
}

export class SchoolSelection extends Component<SchoolSelectionProps, State> {
    state: State = {
        loaded: false,
        query: '',
        selectedIndex: -1,
        showDropdown: false,
    }

    unmounted = false
    input: HTMLInputElement | null = null

    // Declared as a property because it's used in an EventBus handler
    onFocus: (() => Promise<void>) | null = null

    async componentDidMount() {
        this.onFocus = async () => {
            if (this.input) {
                this.input.focus()
            }
        }

        EventBus.on('focus-school-input', this.onFocus!)

        const schools = await API.getSchools()

        if (!this.unmounted) {
            await Utils.setState(this, { loaded: true, schools })
            await EventBus.fire('school-rendered')
        }
    }

    componentWillUnmount() {
        EventBus.off('focus-school-input', this.onFocus!)
        this.unmounted = true
    }

    async onChange(evt: ChangeEvent<HTMLInputElement>) {
        await this.setQuery(evt.target.value, true)
    }

    async setQuery(query: string, updateParent: boolean) {
        await Utils.setState(this, {
            query,
            selectedIndex: -1,
            showDropdown: Boolean(query),
        })

        if (updateParent) {
            this.props.afterChange(this.props.selectedSchool || query)
        }
    }

    getMatches() {
        const matches = Utils.filterMap(this.state.schools!, (school) => {
            return Utils.contains(school.name, this.state.query)
        })

        // Limit to 50 results
        return matches.slice(0, 50)
    }

    async onSelect(school: SchoolWithId) {
        this.props.onSelectSchool(school._id)
        await this.setQuery('', true)
    }

    render() {
        if (!this.state.loaded) {
            return <LoadingIcon />
        }

        if (this.props.selectedSchool) {
            const selectedSchool = this.state.schools![this.props.selectedSchool]

            return (
                <span>
                    {selectedSchool.name}{' '}
                    <button className="delete" onClick={() => this.props.onDeselectSchool()} />
                </span>
            )
        } else {
            const matches = this.getMatches()

            let suggestions: ReactNode = matches.map((school, index) => {
                let className = 'autocomplete__option'

                if (index === this.state.selectedIndex) {
                    className += ' autocomplete__option--highlighted'
                }

                let text: ReactNode = ''

                if (Utils.contains(school.name, this.state.query)) {
                    text = Utils.highlight(school.name, this.state.query)
                }

                return (
                    <div
                        key={index}
                        className={className}
                        onMouseOver={() => this.setState({ selectedIndex: index })}
                        onMouseOut={() => {
                            if (this.state.selectedIndex === index) {
                                this.setState({ selectedIndex: -1 })
                            }
                        }}
                        onClick={async () => this.onSelect(school)}
                    >
                        {text}
                    </div>
                )
            })

            if (!matches.length) {
                suggestions = (
                    <span style={{ fontSize: '85%', color: 'gray' }}>
                        Ei leitud ühtegi kooli. Proovi alternatiivseid nimesid.
                    </span>
                )
            }

            const newPos = (diff: number) => {
                let newIndex = this.state.selectedIndex + diff

                while (newIndex < 0) {
                    newIndex += matches.length
                }

                while (newIndex >= matches.length) {
                    newIndex -= matches.length
                }

                return newIndex
            }

            let dropdown: ReactNode = null

            if (this.state.showDropdown) {
                dropdown = <div className="autocomplete__options">{suggestions}</div>
            }

            return (
                <div className="autocomplete">
                    <input
                        type="text"
                        ref={(node) => (this.input = node)}
                        style={{ width: 300 }}
                        className="form-control"
                        onChange={async (evt) => this.onChange(evt)}
                        value={this.state.query}
                        onKeyDown={async (evt) => {
                            // TODO: scrolling?
                            if (evt.key === 'ArrowUp') {
                                this.setState({ selectedIndex: newPos(-1) })
                            } else if (evt.key === 'ArrowDown') {
                                this.setState({ selectedIndex: newPos(1) })
                            } else if (evt.key === 'Enter') {
                                const school = matches[this.state.selectedIndex]

                                if (school) {
                                    await this.onSelect(school)
                                }
                            }
                        }}
                    />
                    {dropdown}
                </div>
            )
        }
    }
}
