import { Component } from 'react'
import { createRoot } from 'react-dom/client'

import { assert } from '../util/assert'
import { Utils } from './utils'

interface State {
    visible: boolean
    notifyAfter: Date | null
}

class ReloadRecommendation extends Component<{}, State> {
    constructor(props: {}) {
        super(props)

        // If the app starts up before 5:00 AM and is still open after this time,
        // it has probably stayed open overnight.
        const notifyHour = 5

        const notifyAfter = Utils.getNow()
        notifyAfter.setHours(notifyAfter.getHours() < notifyHour ? notifyHour : notifyHour + 24)
        notifyAfter.setMinutes(0)
        notifyAfter.setSeconds(0)

        this.state = { visible: false, notifyAfter }
    }

    componentDidMount() {
        this.checkTime()
    }

    checkTime() {
        if (this.state.notifyAfter! < Utils.getNow()) {
            this.setState({ visible: true, notifyAfter: null })
        } else {
            setTimeout(() => this.checkTime(), 60000) // Check every minute
        }
    }

    render() {
        if (this.state.visible) {
            return (
                <div>
                    Soovitame lehte uuesti laadida (enamikes brauserites F5 nupuga), kuna Logiraamat
                    on ööseks lahti jäänud ning öösiti toimuvad vahel süsteemi uuendused.
                    <br />
                    Kui teil on salvestamata muudatusi, salvestage need enne ära, kuna need läheks
                    uuesti laadimisel kaduma.
                </div>
            )
        } else {
            return <span />
        }
    }
}

export const renderReloadRecommendation = () => {
    const container = document.getElementById('reload-recommendation')
    assert(container)
    const root = createRoot(container)
    root.render(<ReloadRecommendation />)
}
