import { FC } from 'react'

import { getAddEventButtonProps } from './props/search-panel-new-item'
import { SearchPanelNewItem } from './search-panel/new-item'
import { AppView } from './state'

interface Props {
    view: AppView
    search?: string
    categoryId?: string
}

// TODO inline
export const AddEventButton: FC<Props> = ({ view, search, categoryId }) => (
    <SearchPanelNewItem {...getAddEventButtonProps(view, search, categoryId)} />
)
