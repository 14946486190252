import { CSSProperties, FC, InputHTMLAttributes, RefObject, useEffect, useRef } from 'react'

import { EventBus } from './event-bus'

export interface TextInputProps {
    value: string
    onChange: (value: string) => void
    type?: 'text' | 'password'
    focusOnMount?: true
    focusEventName?: string
    onEnter?: () => void
    id?: string
    className?: string
    style?: CSSProperties
    additional?: InputHTMLAttributes<HTMLInputElement>
}

const focus = (nodeRef: RefObject<HTMLInputElement>) => {
    if (nodeRef.current) {
        nodeRef.current.focus()
    }
}

export const TextInput: FC<TextInputProps> = (props) => {
    const nodeRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (props.focusOnMount) {
            focus(nodeRef)
        }

        if (props.focusEventName) {
            const subscription = EventBus.on(props.focusEventName, async () => focus(nodeRef))
            return subscription.remove
        } else {
            return undefined
        }
    }, [])

    const domProps: InputHTMLAttributes<HTMLInputElement> = {
        type: props.type || 'text',
        value: props.value,
        onChange: (evt) => props.onChange(evt.currentTarget.value),
        id: props.id,
        className: props.className,
        style: props.style,
    }

    if (props.onEnter) {
        domProps.onKeyDown = (evt) => {
            if (evt.key === 'Enter') {
                props.onEnter!()
            }
        }
    }

    return <input ref={nodeRef} {...domProps} {...props.additional} />
}
