import { FC, useEffect } from 'react'

import { API } from './api'
import { CardsMenu } from './cards-menu'
import { handlePromiseRejection } from './common-conf'
import { DateField } from './date-field'
import { DropdownOption, renderDropdown } from './dropdown'
import { partitions } from './enums'
import { getEsfRows } from './esf-report-utils'
import { getInitialEsfReportState } from './initial-state'
import { LoadingIcon } from './loading-icon'
import { Column, getTableProps } from './props/table'
import { AppView, EsfReportRow, PartitionOption } from './state'
import { Table } from './table'
import { Utils } from './utils'

interface Props {
    view: AppView // TODO refactor props
}

type DateFilterKey = 'phase3EndDate' | 'phase2StartDate' | 'phase2Or3Date'

export const EsfReport: FC<Props> = (props) => {
    const { view } = props
    const { state } = view
    const { esfReport } = state

    useEffect(() => {
        API.getAllCards(view)
            .then(({ cards, centres }) => {
                esfReport.cards = cards
                esfReport.centres = centres
                view.update()
            })
            .catch((err) => handlePromiseRejection(view, err))

        return () => {
            state.esfReport = getInitialEsfReportState()
            view.update()
        }
    }, [])

    const dateFilterIsValid = (filterKey: DateFilterKey) => {
        const date = state.esfReport.filters[filterKey]
        return date === '' || !isNaN(Date.parse(date))
    }

    const renderInvalidDateMessage = (filterKey: DateFilterKey) => {
        if (!dateFilterIsValid(filterKey)) {
            return <div className="text-danger">Kuupäev pole korrektne.</div>
        }

        return null
    }

    const renderDateFilter = (label: string, filterKey: DateFilterKey) => {
        return (
            <div className="bottom-margin">
                {label}{' '}
                <DateField
                    value={state.esfReport.filters[filterKey]}
                    onChange={(date) => {
                        esfReport.filters[filterKey] = date
                        view.update()
                    }}
                />
                {renderInvalidDateMessage(filterKey)}
            </div>
        )
    }

    const renderPhase3EndDateFilter = () => {
        return renderDateFilter(
            'Arvesta sisse ainult kaardid, kus 3. faas on lõppenud alates',
            'phase3EndDate',
        )
    }

    const renderPhase2StartDateFilter = () => {
        return renderDateFilter(
            'Arvesta sisse ainult kaardid, kus 2. faas on alanud alates',
            'phase2StartDate',
        )
    }

    const renderPhase2Or3DateFilter = () => {
        const label =
            'Arvesta sisse ainult kaardid, mis olid valitud kuupäeval 2. faasis, 2. ja 3. ' +
            'faasi vahel või 3. faasis'

        return renderDateFilter(label, 'phase2Or3Date')
    }

    const renderCentreFilter = () => {
        const { centres, filters } = state.esfReport

        if (!centres) {
            return <LoadingIcon />
        }

        const options = Utils.mapMap(centres, ({ _id, name }) => ({
            id: _id,
            label: name,
        }))

        options.unshift({ id: '', label: '- Kõik keskused -' })

        return (
            <div className="bottom-margin">
                {'Arvesta sisse ainult kaardid valitud keskusest: '}
                {renderDropdown({
                    options,
                    value: filters.centreId,
                    onChange: (centreId) => {
                        esfReport.filters.centreId = centreId
                        view.update()
                    },
                })}
            </div>
        )
    }

    const renderPartitionFilter = () => {
        const options: DropdownOption<PartitionOption>[] = [
            { id: '', label: '- Kõik keskused -' },
            { id: 'tln', label: partitions.tln },
            { id: 'other', label: partitions.other },
        ]

        return (
            <div className="bottom-margin">
                {'Arvesta sisse ainult kaardid valitud osast: '}
                {renderDropdown({
                    options,
                    value: state.esfReport.filters.partition,
                    onChange: (partition) => {
                        esfReport.filters.partition = partition
                        view.update()
                    },
                })}
            </div>
        )
    }

    const renderFilters = () => {
        return (
            <div>
                {renderPhase3EndDateFilter()}
                {renderPhase2StartDateFilter()}
                {renderPhase2Or3DateFilter()}
                {renderCentreFilter()}
                {renderPartitionFilter()}
            </div>
        )
    }

    const getColumns = (): Column<EsfReportRow>[] => {
        return [
            {
                id: 'label',
                header: 'Tulemuse valik(ud)',
                editCellProps: (cellProps) => (cellProps.className = 'esf-report__label'),
            },
            {
                id: 'sum',
                header: 'Juhtumikaartide arv',
                editCellProps: (cellProps) => (cellProps.className = 'text-right bold'),
            },
        ]
    }

    const dateFiltersAreValid = () => {
        return (
            dateFilterIsValid('phase3EndDate') &&
            dateFilterIsValid('phase2StartDate') &&
            dateFilterIsValid('phase2Or3Date')
        )
    }

    const renderReportTable = () => {
        const { cards, filters, centres } = state.esfReport

        if (!cards || !centres) {
            return <LoadingIcon />
        }

        if (!dateFiltersAreValid()) {
            return <div className="text-danger">Leidub vigaseid filtri väärtusi</div>
        }

        const columns = getColumns()
        const rows = getEsfRows(cards, filters, centres)
        return <Table {...getTableProps({ columns, rows, className: 'bordered stats' })} />
    }

    return (
        <div>
            <CardsMenu view={view} page="esf-report" />
            <div className="main-panel esf-report">
                <h2>ESF aruande andmed</h2>
                {renderFilters()}
                {renderReportTable()}
            </div>
        </div>
    )
}
