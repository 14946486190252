import { LoggedInUserContext } from '../../server/types'
import { getCentreCount, isAdmin } from '../access'
import { API } from '../api'
import { EmployeeInfoProps } from '../employee-info'
import { EventBus } from '../event-bus'
import { AppView } from '../state'
import { initIfNeeded } from './init-if-needed'

export const getEmployeeInfoProps = (view: AppView): EmployeeInfoProps => {
    const { state } = view
    const { session } = state

    initIfNeeded(view, 'employeeInfo', async () => {
        await EventBus.fire('employee-info-rendered')
    })

    const props: EmployeeInfoProps = {
        logoutLink: {
            isLoading: state.isLoggingOut,
            text: 'Logi välja',
            onClick: () => logOut(view),
            style: { cursor: 'pointer' },
        },
    }

    if (session.loggedIn) {
        props.employee = {
            text: session.employee.name,
        }

        if (session.employeeMode) {
            props.employee.url = '#/employee'
        }

        const centreName = getCentreName(session)

        if (centreName) {
            props.centre = {
                text: centreName,
            }

            if (canChangeCentre(session)) {
                props.centre.id = 'change-centre'
                props.centre.url = '#/select-centre'
            }
        }
    } else {
        props.notLoggedInText = '(pole sisse logitud)'
    }

    return props
}

const logOut = async (view: AppView) => {
    const { state, update } = view
    view.navigate(['login'])

    state.isLoggingOut = true
    update()

    try {
        await API.logout(view)
    } finally {
        state.isLoggingOut = false
        update()
    }
}

const getCentreName = (session: LoggedInUserContext): string | void => {
    if (session.centre) {
        return session.centre.name
    } else if (isAdmin(session.employee)) {
        return 'Eesti ANK'
    }
}

const canChangeCentre = (session: LoggedInUserContext) => {
    if (!session.employeeMode) {
        return false
    }

    if (isAdmin(session.employee)) {
        return true
    }

    return getCentreCount(session.employee) > 1
}
