import { Component, Fragment, ReactNode } from 'react'
import toastr from 'toastr'

import { GetAvailableCentres_Centre } from '../server/commands/get-available-centres'
import { County } from '../server/types'
import { keys } from '../util/keys'
import { sortAsStrings } from '../util/sort-as-strings'
import { canEditPassword, isAdmin } from './access'
import { ActionButton } from './action-button'
import { API } from './api'
import { bindProps } from './bind-props'
import { DropdownOption, renderDropdown } from './dropdown'
import { EmployeeInfo } from './employee-info'
import { Enums } from './enums'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { LoadingIcon } from './loading-icon'
import { getEmployeeInfoProps } from './props/employee-info'
import { getEmployee } from './session'
import { AppView } from './state'
import { setState } from './utils'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

interface SelectCentreProps {
    view: AppView // TODO refactor props
}

interface State {
    loaded: boolean
    processing: boolean
    name: string
    county: County | ''
    centres?: GetAvailableCentres_Centre[]
    validationErrors?: Record<string, ValidationError>
    shouldRenderArchived: boolean
}

const sortCentres = (centres: GetAvailableCentres_Centre[]) => {
    sortAsStrings(centres, (centre) => centre.name)
}

export class SelectCentre extends Component<SelectCentreProps, State> {
    state: State = {
        loaded: false,
        processing: false,
        name: '',
        county: '',
        shouldRenderArchived: false,
    }

    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const centres = await API.getAvailableCentres(view)

        if (!this.unmounted) {
            sortCentres(centres)
            await setState(this, { loaded: true, centres })
        }

        await EventBus.fire('centre-selection-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async reloadCentres() {
        const { view } = this.props
        const centres = await API.getAvailableCentres(view)
        sortCentres(centres)
        await setState(this, { centres })
    }

    async selectCentre(centreId: string) {
        const { view } = this.props
        await API.selectCentre(view, centreId)
        view.navigate([])
    }

    async selectGeneric() {
        const { view } = this.props
        await API.selectAdminMode(view)
        view.navigate(['stats'])
    }

    async onAddNew() {
        const { view } = this.props
        this.setState({ processing: true })

        try {
            await clearValidationErrors(view, this)
            const { county } = this.state

            if (!county) {
                throw new Error('County must not be empty')
            }

            await API.addCentre(view, this.state.name.trim(), county)
            await this.reloadCentres()

            // Reset form
            this.setState({ name: '', county: '' })
        } catch (error) {
            await checkValidationErrors(view, this, error)
        } finally {
            this.setState({ processing: false })
        }
    }

    renderCounts() {
        const { view } = this.props

        if (!isAdmin(getEmployee(view))) {
            return null
        }

        const { centres } = this.state
        const numCentres = centres!.length
        const archivedCentres = centres!.filter(({ archived }) => archived)
        const numArchivedCentres = archivedCentres.length
        const numActiveCentres = numCentres - numArchivedCentres

        return (
            <div>
                {`Aktiivseid keskusi: ${numActiveCentres}; arhiveeritud keskusi: ${numArchivedCentres}; ` +
                    `kokku: ${numCentres}`}
            </div>
        )
    }

    renderGenericSelection() {
        const { view } = this.props

        if (!isAdmin(getEmployee(view))) {
            return null
        }

        return (
            <div className="centre-selection">
                <a onClick={async () => this.selectGeneric()} className="centre-link">
                    Eesti ANK
                </a>
                <div style={{ fontSize: '85%' }}>
                    Üldiste tegevuste jaoks, mis pole seotud ühe konkreetse keskusega
                </div>
            </div>
        )
    }

    async archiveCentre(centreId: string) {
        const { view } = this.props

        if (!confirm('Kas olete kindel, et soovite keskuse arhiveerida?')) {
            return
        }

        const result = await API.archiveCentre(view, centreId)

        if (result.success) {
            toastr.success('Keskus arhiveeritud')
            await this.reloadCentres()
        }
    }

    renderArchiveButton(centreId: string): ReactNode {
        const { view } = this.props
        const employee = getEmployee(view)

        if (!isAdmin(employee)) {
            return null
        }

        return (
            <ActionButton
                onClick={() => this.archiveCentre(centreId)}
                title="Arhiveeri keskus"
                className="float-right"
                iconName="save"
                actionType="danger"
            />
        )
    }

    renderPasswordAccessManagement(centreId: string) {
        const { view } = this.props
        const employee = getEmployee(view)

        if (isAdmin(employee)) {
            return null
        }

        if (canEditPassword(centreId, employee)) {
            const onClick = async () => {
                await API.removePasswordAccess(view, centreId)
                this.forceUpdate()
            }

            return (
                <div>
                    {'Selle keskuse haldajad '}
                    <b>saavad</b>
                    {' teie parooli muuta. '}
                    <a onClick={onClick} style={{ cursor: 'pointer' }}>
                        Eemalda neilt see õigus
                    </a>
                </div>
            )
        } else {
            const onClick = async () => {
                await API.givePasswordAccess(view, centreId)
                this.forceUpdate()
            }

            return (
                <div>
                    {'Selle keskuse haldajad '}
                    <b>ei saa</b>
                    {' teie parooli muuta. '}
                    <a onClick={onClick} style={{ cursor: 'pointer' }}>
                        Anna neile see õigus
                    </a>
                </div>
            )
        }
    }

    renderAddNew(): ReactNode {
        const { view } = this.props

        if (!isAdmin(getEmployee(view))) {
            return null
        }

        let buttonOrLoading: ReactNode

        if (this.state.processing) {
            buttonOrLoading = <LoadingIcon />
        } else {
            buttonOrLoading = <button onClick={async () => this.onAddNew()}>Lisa</button>
        }

        const countyOptions: DropdownOption<County | ''>[] = [
            { id: '', label: '' },
            ...keys(Enums.counties).map((county) => {
                return { id: county, label: Enums.counties[county] }
            }),
        ]

        return (
            <div className="centre-selection">
                <div style={{ marginBottom: 5 }}>Lisa uus keskus:</div>
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                    <input
                        {...bindProps(this, 'name', {
                            placeholder: 'Nimi',
                            style: { width: 300 },
                        })}
                    />
                    {renderValidationError(this.state.validationErrors, 'name')}
                </div>{' '}
                <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                    {renderDropdown({
                        options: countyOptions,
                        value: this.state.county,
                        onChange: (county) => this.setState({ county }),
                        additional: { style: { height: 26 } },
                    })}
                    {renderValidationError(this.state.validationErrors, 'county')}
                </div>{' '}
                {buttonOrLoading}
            </div>
        )
    }

    renderArchivedCentres(): ReactNode {
        const { view } = this.props

        if (!isAdmin(getEmployee(view))) {
            return null
        }

        if (!this.state.shouldRenderArchived) {
            return (
                <span
                    className="action-default action-button"
                    onClick={() => this.setState({ shouldRenderArchived: true })}
                >
                    Kuva arhiveeritud keskused
                </span>
            )
        }

        return (
            <Fragment>
                <span
                    className="action-default action-button"
                    onClick={() => this.setState({ shouldRenderArchived: false })}
                >
                    Peida arhiveeritud keskused
                </span>
                {this.state
                    .centres!.filter(({ archived }) => archived)
                    .map((centre) => (
                        <div className="centre-selection" key={centre._id}>
                            {centre.name}
                        </div>
                    ))}
            </Fragment>
        )
    }

    renderMainContent(): ReactNode {
        const { view } = this.props

        if (!isAdmin(getEmployee(view)) && !Object.keys(this.state.centres!).length) {
            return (
                <div
                    id="no-centres-note"
                    style={{ width: '75%', maxWidth: 800, margin: '150px auto' }}
                >
                    Teie töötaja konto pole hetkel seotud ühegi noortekeskusega.
                    <br />
                    Kui teil peaks olema ligipääs Logiraamatule läbi mõne keskuse,
                    {' palun pöörduge selle keskuse Logiraamatu haldaja poole.'}
                </div>
            )
        }

        return (
            <div style={{ width: '75%', maxWidth: 800, margin: '150px auto' }}>
                {this.renderCounts()}
                <div style={{ fontSize: 24 }}>Vali noortekeskus:</div>
                {this.renderGenericSelection()}
                {this.state
                    .centres!.filter(({ archived }) => !archived)
                    .map((centre) => {
                        const onClick = async () => this.selectCentre(centre._id)

                        return (
                            <div className="centre-selection" key={centre._id}>
                                <a onClick={onClick} className="centre-link">
                                    {centre.name}
                                </a>
                                {this.renderArchiveButton(centre._id)}
                                {this.renderPasswordAccessManagement(centre._id)}
                            </div>
                        )
                    })}
                {this.renderAddNew()}
                {this.renderArchivedCentres()}
            </div>
        )
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div>
                <div style={{ float: 'right' }}>
                    <EmployeeInfo {...getEmployeeInfoProps(view)} />
                </div>
                {
                    // Invisible element to align floating employee info
                    '\u00a0'
                }
                {this.renderMainContent()}
            </div>
        )
    }
}
