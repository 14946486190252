import { Component, ReactNode } from 'react'

import { API, ManagedVisitorExt } from './api'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { getVisitorSearchPanelProps } from './props/visitor-search-panel'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'
import { VisitorSearchPanel } from './visitor-search-panel'
import { isManagedVisitorExt, isString, VisitorMatch } from './visitor-search-utils'

export interface LinkCardProps {
    view: AppView
    cardId: string
}

interface State {
    loaded: boolean
    errorType: null | 'cardAlreadyLinked' | 'managedByOtherCentre' | 'visitorHasCard'
    managedVisitors?: Record<string, ManagedVisitorExt>
    visitorId?: string
}

export class LinkCard extends Component<LinkCardProps, State> {
    state: State = { loaded: false, errorType: null }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const managedVisitors = await API.getManagedVisitors(view)

        if (!this.unmounted) {
            const currentCentreId = Session.getCentre(view)!._id

            // Add the field here, no need to download it from the server
            Utils.iterMap(managedVisitors, (visitor) => {
                visitor.managingCentreId = currentCentreId
            })

            await Utils.setState(this, { loaded: true, managedVisitors })
            await EventBus.fire('card-linker-rendered')
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async onSelectVisitor(visitor: VisitorMatch) {
        const { view } = this.props

        if (
            isManagedVisitorExt(visitor) &&
            visitor.managingCentreId === Session.getCentre(view)!._id
        ) {
            const cardId = this.props.cardId
            const response = await API.linkCard(view, cardId, visitor._id)

            if (response.success) {
                view.navigate(['cards', 'view', cardId])
            } else {
                await Utils.setState(this, { errorType: response.errorType || null })
                await EventBus.fire('visitor-selected')
            }
        } else {
            const visitorId = isString(visitor)
                ? (await API.getVisitorId(view, visitor))._id
                : visitor._id

            await Utils.setState(this, {
                errorType: 'managedByOtherCentre',
                visitorId,
            })

            await EventBus.fire('visitor-selected')
        }
    }

    renderError() {
        const errorType = this.state.errorType
        let message: ReactNode = null

        if (errorType === 'cardAlreadyLinked') {
            message = 'See juhtumikaart on juba külastajaga seotud.'
        } else if (errorType === 'visitorHasCard') {
            message = 'See külastaja on juba juhtumikaardiga seotud.'
        } else if (errorType === 'managedByOtherCentre') {
            message = (
                <span>
                    {'Seda külastajat haldab teine keskus. '}
                    <a href={'#/transfer/' + this.state.visitorId}>Rohkem infot</a>
                </span>
            )
        } else if (errorType) {
            throw new Error('Invalid errorType: ' + errorType)
        }

        if (message) {
            return (
                <div id="link-error" style={{ color: '#c00', marginTop: 15 }}>
                    {message}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        const visitorSearchPanelProps = getVisitorSearchPanelProps(view, {
            primaryVisitors: this.state.managedVisitors,
            includeOtherVisitors: true,
            clearAfterSelection: true,
            onSelectVisitor: async (visitor) => this.onSelectVisitor(visitor),
            getHeader: () => 'Alusta kasutajanime trükkimist, et leida külastaja.',
        })

        return (
            <table className="vtop">
                <tbody>
                    <tr>
                        <td className="left-panel">
                            <h2>Vali külastaja</h2>
                            <VisitorSearchPanel {...visitorSearchPanelProps} />
                        </td>
                        <td className="right-panel">
                            <div>
                                {'Vali vasakult külastaja, kellega '}
                                <a href={'#/cards/view/' + this.props.cardId}>juhtumikaart</a>
                                {' siduda.'}
                            </div>
                            {this.renderError()}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }
}
