import classnames from 'classnames'
import { Component, ReactNode } from 'react'
import toastr from 'toastr'

import { CentreStorageInfo } from '../../server/types'
import { assertNever } from '../../util/assert-never'
import { FileStatus as AttachmentStatus } from '../../util/enums'
import { extractFilename } from '../../util/file-path'
import { API } from '../api'
import { Loading } from '../loading'
import { LoadingIcon } from '../loading-icon'
import { Column, getTableProps } from '../props/table'
import { getCentre } from '../session'
import { AppView } from '../state'
import { Table } from '../table'
import { Utils } from '../utils'

interface Props {
    view: AppView // TODO refactor props
    updateFileOperationInProgress: (fileOperationInProgress: boolean) => void
}

// TODO move to constants
const MAX_FILE_SIZE = 5242880 // 5 MiB

const filesDirName = 'parent-notification'

enum FileStatus {
    existing = 'existing',
    loading = 'loading',
    added = 'added',
    duplicate = 'duplicate',
    errorAdding = 'errorAdding',
    removing = 'removing',
    errorRemoving = 'errorRemoving',
}

interface FileState {
    id: string
    status: FileStatus
    name: string
    relativePath?: string
    url?: string
}

interface State {
    loaded: boolean
    baseUrl?: string
    files?: Record<string, FileState>
    centreUsage?: CentreStorageInfo
    oversizeFileError?: boolean
}

const getStatus = (status: AttachmentStatus.added | AttachmentStatus.duplicate): FileStatus => {
    if (status === AttachmentStatus.added) {
        return FileStatus.added
    } else if (status === AttachmentStatus.duplicate) {
        return FileStatus.duplicate
    } else {
        throw assertNever(status, 'parent notification attachment status')
    }
}

export class Attachments extends Component<Props, State> {
    unmounted = false
    fileInput: HTMLInputElement | null = null

    constructor(props: Props) {
        super(props)
        this.state = { loaded: false }
    }

    async componentDidMount() {
        const { view } = this.props
        const { baseUrl, centreUsage } = await API.getFileStorageInfo(view)

        await Utils.setState(this, {
            loaded: true,
            baseUrl,
            centreUsage,
            files: this.buildFilesMap(baseUrl),
        })
    }

    componentDidUpdate() {
        const fileOperationInProgress = this.fileOperationInProgress()
        this.props.updateFileOperationInProgress(fileOperationInProgress)
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    buildFilesMap(baseUrl: string) {
        const { view } = this.props
        const { parentNotification } = getCentre(view)!
        const files: Record<string, FileState> = {}

        for (const { path } of parentNotification!.attachments) {
            files[path] = this.buildExistingFile(baseUrl, path)
        }

        return files
    }

    buildExistingFile(baseUrl: string, relativePath: string): FileState {
        return {
            id: relativePath,
            status: FileStatus.existing,
            name: extractFilename(relativePath),
            relativePath,
            url: this.getFileUrl(baseUrl, relativePath),
        }
    }

    getFileUrl(baseUrl: string, relativePath: string) {
        const { view } = this.props
        const centre = getCentre(view)

        // If filename contains spaces or plus signs, the URL needs some tweaking
        const idxSlash = relativePath.lastIndexOf('/')
        const hash = relativePath.substring(0, idxSlash)
        const filename = relativePath.substring(idxSlash + 1)
        const encodedFilename = encodeURIComponent(filename).replace(/%20/g, '+')

        return baseUrl + filesDirName + '/' + centre!._id + '/' + hash + '/' + encodedFilename
    }

    async removeFileFromList(key: string) {
        const files = Utils.clone(this.state.files!)
        delete files[key]
        await Utils.setState(this, { files })
    }

    async markFileErrorRemoving(key: string) {
        const files = Utils.clone(this.state.files!)
        files[key].status = FileStatus.errorRemoving
        await Utils.setState(this, { files })
    }

    async removeFile(key: string) {
        const { view } = this.props
        const files = Utils.clone(this.state.files!)
        const file = files[key]

        if (!file) {
            throw new Error('File does not exist')
        } else if (file.status === FileStatus.loading) {
            throw new Error('Attempting to remove a file that is still uploading')
        } else if (
            file.status === FileStatus.duplicate ||
            file.status === FileStatus.errorAdding ||
            file.status === FileStatus.errorRemoving
        ) {
            return this.removeFileFromList(key)
        } else if (file.status === FileStatus.existing || file.status === FileStatus.added) {
            if (!confirm('Kas olete kindel, et soovite selle faili eemaldada?')) {
                return
            }

            file.status = FileStatus.removing
            await Utils.setState(this, { files })
            const response = await API.removeParentNotificationAttachment(view, file.relativePath!)

            if (response.success) {
                this.setState({ centreUsage: response.centreUsage! })
                toastr.success('Fail eemaldatud')
                return this.removeFileFromList(key)
            } else {
                let message = ''

                if (response.errorType === 'fileRemoveFailed') {
                    message = 'Faili kustutamine ebaõnnestus'
                } else if (response.errorType === 'attachmentNotOnParentNotification') {
                    message = 'Manust ei leitud'
                } else if (response.errorType === 'parentNotificationNotOnCentre') {
                    message = 'Kirja ei leitud'
                }

                toastr.error(message, 'Viga faili eemaldamisel')
                return this.markFileErrorRemoving(key)
            }
        } else if (file.status === FileStatus.removing) {
            throw new Error('Attempting to remove a file that is already being removed')
        } else {
            throw new Error('Invalid file status ' + file.status)
        }
    }

    renderRemoveFileButton(key: string, title: string) {
        return (
            <button className="delete" onClick={async () => this.removeFile(key)} title={title} />
        )
    }

    renderRemoveFileCell(file: FileState) {
        if (file.status === FileStatus.loading || file.status === FileStatus.removing) {
            return <LoadingIcon />
        } else if (file.status === FileStatus.existing || file.status === FileStatus.added) {
            return this.renderRemoveFileButton(file.id, 'Eemalda')
        } else if (
            file.status === FileStatus.duplicate ||
            file.status === FileStatus.errorAdding ||
            file.status === FileStatus.errorRemoving
        ) {
            return this.renderRemoveFileButton(file.id, 'Eira')
        } else {
            throw new Error('Invalid file status: ' + file.status)
        }
    }

    renderWarningEm(text: string) {
        return <em className="text-warning">{text}</em>
    }

    renderErrorEm(text: string) {
        return <em className="text-danger">{text}</em>
    }

    renderFileCell(file: FileState) {
        if (file.status === FileStatus.loading) {
            return <em>{'Laeb üles... (' + file.name + ')'}</em>
        } else if (file.status === FileStatus.existing || file.status === FileStatus.added) {
            return (
                <a href={file.url} target="_blank">
                    {file.name}
                </a>
            )
        } else if (file.status === FileStatus.duplicate) {
            return this.renderWarningEm('Sama fail oli juba üles laetud (' + file.name + ')')
        } else if (file.status === FileStatus.errorAdding) {
            return this.renderErrorEm('Faili üles laadimisel ilmnes viga (' + file.name + ')')
        } else if (file.status === FileStatus.errorRemoving) {
            return this.renderErrorEm('Faili kustutamisel ilmnes viga (' + file.name + ')')
        } else if (file.status === FileStatus.removing) {
            return <em>{'Eemaldatakse... (' + file.name + ')'}</em>
        } else {
            throw new Error('Invalid file status: ' + file.status)
        }
    }

    getColumns() {
        const columns: Column<FileState>[] = [
            {
                id: 'remove',
                getContents: (file) => this.renderRemoveFileCell(file),
            },
            {
                id: 'name',
                header: 'Fail',
                getContents: (file) => this.renderFileCell(file),
                editCellProps: (props, file) => {
                    if (file.status === FileStatus.duplicate) {
                        props.className = 'bg-warning'
                    } else if (
                        file.status === FileStatus.errorAdding ||
                        file.status === FileStatus.errorRemoving
                    ) {
                        props.className = 'bg-danger'
                    }
                },
            },
        ]

        return columns
    }

    renderFiles() {
        if (!Object.keys(this.state.files!).length) {
            return (
                <div className="small bottom-margin">
                    <em>Kirjale pole manuseid lisatud</em>
                </div>
            )
        }

        return (
            <Table
                {...getTableProps({
                    columns: this.getColumns(),
                    rows: Utils.mapValues(this.state.files!),
                    id: 'card-diary-files',
                    className: 'bordered bottom-margin',
                })}
            />
        )
    }

    async readFileBase64(file: File) {
        return new Promise<string>((resolve) => {
            const reader = new FileReader()

            reader.addEventListener('load', () => {
                const fileContentBase64 = btoa(reader.result as string)
                resolve(fileContentBase64)
            })

            reader.readAsBinaryString(file)
        })
    }

    async markFileErrorAdding(key: string) {
        const files = Utils.clone(this.state.files!)
        files[key].status = FileStatus.errorAdding
        await Utils.setState(this, { files })
    }

    async loadNewFile(fileList: FileList | null) {
        const { view } = this.props

        if (!fileList || fileList.length !== 1) {
            return
        }

        this.setState({ oversizeFileError: false })
        const file = fileList[0]

        if (file.size > MAX_FILE_SIZE) {
            this.setState({ oversizeFileError: true })
            return
        }

        const filename = file.name
        const files = Utils.clone(this.state.files!)
        const key = Utils.randomString(10)
        files[key] = { id: key, status: FileStatus.loading, name: filename }

        await Utils.setState(this, { files })

        try {
            const fileContentBase64 = await this.readFileBase64(file)
            const response = await API.addParentNotificationAttachment(
                view,
                filename,
                fileContentBase64,
            )
            const { status } = response

            if (status === AttachmentStatus.overStorageLimit) {
                toastr.error(
                    'Keskusele eraldatud salvestusruum on ammendatud',
                    'Viga faili lisamisel',
                )
                return this.markFileErrorAdding(key)
            } else {
                const relativePath = response.relativePath!
                const newFiles = Utils.clone(this.state.files!)

                newFiles[key] = {
                    id: key,
                    status: getStatus(status),
                    name: extractFilename(relativePath),
                    relativePath,
                    url: this.getFileUrl(this.state.baseUrl!, relativePath),
                }

                if (status === AttachmentStatus.added) {
                    const centreUsage = response.centreUsage!
                    return Utils.setState(this, {
                        files: newFiles,
                        centreUsage,
                    })
                } else {
                    return Utils.setState(this, { files: newFiles })
                }
            }
        } catch (err) {
            await this.markFileErrorAdding(key)
            throw err
        }
    }

    renderOversizeFileError() {
        if (!this.state.oversizeFileError) {
            return null
        }

        return (
            <div className="validation-error" style={{ color: 'red', fontSize: '80%' }}>
                Valitud fail on liiga suur
            </div>
        )
    }

    storageLimitExceeded() {
        const centreUsage = this.state.centreUsage!
        const usedStorage = centreUsage.used
        const maxStorage = centreUsage.max
        return usedStorage > maxStorage
    }

    renderAddFileButton(): ReactNode {
        if (this.storageLimitExceeded()) {
            return (
                <div className="alert alert-danger">
                    Keskusele eraldatud salvestusruum on ammendatud. Rohkem faile lisada ei saa.
                </div>
            )
        }

        return (
            <div>
                <button onClick={() => this.fileInput!.click()}>Lisa fail</button>
                <p className="small">Maksimaalne suurus 5 MB</p>
                <input
                    ref={(input) => (this.fileInput = input)}
                    type="file"
                    onChange={async (evt) => this.loadNewFile(evt.currentTarget.files)}
                    style={{ display: 'none' }}
                />
                {this.renderOversizeFileError()}
            </div>
        )
    }

    renderStorageInfo() {
        const { view } = this.props
        const centre = getCentre(view)!

        const centreUsage = this.state.centreUsage!
        const usedStorage = centreUsage.used
        const maxStorage = centreUsage.max
        const percentUsed = maxStorage === 0 ? 0 : (usedStorage / maxStorage) * 100
        const usedStorageMiB = Utils.bytesToMiB(usedStorage)
        const maxStorageMiB = Utils.bytesToMiB(maxStorage)

        const className = classnames('top-margin', 'small', {
            'text-danger': this.storageLimitExceeded(),
        })

        return (
            <div className={className}>
                <strong>{centre.name}</strong>
                {' kasutab hetkel failide salvestamiseks '}
                <strong>{Utils.formatDecimal(percentUsed, 1)}%</strong>
                {' lubatud mahust ('}
                {Utils.formatDecimal(usedStorageMiB, 1)}/{Utils.formatDecimal(maxStorageMiB, 1)}
                {' MB).'}
                <br />
                Kui soovite muuta failide salvestamise piiranguid, võtke ühendust EANK-iga.
            </div>
        )
    }

    fileOperationInProgress() {
        return Utils.mapSome(this.state.files || {}, (file) => {
            return file.status === FileStatus.loading || file.status === FileStatus.removing
        })
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div>
                <h3>Manused</h3>
                {this.renderFiles()}
                {this.renderAddFileButton()}
                {this.renderStorageInfo()}
            </div>
        )
    }
}
