import { API } from '../api'
import { EventBus } from '../event-bus'
import { EventListProps } from '../event-list'
import { getInitialEventListState } from '../initial-state'
import { AppView } from '../state'
import { Utils } from '../utils'
import { getEventViewProps } from './event-view'
import { initIfNeeded } from './init-if-needed'
import { getSelectEventPanelProps } from './select-event-panel'

export const getEventListProps = (view: AppView, selectedEventId?: string) => {
    const { state } = view

    initIfNeeded(view, 'eventList', async ({ setCleanup, isMounted }) => {
        setCleanup(() => (state.eventList = getInitialEventListState()))
        await loadData(view, isMounted)
    })

    const props: EventListProps = {
        isLoading: !state.eventList.loaded,
        panel: getSelectEventPanelProps(view),
    }

    let found = false

    Utils.iterMap(state.eventList.events, (evt) => {
        if (evt._id === selectedEventId) {
            props.eventView = getEventViewProps(view, evt)
            found = true
        }
    })

    if (selectedEventId && !found) {
        props.eventError = 'Event ' + selectedEventId + ' not found'
    }

    props.placeholder = !props.eventView && !props.eventError
    return props
}

const loadData = async (view: AppView, isMounted: () => boolean) => {
    const { state, update } = view

    const [eventCategories, events, employees, extendedEmployees] = await Promise.all([
        API.getCentreEventCategories(view),
        API.getEvents(view),
        API.getEmployees(view),
        API.getExtendedEmployees(view),
    ])

    if (!isMounted()) {
        return
    }

    state.eventList.loaded = true
    state.eventList.eventCategories = eventCategories
    state.eventList.events = events
    state.eventList.employees = employees
    state.eventList.extendedEmployees = extendedEmployees
    update()
    await EventBus.fire('event-list-rendered')
}
