import { FC } from 'react'

import { AreaSelection, AreaSelectionProps } from './area-selection'
import { BirthDate, BirthDateProps } from './birth-date'
import { CountrySelection, CountrySelectionProps } from './country-selection'
import { RadioButton, RadioButtonProps } from './radio-button'
import { SchoolSelection, SchoolSelectionProps } from './school-selection'
import { TextInput, TextInputProps } from './text-input'
import { TextArea, TextAreaProps } from './textarea'
import { ValErr, ValErrProps } from './validation'

export interface EditVisitorFormProps {
    firstName?: {
        label: string
        input: TextInputProps
        error?: ValErrProps
    }
    lastName?: {
        label: string
        input: TextInputProps
        error?: ValErrProps
    }
    username?: {
        label: string
        value?: string
        input?: TextInputProps
        error?: ValErrProps
        note?: string
    }
    country?: {
        label: string
        input: CountrySelectionProps
        error?: ValErrProps
    }
    area?: {
        label: string
        input: AreaSelectionProps
        error?: ValErrProps
    }
    settlement?: {
        label: string
        input: TextInputProps
        error?: ValErrProps
        note: string
    }
    birthDate?: {
        label: string
        input: BirthDateProps
    }
    birthYear?: {
        label: string
        value: string
    }
    gender?: {
        label: string
        buttons: RadioButtonProps[]
        error?: ValErrProps
    }
    lang?: {
        label: string
        buttons: RadioButtonProps[]
        otherButton: RadioButtonProps
        otherInput: TextInputProps
        emptyButton: RadioButtonProps
        langError?: ValErrProps
        langOtherError?: ValErrProps
    }
    school?: {
        label: string
        yesButton: RadioButtonProps
        schoolInput: SchoolSelectionProps
        typeButtons: RadioButtonProps[]
        error?: ValErrProps
    }
    working?: {
        label: string
        buttons: RadioButtonProps[]
        error?: ValErrProps
    }
    email?: {
        label: string
        input: TextInputProps
        note: string
        error?: ValErrProps
    }
    parentEmail?: {
        label: string
        input: TextInputProps
        noteLines: string[]
        error?: ValErrProps
    }
    ownPhone?: {
        label: string
        input: TextInputProps
        error?: ValErrProps
        note: string
    }
    notes?: {
        label: string
        input: TextAreaProps
        error?: ValErrProps
        note: string
    }
    info?: {
        requestInfo: {
            text: string
            lineBreak?: boolean
            moreInfoUrl: string
        }
        creationInfo?: string
    }
}

export const EditVisitorForm: FC<EditVisitorFormProps> = (props) => (
    <table className="vtop" style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
        <tbody>
            {props.firstName && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.firstName.label}</td>
                    <td>
                        <TextInput {...props.firstName.input} />
                        {props.firstName.error && <ValErr {...props.firstName.error} />}
                    </td>
                </tr>
            )}
            {props.lastName && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.lastName.label}</td>
                    <td>
                        <TextInput {...props.lastName.input} />
                        {props.lastName.error && <ValErr {...props.lastName.error} />}
                    </td>
                </tr>
            )}
            {props.username && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.username.label}</td>
                    <td>
                        {props.username.value && <span id="username">{props.username.value}</span>}
                        {props.username.input && <TextInput {...props.username.input} />}
                        {props.username.error && <ValErr {...props.username.error} />}
                        {props.username.note && (
                            <div className="input-note">{props.username.note}</div>
                        )}
                    </td>
                </tr>
            )}
            {props.country && (
                <tr id="row-country">
                    <td style={{ fontWeight: 'bold' }}>{props.country.label}</td>
                    <td>
                        <CountrySelection {...props.country.input} />
                        {props.country.error && <ValErr {...props.country.error} />}
                    </td>
                </tr>
            )}
            {props.area && (
                <tr id="row-area">
                    <td style={{ fontWeight: 'bold' }}>{props.area.label}</td>
                    <td>
                        <AreaSelection {...props.area.input} />
                        {props.area.error && <ValErr {...props.area.error} />}
                    </td>
                </tr>
            )}
            {props.settlement && (
                <tr id="row-settlement">
                    <td style={{ fontWeight: 'bold' }}>{props.settlement.label}</td>
                    <td>
                        <TextInput {...props.settlement.input} />
                        {props.settlement.error && <ValErr {...props.settlement.error} />}
                        <div className="input-note">{props.settlement.note}</div>
                    </td>
                </tr>
            )}
            {props.birthDate && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.birthDate.label}</td>
                    <td>
                        <BirthDate {...props.birthDate.input} />
                    </td>
                </tr>
            )}
            {props.gender && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.gender.label}</td>
                    <td style={{ paddingBottom: 8 }}>
                        {props.gender.buttons.map((button) => (
                            <RadioButton key={button.reactKey} {...button} />
                        ))}
                        {props.gender.error && <ValErr {...props.gender.error} />}
                    </td>
                </tr>
            )}
            {props.lang && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.lang.label}</td>
                    <td style={{ paddingBottom: 8 }}>
                        <div id="lang">
                            {props.lang.buttons.map((button) => (
                                <RadioButton key={button.reactKey} {...button} />
                            ))}
                            <RadioButton {...props.lang.otherButton}>
                                <TextInput {...props.lang.otherInput} />
                            </RadioButton>
                            <RadioButton {...props.lang.emptyButton} />
                        </div>
                        {props.lang.langError && <ValErr {...props.lang.langError} />}
                        {props.lang.langOtherError && <ValErr {...props.lang.langOtherError} />}
                    </td>
                </tr>
            )}
            {props.school && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.school.label}</td>
                    <td style={{ paddingBottom: 8 }}>
                        <div id="schoolType">
                            <RadioButton {...props.school.yesButton}>
                                <SchoolSelection {...props.school.schoolInput} />
                            </RadioButton>
                            {props.school.typeButtons.map((button) => (
                                <RadioButton key={button.reactKey} {...button} />
                            ))}
                        </div>
                        {props.school.error && <ValErr {...props.school.error} />}
                    </td>
                </tr>
            )}
            {props.working && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.working.label}</td>
                    <td style={{ paddingBottom: 8 }}>
                        <div id="working">
                            {props.working.buttons.map((button) => (
                                <RadioButton key={button.reactKey} {...button} />
                            ))}
                        </div>
                        {props.working.error && <ValErr {...props.working.error} />}
                    </td>
                </tr>
            )}
            {props.email && (
                <tr id="row-email">
                    <td style={{ fontWeight: 'bold' }}>{props.email.label}</td>
                    <td>
                        <TextInput {...props.email.input} />
                        <div className="input-note">{props.email.note}</div>
                        {props.email.error && <ValErr {...props.email.error} />}
                    </td>
                </tr>
            )}
            {props.parentEmail && (
                <tr id="row-parentEmail">
                    <td style={{ fontWeight: 'bold' }}>{props.parentEmail.label}</td>
                    <td>
                        <TextInput {...props.parentEmail.input} />
                        <div className="input-note">
                            {props.parentEmail.noteLines.map((line, index) => (
                                <div key={index}>{line}</div>
                            ))}
                        </div>
                        {props.parentEmail.error && <ValErr {...props.parentEmail.error} />}
                    </td>
                </tr>
            )}
            {props.ownPhone && (
                <tr id="row-ownPhone">
                    <td style={{ fontWeight: 'bold' }}>{props.ownPhone.label}</td>
                    <td>
                        <TextInput {...props.ownPhone.input} />
                        {props.ownPhone.error && <ValErr {...props.ownPhone.error} />}
                        <div className="input-note">{props.ownPhone.note}</div>
                    </td>
                </tr>
            )}
            {props.notes && (
                <tr id="row-notes">
                    <td style={{ fontWeight: 'bold' }}>{props.notes.label}</td>
                    <td>
                        <TextArea {...props.notes.input} />
                        {props.notes.error && <ValErr {...props.notes.error} />}
                        <div className="input-note">{props.notes.note}</div>
                    </td>
                </tr>
            )}
            {props.birthYear && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>{props.birthYear.label}</td>
                    <td>
                        <span id="birthYear">{props.birthYear.value}</span>
                    </td>
                </tr>
            )}
            {props.info && (
                <tr key="info">
                    <td colSpan={2}>
                        <p id="request-info">
                            {props.info.requestInfo.text}
                            {props.info.requestInfo.lineBreak ? <br /> : ' '}
                            <a href={props.info.requestInfo.moreInfoUrl}>Rohkem infot</a>
                        </p>
                        {props.info.creationInfo && (
                            <div id="creation-info">{props.info.creationInfo}</div>
                        )}
                    </td>
                </tr>
            )}
        </tbody>
    </table>
)
