import { createElement } from 'react'

import { MeetingVisitor } from '../../server/commands/get-meeting-visitors'
import { Activity, ActivityConf, Meeting, Participation } from '../../server/types'
import { API } from '../api'
import { Enums } from '../enums'
import { EventBus } from '../event-bus'
import { LinkProps } from '../link'
import { participantTableCells, ParticipantTableProps } from '../participant-table'
import { Session } from '../session'
import { AppView } from '../state'
import { Utils } from '../utils'
import { getEditActivityProps } from './edit-activity'
import { getEditVisitorProps } from './edit-visitor'
import { initIfNeeded } from './init-if-needed'
import { Column, getTableProps } from './table'

export interface ParticipantTableParams {
    // Not used in participant mode
    onRemoveParticipation?: (participationId: number) => Promise<void>

    reloadVisitors?: () => Promise<void> // Not used in participant mode
    onLeave?: (participationId: number) => Promise<void>

    // Not used in participant mode
    reloadMeeting?: () => Promise<void>
}

interface Row {
    id: number
    visitorId: string
    shownName: string
    managedByOtherCentre: boolean
    birthDate?: string
    language?: string
    school?: string
    working?: string
    notes?: string
    arrived?: string
    departed?: string
    activity?: Activity
    activityText?: string | null
}

export const getParticipantTableProps = (
    view: AppView,
    params: ParticipantTableParams,
): ParticipantTableProps => {
    const { state, update } = view
    const meetingState = state.meeting

    initIfNeeded(view, 'participantTable', async ({ setCleanup }) => {
        setCleanup(() => {
            meetingState.showFullDetails = false
            delete meetingState.editingVisitorId
        })

        if (!state.schools) {
            const schools = await API.getSchools()
            state.schools = schools
            update()
        }

        await EventBus.fire('participations-table-loaded')
    })

    const visitors = meetingState.visitors!
    const event = meetingState.event!
    const meeting = meetingState.meeting!

    const participations = meeting.participations.filter((p) => {
        return Session.isEmployeeMode(view) || !p.departed
    })

    const activityConf = Utils.getActivityConf(event, meeting)
    const uniqueVisitors = {}

    const columns = getColumns(view, params, activityConf)
    const rows = getRows(view, participations, uniqueVisitors, activityConf)

    const props: ParticipantTableProps = {
        isLoading: !state.schools,
        noParticipations: participations.length === 0,
        table: getTableProps({
            id: 'participations',
            columns,
            rows,
        }),
    }

    if (participations.length === 0) {
        props.noParticipations = true
        return props
    }

    if (Session.isEmployeeMode(view)) {
        props.toggleDetailsLink = {
            text: meetingState.showFullDetails ? 'Peida isikuandmed' : 'Näita isikuandmeid',
            onClick: () => {
                meetingState.showFullDetails = !meetingState.showFullDetails
                update()
            },
            id: 'toggle-details',
            style: { cursor: 'pointer' },
        }

        props.countInfo = 'Osalejate arv: ' + Object.keys(uniqueVisitors).length
    }

    const { editingVisitorId } = state.meeting

    if (editingVisitorId && state.editVisitor.editModalVisible) {
        props.editVisitor = getEditVisitorProps(view, {
            close: async () => {
                state.editVisitor.editModalVisible = false
                delete meetingState.editingVisitorId
                update()

                await EventBus.fire('modal-closed')
            },
            isNew: false,
            visitor: visitors[editingVisitorId],
            afterSave: async () => params.reloadVisitors!(),
        })
    }

    if (meetingState.activityModalConf) {
        props.editActivity = getEditActivityProps(view)
    }

    return props
}

const getColumns = (view: AppView, params: ParticipantTableParams, activityConf: ActivityConf) => {
    const { state, update } = view
    const meetingState = state.meeting

    const evt = meetingState.event!
    const meeting = meetingState.meeting!

    const showFullDetails = shouldShowFullDetails(view)
    const employeeMode = Session.isEmployeeMode(view)

    const columns: Column<Row>[] = []

    columns.push({
        id: 'name',
        header: showFullDetails ? 'Nimi' : 'Kasutajanimi',
        getContents: (rowData) => {
            if (employeeMode) {
                const linkProps: LinkProps = {
                    text: rowData.shownName,
                    onClick: async () => {
                        meetingState.editingVisitorId = rowData.visitorId
                        state.editVisitor.editModalVisible = true
                        update()
                    },
                    style: { cursor: 'pointer' },
                }

                return participantTableCells.link(linkProps)
            } else {
                return rowData.shownName
            }
        },
    })

    if (showFullDetails) {
        columns.push({ id: 'birthDate', header: 'Sündinud' })

        columns.push({
            id: 'language',
            header: 'Suhtluskeel',
            getContents(rowData) {
                if (rowData.managedByOtherCentre) {
                    const linkProps = getHiddenLinkProps(rowData.visitorId)
                    return participantTableCells.link(linkProps)
                } else {
                    return rowData.language
                }
            },
        })

        columns.push({
            id: 'school',
            header: 'Õppeasutus',
            getContents(rowData) {
                if (rowData.managedByOtherCentre) {
                    const linkProps = getHiddenLinkProps(rowData.visitorId)
                    return participantTableCells.link(linkProps)
                } else {
                    return rowData.school
                }
            },
        })

        columns.push({
            id: 'working',
            header: 'Töötab',
            getContents(rowData) {
                if (rowData.managedByOtherCentre) {
                    const linkProps = getHiddenLinkProps(rowData.visitorId)
                    return participantTableCells.link(linkProps)
                } else {
                    return rowData.working
                }
            },
        })

        columns.push({
            id: 'notes',
            header: 'Märkmed',
            editCellProps(props) {
                props.style = { maxWidth: 400, whiteSpace: 'pre-wrap' }
            },
            getContents(rowData) {
                if (rowData.managedByOtherCentre) {
                    const linkProps = getHiddenLinkProps(rowData.visitorId)
                    return participantTableCells.link(linkProps)
                } else {
                    return rowData.notes
                }
            },
        })
    }

    if (evt.timesNeeded) {
        columns.push({
            id: 'arrived',
            header: 'Saabumisaeg',
            getContents: (rowData) => {
                // TODO functional component
                return createElement(participantTableCells.time, {
                    view,
                    meeting,
                    participationId: rowData.id,
                    fieldName: 'arrived',
                    initialValue: rowData.arrived || '',
                    reload: params.reloadMeeting,
                })
            },
        })

        if (employeeMode) {
            columns.push({
                id: 'departed',
                header: 'Lahkumisaeg',
                getContents: (rowData) => {
                    return createElement(participantTableCells.time, {
                        view,
                        meeting,
                        participationId: rowData.id,
                        fieldName: 'departed',
                        initialValue: rowData.departed || '',
                        reload: params.reloadMeeting,
                    })
                },
            })
        }
    }

    if (activityConf.mode !== 'none') {
        columns.push({
            id: 'activity',
            header: 'Tegevus',
            getContents: (rowData) => {
                if (!employeeMode) {
                    return rowData.activityText
                }

                return participantTableCells.link({
                    text: rowData.activityText || '[Lisa]',
                    onClick: () => {
                        meetingState.activityModalConf = {
                            activity: rowData.activity,
                            action: {
                                type: 'update',
                                participationId: rowData.id,
                            },
                        }

                        update()
                    },
                    style: { cursor: 'pointer' },
                })
            },
        })
    }

    if (employeeMode || evt.timesNeeded) {
        columns.push({
            id: 'action',
            getContents: (rowData) => {
                if (employeeMode) {
                    return createElement(participantTableCells.loadingButton, {
                        text: 'Eemalda',
                        getPromise: async () => params.onRemoveParticipation!(rowData.id),
                        restoreOnlyOnFailure: true,
                    })
                } else if (evt.timesNeeded) {
                    return createElement(participantTableCells.loadingButton, {
                        text: 'Märgi lahkumine',
                        getPromise: async () => params.onLeave!(rowData.id),
                        className: 'leave-meeting',
                        restoreOnlyOnFailure: true,
                    })
                } else {
                    return null
                }
            },
        })
    }

    return columns
}

const getRows = (
    view: AppView,
    participations: Participation[],
    uniqueVisitors: Record<string, true>, // TODO Set<string>
    activityConf: ActivityConf,
) => {
    const { state } = view
    const meetingState = state.meeting

    const evt = meetingState.event!
    const meeting = meetingState.meeting!
    const visitors = meetingState.visitors!

    const showFullDetails = shouldShowFullDetails(view)

    return participations.map((participation) => {
        const visitor = visitors[participation.visitorId]

        if (!visitor) {
            throw new Error('Visitor ' + participation.visitorId + ' not found')
        }

        uniqueVisitors[participation.visitorId] = true

        const managedByCurrentCentre = visitor.managingCentreId === Session.getCentre(view)!._id

        const row: Row = {
            id: participation._id,
            visitorId: participation.visitorId,
            shownName: visitor.username,
            managedByOtherCentre: !managedByCurrentCentre,
        }

        if (Session.isEmployeeMode(view) && showFullDetails && managedByCurrentCentre) {
            const visitorName = visitor.firstname + ' ' + visitor.lastname
            row.shownName = visitorName + ' (' + visitor.username + ')'
        }

        if (showFullDetails) {
            row.birthDate = renderBirthDate(visitor, meeting)

            if (managedByCurrentCentre) {
                row.language =
                    visitor.lang === 'other'
                        ? visitor.langOther || ''
                        : visitor.lang
                          ? Enums.visitorLanguages[visitor.lang]
                          : ''

                row.school = renderSchool(view, visitor)
                row.working = renderWorking(visitor)
                row.notes = visitor.notes
            }
        }

        if (evt.timesNeeded) {
            row.arrived = participation.arrived
            row.departed = participation.departed
        }

        if (activityConf.mode !== 'none') {
            row.activity = participation.activity
            row.activityText = Utils.getActivityText(participation.activity!, activityConf)
        }

        return row
    })
}

const shouldShowFullDetails = (view: AppView) => {
    return Session.isEmployeeMode(view) && view.state.meeting.showFullDetails
}

const getHiddenLinkProps = (visitorId: string): LinkProps => ({
    text: '(peidetud)',
    url: `#/transfer/${visitorId}`,
    style: { color: '#b8b8b8' },
})

const renderBirthDate = (visitor: MeetingVisitor, meeting: Meeting) => {
    if (!visitor.birthDate) {
        // Can happen after entering employee mode before visitor data is reloaded
        return '-'
    }

    const birthDate = Utils.clone(visitor.birthDate)

    if (birthDate.year && birthDate.year <= Utils.getMaxAgeYear()) {
        birthDate.maxAge = true
        delete birthDate['year']
    }

    const refDate = new Date(meeting.startDate)

    const text = Utils.formatPartialDate(birthDate)
    const age = Utils.getAge(birthDate, refDate)

    return age === null ? text : `${text} (${age})`
}

const renderSchool = (view: AppView, visitor: MeetingVisitor) => {
    const { state } = view

    if (visitor.schoolType === 'yes') {
        const school = state.schools![visitor.school!]

        if (school) {
            return school.name
        } else {
            API.logWarning(view, 'School not found: ' + visitor.school, false)
            return '(viga: kooli ei leitud)'
        }
    } else if (visitor.schoolType === 'no') {
        return '(ei õpi)'
    } else {
        return ''
    }
}

const renderWorking = (visitor: MeetingVisitor) => {
    if (visitor.working === 'yes') {
        return 'Jah'
    } else if (visitor.working === 'no') {
        return 'Ei'
    } else {
        return ''
    }
}
