import { FC } from 'react'

import { LoadingIcon } from './loading-icon'

export const Loading: FC = () => (
    <div style={{ margin: 10 }}>
        Laen andmeid...
        <LoadingIcon />
    </div>
)
