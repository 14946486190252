import { ApiEmployee, Permission, Role } from '../server/types'

const isLinked = (employee: ApiEmployee, centreId: string | null) => {
    return centreId && centreId in employee.centrePermissions
}

export const isDev = (employee: { role: Role }) => employee.role === 'dev'

const hasPermission = (
    employee: ApiEmployee,
    centreId: string | null,
    permissionName: Permission,
) => Boolean(isLinked(employee, centreId) && employee.centrePermissions[centreId!][permissionName])

const hasFullCentreAccess = (employee: ApiEmployee, centreId: string | null) =>
    Boolean(centreId && (isLinked(employee, centreId) || isDev(employee)))

export const isAdmin = (employee: { role: Role }) => employee.role === 'ank' || isDev(employee)

export const canSeeCards = (employee: ApiEmployee, centreId: string | null) =>
    Boolean(centreId && (isAdmin(employee) || hasPermission(employee, centreId, 'cards')))

export const canSeeEvents = hasFullCentreAccess

// Only used in code that is run for admins, performs an additional check
export const canEditCentreForm = hasFullCentreAccess

export const canSeeAllEvents = (employee: ApiEmployee, centreId: string) => {
    return isDev(employee) || hasPermission(employee, centreId, 'allEvents')
}

// Checks centre-specific permissions, but not admin role
export const hasManagePermission = (employee: ApiEmployee, centreId: string | null) => {
    return hasPermission(employee, centreId, 'manage')
}

export const canEditPassword = (centreId: string, employee: ApiEmployee) => {
    return hasPermission(employee, centreId, 'password')
}

export const canManage = (employee: ApiEmployee, centreId: string | null) => {
    return isAdmin(employee) || hasManagePermission(employee, centreId)
}

export const getCentreCount = (employee: ApiEmployee) => {
    return Object.keys(employee.centrePermissions).length
}
