import classnames from 'classnames'

import { Button } from './button-group'

// TODO unduplicate with ButtonGroup

interface Props<T> {
    buttons: Button<T>[]
    onClick: (value: T[]) => void
    active: T[] | undefined
    vertical?: boolean
}

const isActive = <T,>(props: Props<T>, button: Button<T>) => {
    const activeValue = props.active
    return activeValue && Array.isArray(activeValue) && activeValue.indexOf(button.value) !== -1
}

const getNewValue = <T,>(props: Props<T>, button: Button<T>) => {
    const activeValue = props.active

    if (!Array.isArray(activeValue)) {
        throw new Error('Expected an array')
    }

    const newValue = [...activeValue]

    if (isActive(props, button)) {
        const index = newValue.indexOf(button.value)

        if (index === -1) {
            throw new Error('Value ' + button.value + ' not found')
        } else {
            newValue.splice(index, 1)
        }
    } else {
        newValue.push(button.value)
    }

    return newValue
}

const ButtonGroupMulti = <T,>(props: Props<T>) => {
    const groupClassName = classnames(props.vertical ? 'btn-group-vertical' : 'btn-group')

    return (
        <div className={groupClassName} data-toggle="buttons">
            {props.buttons.map((button) => {
                const className = classnames(
                    'btn btn-default',
                    { active: isActive(props, button) },
                    button.className,
                )

                return (
                    <label key={String(button.value)} id={button.id} className={className}>
                        <input
                            type="radio"
                            onClick={() => props.onClick!(getNewValue(props, button))}
                        />
                        {button.label}
                    </label>
                )
            })}
        </div>
    )
}

export const renderButtonGroupMulti = <T,>(props: Props<T>) => <ButtonGroupMulti {...props} />
