import { ChangeEvent, Component } from 'react'

import { Meeting } from '../server/types'
import { API } from './api'
import { LoadingButton } from './loading-button'
import { useCustomHandlerDuring } from './request-failure-handler'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'
import { checkValidationErrors, renderValidationError, ValidationError } from './validation'

export interface ParticipationTimeProps {
    view: AppView // TODO refactor props
    meeting: Meeting
    participationId: number
    fieldName: 'arrived' | 'departed'
    initialValue: string
    reload?: () => Promise<void>
}

interface State {
    editMode: boolean
    value: string | undefined
    validationErrors?: Record<string, ValidationError>
}

export class ParticipationTime extends Component<ParticipationTimeProps, State> {
    state: State = { editMode: false, value: undefined }
    unmounted = false

    componentWillUnmount() {
        this.unmounted = true
    }

    enterEditMode() {
        this.setState({ editMode: true, value: this.props.initialValue })
    }

    leaveEditMode() {
        this.setState({ editMode: false, value: undefined })
    }

    async save() {
        const { view } = this.props

        // Only save if value was actually edited
        if (this.state.value !== this.props.initialValue) {
            await useCustomHandlerDuring(
                Utils.getConcurrentEditErrorHandler('kellaaja'),
                async () => {
                    try {
                        await API.updateParticipationTime(
                            view,
                            this.props.meeting._id,
                            this.props.participationId,
                            this.props.fieldName,
                            this.state.value!,
                            this.props.initialValue,
                            this.props.meeting.rev,
                        )

                        if (this.props.reload) {
                            await this.props.reload()
                        }
                    } catch (error) {
                        await checkValidationErrors(view, this, error)
                    }
                },
            )
        }

        this.leaveEditMode()
    }

    render() {
        const { view } = this.props
        const value = this.state.editMode ? this.state.value : this.props.initialValue

        if (!Session.isEmployeeMode(view)) {
            return <span>{value}</span>
        }

        if (this.state.editMode) {
            return (
                <span>
                    <input
                        value={value}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            this.setState({ value: evt.target.value })
                        }}
                        style={{ width: 50, marginRight: 3 }}
                    />
                    <LoadingButton
                        text="Salvesta"
                        getPromise={async () => this.save()}
                        restoreOnlyOnFailure
                    />
                    {renderValidationError(this.state.validationErrors, 'newValue')}
                </span>
            )
        } else {
            return (
                <a onClick={() => this.enterEditMode()} style={{ cursor: 'pointer' }}>
                    {value || '[Lisa]'}
                </a>
            )
        }
    }
}
