import classnames from 'classnames'
import { ChangeEvent, Component } from 'react'

import { GetAllCards_Card } from '../server/commands/get-all-cards'
import { VisitorForCards } from '../server/commands/get-visitors-for-cards'
import { LimitedEmployee } from '../server/remove-employee-fields'
import { API } from './api'
import { CardsMenu } from './cards-menu'
import { renderDropdown } from './dropdown'
import { Enums } from './enums'
import { Loading } from './loading'
import { AppView } from './state'
import { Utils } from './utils'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

export interface TransferCardsSelectionProps {
    view: AppView // TODO refactor props
    origin: string
    destination: string
}

interface State {
    loaded: boolean
    employeeId: string
    selectedCardIds: Record<string, true> // TODO Set<string>
    originName?: string
    destinationName?: string
    originEmployees?: Record<string, LimitedEmployee>
    destinationEmployees?: LimitedEmployee[]
    linkedCards?: GetAllCards_Card[]
    unlinkedCards?: GetAllCards_Card[]
    visitors?: Record<string, VisitorForCards>
    validationErrors?: Record<string, ValidationError>
}

interface CentreNames {
    originName?: string
    destinationName?: string
}

export class TransferCardsSelection extends Component<TransferCardsSelectionProps, State> {
    state: State = { loaded: false, employeeId: '', selectedCardIds: {} }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const centreNamesPromise = this.getCentreNames()
        const originEmployeesPromise = API.getCentreEmployees(view, this.props.origin)
        const destinationEmployeesPromise = this.getAllowedDestinationEmployees()
        const cardsAndVisitors = await this.getOriginCardsAndVisitors()
        const centreNames = await centreNamesPromise

        this.setState({
            loaded: true,
            originName: centreNames.originName,
            destinationName: centreNames.destinationName,
            originEmployees: await originEmployeesPromise,
            destinationEmployees: await destinationEmployeesPromise,
            linkedCards: cardsAndVisitors.linkedCards,
            unlinkedCards: cardsAndVisitors.unlinkedCards,
            visitors: cardsAndVisitors.visitors,
        })
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async getCentreNames() {
        const { view } = this.props
        const origId = this.props.origin
        const destId = this.props.destination

        const centres = await API.getAvailableCentres(view)
        const names: CentreNames = {}

        for (const centre of centres) {
            if (centre._id === origId) {
                names.originName = centre.name
            }

            if (centre._id === destId) {
                names.destinationName = centre.name
            }
        }

        return names
    }

    async getAllowedDestinationEmployees() {
        const { view } = this.props
        const centreId = this.props.destination
        const employees = await API.getCentreEmployees(view, centreId)
        return Utils.filterMap(employees, (employee) => employee.centrePermissions[centreId].cards)
    }

    async getOriginCardsAndVisitors() {
        const { view } = this.props
        const orig = this.props.origin

        const result = await API.getAllCards(view)
        const linkedCards: GetAllCards_Card[] = []
        const unlinkedCards: GetAllCards_Card[] = []
        const cardIds = Object.keys(result.cards)

        for (const id of cardIds) {
            const card = result.cards[id]

            if (card.centreId === orig) {
                if (card.unlinked) {
                    unlinkedCards.push(card)
                } else {
                    linkedCards.push(card)
                }
            }
        }

        return { linkedCards, unlinkedCards, visitors: result.visitors }
    }

    renderError(msg: string) {
        return (
            <div className="main-panel">
                <div className="alert alert-danger">{msg}</div>
                <div>
                    <a href="#/cards/transfer">Tagasi</a>
                </div>
            </div>
        )
    }

    renderTitle() {
        return <h2>Juhtumikaartide üle tõstmine</h2>
    }

    renderCentres() {
        const originName = this.state.originName
        const destinationName = this.state.destinationName

        return (
            <div>
                <div>
                    {'Keskusest '}
                    <b>{originName}</b>
                </div>
                <div>
                    {'Keskusesse '}
                    <b>{destinationName}</b>
                </div>
            </div>
        )
    }

    renderEmployeeSelect() {
        return (
            <div className="bottom-margin">
                {renderDropdown({
                    options: [
                        {
                            id: '',
                            label: 'Uus vastutav töötaja',
                            additional: { className: 'hint' },
                        },
                        ...this.state.destinationEmployees!.map((employee) => {
                            return { id: employee._id, label: employee.name }
                        }),
                    ],
                    value: this.state.employeeId,
                    onChange: (employeeId) => this.setState({ employeeId }),
                    additional: { className: 'form-control' },
                })}
                {renderValidationError(this.state.validationErrors, 'newEmployeeId')}
            </div>
        )
    }

    toggleCardSelected(cardId: string, checked: boolean) {
        const selectedCardIds = Utils.clone(this.state.selectedCardIds)

        if (checked) {
            selectedCardIds[cardId] = true
        } else {
            delete selectedCardIds[cardId]
        }

        this.setState({ selectedCardIds })
    }

    selectCards(cards: GetAllCards_Card[]) {
        const selectedCardIds = Utils.clone(this.state.selectedCardIds)

        for (const card of cards) {
            selectedCardIds[card._id] = true
        }

        this.setState({ selectedCardIds })
    }

    getParticipantName(card: GetAllCards_Card) {
        if (card.unlinked) {
            return card.general['nimi']
        } else {
            const visitor = this.state.visitors![card.visitorId!]
            return visitor.firstname + ' ' + visitor.lastname
        }
    }

    renderSelectAllLink(cards: GetAllCards_Card[]) {
        const onClick = () => this.selectCards(cards)

        return (
            <div>
                <a onClick={onClick} style={{ cursor: 'pointer' }}>
                    Vali kõik
                </a>
            </div>
        )
    }

    renderCardRow(card: GetAllCards_Card) {
        return (
            <tr key={card._id}>
                <td>
                    <input
                        type="checkbox"
                        value={card._id}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                            this.toggleCardSelected(card._id, evt.currentTarget.checked)
                        }}
                        checked={card._id in this.state.selectedCardIds}
                    />
                </td>
                <td>{this.getParticipantName(card)}</td>
                <td>{Enums.cardPhaseDescriptions[card.phase]}</td>
                <td>{this.state.originEmployees![card.employeeId].name}</td>
            </tr>
        )
    }

    renderCardsTable(cards: GetAllCards_Card[]) {
        return (
            <table className="bordered transfer-card-list">
                <thead>
                    <tr>
                        <th />
                        <th>Nimi</th>
                        <th>Faas</th>
                        <th>Vastutaja</th>
                    </tr>
                </thead>
                <tbody>{cards.map((card) => this.renderCardRow(card))}</tbody>
            </table>
        )
    }

    renderLinkedCards() {
        if (!this.state.linkedCards!.length) {
            return null
        }

        return (
            <div className="bottom-margin">
                <h4>
                    <b>Külastajate kontodega seotud kaardid</b>
                </h4>
                {
                    // In this case, bottom-margin is used to reduce the margin, not to add it
                    <div className="alert alert-warning bottom-margin">
                        NB! Ümber tõstmisel ühendatakse need kaardid kasutajakontodest lahti.
                    </div>
                }
                {this.renderSelectAllLink(this.state.linkedCards!)}
                {this.renderCardsTable(this.state.linkedCards!)}
            </div>
        )
    }

    renderUnlinkedCards() {
        if (!this.state.unlinkedCards!.length) {
            return null
        }

        return (
            <div className="bottom-margin">
                <h4>
                    <b>Külastajate kontodega sidumata kaardid</b>
                </h4>
                {this.renderSelectAllLink(this.state.unlinkedCards!)}
                {this.renderCardsTable(this.state.unlinkedCards!)}
                {renderValidationError(this.state.validationErrors, 'cardIds')}
            </div>
        )
    }

    async transfer() {
        const { view } = this.props
        const fromCentreId = this.props.origin
        const toCentreId = this.props.destination
        const newEmployeeId = this.state.employeeId
        const cardIds = Object.keys(this.state.selectedCardIds)

        await clearValidationErrors(view, this)

        try {
            await API.transferCards(view, fromCentreId, toCentreId, newEmployeeId, cardIds)
            view.navigate(['cards', 'transfer', 'success'])
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    isCardSelected() {
        return Object.keys(this.state.selectedCardIds).length > 0
    }

    getMessage() {
        if (!this.state.employeeId) {
            return 'Uus vastutav töötaja on valimata'
        }

        if (!this.isCardSelected()) {
            return 'Kaardid on valimata'
        }

        return undefined
    }

    renderTransferButton() {
        const disabled = !this.state.employeeId || !this.isCardSelected()
        const className = classnames({ disabled: disabled })

        return (
            <button
                className={className}
                disabled={disabled}
                onClick={async () => this.transfer()}
                title={this.getMessage()}
            >
                Tõsta kaardid ümber
            </button>
        )
    }

    renderMain() {
        const orig = this.props.origin
        const dest = this.props.destination

        if (orig === dest) {
            return this.renderError('Alg- ja lõppkeskus ei tohi olla sama')
        }

        if (!this.state.loaded) {
            return <Loading />
        }

        if (!this.state.linkedCards!.length && !this.state.unlinkedCards!.length) {
            return this.renderError('Valitud algkeskuses pole juhtumikaarte')
        }

        if (!this.state.destinationEmployees!.length) {
            return this.renderError('Lõppkeskuses pole ühtki kaartide haldamise õigusega töötajat')
        }

        return (
            <div className="main-panel">
                {this.renderTitle()}
                {this.renderCentres()}
                {this.renderLinkedCards()}
                {this.renderUnlinkedCards()}
                {this.renderEmployeeSelect()}
                {this.renderTransferButton()}
            </div>
        )
    }

    render() {
        const { view } = this.props

        return (
            <div>
                <CardsMenu view={view} page="transfer" />
                {this.renderMain()}
            </div>
        )
    }
}
