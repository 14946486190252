import { FC } from 'react'

import { Meeting } from '../server/types'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'

export interface MeetingListProps {
    view: AppView // TODO refactor props
    meetings: Record<string, Meeting>
}

export const MeetingList: FC<MeetingListProps> = ({ view, meetings }) => {
    const sortedMeetings = Utils.mapValues(meetings)

    // Sort by start date/time, newest first
    sortedMeetings.sort((meeting1, meeting2) => {
        let result = meeting2.startDate.localeCompare(meeting1.startDate)

        if (result === 0) {
            result = (meeting2.startTime || '').localeCompare(meeting1.startTime || '')
        }

        if (result === 0) {
            result = meeting2._id.localeCompare(meeting1._id)
        }

        return result
    })

    const meetingRows = sortedMeetings.map((meeting) => {
        const time = Utils.formatTimeRange(
            meeting.startDate,
            meeting.startTime,
            meeting.endDate,
            meeting.endTime,
        )

        const uniqueVisitors = new Set<string>()

        for (const participation of meeting.participations) {
            uniqueVisitors.add(participation.visitorId)
        }

        const visitorCount = uniqueVisitors.size
        let anonymousCount = 0

        if (meeting.anonymous) {
            for (const group of meeting.anonymous) {
                anonymousCount += group.total || 0
            }
        }

        const centre = Session.getCentre(view)!

        return (
            <tr key={meeting._id}>
                <td>{time}</td>
                <td>{meeting.externalLocation ? meeting.externalLocation : centre.name}</td>
                <td>{meeting.name}</td>
                <td>{visitorCount}</td>
                <td>{anonymousCount}</td>
                <td>{visitorCount + anonymousCount}</td>
                <td>
                    <button onClick={() => view.navigate(['meeting', meeting._id])}>
                        Detailvaade
                    </button>
                </td>
            </tr>
        )
    })

    return (
        <table className="bordered">
            <thead>
                <tr>
                    <th>Aeg</th>
                    <th>Asukoht</th>
                    <th>Nimetus</th>
                    <th>Nimelisi osalejaid</th>
                    <th>Anonüümseid osalejaid</th>
                    <th>Kokku</th>
                    <th>Detailvaade</th>
                </tr>
            </thead>
            <tbody>{meetingRows}</tbody>
        </table>
    )
}
