import xmlEscape from 'xml-escape'

import { copyStringToArray } from './copy-string-to-array'

/**
 * Utility for building XML strings.
 *
 * The method names are unconventionally short, but when used repetitively
 * to build complex XML structures, it can arguably improve readability.
 *
 * D = return type of done()
 */
export interface XmlBuilder<D = unknown> {
    /** Add attribute */
    a: (attrName: string, value: string | number) => this

    /**
     * Add attribute and convert Unicode characters in the value to UTF-8 bytes.
     * This is useful for zip file generation.
     */
    au: (attrName: string, value: string | number) => this

    /** Add child element */
    e: (tagName: string) => XmlBuilder<this>

    /** Set inner text */
    t: (text: string | number) => this

    /**
     * Set inner text and convert Unicode characters to UTF-8 bytes.
     * This is useful for zip file generation.
     */
    u: (text: string) => this

    /** Execute custom function on current builder */
    f: (func: (builder: this) => any) => this

    /** Finish building current element */
    done: () => D
}

/** Structure: [name, value] */
type Attribute = [string, string]

type Children = Child[] | string

interface Child {
    /** Tag name */
    t: string
    a: Attribute[]
    c: Children
}

const HEADER = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n'
const HEADER_ARRAY = new Uint8Array(HEADER.length)
copyStringToArray(HEADER, HEADER_ARRAY)

const toUtfBytes = (str: string) => unescape(encodeURIComponent(str))

const createElementBuilder = <D>(
    tagName: string,
    depth: number,
    internalDone: (attributes: Attribute[], children: Children, length: number) => D,
): XmlBuilder<D> => {
    let len = tagName.length + depth * 2 + 2
    const attributes: Attribute[] = []
    let children: Children
    let isDone = false

    const builder: XmlBuilder<D> = {
        a(attrName, value) {
            const escaped: string = xmlEscape(String(value))
            attributes.push([attrName, escaped])
            len += attrName.length + escaped.length + 4
            return builder
        },
        au(attrName, value) {
            return builder.a(attrName, toUtfBytes(String(value)))
        },
        e(childTagName) {
            return createElementBuilder(childTagName, depth + 1, (a, c, l) => {
                if (typeof children === 'string') {
                    throw new Error('Cannot mix text and elements')
                }

                if (!children) {
                    children = []
                }

                children.push({ t: childTagName, a, c })
                len += l + 1
                return builder
            })
        },
        t(text) {
            if (children) {
                throw new Error('Children already set')
            }

            const escaped: string = xmlEscape(String(text))
            children = escaped
            len += escaped.length
            return builder
        },
        u(text) {
            return builder.t(toUtfBytes(text))
        },
        f(func) {
            func(builder)
            return builder
        },
        done() {
            if (isDone) {
                throw new Error('done() has already been called')
            }

            isDone = true

            if (Array.isArray(children)) {
                len += tagName.length + depth * 2 + 4
            } else if (typeof children === 'string') {
                len += tagName.length + 3
            } else {
                len += 2
            }

            return internalDone(attributes, children, len)
        },
    }

    return builder
}

const writeToArray = (
    arr: Uint8Array,
    indexParam: number,
    tagName: string,
    attributes: Attribute[],
    children: Children,
    indent: string = '',
) => {
    let index = indexParam

    const write = (str: string) => {
        copyStringToArray(str, arr, index)
        index += str.length
    }

    write(indent)
    write('<')
    write(tagName)

    for (const [attrName, value] of attributes) {
        write(' ')
        write(attrName)
        write('="')
        write(value)
        write('"')
    }

    if (Array.isArray(children)) {
        write('>\n')

        for (const { t, a, c } of children) {
            index = writeToArray(arr, index, t, a, c, indent + '  ')
            write('\n')
        }

        write(indent)
        write('</')
        write(tagName)
        write('>')
    } else if (typeof children === 'string') {
        write('>')
        write(children)
        write('</')
        write(tagName)
        write('>')
    } else {
        write(' />')
    }

    return index
}

export const xml = (rootTag: string): XmlBuilder<ArrayBuffer> => {
    return createElementBuilder(rootTag, 0, (attributes, children, len) => {
        const arr = new Uint8Array(HEADER.length + len)
        arr.set(HEADER_ARRAY)
        writeToArray(arr, HEADER.length, rootTag, attributes, children)
        return arr.buffer
    })
}
