import { Component, ReactNode } from 'react'
import toastr from 'toastr'

import { Activity, ActivityConf, BirthDate, Event, Meeting } from '../server/types'
import { assertNever } from '../util/assert-never'
import { filterMapToMap } from '../util/filter-map-to-map'
import { CommonUtils } from './common-utils'
import { Enums } from './enums'
import { CustomRequestHandler } from './request-failure-handler'

type AgeObj =
    | { type: 'unknown' }
    | { type: 'max' }
    | { type: 'negative' }
    | { type: 'exact'; value: number }
    | { type: 'range'; min: number; max: number }
    | { type: 'unknown' }

export interface AlreadyHandledError extends Error {
    alreadyHandled?: boolean
}

export const getAlreadyHandledError = () => {
    // Used to skip elements of promise chains after errors have been handled.
    // If it reaches window.onerror, it will be silently ignored.

    const error = new Error('Handled error - can be ignored') as AlreadyHandledError
    error.alreadyHandled = true
    return error
}

// Alternative to component.setState that returns a promise instead of taking a callback
export const setState = async <S, K extends keyof S>(
    component: Component<unknown, S> & { unmounted: boolean },
    stateUpdate: Pick<S, K>,
) =>
    new Promise<void>((resolve) => {
        if (component.unmounted) {
            resolve()
        } else {
            component.setState(stateUpdate, resolve)
        }
    })

// TODO export members
export const Utils = {
    clone: CommonUtils.clone,
    arrayContains: CommonUtils.arrayContains,
    member: CommonUtils.member,
    iterMap: CommonUtils.iterMap,
    filterMap: CommonUtils.filterMap,
    mapMap: CommonUtils.mapMap,
    mapValues: CommonUtils.mapValues,
    mapSome: CommonUtils.mapSome,
    identity: CommonUtils.identity,
    sortAsStrings: CommonUtils.sortAsStrings,
    findByField: CommonUtils.findByField,
    findById: CommonUtils.findById,
    findIndex: CommonUtils.findIndex,
    findIndexByField: CommonUtils.findIndexByField,
    getNow: CommonUtils.getNow,
    setNow: CommonUtils.setNow,
    getCurrentYear: CommonUtils.getCurrentYear,
    maxAge: CommonUtils.maxAge,
    getMaxAgeYear: CommonUtils.getMaxAgeYear,
    formatTime: CommonUtils.formatTime,
    formatDate: CommonUtils.formatDate,
    formatDateYmd: CommonUtils.formatDateYmd,
    formatDateTime: CommonUtils.formatDateTime,
    formatDateFromString: CommonUtils.formatDateFromString,
    formatDateTimeFromString: CommonUtils.formatDateTimeFromString,
    formatPartialDate: CommonUtils.formatPartialDate,
    twoLastDigits: CommonUtils.twoLastDigits,
    getLatestRevNumber: CommonUtils.getLatestRevNumber,
    getRevision: CommonUtils.getRevision,
    bytesPerKiB: CommonUtils.bytesPerKiB,
    bytesToKiB: CommonUtils.bytesToKiB,
    bytesPerMiB: CommonUtils.bytesPerMiB,
    bytesToMiB: CommonUtils.bytesToMiB,
    bytesPerGiB: CommonUtils.bytesPerGiB,
    bytesToGiB: CommonUtils.bytesToGiB,
    randomString: CommonUtils.randomString,
    minutesSinceMidnight: CommonUtils.minutesSinceMidnight,

    arraysEqual(arr1: string[], arr2: string[]) {
        if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
            throw new Error('Non-array given to arraysEqual')
        }

        if (arr1.length !== arr2.length) {
            return false
        }

        return arr1.every((element, index) => {
            if (typeof element === 'string') {
                // TODO
                return arr2[index] === element
            } else {
                throw new Error('arraysEqual currently only supports arrays with string elements')
            }
        })
    },

    setState,

    pluralize(count: number, singular: string, plural: string) {
        return count + ' ' + (count === 1 ? singular : plural)
    },

    getJSON: async <T,>(url: string): Promise<T> => {
        const response = await Promise.resolve(fetch(url))
        return response.json()
    },

    formatDecimal(valueParam: number, numDecimals = 2) {
        if (isNaN(valueParam)) {
            throw new Error('Invalid number')
        }

        if (!Number.isInteger(numDecimals) || numDecimals < 0) {
            throw new Error('Invalid number of decimals')
        }

        const negative = valueParam < 0
        const value = negative ? -valueParam : valueParam
        const factor = Math.pow(10, numDecimals)
        let str = String(parseInt(String(value * factor + 0.5), 10))

        while (str.length < numDecimals + 1) {
            str = '0' + str
        }

        const full = (negative ? '-' : '') + str.substring(0, str.length - numDecimals)
        let frac = '.' + str.substring(str.length - numDecimals)
        frac = frac.replace(/0+$/, '')

        if (frac === '.') {
            frac = ''
        }

        return full + frac
    },

    getAgeObj(dateObj: BirthDate, refDateParam?: Date): AgeObj {
        const refDate = refDateParam || Utils.getNow()

        if (isNaN(refDate.getTime())) {
            throw new Error('Invalid date')
        }

        if (!dateObj) {
            return { type: 'unknown' }
        }

        if (dateObj.maxAge) {
            return { type: 'max' }
        } else if (dateObj.year) {
            const currentYear = Utils.getCurrentYear(refDate)
            const currentMonth = refDate.getMonth() + 1
            const currentDay = refDate.getDate()

            const yearDiff = currentYear - Number(dateObj.year)

            if (yearDiff < 0) {
                return { type: 'negative' }
            }

            if (dateObj.month && dateObj.day) {
                if (
                    currentMonth > Number(dateObj.month) ||
                    (currentMonth === Number(dateObj.month) && currentDay >= Number(dateObj.day)) ||
                    yearDiff === 0 // Future date in current year - invalid, but count as 0 years old
                ) {
                    return { type: 'exact', value: yearDiff }
                } else {
                    return { type: 'exact', value: yearDiff - 1 }
                }
            } else if (dateObj.year === currentYear) {
                return { type: 'exact', value: 0 }
            } else if (dateObj.month && dateObj.month !== currentMonth) {
                return {
                    type: 'exact',
                    value: dateObj.month > currentMonth ? yearDiff - 1 : yearDiff,
                }
            } else {
                return { type: 'range', min: yearDiff - 1, max: yearDiff }
            }
        } else {
            return { type: 'unknown' }
        }
    },

    getAge(dateObj: BirthDate, refDate?: Date) {
        const ageObj = Utils.getAgeObj(dateObj, refDate)

        if (ageObj.type === 'max') {
            return Utils.maxAge + '+'
        } else if (ageObj.type === 'exact') {
            if (ageObj.value > Utils.maxAge) {
                return Utils.maxAge + '+'
            }

            return ageObj.value.toString()
        } else if (ageObj.type === 'range') {
            return ageObj.min + '-' + ageObj.max
        } else if (ageObj.type === 'negative') {
            return '<0'
        } else if (ageObj.type === 'unknown') {
            return null
        } else {
            throw assertNever(ageObj, 'age type', (ageObj as { type?: string }).type)
        }
    },

    getAgeKey(ageObj: AgeObj, forAnonymous: boolean): string {
        let minAge: number

        if (ageObj.type === 'max') {
            minAge = Utils.maxAge
        } else if (ageObj.type === 'exact') {
            minAge = ageObj.value
        } else if (ageObj.type === 'range') {
            minAge = ageObj.min
        } else if (ageObj.type === 'negative') {
            minAge = 0
        } else {
            throw new Error('Invalid age type: ' + ageObj.type)
        }

        const options = forAnonymous ? Enums.ageOptionsAnon : Enums.ageOptions
        let result: string | undefined

        for (const ageOption of options._order) {
            const optionMin = (options._minAges as Record<string, number>)[ageOption]

            if (optionMin > minAge) {
                break
            } else {
                result = ageOption
            }
        }

        return result!
    },

    formatTimeRange(startDate: string, startTime?: string, endDate?: string, endTime?: string) {
        let fromTime = Utils.formatDateFromString(startDate)
        let toTime = ''

        if (endDate && endDate !== startDate) {
            toTime = Utils.formatDateFromString(endDate)
        }

        if (startTime) {
            fromTime += ' ' + startTime

            if (endTime) {
                toTime += (toTime ? ' ' : '') + endTime
            }
        }

        return fromTime + (toTime ? ' - ' + toTime : '')
    },

    contains(haystack: string, needle: string) {
        return haystack.toLowerCase().indexOf(needle.toLowerCase()) !== -1
    },

    // Case-insensitive
    startsWith(haystack: string, needle: string) {
        return haystack.toLowerCase().substr(0, needle.length) === needle.toLowerCase()
    },

    // TODO: highlightFn param no longer overridden, default is always used
    highlight(
        haystack: string,
        needle?: string,
        highlightFnParam?: (text: string) => ReactNode,
    ): ReactNode {
        if (!needle) {
            return haystack
        }

        const i = haystack.toLowerCase().indexOf(needle.toLowerCase())

        if (i === -1) {
            return haystack
        } else {
            // Key is only here to avoid warning
            const highlightFn = highlightFnParam || ((string) => <b key="dummy">{string}</b>)
            const p1 = haystack.substr(0, i)
            const p2 = haystack.substr(i, needle.length)
            const p3 = haystack.substr(i + needle.length)
            return [p1, highlightFn(p2), p3]
        }
    },

    cleanName(originalName: string) {
        return originalName
            .toLowerCase()
            .replace(/[Õõ]/g, '6')
            .replace(/[Ää]/g, '2')
            .replace(/[Öö]/g, '8')
            .replace(/[Üü]/g, 'y')
            .replace(/[()"]/g, '')
            .replace(/[^A-Za-z0-9-]/g, '-')
            .replace(/-+/g, '-')
    },

    uniqueId(cleanName: string, existingIds: string[]) {
        let id = cleanName
        let index = 0

        while (existingIds.indexOf(id) !== -1) {
            index += 1
            id = cleanName + '-' + index
        }

        return id
    },

    upperCaseFirst(string: string) {
        return string[0].toUpperCase() + string.substr(1)
    },

    range(start: number, end: number) {
        const result: number[] = []

        for (let i = start; i <= end; i++) {
            result.push(i)
        }

        return result
    },

    _monthNames: [
        'jaanuar',
        'veebruar',
        'märts',
        'aprill',
        'mai',
        'juuni',
        'juuli',
        'august',
        'september',
        'oktoober',
        'november',
        'detsember',
    ],

    getMonthName(monthNum: number) {
        return Utils._monthNames[monthNum - 1]
    },

    getMonthYearString(month: string) {
        // Param is a "YYYY-MM" string
        const monthNum = Number(month.substr(5))
        const year = month.substr(0, 4)
        return Utils.upperCaseFirst(Utils.getMonthName(monthNum)) + ' ' + year
    },

    scrollToIfNeeded(node: HTMLElement) {
        const topCoord = node.getBoundingClientRect().top

        // Scroll only if top coord is not already in the viewport
        if (topCoord < 0 || topCoord > window.innerHeight) {
            node.scrollIntoView()
        }
    },

    getAlreadyHandledError,

    getConcurrentEditMessage(objectName: string) {
        return (
            'Keegi teine on vahepeal selle ' +
            objectName +
            ' juures muudatusi teinud. ' +
            'Et oma muudatusi teha, pead kõigepealt lehe uuesti laadima ' +
            '(tavaliselt F5 nupuga), kuid selle peale lähevad kaduma ' +
            'sinu praegused salvestamata muudatused.'
        )
    },

    getErrorHandler(errorType: string, message: string): CustomRequestHandler {
        return (response) => {
            if ((response as { errorType?: string }).errorType === errorType) {
                toastr.error(message, '', { timeOut: 0, extendedTimeOut: 0 })
                return true
            } else {
                return false
            }
        }
    },

    getConcurrentEditErrorHandler(objectName: string) {
        const message = Utils.getConcurrentEditMessage(objectName)
        return Utils.getErrorHandler('concurrent-edit', message)
    },

    getActivityConf(evt: Event, meeting: Meeting): ActivityConf {
        let activityConf = evt.activityConf

        if (activityConf.mode === 'meeting') {
            activityConf = meeting.activityConf!
        }

        return activityConf
    },

    getActivityText(activity: Activity, activityConf: ActivityConf): string | null {
        if (activityConf.mode === 'choice') {
            if (typeof activity !== 'object' || !activity) {
                throw new Error('Expected activity object')
            }

            const values = activity.selected.slice()

            if (activityConf.other && activity.other) {
                values.push(activity.other)
            }

            return values.join(', ')
        } else {
            if (activity !== null && typeof activity !== 'string') {
                throw new Error('Expected activity string or null')
            }

            return activity
        }
    },

    filterEventsByArchived(events: Record<string, Event>, includeArchived: boolean) {
        return filterMapToMap(events, (evt) => !evt.archived || includeArchived)
    },

    filterMeetingsByDate: <T extends { startDate: string; endDate?: string }>(
        meetings: T[] | Record<string, T>,
        dateFrom?: string,
        dateTo?: string,
    ) => {
        const filter = (meeting: T) => {
            let matches = true

            // Meetings may have a duration of several days.
            // We include the meeting in the results if its date range at least overlaps
            // with the filtered range.

            if (dateFrom) {
                // If meeting has no end date specified, it ends on the same date it starts
                const endDate = meeting.endDate || meeting.startDate

                matches = endDate >= dateFrom
            }

            if (matches && dateTo) {
                matches = meeting.startDate <= dateTo
            }

            return matches
        }

        return Array.isArray(meetings) ? meetings.filter(filter) : Utils.filterMap(meetings, filter)
    },
}
