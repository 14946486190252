import { GetAllCards_Card } from '../server/commands/get-all-cards'
import { Centre, MultiCheckboxChoice, MultiCheckboxValue } from '../server/types'
import { CardAction } from '../util/card-action'
import { CardFields } from './card-fields'
import { EsfReportFilters, PartitionOption } from './state'
import { Utils } from './utils'

interface CategoryCondition {
    key: string
    detailKey?: string
}

interface RegularCategory {
    type: 'regular'
    label: string
    conditions: CategoryCondition[]
}

interface WorkStudyCategory {
    type: 'work-study'
    label: string
    working: boolean
    studying: boolean
}

type Category = RegularCategory | WorkStudyCategory

const phase4ValueOptions = CardFields.getPhase4Fields()[0].valueField!.options!

const workConditions = [{ key: 'tool' }]

const studyConditions = [
    { key: 'taseme' },
    { key: 'kursustel' },
    { key: 'eratund' },
    { key: 'valjasp' },
]

const extractCategoryConditionLabel = ({ key, detailKey }: CategoryCondition) => {
    const option = phase4ValueOptions.find((opt) => opt.id === key)

    if (!option) {
        throw new Error('No option found for category condition key ' + key)
    }

    if (!detailKey) {
        return '"' + option.label + '"'
    }

    if (!option.details || !option.details.options) {
        throw new Error('Option with category condition key ' + key + ' has no sub-options')
    }

    const subOption = option.details.options.find((subOpt) => subOpt.id === detailKey)

    if (!subOption) {
        throw new Error('No sub-option found for category condition detail key ' + detailKey)
    }

    return '"' + option.label + ' - ' + subOption.label + '"'
}

const generateRegularCategories = (): RegularCategory[] => {
    const conditionsArrays: CategoryCondition[][] = [
        [{ key: 'praktika' }],
        [{ key: 'ettev' }],
        [{ key: 'otsib', detailKey: 'reg' }],
        [{ key: 'star' }],
        [{ key: 'huvihar' }, { key: 'noorsootoo', detailKey: 'pikk-huvihar' }],
        [{ key: 'noorsootoo', detailKey: 'pikk-huviteg' }],
        [{ key: 'noorsootoo', detailKey: 'vabat-est' }],
        [{ key: 'noorsootoo', detailKey: 'solid-est' }],
        [{ key: 'noorsootoo', detailKey: 'solid-vaba-valis' }],
        [{ key: 'noorsootoo', detailKey: 'ava' }],
        [{ key: 'noorsootoo', detailKey: 'pikk-proj' }],
        [{ key: 'noorsootoo', detailKey: 'osalusk' }],
        [{ key: 'sojav' }],
        [{ key: 'syn' }],
        [{ key: 'ebas' }],
    ]

    return conditionsArrays.map((conditions) => {
        const conditionLabels = conditions.map(extractCategoryConditionLabel)
        const label = conditionLabels.join(', ')
        return { type: 'regular', label, conditions }
    })
}

const workStudyCategories: Category[] = [
    {
        type: 'work-study',
        label: 'Õpib ja töötab',
        working: true,
        studying: true,
    },
    {
        type: 'work-study',
        label: 'Töötab, aga ei õpi',
        working: true,
        studying: false,
    },
    {
        type: 'work-study',
        label: 'Õpib, aga ei tööta',
        working: false,
        studying: true,
    },
]

const regularCategories = generateRegularCategories()

const categories: Category[] = workStudyCategories.concat(regularCategories)

const cardHasPhase4Choices = (card: GetAllCards_Card) =>
    Boolean(
        card.phase4 &&
            card.phase4.faas4 &&
            card.phase4.faas4.value &&
            card.phase4.faas4.value.choices,
    )

const choiceSatisfiesCondition = (choice: MultiCheckboxChoice, condition: CategoryCondition) => {
    if (choice.key !== condition.key) {
        return false
    }

    if (!condition.detailKey) {
        return true
    }

    if (!choice.details) {
        return false
    }

    const details: MultiCheckboxValue = choice.details as any // TODO

    if (!details.choices) {
        return false
    }

    return details.choices.some((detailChoice) => detailChoice.key === condition.detailKey)
}

const choicesSatisfySomeCondition = (
    choices: MultiCheckboxChoice[],
    conditions: CategoryCondition[],
) =>
    conditions.some((condition) =>
        choices.some((choice) => choiceSatisfiesCondition(choice, condition)),
    )

const cardPhase3EndDateMatches = (card: GetAllCards_Card, date: string) => {
    if (date === '') {
        return true
    }

    return card.log
        .filter(({ action }) => action === CardAction.END3)
        .every(({ time }) => time > date) // Comparing ISO string to YYYY-MM-DD
}

const cardPhase2StartDateMatches = (card: GetAllCards_Card, date: string) => {
    if (date === '') {
        return true
    }

    const { START2, RESUME } = CardAction

    return card.log
        .filter(({ action }) => action === START2 || action === RESUME)
        .some(({ time }) => time >= date) // Comparing ISO string to YYYY-MM-DD
}

const cardCentreMatches = (card: GetAllCards_Card, centreId: string) => {
    return centreId === '' || card.centreId === centreId
}

const cardPartitionMatches = (
    card: GetAllCards_Card,
    partition: PartitionOption,
    centres: Record<string, Centre>,
) => {
    const centre = centres[card.centreId]

    if (partition === 'tln') {
        return centre.partition === partition
    }

    if (partition === 'other') {
        return centre.partition === undefined
    }

    return true
}

const cardPhase2Or3DateMatches = (card: GetAllCards_Card, date: string) => {
    if (date === '') {
        return true
    }

    let dateIsWithinRange = false

    for (const { time, action } of card.log) {
        const strTime = Utils.formatDateYmd(new Date(time))

        if (strTime > date) {
            break
        }

        if (action === CardAction.START2 || action === CardAction.RESUME) {
            dateIsWithinRange = true
        } else if (action === CardAction.END3 || action === CardAction.CANCEL) {
            dateIsWithinRange = false
        }
    }

    return dateIsWithinRange
}

const cardMatchesFilters = (
    card: GetAllCards_Card,
    filters: EsfReportFilters,
    centres: Record<string, Centre>,
) => {
    const { phase3EndDate, phase2StartDate, centreId, partition, phase2Or3Date } = filters

    return (
        cardPhase3EndDateMatches(card, phase3EndDate) &&
        cardPhase2StartDateMatches(card, phase2StartDate) &&
        cardCentreMatches(card, centreId) &&
        cardPartitionMatches(card, partition, centres) &&
        cardPhase2Or3DateMatches(card, phase2Or3Date)
    )
}

const countCardCategories = (
    sums: Record<number, number>,
    card: GetAllCards_Card,
    filters: EsfReportFilters,
    centres: Record<string, Centre>,
) => {
    if (!cardHasPhase4Choices(card) || !cardMatchesFilters(card, filters, centres)) {
        return
    }

    const { choices } = card.phase4!.faas4!.value!
    const isWorking = choicesSatisfySomeCondition(choices, workConditions)
    const isStudying = choicesSatisfySomeCondition(choices, studyConditions)

    categories.forEach((category, idx) => {
        let satisfiesCondition = false

        if (category.type === 'regular') {
            satisfiesCondition = choicesSatisfySomeCondition(choices, category.conditions)
        } else {
            satisfiesCondition = category.working === isWorking && category.studying === isStudying
        }

        if (satisfiesCondition) {
            if (!(idx in sums)) {
                throw new Error('Invalid category index: ' + idx)
            }

            sums[idx] += 1
        }
    })
}

export const getEsfRows = (
    cards: Record<string, GetAllCards_Card>,
    filters: EsfReportFilters,
    centres: Record<string, Centre>,
) => {
    const sums: Record<number, number> = {}
    categories.forEach((_category, idxCategory) => (sums[idxCategory] = 0))
    Utils.iterMap(cards, (card) => countCardCategories(sums, card, filters, centres))
    return categories.map(({ label }, idx) => ({
        id: idx,
        label,
        sum: sums[idx],
    }))
}
