import { FC } from 'react'

import { EventPlaceholder } from './event-placeholder'
import { EventView, EventViewProps } from './event-view'
import { Loading } from './loading'
import { SelectEventPanel, SelectEventPanelProps } from './select-event-panel'

export interface EventListProps {
    isLoading: boolean
    panel: SelectEventPanelProps
    placeholder?: boolean
    eventView?: EventViewProps
    eventError?: string
}

export const EventList: FC<EventListProps> = (props) => {
    if (props.isLoading) {
        return <Loading />
    }

    return (
        <div>
            <table className="vtop">
                <tbody>
                    <tr>
                        <td className="left-panel">
                            <SelectEventPanel {...props.panel} />
                        </td>
                        <td className="right-panel">
                            {props.placeholder && <EventPlaceholder />}
                            {props.eventView && (
                                <EventView key={props.eventView.reactKey} {...props.eventView} />
                            )}
                            {props.eventError && (
                                <div className="alert alert-danger">
                                    <b>{'Error: '}</b>
                                    {props.eventError}
                                </div>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
