// TODO break into smaller files
import { Component, CSSProperties, ReactNode } from 'react'
import toastr from 'toastr'

import { CardInput } from '../server/commands/add-card'
import { ExistingCardInfoTrue } from '../server/commands/check-existing-card'
import { CardNameInfo } from '../server/commands/find-cards-by-name'
import { EmployeeForCards } from '../server/commands/get-employees-for-cards'
import { GetVisitor_Visitor } from '../server/commands/get-visitor'
import { VisitorRequestInfo } from '../server/commands/get-visitor-request-info'
import { CardUpdate } from '../server/commands/update-card'
import { UpdateCardPhase_Params } from '../server/commands/update-card-phase'
import { LimitedEmployee } from '../server/remove-employee-fields'
import { Card, CardDiaryEntry, CentreFormContents, Field, SingleChoiceValue } from '../server/types'
import { CardAction } from '../util/card-action'
import { CardPhase } from '../util/card-phase'
import { filterMapToMap } from '../util/filter-map-to-map'
import { isAdmin } from './access'
import { API } from './api'
import { CardEmployee } from './card-employee'
import { CardFields, CentreFormParams } from './card-fields'
import { CardUtils } from './card-utils'
import { Enums } from './enums'
import { EventBus } from './event-bus'
import { expandValues, finalize, finalizeValues, Form, renderEditor } from './form'
import { Loading } from './loading'
import { LoadingButton, LoadingButtonProps } from './loading-button'
import { useCustomHandlerDuring } from './request-failure-handler'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

enum Mode {
    view = 'view',
    edit = 'edit',
    cancelCard = 'cancelCard',
    editEmployee = 'editEmployee',
}

enum DuplicateStatus {
    notChecked = 'notChecked',
    unique = 'unique',
    possiblyDuplicate = 'possiblyDuplicate',
}

export interface CardViewProps {
    reactKey?: string
    view: AppView // TODO refactor props
    isNew: boolean
    unlinked?: boolean
    visitorId?: string
    cardId?: string
}

interface CentreMismatchError extends Error {
    isCentreMismatch: true
    visitorRequestInfo: VisitorRequestInfo
    visitor: GetVisitor_Visitor
}

interface ExistingCardError extends Error {
    existingCardInfo: ExistingCardInfoTrue
    visitor: GetVisitor_Visitor
}

interface State {
    loaded: boolean
    visitor: GetVisitor_Visitor | null
    cardEmployees?: Record<string, LimitedEmployee>
    logEmployees?: Record<string, EmployeeForCards>
    employeeId?: string
    mode?: Mode
    unlinked?: boolean
    duplicateStatus?: DuplicateStatus
    nameSaved?: boolean
    ignorePossibleDuplicates?: boolean
    centreFormValues?: CentreFormContents
    generalFields?: Field[]
    phase2fields?: Field[]
    phase3fields?: Field[]
    feedbackFields?: Field[]
    phase4fields?: Field[]
    phase?: CardPhase
    generalValues?: Card['general']
    phase2values?: Card['phase2']
    phase3values?: Card['phase3']
    feedbackValues?: Card['feedback']
    phase4values?: Card['phase4']
    phase4date?: string
    log?: Card['log']
    rev?: number
    diaryEntries?: Record<string, CardDiaryEntry>
    errorType?: 'centre-mismatch' | 'existing-card'
    visitorRequestInfo?: VisitorRequestInfo
    existingCardInfo?: ExistingCardInfoTrue
    cancelReason?: SingleChoiceValue<'no-contact' | 'other' | null>
    validationErrors?: Record<string, ValidationError>
    possibleDuplicates?: CardNameInfo[]
}

const phases = Enums.cardPhases
const actions = Enums.cardActions

const buttonTexts: Record<CardAction, string> = {
    [CardAction.START2]: '', // Unused
    [CardAction.END2]: 'Lõpeta 2. faas',
    [CardAction.START3]: 'Alusta 3. faasi',
    [CardAction.END3]: 'Lõpeta 3. faas',
    [CardAction.START4]: 'Alusta 4. faasi',
    [CardAction.CANCEL]: 'Katkesta programm',
    [CardAction.RESUME]: 'Jätka programmi',
}

const isCentreMismatchError = (error: Error): error is CentreMismatchError => {
    return 'isCentreMismatch' in error
}

const isExistingCardError = (error: Error): error is ExistingCardError => {
    return 'existingCardInfo' in error
}

export class CardView extends Component<CardViewProps, State> {
    state: State = { loaded: false, visitor: null }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        let card: Card | undefined
        let visitor: GetVisitor_Visitor | null = null
        let logEmployees: Record<string, EmployeeForCards> | undefined

        const currentCentre = Session.getCentre(view)

        if (!currentCentre) {
            return
        }

        try {
            if (!this.props.isNew) {
                const response = await API.getCardAndEmployees(view, this.props.cardId!)
                card = response.card
                logEmployees = response.employees

                if (!card.unlinked) {
                    const visitorParam = await API.getVisitor(view, card.visitorId!)
                    visitor = visitorParam
                }
            } else if (!this.props.unlinked) {
                const visitorId = this.props.visitorId!

                const visitorPromise = API.getVisitor(view, visitorId)
                const existingCardInfo = await API.checkExistingCard(view, visitorId)
                visitor = await visitorPromise

                if (visitor.managingCentreId !== currentCentre._id) {
                    const info = await API.getVisitorRequestInfo(view, visitor._id)
                    const error = new Error() as CentreMismatchError
                    error.isCentreMismatch = true
                    error.visitorRequestInfo = info
                    error.visitor = visitor
                    throw error
                } else if (existingCardInfo.exists) {
                    const error = new Error() as ExistingCardError
                    error.existingCardInfo = existingCardInfo
                    error.visitor = visitor
                    throw error
                }
            }

            const centreEmployeesPromise = API.getEmployees(view)

            const diaryPromise = this.props.isNew
                ? Promise.resolve(undefined)
                : API.getCardDiaryEntries(view, this.props.cardId!)

            const [centreFormData, centreEmployees, diaryEntries] = await Promise.all([
                API.getCentreForm(view),
                centreEmployeesPromise,
                diaryPromise,
            ])

            const unlinked = this.props.isNew ? this.props.unlinked! : card!.unlinked!

            let centreFormValues: CentreFormContents = {}

            if (centreFormData) {
                const activeRevision = Utils.getLatestRevNumber(centreFormData)
                centreFormValues = Utils.getRevision(centreFormData.revisions, activeRevision)
            }

            const cardEmployees = filterMapToMap(centreEmployees, (employee) => {
                return employee.centrePermissions[currentCentre._id].cards
            })

            const currentEmployee = Session.getEmployee(view)

            let phase: CardPhase = CardPhase.STARTED2
            let generalValues: Card['general'] = {}
            let phase2values: Card['phase2'] = {}
            let phase3values: Card['phase3'] = {}
            let feedbackValues: Card['feedback'] = {}
            let phase4values: Card['phase4'] = {}
            let phase4date: string | undefined
            let log: Card['log'] = []
            let rev = 0
            let employeeId = ''

            if (card) {
                phase = card.phase
                generalValues = card.general
                phase2values = card.phase2
                phase3values = card.phase3
                feedbackValues = card.feedback
                phase4values = card.phase4
                phase4date = card.phase4date
                log = card.log
                rev = card.rev
                employeeId = card.employeeId

                if (this.isPhase3Visible(card.phase)) {
                    phase3values = phase3values || {}
                    feedbackValues = feedbackValues || {}
                }

                if (this.isPhase4Visible(card.phase)) {
                    phase4values = phase4values || {}
                }
            } else if (currentEmployee._id in cardEmployees) {
                employeeId = currentEmployee._id
            }

            const centreFormParams: CentreFormParams = {
                mode: 'centre-specific',
                values: centreFormValues,
                card,
            }

            const generalFields = CardFields.getGeneralFields(unlinked, visitor, () =>
                this.renderCheckDuplicateButton(),
            )

            const phase2fields = CardFields.getPhase2Fields(centreFormParams)
            const phase3fields = CardFields.getPhase3Fields(centreFormParams)
            const feedbackFields = CardFields.getFeedbackFields()
            const phase4fields = CardFields.getPhase4Fields()

            expandValues(generalFields, generalValues)
            expandValues(phase2fields, phase2values)
            expandValues(phase3fields, phase3values)
            expandValues(feedbackFields, feedbackValues)
            expandValues(phase4fields, phase4values)

            // cardEmployees - employees of the current centre who have permissions to access cards.
            // logEmployees - employees associated with entries of this card's log. May sometimes
            //   not be linked to this centre or not have card access, due to changes made since.

            await Utils.setState(this, {
                loaded: true,
                visitor,
                cardEmployees,
                logEmployees,
                employeeId,
                mode: this.props.isNew ? Mode.edit : Mode.view,
                unlinked,
                duplicateStatus: DuplicateStatus.notChecked,
                nameSaved: !this.props.isNew,
                ignorePossibleDuplicates: false,
                centreFormValues,
                generalFields,
                phase2fields,
                phase3fields,
                feedbackFields,
                phase4fields,
                phase,
                generalValues,
                phase2values,
                phase3values,
                feedbackValues,
                phase4values,
                phase4date,
                log,
                rev,
                diaryEntries,
            })

            await EventBus.fire('card-rendered')
        } catch (error) {
            if (isCentreMismatchError(error)) {
                await Utils.setState(this, {
                    errorType: 'centre-mismatch',
                    visitorRequestInfo: error.visitorRequestInfo,
                    visitor: error.visitor,
                })
            } else if (isExistingCardError(error)) {
                await Utils.setState(this, {
                    errorType: 'existing-card',
                    existingCardInfo: error.existingCardInfo,
                    visitor: error.visitor,
                })
            } else {
                throw error
            }

            await EventBus.fire('error-rendered')
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async reloadCard() {
        const { view } = this.props
        const { card, employees } = await API.getCardAndEmployees(view, this.props.cardId!)

        if (this.isPhase3Visible(card.phase)) {
            card.phase3 = card.phase3 || {}
            card.feedback = card.feedback || {}
        }

        if (this.isPhase4Visible(card.phase)) {
            card.phase4 = card.phase4 || {}
        }

        const centreFormParams: CentreFormParams = {
            mode: 'centre-specific',
            values: this.state.centreFormValues,
            card,
        }

        let generalFields = this.state.generalFields!

        // If an unlinked card is linked, the component will be unmounted,
        // so we don't need to worry about updating the state.
        // However, a linked card can be unlinked without leaving this view.
        // In this case, we need to update generalFields.
        if (card.unlinked) {
            const renderDupButton = () => this.renderCheckDuplicateButton()
            generalFields = CardFields.getGeneralFields(true, null, renderDupButton)
        }

        const phase2fields = CardFields.getPhase2Fields(centreFormParams)
        const phase3fields = CardFields.getPhase3Fields(centreFormParams)

        expandValues(generalFields, card.general)
        expandValues(phase2fields, card.phase2)
        expandValues(phase3fields, card.phase3)
        expandValues(this.state.feedbackFields!, card.feedback)
        expandValues(this.state.phase4fields!, card.phase4)

        await Utils.setState(this, {
            logEmployees: employees,
            employeeId: card.employeeId,
            mode: Mode.view,
            unlinked: card.unlinked,
            duplicateStatus: DuplicateStatus.notChecked,
            nameSaved: !this.props.isNew,
            ignorePossibleDuplicates: false,
            generalFields,
            phase2fields,
            phase3fields,
            phase: card.phase,
            generalValues: card.general,
            phase2values: card.phase2,
            phase3values: card.phase3,
            feedbackValues: card.feedback,
            phase4values: card.phase4,
            phase4date: card.phase4date,
            log: card.log,
            rev: card.rev,
            cancelReason: { key: null, details: null, other: null },
            visitor: card.unlinked ? null : this.state.visitor,
        })

        await EventBus.fire('card-reloaded')
    }

    phase4open() {
        return Utils.getNow() > new Date(this.state.phase4date!)
    }

    async enterEditMode() {
        await Utils.setState(this, { mode: Mode.edit })
        await EventBus.fire('card-editor-rendered')
    }

    async enterCancelCardMode() {
        return Utils.setState(this, {
            mode: Mode.cancelCard,
            cancelReason: { key: null, other: null, details: null },
        })
    }

    onCancelReasonChange(_id: string, newValue: State['cancelReason']) {
        this.setState({ cancelReason: newValue })
    }

    async save() {
        const { view } = this.props

        const common = {
            general: finalizeValues(this.state.generalFields!, this.state.generalValues),
            phase2: finalizeValues(this.state.phase2fields!, this.state.phase2values),
        }

        await clearValidationErrors(view, this)

        try {
            if (this.props.isNew) {
                const card: CardInput = {
                    ...common,
                    employeeId: this.state.employeeId!,
                }

                if (this.props.unlinked) {
                    card.unlinked = true
                } else {
                    card.visitorId = this.props.visitorId!
                }

                const response = await API.addCard(view, card)
                view.navigate(['cards', 'view', response.id])
            } else {
                const card: CardUpdate = common

                if (this.isPhase3Visible(this.state.phase!)) {
                    card.phase3 = finalizeValues(this.state.phase3fields!, this.state.phase3values)
                    card.feedback = finalizeValues(
                        this.state.feedbackFields!,
                        this.state.feedbackValues,
                    )
                }

                if (this.isPhase4Visible(this.state.phase!)) {
                    card.phase4 = finalizeValues(this.state.phase4fields!, this.state.phase4values)
                }

                await useCustomHandlerDuring(
                    Utils.getConcurrentEditErrorHandler('juhtumikaardi'),
                    async () => API.updateCard(view, this.props.cardId!, this.state.rev!, card),
                )

                await this.reloadCard()
            }
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    async updatePhase(action: CardAction, additional?: UpdateCardPhase_Params['additional']) {
        const { view } = this.props

        await clearValidationErrors(view, this)

        try {
            await API.updateCardPhase(view, this.props.cardId!, action, additional)
            await this.reloadCard()
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    async unlinkVisitor() {
        const { view } = this.props
        const response = await API.unlinkCard(view, this.props.cardId!)

        if (response.success) {
            return this.reloadCard()
        }

        if (response.errorType === 'cardAlreadyUnlinked') {
            toastr.error('Juhtumikaart on juba lahti ühendatud')
        } else if (response.errorType === 'cardHasNoVisitorId') {
            toastr.error('Juhtumikaart ei viita ühelegi külastajale')
        } else if (response.errorType === 'visitorNotFound') {
            toastr.error('Kaardiga seotud külastajat ei leitud')
        } else {
            toastr.error('Viga: ' + response.errorType)
        }
    }

    async deleteCard() {
        const { view } = this.props

        if (!confirm('Kas olete kindel, et soovite seda juhtumikaarti kustutada?')) {
            return
        }

        await API.deleteCard(view, this.props.cardId!)
        view.navigate(['cards'])
    }

    isPhase3Visible(phase: CardPhase) {
        return phase === phases.started3 || phase === phases.ended3 || phase === phases.started4
    }

    isPhase4Visible(phase: CardPhase) {
        return phase === phases.started4
    }

    renderCentreMismatchInfo() {
        return (
            <div className="main-panel">
                {this.renderBackToNewLink()}
                <p>Teil puuduvad piisavad õigused, et sellele külastajale juhtumikaarti luua.</p>
                <p>
                    <a href={'#/transfer/' + this.state.visitor!._id}>Rohkem infot</a>
                </p>
            </div>
        )
    }

    renderExistingCardInfo() {
        const { view } = this.props
        const info = this.state.existingCardInfo!
        const visitor = this.state.visitor!

        let message: ReactNode

        if (info.isCurrentCentre) {
            message = (
                <div>
                    {'Külastajale kasutajanimega '}
                    <b>{visitor.username}</b>
                    {' on '}
                    <a href={'#/cards/view/' + info.cardId}>juhtumikaart</a>
                    {' juba loodud.'}
                </div>
            )
        } else {
            throw new Error(
                'Visitor ' +
                    visitor._id +
                    ' already has a card, but at ' +
                    info.centreId +
                    ' instead of ' +
                    Session.getCentre(view)!._id,
            )
        }

        return (
            <div className="main-panel">
                {this.renderBackToNewLink()}
                {message}
            </div>
        )
    }

    renderBackLink() {
        return (
            <div style={{ marginBottom: 10 }}>
                <a href="#/cards">Tagasi nimekirja</a>
            </div>
        )
    }

    renderBackToNewLink() {
        return (
            <div style={{ marginBottom: 10 }}>
                <a href="#/cards/new">Tagasi</a>
            </div>
        )
    }

    renderDiary() {
        const { view } = this.props

        if (this.props.isNew) {
            return
        }

        return (
            <div style={{ marginBottom: 10 }}>
                <h3 style={{ marginTop: 0 }}>
                    <b>Päevik</b>
                </h3>
                {this.renderDiaryTable()}
                <div>
                    <LoadingButton
                        id="new-diary-entry"
                        getPromise={async () => {
                            view.navigate(['cards', 'new-diary', this.props.cardId!])
                        }}
                        text="Lisa uus sissekanne"
                        disabled={this.state.mode !== Mode.view}
                    />
                </div>
            </div>
        )
    }

    renderDiaryTable() {
        if (Object.keys(this.state.diaryEntries!).length > 0) {
            return (
                <table id="diary" className="bordered" style={{ marginBottom: 10 }}>
                    <thead>
                        <tr>
                            <th>Kuupäev</th>
                            <th>Koht</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Utils.mapMap(this.state.diaryEntries!, (entry) => (
                            <tr key={entry._id}>
                                <td>
                                    <a href={'#/cards/view-diary/' + entry._id}>
                                        {Utils.formatDateFromString(entry.date)}
                                    </a>
                                </td>
                                <td>{entry.location}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )
        } else {
            return (
                <div id="lbl-no-diary-entries" style={{ marginBottom: 5 }}>
                    Te pole veel teinud sellel juhtumikaardil ühtegi päeviku sissekannet.
                </div>
            )
        }
    }

    renderLog() {
        if (this.props.isNew) {
            return
        }

        return (
            <div>
                <h3 style={{ marginTop: 0 }}>
                    <b>Logi</b>
                </h3>
                <table id="log" className="bordered">
                    <thead>
                        <tr>
                            <th>Aeg</th>
                            <th>Töötaja</th>
                            <th>Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.log!.map((entry, ix) => {
                            const employee = this.state.logEmployees![entry.employeeId]

                            return (
                                <tr key={ix}>
                                    <td>{Utils.formatDateTime(new Date(entry.time))}</td>
                                    <td>{employee.name}</td>
                                    <td>{CardUtils.renderLogInfo(entry)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderPhase4Info() {
        if (this.state.phase === phases.ended3 && !this.phase4open()) {
            return (
                <div id="phase4-note" style={{ marginTop: 10 }}>
                    {'4. faas avaneb täitmiseks kuue kuu möödumisel ehk '}
                    {Utils.formatDate(new Date(this.state.phase4date!))}
                </div>
            )
        } else {
            return null
        }
    }

    getName() {
        if (this.state.unlinked) {
            return this.state.generalValues!['nimi']!.trim()
        } else {
            const visitor = this.state.visitor!
            return visitor.firstname + ' ' + visitor.lastname
        }
    }

    async checkDuplicate() {
        const { view } = this.props
        const cardName = this.getName()
        const possibleDuplicates = await API.findCardsByName(view, cardName, this.props.cardId!)

        const duplicateStatus = possibleDuplicates.length
            ? DuplicateStatus.possiblyDuplicate
            : DuplicateStatus.unique

        await Utils.setState(this, { duplicateStatus, possibleDuplicates })
    }

    getDuplicatesMessage() {
        const numPossibleDuplicates = (this.state.possibleDuplicates || []).length

        if (this.state.ignorePossibleDuplicates) {
            if (numPossibleDuplicates === 1) {
                return 'Leitud sama nimega kaart pole seotud selle inimesega'
            } else {
                return 'Leitud sama nimega kaardid pole seotud selle inimesega'
            }
        }

        if (numPossibleDuplicates === 0) {
            return 'Sama nimega juhtumikaarte ei leidu'
        } else if (numPossibleDuplicates === 1) {
            return 'Leidub sama nimega juhtumikaart'
        } else {
            return 'Leidub sama nimega juhtumikaarte'
        }
    }

    async checkDuplicateAndSave() {
        if (this.state.duplicateStatus === DuplicateStatus.unique || this.state.nameSaved) {
            return this.save()
        }

        await this.checkDuplicate()

        // TypeScript does not realize that this.state can change
        // during checkDuplicate() so we need to add 'as DuplicateStatus'
        if ((this.state.duplicateStatus as DuplicateStatus) === DuplicateStatus.unique) {
            return this.save()
        } else {
            window.scrollTo(0, 0)
            const msg = this.getDuplicatesMessage()
            toastr.error(msg)
        }
    }

    renderSaveButton(atPageBottom: boolean) {
        if (this.state.mode === Mode.edit) {
            const style: CSSProperties = { marginRight: 5 }

            const props: LoadingButtonProps = {
                key: 'save',
                className: 'save',
                getPromise: async () => this.checkDuplicateAndSave(),
                text: this.props.isNew ? 'Salvesta' : 'Salvesta muudatused',
                style,
                disabled: this.state.duplicateStatus === DuplicateStatus.possiblyDuplicate,
            }

            if (props.disabled) {
                props.tooltip = this.getDuplicatesMessage()
            }

            if (atPageBottom) {
                style.marginTop = 10
            }

            return <LoadingButton {...props} />
        } else {
            return null
        }
    }

    renderCancelEditsButton() {
        const { view } = this.props

        if (!this.props.isNew && this.state.mode === Mode.edit) {
            return (
                <LoadingButton
                    key="cancel-edits"
                    id="cancel"
                    getPromise={async () => {
                        await clearValidationErrors(view, this)
                        await this.reloadCard()
                    }}
                    text="Tühista muudatused"
                    style={{ marginRight: 5 }}
                />
            )
        } else {
            return null
        }
    }

    renderEditButton() {
        if (!this.props.isNew && this.state.mode === Mode.view) {
            return (
                <LoadingButton
                    key="edit"
                    id="edit"
                    getPromise={async () => this.enterEditMode()}
                    text="Muuda andmeid"
                    style={{ marginRight: 5 }}
                />
            )
        } else {
            return null
        }
    }

    renderPhaseButton(action: CardAction) {
        if (this.props.isNew) {
            return null
        }

        const props: LoadingButtonProps = {
            key: action,
            text: buttonTexts[action],
            style: { marginRight: 5 },
        }

        if (this.state.mode === Mode.view) {
            props.getPromise = async () => this.updatePhase(action)
        } else {
            props.disabled = true
        }

        return <LoadingButton {...props} />
    }

    renderCancelCardButton() {
        if (this.props.isNew || this.state.mode === Mode.cancelCard) {
            return null
        }

        const props: LoadingButtonProps = {
            key: actions.cancel,
            text: buttonTexts[CardAction.CANCEL],
            id: 'cancel-card',
            style: { marginRight: 5 },
        }

        if (this.state.mode === Mode.view) {
            props.getPromise = async () => this.enterCancelCardMode()
        } else {
            props.disabled = true
        }

        return <LoadingButton {...props} />
    }

    renderLinkVisitorButton() {
        const { view } = this.props

        if (!this.props.isNew && this.state.unlinked) {
            return (
                <LoadingButton
                    key="link"
                    disabled={this.state.mode !== Mode.view}
                    getPromise={async () => {
                        view.navigate(['cards', 'link', this.props.cardId!])
                    }}
                    text="Seo külastajaga"
                    style={{ marginRight: 5 }}
                />
            )
        } else {
            return null
        }
    }

    renderUnlinkVisitorButton() {
        if (!this.props.isNew && !this.state.unlinked) {
            return (
                <LoadingButton
                    key="unlink"
                    disabled={this.state.mode !== Mode.view}
                    getPromise={async () => this.unlinkVisitor()}
                    restoreOnlyOnFailure
                    text="Ühenda külastaja kontost lahti"
                    style={{ marginRight: 5 }}
                />
            )
        } else {
            return null
        }
    }

    renderDeleteCardButton() {
        const { view } = this.props

        if (!this.props.isNew && isAdmin(Session.getEmployee(view))) {
            return (
                <LoadingButton
                    key="delete"
                    disabled={this.state.mode !== Mode.view}
                    getPromise={async () => this.deleteCard()}
                    text="Kustuta kaart"
                    style={{ marginRight: 5 }}
                />
            )
        } else {
            return null
        }
    }

    renderEditDatesButton() {
        const { view } = this.props

        if (isAdmin(Session.getEmployee(view))) {
            return (
                <LoadingButton
                    key="edit-dates"
                    disabled={this.state.mode !== Mode.view}
                    getPromise={async () => {
                        view.navigate(['cards', 'edit-dates', this.props.cardId!])
                    }}
                    text="Muuda kuupäevi"
                    style={{ marginRight: 5 }}
                />
            )
        } else {
            return null
        }
    }

    renderButtons() {
        const buttons: ReactNode[] = []
        const phase = this.state.phase

        buttons.push(this.renderSaveButton(false))

        buttons.push(this.renderCancelEditsButton())

        buttons.push(this.renderEditButton())

        // TODO move canApplyActionInPhase from CardPhases to CardUtils and use it here?

        if (phase === phases.started2) {
            buttons.push(this.renderPhaseButton(CardAction.END2))
        }

        if (phase === phases.ended2) {
            buttons.push(this.renderPhaseButton(CardAction.START3))
        }

        if (phase === phases.started3) {
            buttons.push(this.renderPhaseButton(CardAction.END3))
        }

        if (phase === phases.ended3) {
            if (this.phase4open()) {
                buttons.push(this.renderPhaseButton(CardAction.START4))
            }

            buttons.push(this.renderPhaseButton(CardAction.RESUME))
        }

        if (phase === phases.cancelled) {
            buttons.push(this.renderPhaseButton(CardAction.RESUME))
        }

        if (phase !== phases.cancelled) {
            buttons.push(this.renderCancelCardButton())
        }

        if (!this.props.isNew) {
            if (this.state.unlinked) {
                buttons.push(this.renderLinkVisitorButton())
            } else {
                buttons.push(this.renderUnlinkVisitorButton())
            }
        }

        buttons.push(this.renderDeleteCardButton())
        buttons.push(this.renderEditDatesButton())

        return (
            <div id="buttons" style={{ marginTop: 10 }}>
                {buttons}
            </div>
        )
    }

    renderCancelConfirmation() {
        if (this.state.mode === Mode.cancelCard) {
            const cancelReasonField = CardUtils.getCancelReasonField()

            return (
                <div style={{ marginTop: 5 }}>
                    <div id="cancel-form">
                        {renderEditor(cancelReasonField, this.state.cancelReason, (id, value) =>
                            this.onCancelReasonChange(id, value),
                        )}
                    </div>
                    {renderValidationError(this.state.validationErrors, 'additional.reason', {
                        // If value is sent as string instead of object,
                        // it probably means the text field was empty
                        array: 'Kohustuslik väli',
                    })}
                    <LoadingButton
                        text={buttonTexts[CardAction.CANCEL]}
                        getPromise={async () => {
                            const reason = finalize(cancelReasonField, this.state.cancelReason)
                            await this.updatePhase(CardAction.CANCEL, { reason })
                        }}
                        id="confirm-cancel"
                        style={{ marginTop: 5 }}
                    />
                </div>
            )
        } else {
            return null
        }
    }

    renderEmployee(): ReactNode {
        const { view } = this.props
        const mode = this.state.mode

        return (
            <div style={{ marginTop: 10 }}>
                <CardEmployee
                    view={view}
                    directMode={this.props.isNew}
                    editMode={mode === Mode.editEmployee}
                    disabled={mode !== Mode.view}
                    employees={this.state.cardEmployees!}
                    employeeId={this.state.employeeId!}
                    validationErrors={this.state.validationErrors}
                    openEditMode={() => this.setState({ mode: Mode.editEmployee })}
                    cancelEditMode={async () => Utils.setState(this, { mode: Mode.view })}
                    onSave={async (employeeId) => {
                        if (this.props.isNew) {
                            // Direct mode - update state, no API call yet
                            await Utils.setState(this, { employeeId })
                        } else {
                            const response = await API.updateCardEmployee(
                                view,
                                this.props.cardId!,
                                employeeId,
                            )
                            await Utils.setState(this, {
                                mode: Mode.view,
                                employeeId,
                                rev: response.newRev,
                            })
                            await EventBus.fire('card-employee-updated')
                        }
                    }}
                />
            </div>
        )
    }

    renderActivityLink() {
        if (this.state.visitor && this.state.mode === Mode.view) {
            return (
                <div style={{ marginTop: 10 }}>
                    <a
                        href={'#/stats/visitor-activity/' + this.state.visitor._id}
                        id="activity-link"
                    >
                        Vaata külastaja aktiivsust keskuse sündmustel
                    </a>
                </div>
            )
        } else {
            return null
        }
    }

    renderCheckDuplicateButton() {
        if (this.state.mode !== Mode.edit || (!this.state.unlinked && !this.props.isNew)) {
            return null
        }

        const cardName = this.state.generalValues!['nimi']

        const disabled =
            (this.state.unlinked && (!cardName || !cardName.trim())) ||
            this.state.duplicateStatus !== DuplicateStatus.notChecked

        return (
            <div className="top-margin">
                <LoadingButton
                    text={
                        this.state.nameSaved
                            ? 'Kontrolli, kas mõni teine sama nimega juhtumikaart leidub'
                            : 'Kontrolli, kas sellise nimega juhtumikaart juba leidub'
                    }
                    getPromise={async () => this.checkDuplicate()}
                    disabled={disabled}
                />
                {this.renderDuplicateCheck()}
            </div>
        )
    }

    markUnique() {
        this.setState({
            duplicateStatus: DuplicateStatus.unique,
            ignorePossibleDuplicates: true,
        })
    }

    renderMarkUniqueButton() {
        return (
            <button className="top-margin" onClick={() => this.markUnique()}>
                Tegu ei ole sama inimesega
            </button>
        )
    }

    renderDuplicateCardInfo(cardInfo: CardNameInfo) {
        return (
            <li key={cardInfo.cardId}>
                {cardInfo.centreName}
                {': '}
                <strong>{cardInfo.cardName}</strong>
            </li>
        )
    }

    renderDuplicatesWarning() {
        return (
            <div className="alert alert-warning top-margin">
                <p className="bold">{this.getDuplicatesMessage()}</p>
                <p>
                    {'Kontrollimaks, kas tegemist on sama inimesega, võtke ühendust vastava keskuse ' +
                        'töötajatega'}
                </p>
                <ul>
                    {this.state.possibleDuplicates!.map((info) =>
                        this.renderDuplicateCardInfo(info),
                    )}
                </ul>
                {this.renderMarkUniqueButton()}
            </div>
        )
    }

    renderDuplicateCheck() {
        if (this.state.mode !== Mode.edit) {
            return null
        }

        const duplicateStatus = this.state.duplicateStatus

        if (duplicateStatus === DuplicateStatus.notChecked) {
            return null
        } else if (duplicateStatus === DuplicateStatus.unique) {
            return <div className="top-margin">{this.getDuplicatesMessage()}</div>
        } else if (duplicateStatus === DuplicateStatus.possiblyDuplicate) {
            return this.renderDuplicatesWarning()
        } else {
            throw new Error('Unrecognized duplicate status: ' + duplicateStatus)
        }
    }

    renderForm(
        fields: Field[],
        valuesVar:
            | 'phase3values' // TODO
            | 'feedbackValues'
            | 'phase4values'
            | 'generalValues'
            | 'phase2values',
        validationPrefix: string,
    ) {
        const validationErrors: Record<string, ValidationError> = {}

        if (this.state.validationErrors) {
            for (const fieldName of Object.keys(this.state.validationErrors)) {
                if (fieldName.startsWith(validationPrefix + '.')) {
                    const unprefixed = fieldName.substr(validationPrefix.length + 1)
                    validationErrors[unprefixed] = this.state.validationErrors[fieldName]
                }
            }
        }

        return (
            <Form
                editMode={this.state.mode === Mode.edit}
                fields={fields}
                // TODO
                values={this.state[valuesVar] as any}
                validationErrors={validationErrors}
                onChange={async (fieldId: string, newValue: any) => {
                    // TODO
                    const newValues = Utils.clone(this.state[valuesVar] as any) // TODO
                    newValues[fieldId] = newValue

                    if (fieldId === 'nimi') {
                        return Utils.setState(this, {
                            [valuesVar]: newValues,
                            duplicateStatus: DuplicateStatus.notChecked,
                            nameSaved: false,
                            ignorePossibleDuplicates: false,
                        } as any) // TODO
                    } else {
                        return Utils.setState(this, { [valuesVar]: newValues } as any) // TODO
                    }
                }}
            />
        )
    }

    renderPhase3Form() {
        if (this.isPhase3Visible(this.state.phase!)) {
            return (
                <div>
                    <h3>
                        <b>3. faas</b>
                    </h3>
                    {this.renderForm(this.state.phase3fields!, 'phase3values', 'card.phase3')}
                    <h4>
                        <b>Tagasiside</b>
                    </h4>
                    {this.renderForm(this.state.feedbackFields!, 'feedbackValues', 'card.feedback')}
                </div>
            )
        } else {
            return null
        }
    }

    renderPhase4Form() {
        if (this.isPhase4Visible(this.state.phase!)) {
            return (
                <div>
                    <h3>
                        <b>4. faas</b>
                    </h3>
                    {this.renderForm(this.state.phase4fields!, 'phase4values', 'card.phase4')}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        const errorType = this.state.errorType

        if (errorType === 'centre-mismatch') {
            return this.renderCentreMismatchInfo()
        } else if (errorType === 'existing-card') {
            return this.renderExistingCardInfo()
        } else if (errorType) {
            throw new Error('Unrecognized error type: ' + errorType)
        }

        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div className="main-panel">
                {this.renderBackLink()}
                {this.renderDiary()}
                {this.renderLog()}
                {this.renderPhase4Info()}
                {this.renderButtons()}
                {this.renderCancelConfirmation()}
                {this.renderEmployee()}
                {this.renderActivityLink()}
                <h3>
                    <b>Andmed</b>
                </h3>
                {this.renderForm(this.state.generalFields!, 'generalValues', 'card.general')}
                <h3>
                    <b>2. faas</b>
                </h3>
                {this.renderForm(this.state.phase2fields!, 'phase2values', 'card.phase2')}
                {this.renderPhase3Form()}
                {this.renderPhase4Form()}
                {this.renderSaveButton(true)}
            </div>
        )
    }
}
