import { ChangeEvent, CSSProperties, FC, ReactNode } from 'react'

import { Field, MultiCheckboxValue, Option, SubField } from '../server/types'
import { Utils } from './utils'

const DEFAULT_GROUP = 'default'

// TODO: simpler interface for using outside Form

export interface MultiCheckboxProps {
    value: MultiCheckboxValue
    viewMode?: boolean
    onChange?: (value: MultiCheckboxValue) => void // Required if !viewMode
    other?: Field['other']
    groups?: Field['groups']
    options: Option[]
    columns?: number

    // Only needed if any option has details

    renderDetails?: (
        field: SubField,
        value: any, // TODO
        onChange: (id: string, value: any) => void, // TODO
    ) => ReactNode
    renderView?: (field: SubField, value: any) => ReactNode // TODO
    expandValue?: (field: SubField, value: any) => any // TODO
    isMultiline?: (field: SubField) => boolean
}

interface RenderContext {
    anyGroupsRendered: boolean
}

export const MultiCheckbox: FC<MultiCheckboxProps> = (props) => {
    const onCheckOther = (checked: boolean) => {
        const newValue = Utils.clone(props.value)
        newValue.other = checked ? '' : null
        props.onChange!(newValue)
    }

    const onChangeOtherText = (value: string) => {
        const newValue = Utils.clone(props.value)
        newValue.other = value
        props.onChange!(newValue)
    }

    const getChoiceIndex = (option: Option) => {
        const choices = props.value.choices
        return choices ? Utils.findIndexByField(choices, 'key', option.id) : -1
    }

    const getChoice = (option: Option) => {
        const index = getChoiceIndex(option)
        return index === -1 ? null : props.value.choices[index]
    }

    const shouldRenderOption = (option: Option) => {
        return !props.viewMode || getChoiceIndex(option) !== -1
    }

    const renderColumns = (renderedOptions: ReactNode[]) => {
        if (!props.columns || props.viewMode) {
            return renderedOptions
        }

        const columns: ReactNode[] = []
        const chunkSize = Math.ceil(renderedOptions.length / props.columns)

        for (let i = 0, len = renderedOptions.length; i < len; i += chunkSize) {
            columns.push(renderedOptions.slice(i, i + chunkSize))
        }

        return (
            <div>
                {columns.map((column, ix) => {
                    const style = {
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginRight: 30,
                    }
                    return (
                        <div key={ix} style={style}>
                            {column}
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderGroupTitle = (context: RenderContext, label: string): ReactNode => {
        if (props.groups) {
            const titleStyle: CSSProperties = { fontWeight: 'bold' }

            if (context.anyGroupsRendered) {
                titleStyle.marginTop = props.viewMode ? 5 : 10
            }

            return <div style={titleStyle}>{label}</div>
        } else {
            return null
        }
    }

    const renderRegularGroupTitle = (groupId: string, context: RenderContext): ReactNode => {
        const isDefaultGroup = groupId === DEFAULT_GROUP
        const group = isDefaultGroup ? null : Utils.findById(props.groups!, groupId)

        if (group) {
            return renderGroupTitle(context, group.label)
        } else {
            return null
        }
    }

    const renderDetails = (option: Option): ReactNode => {
        const index = getChoiceIndex(option)
        const checked = index !== -1

        if (checked && option.details) {
            const details = props.value.choices[index].details

            const onDetailsChange = (_id: string, newDetails: any) => {
                // TODO
                const newValue = Utils.clone(props.value)
                newValue.choices[index].details = newDetails
                props.onChange!(newValue)
            }

            return props.renderDetails!(option.details!, details, onDetailsChange)
        } else {
            return null
        }
    }

    const renderOption = (option: Option): ReactNode => {
        if (props.viewMode) {
            const choice = getChoice(option)!

            if (choice.details) {
                const details = props.renderView!(option.details!, choice.details)

                if (details === '') {
                    return <span>{option.label}</span>
                } else if (props.isMultiline!(option.details!)) {
                    return (
                        <div>
                            <div className="choice">{option.label}</div>
                            <div className="multiline-details" style={{ marginLeft: 20 }}>
                                {details}
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <span>
                            {option.label}
                            {' ('}
                            {details})
                        </span>
                    )
                }
            }

            return option.label
        } else {
            const index = Utils.findIndexByField(props.value.choices, 'key', option.id)
            const checked = index !== -1

            const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
                const newValue = Utils.clone(props.value)

                if (evt.target.checked) {
                    let details = null

                    if (option.details) {
                        details = props.expandValue!(option.details, null)
                    }

                    newValue.choices.push({ key: option.id, details })
                } else {
                    const ix = getChoiceIndex(option)
                    newValue.choices.splice(ix, 1)
                }

                props.onChange!(newValue)
            }

            return (
                <div>
                    <input type="checkbox" checked={checked} onChange={onChange} /> {option.label}
                    {renderDetails(option)}
                </div>
            )
        }
    }

    const renderGroup = (groupId: string, options: Option[], context: RenderContext) => {
        const renderedOptions = options.map(
            (option): ReactNode => (
                <div key={option.id} className="multi-checkbox-option">
                    {renderOption(option)}
                </div>
            ),
        )

        return (
            <div key={groupId}>
                {renderRegularGroupTitle(groupId, context)}
                {renderColumns(renderedOptions)}
            </div>
        )
    }

    const renderGroups = (context: RenderContext) => {
        const optionsByGroup: Record<string, Option[]> = {}
        optionsByGroup[DEFAULT_GROUP] = []

        const orderedGroupIds = [DEFAULT_GROUP]

        if (props.groups) {
            for (const group of props.groups) {
                if (group.id === DEFAULT_GROUP) {
                    throw new Error('"' + group.id + '" is not allowed as a group id')
                }

                orderedGroupIds.push(group.id)
                optionsByGroup[group.id] = []
            }
        }

        for (const option of props.options) {
            if (shouldRenderOption(option)) {
                const groupId = option.group || DEFAULT_GROUP

                if (!(groupId in optionsByGroup)) {
                    throw new Error('Invalid group id: ' + groupId)
                }

                optionsByGroup[groupId].push(option)
            }
        }

        const renderedGroups: ReactNode[] = []

        for (const groupId of orderedGroupIds) {
            const options = optionsByGroup[groupId]

            if (options.length) {
                renderedGroups.push(renderGroup(groupId, options, context))
                context.anyGroupsRendered = true
            }
        }

        return renderedGroups
    }

    const renderOtherGroup = (context: RenderContext) => {
        if (!props.other) {
            return null
        }

        const value = props.value
        const checked = value.other !== null

        if (props.viewMode && !checked) {
            return null
        }

        const otherLabel = props.other.label!
        const title = renderGroupTitle(context, otherLabel)
        let otherElement: ReactNode = otherLabel

        if (!props.viewMode) {
            otherElement = (
                <div style={{ marginTop: 5 }}>
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={(evt) => onCheckOther(evt.currentTarget.checked)}
                    />{' '}
                    {otherLabel}
                    {': '}
                    <input
                        type="text"
                        value={
                            (value.other as any) === undefined || value.other === null
                                ? ''
                                : value.other
                        }
                        disabled={!checked}
                        onChange={(evt) => onChangeOtherText(evt.currentTarget.value)}
                    />
                </div>
            )
        } else if (value.other) {
            otherElement += ' (' + value.other + ')'
        }

        return (
            <div className="other">
                {title}
                {otherElement}
            </div>
        )
    }

    const context: RenderContext = { anyGroupsRendered: false }
    const groups = renderGroups(context)
    const otherGroup = renderOtherGroup(context)
    return (
        <div>
            {groups}
            {otherGroup}
        </div>
    )
}
