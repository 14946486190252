import { xml, XmlBuilder } from '../../util/xml'
import { NS } from './constants'
import { ExcelFont, ExcelNumberFormat, ExcelStyle, StyleManager } from './types'

export const HEADER_STYLE: ExcelStyle = {
    font: ExcelFont.bold,
    alignment: { vertical: 'center' },
}

interface StyleEntry {
    index: number
    style: ExcelStyle
}

const writeStyle = (style: ExcelStyle, builder: XmlBuilder) => {
    builder.a('applyFont', 1).a('fontId', style.font || ExcelFont.regular)

    if (style.numberFormat) {
        builder.a('applyNumberFormat', 1).a('numFmtId', style.numberFormat)
    }

    builder.a('applyAlignment', 1)

    builder
        .e('alignment')
        .f((alignBuilder) => {
            let vertical = 'top'
            const { alignment } = style

            if (alignment) {
                if (alignment.horizontal) {
                    alignBuilder.a('horizontal', alignment.horizontal)
                }

                if (alignment.vertical) {
                    vertical = alignment.vertical
                }
            }

            alignBuilder.a('vertical', vertical)
        })
        .a('wrapText', 1)
        .done()
}

export const createStyleManager = (): StyleManager => {
    const stringMap = new Map<string, StyleEntry>()

    // Cache to avoid rebuilding XML string for same object
    const objectMap = new Map<ExcelStyle, StyleEntry>()

    return {
        getIndex: async (style: ExcelStyle) => {
            let entry = objectMap.get(style)

            if (entry) {
                return entry.index
            }

            const data = xml('xf')
                .f((builder) => writeStyle(style, builder))
                .done()
            const xmlString = String.fromCharCode.apply(null, new Uint8Array(data) as any)
            entry = stringMap.get(xmlString)

            if (!entry) {
                entry = { index: stringMap.size + 1, style }
                stringMap.set(xmlString, entry)
                objectMap.set(style, entry)
            }

            return entry.index
        },
        writeXml: (xmlBuilder) => {
            const xfsBuilder = xmlBuilder.e('cellXfs')
            xfsBuilder.e('xf').done() // default (index = 0)
            let expectedIndex = 1

            for (const entry of stringMap.values()) {
                if (entry.index !== expectedIndex) {
                    throw new Error('Expected index ' + expectedIndex + ' but got ' + entry.index)
                }

                xfsBuilder
                    .e('xf')
                    .f((builder) => writeStyle(entry.style, builder))
                    .done()
                expectedIndex += 1
            }

            xfsBuilder.done()
        },
    }
}

// prettier-ignore
export const getStylesheetXml = (styleManager: StyleManager) => xml('styleSheet')
    .a('xmlns', NS.main)
    .e('numFmts')
        .e('numFmt').a('numFmtId', ExcelNumberFormat.month).au('formatCode', 'mmmm yyyy').done()
        .e('numFmt')
            .a('numFmtId', ExcelNumberFormat.money)
            .au('formatCode', '#,##0.00; -#,##0.00')
        .done()
        .e('numFmt')
            .a('numFmtId', ExcelNumberFormat.moneyDash)
            .au('formatCode', '#,##0.00; -#,##0.00; "-"')
        .done()
    .done()
    .e('fonts')
        .e('font').done() // fontId 0, default
        .e('font').e('b').done().done() // fontId 1, bold
    .done()
    .e('fills')
        .e('fill').done() // fillId 0, no fill (reserved, not customizable)
        .e('fill').done() // fillId 1, gray 125 (reserved, not customizable)
    .done()
    .e('borders')
        .e('border').done() // borderId 0, none
    .done()
    .f((builder) => styleManager.writeXml(builder))
.done()
