import { Component } from 'react'

import { VisitorAddition } from '../server/commands/get-visitor-additions'
import { API } from './api'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { AppView } from './state'
import { Utils } from './utils'

export interface NewVisitorStatsProps {
    view: AppView // TODO refactor props
    dateFrom?: string
    dateTo?: string
}

interface State {
    loaded: boolean
    additions?: Record<string, VisitorAddition>
}

export class NewVisitorStats extends Component<NewVisitorStatsProps, State> {
    unmounted = false
    state: State = { loaded: false }

    async componentDidMount() {
        const { view } = this.props
        const additions = await API.getVisitorAdditions(view)
        await Utils.setState(this, { loaded: true, additions })
        await EventBus.fire('new-visitor-stats-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const additions = Utils.filterMap(this.state.additions!, (visitor) => {
            if (this.props.dateFrom && visitor.createdTime < this.props.dateFrom) {
                return false
            }

            if (this.props.dateTo && visitor.createdTime > this.props.dateTo + 'T23:59:59.999Z') {
                return false
            }

            return true
        })

        const byMonth: Record<string, number> = {}

        for (const visitor of additions) {
            const month = visitor.createdTime.substr(0, 7)
            Utils.member(byMonth, month, 0)
            byMonth[month] += 1
        }

        const months = Object.keys(byMonth)
        months.sort()

        return (
            <div className="main-panel">
                <div style={{ marginBottom: 10 }}>
                    Tabelis on näidatud külastajate arv, kelle profiil on antud kuu jooksul loodud
                    siin noortekeskuses.
                </div>
                <table className="bordered">
                    <thead>
                        <tr>
                            <th>Kuu</th>
                            <th>Uusi külastajaid</th>
                        </tr>
                    </thead>
                    <tbody>
                        {months.map((month) => {
                            const monthName = Utils.getMonthYearString(month)
                            const count = byMonth[month]

                            return (
                                <tr key={month}>
                                    <td>{monthName}</td>
                                    <td>{count}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}
