import toastr from 'toastr'

import { FailureJson, RequestLogger } from './api'
import { AppView } from './state'
import { AlreadyHandledError, getAlreadyHandledError } from './utils'

export type CustomRequestHandler = (json: unknown, statusCode: number) => boolean

interface ErrorWithDetails extends AlreadyHandledError {
    status: number
    json: unknown
}

let customHandler: CustomRequestHandler | null = null

let loginToastVisible = false
let participantModeWarningVisible = false
let noCentreToastVisible = false

export const useCustomHandlerDuring = async <T>(
    handler: CustomRequestHandler,
    func: () => Promise<T>,
) => {
    customHandler = handler

    try {
        await func()
    } finally {
        customHandler = null
    }
}

export const handleRequestFailure = (
    view: AppView,
    statusCode: number,
    json: FailureJson,
    logger: RequestLogger,
): never => {
    if (statusCode === 401 && json.errorType === 'not-logged-in') {
        if (!loginToastVisible) {
            toastr.error('Pead sisse logima', '', {
                onHidden() {
                    loginToastVisible = false
                },
            })

            loginToastVisible = true
            view.navigate(['login'])
        }
    } else if (statusCode === 401 && json.errorType === 'session-expired') {
        if (!loginToastVisible) {
            toastr.error('Sessioon on aegunud, palun logige uuesti sisse', '', {
                onHidden() {
                    loginToastVisible = false
                },
            })

            loginToastVisible = true
            view.navigate(['login'])
        }
    } else if (statusCode === 401 && json.errorType === 'participant-mode') {
        const { session } = view.state

        if (session.loggedIn) {
            if (!session.employeeMode) {
                if (!participantModeWarningVisible) {
                    toastr.warning(
                        'Sind suunati kohtumise vaatesse, kuna oled osaleja režiimis',
                        '',
                        {
                            onHidden() {
                                participantModeWarningVisible = false
                            },
                        },
                    )

                    participantModeWarningVisible = true
                    view.navigate(['meeting', session.meetingId!])
                }
            } else {
                logger.error('Got participant mode error from server, but user is in employee mode')
            }
        }
    } else if (statusCode === 409 && json.errorType === 'no-centre') {
        if (!noCentreToastVisible) {
            toastr.error('Pead valima keskuse', '', {
                onHidden() {
                    noCentreToastVisible = false
                },
            })

            noCentreToastVisible = true
            view.navigate([])
        }
    } else if (statusCode === 500 && json.errorType === 'database-connection-lost') {
        toastr.error(json.error)
    } else {
        let skipStandardHandling = false

        if (customHandler) {
            skipStandardHandling = customHandler(json, statusCode)

            if (typeof skipStandardHandling !== 'boolean') {
                throw new Error('Custom error handler must return boolean')
            }
        }

        if (!skipStandardHandling) {
            if (json.error) {
                toastr.error(json.error, 'Serveris esines viga')
            } else {
                logger.error(json)
                toastr.error('Esines viga')
            }
        }
    }

    const error = getAlreadyHandledError() as ErrorWithDetails
    error.status = statusCode
    error.json = json
    throw error
}
