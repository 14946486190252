import { Component, FC, ReactNode } from 'react'
import toastr from 'toastr'

import { EventBus } from './event-bus'
import { AppView } from './state'
import { Utils } from './utils'

export interface ValidationError {
    type: string
    reason: string
    [additional: string]: any
} // TODO
interface ValidationFieldObject {
    childOf?: string
    orSelf: boolean
}

type ValidationField = string | ValidationFieldObject

export type CustomErrorMessage =
    | string
    | ((error: ValidationError, errorField: string) => string | null)

interface WithValidationErrors {
    validationErrors?: Record<string, ValidationError>
}

const errorMessages: Record<string, string> = {
    required: 'Kohustuslik väli',
    'non-empty': 'Kohustuslik väli',
    invalid: 'Ebakorrektne',
    integer: 'Peab olema täisarv',
    decimal: 'Peab olema arv',
    'time-hhmm': 'Peab olema TT:MM formaadis kellaaeg',
    'date-ymd': 'Peab olema AAAA-KK-PP formaadis kuupäev',
    'under-min': 'Liiga vähe',
    'over-max': 'Liiga palju',
    unique: 'Peab olema unikaalne',
}

const getValidationErrors = (error: any): Record<string, ValidationError> | null => {
    // TODO
    if ('json' in error) {
        if (error.status === 409 && error.json.errorType === 'validation') {
            return error.json.validationErrors
        }
    }

    return null
}

export const clearValidationErrors = async <S extends WithValidationErrors>(
    view: AppView,
    // TODO: remove the component parameter
    component?: Component<unknown, S> & { unmounted: boolean },
) => {
    const { state } = view
    toastr.clear()

    if (component) {
        return Utils.setState(component, { validationErrors: {} })
    } else {
        state.validationErrors = {}
        view.update()
    }
}

// Call only if component is still mounted
export const checkValidationErrors = async <S extends WithValidationErrors>(
    view: AppView,
    // TODO: remove the component parameter (null is used as a temporary hack)
    component: (Component<unknown, S> & { unmounted: boolean }) | null,
    error: Error,
) => {
    const { state } = view
    const errors = getValidationErrors(error)

    if (errors) {
        if (component) {
            await Utils.setState(component, { validationErrors: errors })
            await EventBus.fire('validation-errors-rendered')
        } else {
            state.validationErrors = errors
            view.update()
        }
    }

    // Promise chains usually have steps after this function that should be skipped
    // if there were validation errors, so we need to re-throw it.
    throw error
}

export interface ValErrProps {
    fieldName: string | null
    message: string
}

export const ValErr: FC<ValErrProps> = ({ fieldName, message }) => (
    <div
        className="validation-error"
        data-fieldname={fieldName}
        style={{ color: 'red', fontSize: '80%' }}
    >
        {message}
    </div>
)

export const getValErrProps = (
    source: Record<string, ValidationError> | undefined,
    validationField: ValidationField,
    customErrorMessages?: Record<string, CustomErrorMessage>,
): ValErrProps | undefined => {
    if (!source) {
        return undefined
    }

    let error: ValidationError | null = null
    let errorField: string | null = null

    if (typeof validationField === 'string') {
        error = source[validationField]
        errorField = validationField
    } else if (typeof validationField === 'object') {
        // TODO
        for (const fieldName of Object.keys(source)) {
            const validationError = source[fieldName]

            if (validationField.childOf) {
                if (
                    Utils.startsWith(fieldName, validationField.childOf + '.') ||
                    (validationField.orSelf && fieldName === validationField.childOf)
                ) {
                    error = validationError
                    errorField = fieldName
                }
            }
        }
    }

    if (error) {
        let message: string | null = null

        if (customErrorMessages && error.type in customErrorMessages) {
            const messageOrFunc = customErrorMessages[error.type]

            if (typeof messageOrFunc === 'function') {
                // Function can return null to fall back to standard message
                message = messageOrFunc(error, errorField!)
            } else {
                message = messageOrFunc
            }
        }

        if (message === null) {
            if (error.reason) {
                message = error.reason
            } else if (error.type in errorMessages) {
                message = errorMessages[error.type]
            } else {
                message = 'Ebakorrektne'
            }
        }

        return { fieldName: errorField, message }
    }

    return undefined
}

export const renderValidationError = (
    source: Record<string, ValidationError> | undefined,
    validationField: ValidationField,
    customErrorMessages?: Record<string, CustomErrorMessage>,
): ReactNode => {
    const props = getValErrProps(source, validationField, customErrorMessages)
    return props ? <ValErr {...props} /> : null
}
