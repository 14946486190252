import { ReactNode } from 'react'

import { Field, FieldType, MultiCheckboxValue, SingleChoiceValue, SubField } from '../server/types'
import { CommonUtils } from './common-utils'
import { CustomErrorMessage } from './validation'

// TODO add type arg?
export interface FieldTypeImpl {
    expandValue?: (value: any, field: SubField) => any // TODO
    finalize?: (value: any, field: SubField) => any
    isMultiline?: (field: SubField) => boolean
    hint?: string
    renderEditor?: (
        field: SubField | Field,
        value: any,
        onChange: (id: string, value: any) => void,
    ) => ReactNode
    renderView?: (field: SubField | Field, value: any) => ReactNode
    getCustomErrorMessages?: (field: Field) => Record<string, CustomErrorMessage>
}

export const FormUtils = {
    findOption: (field: SubField, optionId: string, noError?: true) =>
        CommonUtils.findById(field.options!, optionId, noError),

    expandValue(fieldTypes: Record<FieldType, FieldTypeImpl>, field: SubField, value: any) {
        // TODO
        const fieldType = fieldTypes[field.type!]
        return fieldType.expandValue ? fieldType.expandValue(value, field) : value
    },

    expandValues(
        fieldTypes: Record<FieldType, FieldTypeImpl>,
        fields: Field[],
        latestValues: Record<string, any> | undefined, // TODO
    ) {
        if (!latestValues) {
            return
        }

        for (const field of fields) {
            if (field.readOnly) {
                continue
            }

            const value = field.id in latestValues ? latestValues[field.id] : null
            latestValues[field.id] = FormUtils.expandValue(fieldTypes, field, value)
        }
    },

    // TODO rename to finalizeValue?
    finalize(fieldTypes: Record<FieldType, FieldTypeImpl>, field: SubField, value: any) {
        // TODO
        const fieldType = fieldTypes[field.type!]
        return fieldType.finalize ? fieldType.finalize(value, field) : value
    },

    finalizeValues(
        fieldTypes: Record<FieldType, FieldTypeImpl>,
        fields: Field[],
        values: Record<string, any>, // TODO
    ): Record<string, any> {
        // TODO
        const finalizedValues: Record<string, any> = {}

        for (const field of fields) {
            if (!field.readOnly && field.id in values) {
                let value = values[field.id]
                const fieldType = fieldTypes[field.type!]

                if (fieldType.finalize) {
                    value = fieldType.finalize(value, field)
                }

                if (value !== null) {
                    finalizedValues[field.id] = value
                }
            }
        }

        return finalizedValues
    },

    getFieldTypes() {
        const fieldTypes: Record<FieldType, FieldTypeImpl> = {
            text: {
                expandValue(value) {
                    return value || ''
                },
                finalize(value) {
                    const trimmedValue = value.trim()
                    return trimmedValue === '' ? null : trimmedValue
                },
                isMultiline(field) {
                    return Boolean(field.multiline)
                },
            },
            conditional: {
                expandValue(value, field) {
                    const expValue = value ? CommonUtils.clone(value) : { bool: false, value: null }

                    if (!expValue.bool) {
                        expValue.value = FormUtils.expandValue(
                            fieldTypes,
                            field.valueField!,
                            expValue.value,
                        )
                    }

                    return expValue
                },
                finalize(value, field) {
                    if (value.bool) {
                        return { bool: true, value: null }
                    } else {
                        const finValue = FormUtils.finalize(
                            fieldTypes,
                            field.valueField!,
                            value.value,
                        )
                        return finValue === null ? null : { bool: false, value: finValue }
                    }
                },
            },
            number: {
                expandValue(value, field) {
                    if (field.saveAsString) {
                        return typeof value === 'string' ? value : ''
                    } else {
                        return typeof value === 'number' ? value.toString() : ''
                    }
                },
                finalize(value, field) {
                    if (typeof value !== 'string' || value === '') {
                        return null
                    }

                    return field && field.saveAsString ? value : Number(value)
                },
            },
            'single-choice': {
                expandValue(value: SingleChoiceValue<unknown, unknown>, field) {
                    const expValue = {
                        // TODO avoid 'any'
                        key: value && (typeof value as any) === 'object' ? value.key : value,
                        other: value && (typeof value as any) === 'object' ? value.other : null,
                        details: value && (typeof value as any) === 'object' ? value.details : null,
                    }

                    if (expValue.key === undefined) {
                        expValue.key = null
                    }

                    if ((expValue.other as any) === undefined) {
                        // TODO
                        expValue.other = null
                    }

                    if (expValue.details === undefined) {
                        expValue.details = null
                    }

                    if (expValue.details) {
                        if (typeof value.key !== 'string') {
                            throw new Error('Expected string key')
                        }

                        const option = FormUtils.findOption(field, value.key)!
                        expValue.details = FormUtils.expandValue(
                            fieldTypes,
                            option.details!,
                            expValue.details,
                        )
                    }

                    return expValue
                },
                finalize(value: SingleChoiceValue<unknown, unknown>, field) {
                    if (value.key === null && !value.other && !value.details) {
                        return null
                    }

                    const finValue = CommonUtils.clone(value)

                    if (finValue.other) {
                        finValue.other = finValue.other.trim()
                    }

                    if (finValue.details) {
                        if (typeof finValue.key !== 'string') {
                            throw new Error('Expected string key')
                        }

                        const option = FormUtils.findOption(field, finValue.key)!
                        finValue.details = FormUtils.finalize(
                            fieldTypes,
                            option.details!,
                            finValue.details,
                        )
                    }

                    return finValue
                },
            },
            'multiple-choice': {
                expandValue(value) {
                    if (Array.isArray(value)) {
                        return value
                    } else if (value) {
                        throw new Error(
                            'Expected an array or a falsy value, but got a ' +
                                typeof value +
                                ': ' +
                                value,
                        )
                    } else {
                        return []
                    }
                },
                hint: 'Võite valida mitu',
                finalize(value) {
                    // Convert empty array to null
                    return value && value.length === 0 ? null : value
                },
                isMultiline() {
                    return true
                },
            },
            'multiple-choice-checkbox': {
                expandValue(value: MultiCheckboxValue, field) {
                    if (!value) {
                        return { choices: [], other: null }
                    }

                    let expValue = CommonUtils.clone(value)

                    if (Array.isArray(expValue)) {
                        expValue = { choices: expValue, other: null }
                    }

                    if (!(('choices' in expValue) as any)) {
                        // TODO
                        expValue.choices = []
                    }

                    expValue.choices.forEach((choiceParam, index) => {
                        let choice = choiceParam

                        if ((typeof choice as any) === 'string') {
                            // TODO
                            choice = { key: choice as any }
                            expValue.choices[index] = choice
                        }

                        const option = FormUtils.findOption(field, choice.key)!

                        if ('details' in option) {
                            choice.details = FormUtils.expandValue(
                                fieldTypes,
                                option.details!,
                                choice.details,
                            )
                        }
                    })

                    if (!(('other' in expValue) as any)) {
                        // TODO
                        expValue.other = null
                    }

                    return expValue
                },
                hint: 'Võite valida mitu',
                finalize(value, field) {
                    const finValue = CommonUtils.clone(value)

                    if (finValue.other !== null) {
                        finValue.other = finValue.other.trim()
                    }

                    if (!finValue.choices.length && finValue.other === null) {
                        return null
                    }

                    for (const choice of finValue.choices) {
                        if (choice.details) {
                            const option = FormUtils.findOption(field, choice.key)!
                            choice.details = FormUtils.finalize(
                                fieldTypes,
                                option.details!,
                                choice.details,
                            )
                        }
                    }

                    return finValue
                },
                isMultiline() {
                    return true
                },
            },
            map: {
                expandValue(value, field) {
                    if (!value) {
                        return {}
                    }

                    // Hack for PHP backend that turns empty objects to arrays
                    if (Array.isArray(value) && !value.length) {
                        return {}
                    }

                    const expValue = CommonUtils.clone(value)

                    for (const key of Object.keys(expValue)) {
                        const subField: SubField = { type: field.valueType }
                        expValue[key] = FormUtils.expandValue(fieldTypes, subField, expValue[key])
                    }

                    return expValue
                },
                finalize(value, field) {
                    if (value && Object.keys(value).length) {
                        const subType = fieldTypes[field.valueType!]
                        const finalizedValue: Record<string, unknown> = {}

                        for (const key of Object.keys(value)) {
                            let subValue = value[key]

                            if (subType.finalize && subValue !== null) {
                                subValue = subType.finalize(subValue, null as any) // TODO
                            }

                            if (subValue !== null || !field.selectByValue) {
                                finalizedValue[key] = subValue
                            }
                        }

                        return finalizedValue
                    } else {
                        return null
                    }
                },
            },
            date: {},
            'birth-date': {
                expandValue(value) {
                    const date = value || { year: 0, month: 0, day: 0 }
                    return { date, originalDate: CommonUtils.clone(date) }
                },
                finalize(value) {
                    const date = value.date
                    return date.year === 0 && date.month === 0 && date.day === 0 ? null : date
                },
            },
        }

        return fieldTypes
    },
}
