import { ChangeEvent } from 'react'
import toastr from 'toastr'

import { Event, ExtendedEventCategory } from '../../server/types'
import { isDev } from '../access'
import { API } from '../api'
import { EditEventProps } from '../edit-event'
import { EventCategorySearchContainerProps } from '../event-category/search-container'
import { getInitialEventFormState } from '../initial-state'
import { SearchPanelExistingItemProps } from '../search-panel/existing-item'
import { SelectEventPanelProps } from '../select-event-panel'
import { Session } from '../session'
import { AppView } from '../state'
import { Utils } from '../utils'
import { checkValidationErrors, clearValidationErrors } from '../validation'
import { getActivityConfigProps } from './activity-config'
import { getEventFieldsProps } from './event-form'
import { initIfNeeded } from './init-if-needed'
import { reloadEvents } from './reload-events'
import { getAddEventButtonProps } from './search-panel-new-item'

type EventHierarchy = Record<string, ExtendedEventCategory>

export const getSelectEventPanelProps = (view: AppView) => {
    const { state, update } = view

    const onSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
        state.eventList.search = evt.target.value
        update()
    }

    const props: SelectEventPanelProps = {
        searchBox: {
            onChange: async (evt) => onSearchChange(evt),
            search: state.eventList.search,
        },
    }

    if (state.event.visibleModalId === 'new-event') {
        props.editEvent = getAddEventProps(view)
    }

    const { search, selectedEventCategoryId, showArchived } = state.eventList

    const sortEvents = (events: Event[]) => {
        Utils.sortAsStrings(events, ({ archived, name }) => {
            // Leave archived events at the end
            const prefix = archived ? '1' : '0'
            return prefix + name.toLowerCase()
        })
    }

    const getEventCategory = (id: string) => {
        const eventCategory = state.eventList.eventCategories.find(({ _id }) => _id === id)

        if (!eventCategory) {
            throw new Error('Event category with id ' + id + ' not found.')
        }

        return eventCategory
    }

    const selectEventCategory = (eventCategoryId: string) => {
        state.eventList.search = ''
        state.eventList.selectedEventCategoryId = eventCategoryId
        update()
    }

    const filterEventsAndCategories = () => {
        const { search, showArchived } = state.eventList
        const searchLower = search.toLowerCase()

        const hierarchy: EventHierarchy = {}
        const uncategorizedEvts: Event[] = []

        Utils.iterMap(state.eventList.events, (evt) => {
            if (evt.archived && !showArchived) {
                return
            }

            if (evt.name.toLowerCase().indexOf(searchLower) === -1) {
                return
            }

            if (!evt.category) {
                uncategorizedEvts.push(evt)
                return
            }

            if (!hierarchy[evt.category]) {
                const cat = getEventCategory(evt.category)
                hierarchy[evt.category] = { ...cat, events: [] }
            }

            const evtClone = Utils.clone(evt)
            hierarchy[evt.category].events.push(evtClone)
        })

        state.eventList.eventCategories.forEach((evtCat) => {
            if (hierarchy[evtCat._id]) {
                return
            }

            if (evtCat.name.toLowerCase().indexOf(searchLower) !== -1) {
                const regularCat = getEventCategory(evtCat._id)
                hierarchy[evtCat._id] = { ...regularCat, events: [] }
            }
        })

        sortEvents(uncategorizedEvts)

        return {
            categories: sortEventHierarchy(hierarchy),
            uncategorized: uncategorizedEvts,
        }
    }

    const sortEventHierarchy = (hierarchy: EventHierarchy) => {
        const arrCats: ExtendedEventCategory[] = Utils.mapValues(hierarchy)
        Utils.sortAsStrings(arrCats, ({ name }) => name!)
        arrCats.forEach(({ events }) => sortEvents(events))
        return arrCats
    }

    if (selectedEventCategoryId) {
        const getSelectedEventCategory = () => {
            const { selectedEventCategoryId } = state.eventList

            if (!selectedEventCategoryId) {
                throw new Error('No event category selected.')
            }

            return getEventCategory(selectedEventCategoryId)
        }

        const { selectedEventCategoryId, showArchived, search } = state.eventList
        const eventCategory = getSelectedEventCategory()

        const events = Utils.filterMap(state.eventList.events, ({ archived, category }) => {
            if (!showArchived && archived) {
                return false
            }

            return category === selectedEventCategoryId
        })

        sortEvents(events)

        props.eventCategoryContainer = {
            view,
            eventCategory,
            events,
            search,
            onBack: () => {
                state.eventList.selectedEventCategoryId = undefined
                update()
            },
            afterSave: () => reloadEvents(view),
        }
    } else if (!search) {
        const { eventCategories } = state.eventList

        if (eventCategories.length) {
            const sortedCats = Utils.clone(eventCategories)
            Utils.sortAsStrings(sortedCats, ({ name }) => name)

            props.categories = sortedCats.map(
                ({ _id, name }): SearchPanelExistingItemProps => ({
                    reactKey: 'cat_' + _id,
                    primaryName: name,
                    onClick: () => selectEventCategory(_id),
                    className: 'event-category',
                }),
            )
        }

        const events = Utils.filterMap(state.eventList.events, ({ archived, category }) => {
            if (archived && !showArchived) {
                return false
            }

            // When looking at the top level and not searching show only uncategorized events
            if (category) {
                return false
            }

            return true
        })

        sortEvents(events)

        props.uncategorized = events.map(
            (evt): SearchPanelExistingItemProps => ({
                reactKey: 'evt_' + evt._id,
                primaryName: evt.name,
                desaturated: evt.archived,
                onClick: () => view.navigate(['events', evt._id]),
                search: state.eventList.search,
            }),
        )

        props.newEvent = getAddEventButtonProps(view, search)
    } else {
        const { categories, uncategorized } = filterEventsAndCategories()

        props.categorySearchContainers = categories.map(
            (cat): EventCategorySearchContainerProps => ({
                view,
                reactKey: cat._id,
                eventCategory: cat,
                search,
                onClick: () => selectEventCategory(cat._id!),
            }),
        )

        const isEventCategoryNameUnique = () => {
            const { search, eventCategories } = state.eventList
            return eventCategories.every(({ name }) => name !== search)
        }

        const addEventCategory = async () => {
            const { id } = await API.addEventCategory(view, state.eventList.search)
            await reloadEvents(view)
            toastr.success('Kategooria lisatud')
            state.eventList.search = ''
            state.eventList.selectedEventCategoryId = id
            update()
        }

        if (isEventCategoryNameUnique()) {
            props.newCategory = {
                id: 'add-new-event-category',
                label: 'Lisa uus sündmuste kategooria',
                search: state.eventList.search,
                onClick: async () => addEventCategory(),
            }
        }

        // TODO dedup with above
        props.uncategorized = uncategorized.map(
            (evt): SearchPanelExistingItemProps => ({
                reactKey: 'evt_' + evt._id,
                primaryName: evt.name,
                desaturated: evt.archived,
                onClick: () => view.navigate(['events', evt._id]),
                search: state.eventList.search,
            }),
        )

        props.newEvent = getAddEventButtonProps(view, search)
    }

    const anyArchived = Utils.mapSome(state.eventList.events, (evt) => Boolean(evt.archived))

    if (anyArchived) {
        props.showArchived = {
            checked: state.eventList.showArchived,
            onChange: async (checked) => {
                state.eventList.showArchived = checked
                update()
            },
        }
    }

    return props
}

const getAddEventProps = (view: AppView): EditEventProps => {
    const { state, update } = view

    initIfNeeded(view, 'eventForm:new', async ({ setCleanup }) => {
        setCleanup(() => {
            state.event.visibleModalId = undefined
            state.eventForm = getInitialEventFormState()
        })

        await loadFormData(view)
    })

    const close = () => {
        state.event.visibleModalId = undefined
        update()
    }

    const save = async () => {
        try {
            state.event.isSaving = true
            update()
            clearValidationErrors(view)

            const response = await API.addEvent(view, {
                name: state.eventForm.name.trim(),
                type: state.eventForm.type,
                employees: state.eventForm.employees,
                description: state.eventForm.description.trim(),
                activityConf: state.eventForm.activityConf,
                category: state.eventForm.category,
                timesNeeded: state.eventForm.timesNeeded,
            })

            await reloadEvents(view)
            state.eventList.search = ''
            update()
            view.navigate(['events', response.id])
            close()
        } catch (error) {
            await checkValidationErrors(view, null, error)
        } finally {
            state.event.isSaving = false
            update()
        }
    }

    const props: EditEventProps = {
        outer: {
            title: 'Sündmus',
            dialogClassName: 'edit-event',
            closeModal: async () => close(),
            buttons: [
                {
                    isLoading: state.event.isSaving,
                    text: 'Salvesta',
                    onClick: save,
                    className: state.event.isSaving ? 'disabled' : undefined,
                    style: { marginRight: 5 },
                },
                {
                    text: 'Tühista',
                    onClick: close,
                },
            ],
        },
    }

    if (state.eventForm.loaded) {
        props.form = {
            fields: getEventFieldsProps(view, false),
            activityConfig: getActivityConfigProps(view, {
                inUse: false,
                usedOptions: state.eventForm.usedOptions,
                activityConf: state.eventForm.activityConf,
                afterConfChange: update,
                showMeetingOption: true,
                validationErrors: state.validationErrors,
                validationPrefix: 'event',
                choiceText: state.eventForm.activityChoiceText,
                onChoiceTextChange: (value) => {
                    state.eventForm.activityChoiceText = value
                    update()
                },
            }),
        }
    }

    return props
}

const loadFormData = async (view: AppView) => {
    const { state, update } = view
    const employees: string[] = []

    const currentEmployee = Session.getEmployee(view)

    if (!isDev(currentEmployee)) {
        employees.push(currentEmployee._id)
    }

    state.eventForm = {
        loaded: true,
        name: state.eventList.search,
        type: 'open',
        employees,
        description: '',
        timesNeeded: false,
        activityConf: { mode: 'none' },
        activityChoiceText: '',
        category: state.eventList.selectedEventCategoryId || state.event.initialCategoryId,
    }

    update()
}
