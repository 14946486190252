import { Component } from 'react'

import { Area } from '../server/areas'
import { EventStats_Meeting, EventStats_Visitor } from '../server/commands/get-event-stats-data'
import { Country } from '../server/countries'
import { SchoolWithId } from '../server/schools'
import { AnonymousGroup, Meeting, Visitor, VisitorLang } from '../server/types'
import { assert } from '../util/assert'
import { centreFormFields } from '../util/centre-form-fields'
import { keys } from '../util/keys'
import { Button } from './button-group'
import { DistType } from './dist-type'
import { Enums } from './enums'
import { EventBus } from './event-bus'
import {
    CustomColumns,
    CustomOption,
    DataCollector,
    FullKey,
    renderStatsTable,
    RowCounts,
    RowData,
    StatsData,
    ValueFunc,
} from './stats-table'
import { getPercentageOption, wrapCustomOption } from './stats-utils'
import { Utils } from './utils'

interface Props {
    eventCount: number
    singleEventName?: string
    meetings: EventStats_Meeting[]
    visitors: Record<string, EventStats_Visitor>
    countries: Record<string, Country>
    areas: Record<string, Area>
    schools: Record<string, SchoolWithId>
    endDate: string
}

interface State {
    showAverage: boolean
    includeAnonymous: boolean
    program: Meeting['program'] | '_'
    showPercentages: boolean
}

interface Data {
    visitor?: EventStats_Visitor
    meeting: { startDate: string } // TODO replace with meetingStartDate: string
}

interface EventStatsDistTypes {
    country: DistType<Data>
    area: DistType<Data>
    settlement: DistType<Data>
    age: DistType<Data>
    gender: DistType<Data>
    language: DistType<Data, Visitor['lang']>
    school: DistType<Data>
    working: DistType<Data>
    month: DistType<Data>
    day: DistType<Data>
}

const getDistTypes = (
    schools: Record<string, SchoolWithId>,
    countries: Record<string, Country>,
    areas: Record<string, Area>,
    showAverage: boolean,
    includeAnonymous: boolean,
) => {
    const ageOptions = includeAnonymous ? Enums.ageOptionsAnon : Enums.ageOptions

    const distTypes: EventStatsDistTypes = {
        country: {
            name: 'Elukoha riik',
            getKey(data) {
                return data.visitor!.country || null
            },
            getKeyName(key) {
                if (!countries[key]) {
                    return 'Teadmata'
                }

                return countries[key].name
            },
            excelWidth: 30,
        },
        area: {
            name: 'Kohalik omavalitsus',
            getKey(data) {
                return data.visitor!.area || null
            },
            getKeyName(key) {
                if (!areas[key]) {
                    return 'Teadmata'
                }

                return areas[key].name
            },
            excelWidth: 30,
        },
        settlement: {
            name: 'Asula või küla',
            getKey(data) {
                return data.visitor!.settlement || null
            },
            excelWidth: 30,
        },
        age: {
            name: 'Vanus',
            getKey(data) {
                const refDate = new Date(data.meeting.startDate)
                const ageObj = Utils.getAgeObj(data.visitor!.birthDate, refDate)
                return Utils.getAgeKey(ageObj, includeAnonymous)
            },
            excelWidth: 11,
            validAnonKeys: ageOptions._order,
            orderedKeys: ageOptions._order,
        },
        gender: {
            name: 'Sugu',
            getKey(data) {
                return data.visitor!.gender.toLowerCase()
            },
            getKeyName(key) {
                return key.toUpperCase()
            },
            excelWidth: 11,
            validAnonKeys: Enums.genderOptions._order,
            orderedKeys: Enums.genderOptions._order,
        },
        language: {
            name: 'Keel',
            getKey(data) {
                return data.visitor!.lang || null
            },
            getKeyName(key) {
                if (key === 'other') {
                    return 'Muu'
                }

                return Utils.upperCaseFirst(Enums.visitorLanguages[key as VisitorLang])
            },
            getSortKey(key) {
                const langName = distTypes['language'].getKeyName!(key)
                return (key === 'other' ? '1' : '0') + '-' + langName
            },
            excelWidth: 13,
        },
        school: {
            name: 'Kool',
            getKey(data) {
                if (data.visitor!.schoolType === 'no') {
                    return 'n' // not studying
                } else if (data.visitor!.schoolType === 'yes') {
                    return 's-' + data.visitor!.school
                } else {
                    return null // unknown
                }
            },
            getKeyName(key) {
                if (key === 'n') {
                    return 'Ei õpi'
                } else {
                    const schoolId = key.substr(2) // remove 's-'
                    const school = schools[schoolId]
                    return school.name
                }
            },
            getSortKey(key) {
                const schoolName = distTypes['school'].getKeyName!(key)
                return (key === 'n' ? '1' : '0') + '-' + schoolName
            },
            excelWidth: 50,
        },
        working: {
            name: 'Töötamine',
            getKey(data) {
                return data.visitor!.working || null
            },
            getKeyName(key) {
                return key === 'yes' ? 'Töötab' : 'Ei tööta'
            },
            getSortKey(key) {
                return key === 'yes' ? 0 : 1
            },
            excelWidth: 11,
        },
        month: {
            name: 'Kuu',
            getKey(data) {
                return data.meeting.startDate.substr(0, 7)
            },
            getKeyName(key) {
                return Utils.getMonthYearString(key)
            },
            getKeyColumnStyle() {
                return { textAlign: 'right' }
            },
            excelWidth: 18,
            onlyNeedsMeeting: true,
            hideUnknown: true,

            // The generic way the totals are currently calculated does not work well
            // with the way the averages are calculated. Could be changed, but so far hasn't.
            hideTotal: showAverage,
        },
        day: {
            name: 'Päev',
            getKey(data) {
                return data.meeting.startDate
            },
            getKeyName(key) {
                return Utils.formatDateFromString(key)
            },
            getKeyColumnStyle() {
                return { textAlign: 'right' }
            },
            excelWidth: 18,
            onlyNeedsMeeting: true,
            hideUnknown: true,

            // The generic way the totals are currently calculated does not work well
            // with the way the averages are calculated. Could be changed, but so far hasn't.
            hideTotal: showAverage,
        },
    }

    return distTypes
}

export class EventStatsTable extends Component<Props, State> {
    state: State = {
        showAverage: false,
        includeAnonymous: true,
        program: '_',
        showPercentages: false,
    }

    async componentDidMount() {
        await EventBus.fire('event-stats-table-rendered')
    }

    getCustomOptions(): CustomOption<any>[] {
        // These options modify the state of this component through
        // the 'optionStateHolder' property set below

        const programButtons: Button<State['program']>[] = [{ value: '_', label: 'Ära filtreeri' }]

        const programField = Utils.findById(centreFormFields, 'seotud-programmid')!

        // TODO: hide filter if no programs selected or no centre form submitted?
        for (const key of programField.keys!) {
            if (!key.deprecated) {
                programButtons.push({ value: key.id as any, label: key.label! }) // TODO
            }
        }

        return [
            wrapCustomOption<boolean>({
                id: 'showAverage',
                label: 'Külastuste arv',
                buttons: [
                    { value: false, label: 'Koguarv' },
                    { value: true, label: 'Kohtumise keskmine' },
                ],
                onClick: (value) => {
                    if (value) {
                        this.setState({ showAverage: value, showPercentages: false })
                    } else {
                        this.setState({ showAverage: value })
                    }
                },
                active: this.state.showAverage,
            }),
            wrapCustomOption<boolean>({
                id: 'includeAnonymous',
                label: 'Anonüümsed osalejad',
                buttons: [
                    { value: true, label: 'Arvesta sisse' },
                    { value: false, label: 'Jäta välja' },
                ],
                active: this.state.includeAnonymous,
                onClick: (value) => this.setState({ includeAnonymous: value }),
                renderAdditional: (colDistTypeId, rowDistTypeId) => {
                    const hasAgeDist = colDistTypeId === 'age' || rowDistTypeId === 'age'
                    return this.renderAnonNote(hasAgeDist)
                },
            }),
            wrapCustomOption<State['program']>({
                id: 'program',
                label: 'Programm',
                buttons: programButtons,
                active: this.state.program,
                onClick: (value) => this.setState({ program: value }),
            }),
            getPercentageOption(
                this.state.showPercentages,
                (value) => this.setState({ showPercentages: value }),
                this.state.showAverage,
            ),
        ]
    }

    anonToKeys(
        anonGroup: AnonymousGroup,
        singleKey: string | null,
        singleName: 'row' | 'col',
        multiName: 'row' | 'col',
        distType: DistType<Data>,
    ) {
        const fullKeys: FullKey[] = []
        let unknown = anonGroup.total || 0

        for (const multiKey of keys(anonGroup)) {
            const isValidKey = distType.validAnonKeys!.indexOf(multiKey) !== -1

            if (isValidKey) {
                const amount = anonGroup[multiKey]
                assert(typeof amount === 'number')
                unknown -= amount

                const fullKey: FullKey = { amount, row: null, col: null }
                fullKey[singleName] = singleKey
                fullKey[multiName] = multiKey
                fullKeys.push(fullKey)
            }
        }

        const fullKey: FullKey = { amount: unknown, row: null, col: null }
        fullKey[singleName] = singleKey
        fullKey[multiName] = null
        fullKeys.push(fullKey)

        return fullKeys
    }

    initData(dataCollector: DataCollector<Data>) {
        dataCollector.initUniqueGlobal('visitors')
    }

    collectData(
        dataCollector: DataCollector<Data>,
        colDistType: DistType<Data>,
        rowDistType: DistType<Data>,
    ) {
        if (this.state.includeAnonymous && colDistType.validAnonKeys && rowDistType.validAnonKeys) {
            dataCollector.setInvalid(
                'Valitud jaotuste puhul peate anonüümsed külastajad välja jätma.',
            )
            return
        }

        if (this.state.showAverage && !rowDistType.onlyNeedsMeeting) {
            dataCollector.setInvalid(
                'Valitud ridade jaotuse puhul peate külastuste arvuks valima koguarvu.',
            )

            return
        }

        const filteredMeetings = this.props.meetings.filter((meeting) => {
            return this.state.program === '_' || meeting.program === this.state.program
        })

        for (const meeting of filteredMeetings) {
            const simpleData: Data = { meeting }

            if (rowDistType.onlyNeedsMeeting) {
                const rowKey = rowDistType.getKey(simpleData)!
                dataCollector.incCustom(rowKey, 'meetingCount')
            }

            for (const visitorId of meeting.visitorIds || []) {
                const visitor = this.props.visitors[visitorId]
                const data: Data = { visitor, meeting }
                dataCollector.uniqueCustom(data, 'uniqueVisitors', visitorId)
                dataCollector.process(data)
                dataCollector.uniqueGlobal('visitors', visitorId)
            }

            if (this.state.includeAnonymous) {
                for (const anonGroup of meeting.anonymous || []) {
                    let fullKeys: FullKey[]

                    const defRowKey = rowDistType.onlyNeedsMeeting
                        ? rowDistType.getKey(simpleData)
                        : null
                    const defColKey = colDistType.onlyNeedsMeeting
                        ? colDistType.getKey(simpleData)
                        : null

                    if (colDistType.validAnonKeys) {
                        // We can assume that rowDistType does not have validAnonKeys
                        fullKeys = this.anonToKeys(anonGroup, defRowKey, 'row', 'col', colDistType)
                    } else if (rowDistType.validAnonKeys) {
                        // We can assume that colDistType does not have validAnonKeys
                        fullKeys = this.anonToKeys(anonGroup, defColKey, 'col', 'row', rowDistType)
                    } else {
                        // Neither colDistType nor rowdistType have validAnonKeys
                        fullKeys = [{ row: defRowKey, col: defColKey, amount: anonGroup.total }]
                    }

                    for (const fullKey of fullKeys) {
                        dataCollector.addByKey(fullKey.row, fullKey.col, fullKey.amount || 0)
                    }
                }
            }
        }

        dataCollector.setGlobal('meetingCount', filteredMeetings.length)
    }

    addCustomRowData(rowData: RowData, rawRowData: RowCounts) {
        const asNumber = (value: number | Record<string, true>): number => {
            if (typeof value === 'number') {
                return value
            } else {
                throw new Error('Expected number')
            }
        }

        if (rawRowData) {
            rowData.meetingCount = asNumber(rawRowData.custom.meetingCount || 0)
            rowData.uniqueVisitorCount = Object.keys(rawRowData.custom.uniqueVisitors || {}).length
        } else {
            rowData.meetingCount = 0
            rowData.uniqueVisitorCount = 0
        }
    }

    getValueFunc(rawRowData: RowCounts): ValueFunc | null {
        if (this.state.showAverage) {
            const { meetingCount } = rawRowData.custom

            if (typeof meetingCount === 'number') {
                return (value) => value / meetingCount
            } else {
                // TODO getUnknownRowData may call this even if the 'unknown' row is currently disabled
                return () => 0
            }
        } else {
            return null
        }
    }

    getCustomColumns() {
        const columns: CustomColumns<Data> = {
            before: [
                {
                    id: 'meetingCount',
                    header: 'Kohtumisi',
                    style: { textAlign: 'right' },
                    excelWidth: 10,
                    showIf(rowDistType) {
                        return Boolean(rowDistType.onlyNeedsMeeting)
                    },
                    getValue(rowData) {
                        return rowData.meetingCount!
                    },
                    getTableValue(rowData) {
                        return Utils.formatDecimal(rowData.meetingCount!)
                    },
                    getTotalsRowContents(data) {
                        return data.global.meetingCount
                    },
                },
            ],
            after: [],
        }

        if (!this.state.showAverage) {
            columns.after.push({
                id: 'uniqueVisitors',
                header: 'Unikaalseid',
                style: { textAlign: 'right' },
                excelWidth: 12,
                getValue(rowData) {
                    return rowData.uniqueVisitorCount!
                },
                getTableValue(rowData) {
                    return Utils.formatDecimal(rowData.uniqueVisitorCount!)
                },
                getTotalsRowContents(data) {
                    let uniqueVisitorsSum = 0

                    Utils.iterMap(data.byRow, (row) => {
                        if (row && row.custom && row.custom.uniqueVisitors) {
                            uniqueVisitorsSum += Object.keys(row.custom.uniqueVisitors).length
                        }
                    })

                    return uniqueVisitorsSum
                },
            })
        }

        return columns
    }

    getExcelFileNamePart() {
        if (this.props.eventCount === 1) {
            return this.props.singleEventName!
        } else {
            return null
        }
    }

    getDistTypes() {
        const {
            props: { schools, countries, areas },
            state: { showAverage, includeAnonymous },
        } = this
        return getDistTypes(schools, countries, areas, showAverage, includeAnonymous)
    }

    getCustomFooter(data: StatsData) {
        const visitorIds = Object.keys(data.global.visitors)

        if (visitorIds.length === 0) {
            return null
        }

        const fakeMeeting = { startDate: this.props.endDate }

        const distTypes = this.getDistTypes()
        const byGender: Record<string, number> = {}
        const byAge: Record<string, number> = {}

        for (const visitorId of visitorIds) {
            const visitor = this.props.visitors[visitorId]
            const obj: Data = { visitor, meeting: fakeMeeting }

            const ageKey = distTypes['age'].getKey(obj)!
            Utils.member(byAge, ageKey, 0)
            byAge[ageKey] += 1

            const genderKey = distTypes['gender'].getKey(obj)!
            Utils.member(byGender, genderKey, 0)
            byGender[genderKey] += 1
        }

        return (
            <div style={{ marginTop: 10 }}>
                <div>
                    {'Unikaalseid külastajaid kogu perioodi vältel: '}
                    <b id="unique-count">{visitorIds.length}</b>
                    {' (arv ei sisalda anonüümseid külastajaid)'}
                </div>
                <div>
                    {'Nende sooline jaotus: '}
                    {this.renderDist(distTypes['gender'], byGender)}
                </div>
                <div>
                    Ja vanuseline ({Utils.formatDateFromString(this.props.endDate)}
                    {' seisuga): '}
                    {this.renderDist(distTypes['age'], byAge)}
                </div>
            </div>
        )
    }

    renderAnonNote(hasAgeDist: boolean) {
        if (this.state.includeAnonymous && hasAgeDist) {
            return (
                <div>
                    NB! Anonüümseid osalejaid sisse arvestades kasutatakse
                    {' teistsuguseid vanusevahemikke, kuna täpsem info puudub.'}
                </div>
            )
        } else {
            return null
        }
    }

    renderDist(distType: DistType<Data>, data: Record<string, number>) {
        const filteredKeys = distType.orderedKeys!.filter((key) => key in data)

        return filteredKeys.map((key, ix) => {
            const isLast = ix === filteredKeys.length - 1
            const keyName = distType.getKeyName ? distType.getKeyName(key) : key

            return (
                <span key={key}>
                    <b>{keyName}</b>
                    {': '}
                    {data[key]}
                    {isLast ? '' : ', '}
                </span>
            )
        })
    }

    render() {
        let title: string

        if (this.props.eventCount === 1) {
            title = this.props.singleEventName!
        } else {
            title = this.props.eventCount + ' sündmust'
        }

        return (
            <div>
                <h2>{title}</h2>
                {renderStatsTable<Data, any>({
                    id: 'event-stats-table',
                    distTypes: { ...this.getDistTypes() },
                    initialColDist: 'age',
                    initialRowDist: 'month',
                    initData: (dataCollector) => this.initData(dataCollector),
                    collectData: (dataCollector, rowDistType, colDistType) => {
                        this.collectData(dataCollector, rowDistType, colDistType)
                    },
                    getValueFunc: (rawRowData) => this.getValueFunc(rawRowData),
                    getCustomColumns: () => this.getCustomColumns(),
                    addCustomRowData: (rowData, rawRowData) =>
                        this.addCustomRowData(rowData, rawRowData),
                    getCustomOptions: () => this.getCustomOptions(),
                    getCustomFooter: (data) => this.getCustomFooter(data),
                    getExcelFileNamePart: () => this.getExcelFileNamePart(),
                    showPercentages: this.state.showPercentages,
                    isFiltered: this.state.program !== '_',
                })}
            </div>
        )
    }
}
