import { entries } from './entries'

export const filterMapToMap = <T extends object>(
    obj: T,
    predicate: (value: T[keyof T]) => boolean,
) => {
    const result = {} as T

    for (const [key, element] of entries(obj)) {
        if (predicate(element)) {
            result[key] = element
        }
    }

    return result
}
