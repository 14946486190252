import { FC, ReactNode } from 'react'

import { MeetingVisitor } from '../server/commands/get-meeting-visitors'
import { Event, Meeting } from '../server/types'
import { canSeeAllEvents } from './access'
import { CommonUtils } from './common-utils'
import { EditVisitor } from './edit-visitor'
import { EventBus } from './event-bus'
import { getEditVisitorProps } from './props/edit-visitor'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'

export interface VisitorStatsResultsProps {
    view: AppView // TODO refactor props
    visitor: MeetingVisitor
    meetings: Meeting[]
    events: Record<string, Event>
    onVisitorEdited: () => Promise<void>
    includeArchived: boolean
}

interface EventWithMeetings extends Event {
    meetings: Meeting[]
}

export const VisitorStatsResults: FC<VisitorStatsResultsProps> = (props) => {
    const { view } = props
    const { editVisitor } = view.state

    const openEditVisitorModal = async () => {
        editVisitor.editModalVisible = true
        view.update()
    }

    const renderVisitorDesc = () => {
        const visitor = props.visitor

        if (visitor.managingCentreId === Session.getCentre(view)!._id) {
            return visitor.firstname + ' ' + visitor.lastname + ' (' + visitor.username + ')'
        } else {
            return visitor.username
        }
    }

    const events = CommonUtils.mapMapToMap(
        props.events,
        (evt): EventWithMeetings => ({ ...evt, meetings: [] }),
    )

    Utils.iterMap(events, (evt) => (evt.meetings = []))

    for (const meeting of props.meetings) {
        events[meeting.event].meetings.push(meeting)
    }

    const eventArray = Utils.filterMap(events, (evt) => evt.meetings.length > 0)
    Utils.sortAsStrings(eventArray, (evt) => evt.name)
    const visitor = props.visitor

    let visits: ReactNode

    if (eventArray.length) {
        visits = eventArray.map((evt) => {
            const meetings = Utils.clone(evt.meetings)
            Utils.sortAsStrings(meetings, (meeting) => meeting.startDate + ' ' + meeting.startTime)

            return (
                <div key={evt._id} className="event">
                    <div className="event-header">
                        <a className="event-name" href={'#/events/' + evt._id}>
                            {evt.name}
                        </a>
                        {' - '}
                        {Utils.pluralize(evt.meetings.length, 'kohtumine', 'kohtumist')}
                    </div>
                    <ul>
                        {meetings.map((meeting) => {
                            const time = Utils.formatTimeRange(
                                meeting.startDate,
                                meeting.startTime,
                                meeting.endDate,
                                meeting.endTime,
                            )

                            const activityConf = Utils.getActivityConf(evt, meeting)
                            meeting.participations.sort((p1, p2) => p1._id - p2._id)

                            const participations = meeting.participations
                                .map((participation) => {
                                    const parts: string[] = []

                                    if (participation.arrived) {
                                        parts.push('Saabus ' + participation.arrived)
                                    }

                                    if (participation.departed) {
                                        parts.push('Lahkus ' + participation.departed)
                                    }

                                    const activity = Utils.getActivityText(
                                        participation.activity!,
                                        activityConf,
                                    )

                                    if (activity) {
                                        parts.push('Tegevus: ' + activity)
                                    }

                                    return { ...participation, textParts: parts }
                                })
                                .filter((participation) => participation.textParts.length > 0)

                            let participationsElement: ReactNode = null

                            if (participations.length) {
                                participationsElement = (
                                    <div className="participations">
                                        {participations.map((participation) => (
                                            <div key={participation._id}>
                                                {participation.textParts.join(' - ')}
                                            </div>
                                        ))}
                                    </div>
                                )
                            }

                            return (
                                <li key={meeting._id} className="meeting">
                                    <a href={'#/meeting/' + meeting._id}>{time}</a>
                                    {participationsElement}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        })
    } else {
        visits = 'Ei leitud ühtegi külastust.'

        if (!props.includeArchived) {
            visits += ' Proovige seadet "Arvesta sisse arhiveeritud sündmused".'
        }

        const { session } = view.state

        if (session.loggedIn && !canSeeAllEvents(session.employee, session.centre!._id)) {
            visits = (
                <div>
                    <p>{visits}</p>
                    <p>
                        Külastaja võib olla ka osalenud sündmustel, mille andmetele puudub teil
                        ligipääs.
                    </p>
                </div>
            )
        }
    }

    return (
        <div id="visitor-stats">
            <div style={{ marginBottom: 5 }}>
                {'Külastaja '}
                <a style={{ cursor: 'pointer' }} onClick={async () => openEditVisitorModal()}>
                    {renderVisitorDesc()}
                </a>
                {' külastused:'}
            </div>
            {view.state.editVisitor.editModalVisible && (
                <EditVisitor
                    {...getEditVisitorProps(view, {
                        close: async () => {
                            editVisitor.editModalVisible = false
                            view.update()
                            await EventBus.fire('modal-closed')
                        },
                        isNew: false,
                        visitor,
                        afterSave: props.onVisitorEdited,
                    })}
                />
            )}
            {visits}
        </div>
    )
}
