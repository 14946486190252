import classnames from 'classnames'

import { ActivityConf, ActivityMode } from '../../server/types'
import { ActivityConfigProps, ChoiceConfigProps, ChoiceOptionProps } from '../activity-config'
import { RadioButtonProps } from '../radio-button'
import { AppView } from '../state'
import { Utils } from '../utils'
import { getValErrProps, ValidationError } from '../validation'

// TODO refactor after refactoring EditMeetingFormProps
interface ActivityConfigParams {
    inUse: boolean
    usedOptions?: string[]
    activityConf: ActivityConf
    afterConfChange: () => void
    showMeetingOption: boolean
    validationErrors: Record<string, ValidationError> | undefined
    validationPrefix: string
    choiceText: string
    onChoiceTextChange: (value: string) => void
}

export const getActivityConfigProps = (_view: AppView, params: ActivityConfigParams) => {
    const { inUse, activityConf, afterConfChange, showMeetingOption } = params
    const props: ActivityConfigProps = {}

    const optionDescs = {
        none: 'Täpsemat tegevust sisestada ei saa',
        freeform: 'Täpsema tegevuse saab sisestada vaba tekstina',
        choice: 'Täpsema tegevuse jaoks on konkreetsed valikud',
        meeting: 'Võimalused seadistatakse iga kohtumise juures eraldi',
    }

    if (inUse) {
        optionDescs.choice += ':'
        props.modeText = optionDescs[activityConf.mode]

        if (!props.modeText) {
            throw new Error('Invalid activity mode')
        }
    } else {
        const modes: ActivityMode[] = ['none']

        if (showMeetingOption) {
            modes.push('meeting')
        }

        modes.push('freeform', 'choice')

        const setMode = (newMode: ActivityMode) => {
            const prevMode = activityConf.mode
            activityConf.mode = newMode

            if (newMode !== prevMode) {
                if (newMode === 'choice') {
                    activityConf.options = []
                    activityConf.other = false
                } else {
                    delete activityConf.options
                    delete activityConf.other
                }
            }

            afterConfChange()
        }

        props.modeButtons = modes.map(
            (mode: ActivityMode): RadioButtonProps => ({
                reactKey: mode,
                checked: activityConf.mode === mode,
                onSelect: () => setMode(mode),
                label: optionDescs[mode],
            }),
        )
    }

    if (activityConf.mode === 'choice') {
        props.choiceConfig = getChoiceConfigProps(params)
    }

    return props
}

const getChoiceConfigProps = (params: ActivityConfigParams): ChoiceConfigProps => {
    const {
        inUse,
        usedOptions,
        activityConf,
        afterConfChange,
        validationErrors,
        validationPrefix,
        choiceText,
        onChoiceTextChange,
    } = params

    const props: ChoiceConfigProps = {
        textInput: {
            value: choiceText,
            onChange: onChoiceTextChange,
            onEnter: () => {
                const value = choiceText.trim()

                if (value !== '') {
                    activityConf.options!.push(value)
                    afterConfChange()
                }

                onChoiceTextChange('')
            },
        },
        options: (activityConf.options || []).map((option, index): ChoiceOptionProps => {
            let canDelete = true

            if (inUse) {
                canDelete = !Utils.arrayContains(usedOptions!, option)
            }

            let onClick: (() => void) | undefined
            let title: string | undefined

            if (canDelete) {
                onClick = () => {
                    activityConf.options!.splice(index, 1)
                    afterConfChange()
                }
            } else {
                title = 'Seda valikut ei saa kustutada kuna see on kasutusel'
            }

            return {
                reactKey: String(index),
                text: option,
                deleteButton: {
                    text: '',
                    className: classnames({ delete: true, disabled: !canDelete }),
                    onClick,
                    additional: {
                        disabled: !canDelete,
                        title,
                    },
                },
            }
        }),
        valErr: getValErrProps(validationErrors, validationPrefix + '.activityConf.options'),
    }

    if (!inUse) {
        props.other = {
            checkbox: {
                checked: Boolean(activityConf.other),
                onChange: (checked) => {
                    activityConf.other = checked
                    afterConfChange()
                },
            },
        }
    } else if (activityConf.other) {
        props.other = {}
    }

    return props
}
