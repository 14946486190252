import { ChangeEvent, Component, ReactNode } from 'react'

import { Area } from '../server/areas'
import { API } from './api'
import { EventBus } from './event-bus'
import { LoadingIcon } from './loading-icon'
import { Utils } from './utils'

export interface AreaSelectionProps {
    selectedArea: string | null
    onSelectArea: (areaId: string) => void
    onDeselectArea: () => void
}

interface State {
    loaded: boolean
    query: string
    selectedIndex: number
    showDropdown: boolean
    areas?: Record<string, Area>
}

export class AreaSelection extends Component<AreaSelectionProps, State> {
    state: State = {
        loaded: false,
        query: '',
        selectedIndex: -1,
        showDropdown: false,
    }

    unmounted = false
    input: HTMLInputElement | null = null

    // Declared as a property because it's used in an EventBus handler
    onFocus: (() => Promise<void>) | null = null

    async componentDidMount() {
        this.onFocus = async () => {
            if (this.input) {
                this.input.focus()
            }
        }

        EventBus.on('focus-area-input', this.onFocus!)

        const areas = await API.getAreas()

        if (!this.unmounted) {
            await Utils.setState(this, { loaded: true, areas })
            await EventBus.fire('area-rendered')
        }
    }

    componentWillUnmount() {
        EventBus.off('focus-area-input', this.onFocus!)
        this.unmounted = true
    }

    async onChange(evt: ChangeEvent<HTMLInputElement>) {
        await this.setQuery(evt.target.value)
    }

    async setQuery(query: string) {
        await Utils.setState(this, {
            query,
            selectedIndex: -1,
            showDropdown: Boolean(query),
        })
    }

    getMatches() {
        const matches = Utils.filterMap(this.state.areas!, (area) => {
            return Utils.contains(area.name, this.state.query)
        })

        // Limit to 50 results
        return matches.slice(0, 50)
    }

    async onSelect(area: Area) {
        this.props.onSelectArea(area.id)
        await this.setQuery('')
    }

    render() {
        if (!this.state.loaded) {
            return <LoadingIcon />
        }

        if (this.props.selectedArea) {
            const selectedArea = this.state.areas![this.props.selectedArea]

            return (
                <span>
                    {selectedArea.name}{' '}
                    <button className="delete" onClick={() => this.props.onDeselectArea()} />
                </span>
            )
        } else {
            const matches = this.getMatches()

            let suggestions: ReactNode = matches.map((area, index) => {
                let className = 'autocomplete__option'

                if (index === this.state.selectedIndex) {
                    className += ' autocomplete__option--highlighted'
                }

                let text: ReactNode = ''

                if (Utils.contains(area.name, this.state.query)) {
                    text = Utils.highlight(area.name, this.state.query)
                }

                return (
                    <div
                        key={index}
                        className={className}
                        onMouseOver={() => this.setState({ selectedIndex: index })}
                        onMouseOut={() => {
                            if (this.state.selectedIndex === index) {
                                this.setState({ selectedIndex: -1 })
                            }
                        }}
                        onClick={async () => this.onSelect(area)}
                    >
                        {text}
                    </div>
                )
            })

            if (!matches.length) {
                suggestions = (
                    <span style={{ fontSize: '85%', color: 'gray' }}>
                        Ei leitud ühtegi kohalikku omavalitsust. Proovi alternatiivseid nimesid.
                    </span>
                )
            }

            const newPos = (diff: number) => {
                let newIndex = this.state.selectedIndex + diff

                while (newIndex < 0) {
                    newIndex += matches.length
                }

                while (newIndex >= matches.length) {
                    newIndex -= matches.length
                }

                return newIndex
            }

            let dropdown: ReactNode = null

            if (this.state.showDropdown) {
                dropdown = <div className="autocomplete__options">{suggestions}</div>
            }

            return (
                <div className="autocomplete">
                    <input
                        id="area"
                        type="text"
                        ref={(node) => (this.input = node)}
                        className="form-control"
                        onChange={async (evt) => this.onChange(evt)}
                        value={this.state.query}
                        onKeyDown={async (evt) => {
                            // TODO: scrolling?
                            if (evt.key === 'ArrowUp') {
                                this.setState({ selectedIndex: newPos(-1) })
                            } else if (evt.key === 'ArrowDown') {
                                this.setState({ selectedIndex: newPos(1) })
                            } else if (evt.key === 'Enter') {
                                const area = matches[this.state.selectedIndex]

                                if (area) {
                                    await this.onSelect(area)
                                }
                            }
                        }}
                    />
                    {dropdown}
                </div>
            )
        }
    }
}
