import { Event, EventUpdate } from '../../server/types'
import { clone } from '../../util/clone'
import { API } from '../api'
import { ButtonProps } from '../button'
import { EditEventProps } from '../edit-event'
import { EditMeetingProps } from '../edit-meeting'
import { EventBus } from '../event-bus'
import { EditCellProps, EventViewProps } from '../event-view'
import { getConfirmation } from '../get-confirmation'
import { getInitialEventFormState, getInitialEventState } from '../initial-state'
import { useCustomHandlerDuring } from '../request-failure-handler'
import { Session } from '../session'
import { AppView } from '../state'
import { Utils } from '../utils'
import { checkValidationErrors, clearValidationErrors } from '../validation'
import { getActivityConfigProps } from './activity-config'
import { getEditMeetingModalProps } from './edit-meeting-modal'
import { getEventFieldsProps } from './event-form'
import { initIfNeeded } from './init-if-needed'
import { reloadEvents } from './reload-events'

export const getEventViewProps = (view: AppView, evt: Event) => {
    const { state, update } = view

    initIfNeeded(view, `eventView:${evt._id}`, async ({ setCleanup, isMounted }) => {
        setCleanup(() => (state.event = getInitialEventState()))
        await loadData(view, evt._id, isMounted)
    })

    const centre = Session.getCentre(view)!
    const meetingCount = Object.keys(state.event.meetings).length
    const anyMeetings = meetingCount > 0
    const { archived } = evt

    const editCell: EditCellProps = {
        rowSpan: 0, // Gets updated below
        openEventModal: () => {
            state.event.visibleModalId = 'edit-event'
            update()
        },
    }

    if (state.event.visibleModalId === 'edit-event') {
        editCell.editEvent = getEditEventProps(view, evt)
    }

    const deleteButton: ButtonProps = {
        isLoading: state.event.isDeleting,
        text: 'Kustuta sündmus',
        style: { marginTop: 5 },
    }

    if (anyMeetings) {
        deleteButton.className = 'disabled'

        deleteButton.additional = {
            title: 'Sündmuse kustutamiseks tuleb kõigepealt kustutada selle alt kõik kohtumised',
            disabled: true,
        }
    } else {
        deleteButton.onClick = async () => {
            if (!(await getConfirmation('Kas olete kindel, et soovite seda sündmust kustutada?'))) {
                return
            }

            state.event.isDeleting = true
            update()

            try {
                view.navigate(['events'])
                await API.deleteEvent(view, evt._id)
                await reloadEvents(view)
            } finally {
                state.event.isDeleting = false
                update()
            }
        }
    }

    const props: EventViewProps = {
        reactKey: evt._id,
        isLoading: !state.event.loaded,
        eventName: evt.name,
        nameRef: (node) => {
            if (node) {
                Utils.scrollToIfNeeded(node)
            }
        },
        detailRows: [
            {
                reactKey: 'centre',
                label: 'Noortekeskus:',
                value: centre.name,
                editCell,
            },
        ],
        archiveButton: {
            isLoading: state.event.isArchiving,
            onClick: async () => {
                if (archived) {
                    await unarchiveEvent(view, evt._id)
                } else {
                    await archiveEvent(view, evt._id)
                }
            },
            text: archived ? 'Taasta sündmus arhiivist' : 'Arhiveeri sündmus',
        },
        deleteButton,
        addMeeting: getEditMeetingProps(view, evt),
    }

    if (evt.category) {
        const category = state.eventList.eventCategories.find(({ _id }) => _id === evt.category)

        if (!category) {
            throw new Error('Event category with id ' + evt.category + ' not found.')
        }

        props.detailRows.push({
            reactKey: 'category',
            label: 'Kategooria',
            value: category.name,
        })
    }

    props.detailRows.push(
        {
            reactKey: 'eventType',
            label: 'Sündmuse tüüp:',
            value: evt.type === 'open' ? 'Avatud osalus' : 'Kindel grupp',
        },
        {
            reactKey: 'employees',
            label: 'Töötajad:',
            value: evt.employees
                .map((employeeId) => {
                    let employee = state.eventList.employees[employeeId]

                    if (!employee) {
                        // Employee is no longer linked to the centre, look in extended set
                        employee = state.eventList.extendedEmployees[employeeId]
                    }

                    return employee.name
                })
                .join(', '),
        },
        {
            reactKey: 'description',
            label: 'Sisu:',
            value: evt.description,
            isMultiline: true,
        },
    )

    editCell.rowSpan = props.detailRows.length

    if (anyMeetings) {
        props.earlierMeetings = {
            list: {
                view,
                meetings: state.event.meetings,
            },
        }

        if (state.event.hasMore) {
            props.earlierMeetings.loadMore = {
                text: 'Tabelis on näidatud ainult ' + meetingCount + ' viimast kohtumist.',
                button: {
                    getPromise: async () => {
                        const response = await API.getMeetings(view, evt._id, true)

                        state.event.meetings = response.meetings
                        state.event.hasMore = false
                        update()
                    },
                    text: 'Lae kõik kohtumised',
                },
            }
        }
    }

    return props
}

const loadData = async (view: AppView, eventId: string, isMounted: () => boolean) => {
    const { state, update } = view

    const response = await API.getMeetings(view, eventId, false)

    if (!isMounted()) {
        return
    }

    state.event.loaded = true
    state.event.meetings = response.meetings
    state.event.hasMore = Boolean(response.hasMore)
    update()

    await EventBus.fire('event-rendered')
}

const unarchiveEvent = async (view: AppView, eventId: string) => {
    const { state, update } = view
    state.event.isArchiving = true
    update()

    try {
        await API.unarchiveEvent(view, eventId)
        await reloadEvents(view)
    } finally {
        state.event.isArchiving = false
        update()
    }
}

const archiveEvent = async (view: AppView, eventId: string) => {
    const { state, update } = view
    state.event.isArchiving = true
    update()

    try {
        await API.archiveEvent(view, eventId)
        await reloadEvents(view)
    } finally {
        state.event.isArchiving = false
        update()
    }
}

const getEditMeetingProps = (view: AppView, evt: Event) => {
    const { state, update } = view

    const editMeeting: EditMeetingProps = {
        button: {
            id: 'add-new-meeting',
            style: { marginTop: 5, marginBottom: 10 },
            children: 'Lisa uus kohtumine',
            onClick: () => {
                state.meeting.modalVisible = true

                state.meetingForm = {
                    loaded: false,
                    startDate: Utils.formatDateYmd(Utils.getNow()),
                    startTime: '',
                    endDate: '',
                    endTime: '',
                    name: '',
                    locationType: 'centre',
                    externalLocation: '',
                    program: '',
                    collaborator: [],
                    openMode: 'participant',
                    activityConf: { mode: 'none' },
                    activityChoiceText: '',
                }

                update()
            },
        },
    }

    if (state.meeting.modalVisible) {
        editMeeting.modal = getEditMeetingModalProps(view, evt, 'Uus kohtumine', {
            isNew: true,
            event: evt,
        })
    }

    return editMeeting
}

const getEditEventProps = (view: AppView, evt: Event): EditEventProps => {
    const { state, update } = view
    const meetingCount = Object.keys(state.event.meetings).length
    const inUse = meetingCount > 0

    initIfNeeded(view, 'eventForm:edit', async ({ setCleanup, isMounted }) => {
        setCleanup(() => {
            state.event.visibleModalId = undefined
            state.eventForm = getInitialEventFormState()
        })

        await loadFormData(view, evt, isMounted)
    })

    const close = () => {
        state.event.visibleModalId = undefined
        update()
    }

    const save = async () => {
        try {
            state.event.isSaving = true
            update()
            clearValidationErrors(view)

            const eventUpdate: EventUpdate = {
                name: state.eventForm.name.trim(),
                type: state.eventForm.type,
                employees: state.eventForm.employees,
                description: state.eventForm.description.trim(),
                activityConf: state.eventForm.activityConf,
                category: state.eventForm.category,
                rev: evt.rev,
            }

            if (!inUse) {
                eventUpdate.timesNeeded = state.eventForm.timesNeeded
            }

            await useCustomHandlerDuring(
                Utils.getConcurrentEditErrorHandler('sündmuse'),
                async () => {
                    await API.updateEvent(view, evt._id, eventUpdate)
                    await reloadEvents(view)
                },
            )

            close()
        } catch (error) {
            await checkValidationErrors(view, null, error)
        } finally {
            state.event.isSaving = false
            update()
        }
    }

    const props: EditEventProps = {
        outer: {
            title: 'Sündmus',
            dialogClassName: 'edit-event',
            closeModal: async () => close(),
            buttons: [
                {
                    isLoading: state.event.isSaving,
                    text: 'Salvesta',
                    onClick: save,
                    className: state.event.isSaving ? 'disabled' : undefined,
                    style: { marginRight: 5 },
                },
                {
                    text: 'Tühista',
                    onClick: close,
                },
            ],
        },
    }

    if (state.eventForm.loaded) {
        props.form = {
            fields: getEventFieldsProps(view, inUse),
            activityConfig: getActivityConfigProps(view, {
                inUse,
                usedOptions: state.eventForm.usedOptions,
                activityConf: state.eventForm.activityConf,
                afterConfChange: update,
                showMeetingOption: true,
                validationErrors: state.validationErrors,
                validationPrefix: 'event',
                choiceText: state.eventForm.activityChoiceText,
                onChoiceTextChange: (value) => {
                    state.eventForm.activityChoiceText = value
                    update()
                },
            }),
            inUse,
        }
    }

    return props
}

const loadFormData = async (view: AppView, evt: Event, isMounted: () => boolean) => {
    const { state, update } = view

    state.eventForm = {
        loaded: evt.activityConf.mode !== 'choice',
        name: evt.name,
        type: evt.type,
        employees: evt.employees,
        description: evt.description,
        timesNeeded: evt.timesNeeded,
        activityConf: clone(evt.activityConf),
        activityChoiceText: '',
        category: evt.category,
    }

    if (state.eventForm.activityConf.mode === 'choice') {
        const usage = await API.getActivityOptionUsage(view, evt._id)

        if (!isMounted()) {
            return
        }

        state.eventForm.loaded = true
        state.eventForm.usedOptions = usage.usedOptions
        update()
    }
}
