import classnames from 'classnames'
import { ButtonHTMLAttributes, Component, CSSProperties } from 'react'

import { EventBus } from './event-bus'
import { LoadingIcon } from './loading-icon'
import { Utils } from './utils'

export interface LoadingButtonProps {
    key?: string

    // If mode === 'icon', the button is replaced by a loading icon while processing.
    // If mode === 'disable', the button is disabled while processing.
    mode?: 'icon' | 'disable'

    text: string
    getPromise?: () => Promise<void> // Required unless disabled
    id?: string
    style?: CSSProperties
    className?: string

    // If true, button will be always disabled, regardless of mode or whether it's processing.
    disabled?: boolean

    // Useful when button is disabled
    tooltip?: string

    // If true, button won't be restored unless an error occurs.
    // Useful for buttons that disappear from the screen on success,
    // but whose promise may resolve too early.
    restoreOnlyOnFailure?: boolean
}

interface State {
    loading: boolean
}

// TODO replace with Button
export class LoadingButton extends Component<LoadingButtonProps, State> {
    state: State = { loading: false }
    unmounted = false

    componentWillUnmount() {
        this.unmounted = true
    }

    async restore() {
        if (!this.unmounted) {
            await Utils.setState(this, { loading: false })
            EventBus.fire('loading-button-restored')
        }
    }

    async onClick() {
        // This method may be called from other modules
        this.setState({ loading: true })

        try {
            await this.props.getPromise!()

            if (!this.props.restoreOnlyOnFailure) {
                await this.restore()
            }
        } catch (error) {
            await this.restore()
            throw error
        }
    }

    render() {
        const mode = this.props.mode || 'icon'

        if (mode === 'icon' && this.state.loading) {
            return <LoadingIcon />
        } else {
            const props: ButtonHTMLAttributes<HTMLButtonElement> = {
                id: this.props.id,
                className: this.props.className,
                style: this.props.style,
                title: this.props.tooltip,
            }

            if (this.state.loading) {
                // We can infer that mode === 'disable'
                props.disabled = true
            } else {
                props.disabled = this.props.disabled
                props.onClick = async () => this.onClick()
            }

            props.className = classnames(this.props.className, {
                disabled: props.disabled,
            })
            return <button {...props}>{this.props.text}</button>
        }
    }
}
