import { FC, Fragment } from 'react'

import { ConfirmButton, DeleteButton, DiscardButton, EditButton } from '../action-button'

interface Props {
    isEditing: boolean
    text: string
    onActivate: () => void
    onChange: (text: string) => void
    onDiscard: () => void
    onCommit: () => void
    onDelete: () => void
    commitDisabled?: boolean
    canDelete?: boolean
}

enum KeyNames {
    enter = 'Enter',
    escape = 'Escape',
}

const title = 'Muuda kategooria nime'

const renderDeleteButton = (onDelete: () => void, canDelete?: boolean) => {
    if (!canDelete) {
        return null
    }

    return (
        <DeleteButton onClick={onDelete} title="Kustuta kategooria" className="align-flex-start" />
    )
}

const renderView = ({ text, onActivate, canDelete, onDelete }: Props) => (
    <Fragment>
        <span className="editable-text" onClick={onActivate} title={title}>
            {text}
        </span>
        <EditButton onClick={onActivate} className="align-flex-start" title={title} />
        {renderDeleteButton(onDelete, canDelete)}
    </Fragment>
)

const renderEdit = ({ text, onChange, onDiscard, onCommit, commitDisabled }: Props) => (
    <Fragment>
        <input
            autoFocus
            className="editable-text"
            value={text}
            onFocus={(evt) => evt.target.select()}
            onChange={(evt) => onChange(evt.target.value)}
            onKeyDown={({ key }) => {
                if (key === KeyNames.enter && !commitDisabled) {
                    onCommit()
                }

                if (key === KeyNames.escape) {
                    onDiscard()
                }
            }}
        />
        <ConfirmButton onClick={onCommit} className="align-flex-start" disabled={commitDisabled} />
        <DiscardButton onClick={onDiscard} className="align-flex-start" />
    </Fragment>
)

export const EventCategoryTitle: FC<Props> = (props) => (
    <div className="editable-text-container bottom-margin">
        {props.isEditing ? renderEdit(props) : renderView(props)}
    </div>
)
