// TODO break into smaller files
import { isMoment } from 'moment'
import { Attributes, Component, CSSProperties, HTMLAttributes, ReactNode } from 'react'

import { Button, renderButtonGroup } from './button-group'
import { DistType } from './dist-type'
import { DropdownOption, renderDropdown } from './dropdown'
import { generateExcelFile } from './excel'
import { HEADER_STYLE } from './excel/style'
import {
    Column,
    ColumnHeader,
    ExcelFont,
    ExcelNumberFormat,
    ExcelSpec,
    ExcelStyle,
    ExcelValue,
    SimpleExcelValue,
} from './excel/types'
import { isMultiKey } from './stats-utils'
import { Utils } from './utils'

export interface FullKey {
    amount: number
    row: string | null
    col: string | null
}

export interface DataCollector<T> {
    process: (data: T) => void
    addByKey: (rowKey: string | null, colKey: string | null, amount: number) => void
    incCustom: (rowKey: string, fieldName: string) => void
    uniqueCustom: (data: T, fieldName: string, string: string) => void
    setGlobal: (fieldName: string, value: string | number) => void
    initUniqueGlobal: (fieldName: string) => void
    uniqueGlobal: (fieldName: string, value: string) => void
    setInvalid: (message: string) => void
}

export interface RowCounts {
    countByKey: Record<string, number>
    unknown: number

    // TODO separate fields for numbers and string sets?
    custom: Record<string, number | Record<string, true>>
}

export type ValueFunc = (value: number) => number

interface CustomColumn<T> {
    id: string
    header: string
    style: CSSProperties
    getTotalsRowContents?: (data: StatsData) => SimpleExcelValue
    getTableValue?: (rowData: RowData) => SimpleExcelValue
    getValue: (rowData: RowData) => SimpleExcelValue
    excelWidth: number
    showIf?: (rowDistType: DistType<T>) => boolean
}

export interface CustomColumns<T> {
    before: CustomColumn<T>[]
    after: CustomColumn<T>[]
}

export interface CustomOption<T> {
    id: string
    label: string
    buttons: Button<T>[]
    disabled?: boolean
    onClick: (value: T) => void
    active: T
    renderAdditional?: (colDistTypeId: string, rowDistTypeId: string) => ReactNode
}

interface Props<T, U> {
    distTypes: Record<string, DistType<T, any> | undefined>
    initialColDist: string
    initialRowDist: string
    initData?: (dataCollector: DataCollector<T>) => void
    collectData: (
        dataCollector: DataCollector<T>,
        colDistType: DistType<T>,
        rowDistType: DistType<T>,
    ) => void
    getValueFunc?: (rawRowData: RowCounts) => ValueFunc | null
    getCustomColumns?: () => CustomColumns<T>
    addCustomRowData?: (rowData: RowData, rawRowData: RowCounts) => void
    getCustomOptions: () => CustomOption<U>[]
    getCustomFooter?: (data: StatsData) => ReactNode
    getExcelFileNamePart: () => string | null
    dropdownForCol?: boolean
    dropdownForRow?: boolean
    verticalColDists?: boolean

    // Actually means "show if possible" - sometimes percentages
    // will be hidden even if it's true
    showPercentages?: boolean

    // Refers to external filters applied on the data before passing it to this component
    isFiltered?: boolean

    // Refers to internal filters managed by this component
    showFilters?: boolean

    /** DOM id, optional */
    id?: string
}

interface Filter {
    dist: string
    value: string
}

interface State {
    colDistTypeId: string
    rowDistTypeId: string
    filters: Filter[]
}

export interface StatsData {
    invalidConfMessage: string | null
    global: Record<string, any> // TODO
    byRow: Record<string, RowCounts>
    unknownRow: RowCounts
    uniqueColKeys: Record<string, true> // TODO Set<string>
    uniqueRowKeys: Record<string, true>
    filterKeys: Record<string, Record<string, true>>
}

export interface RowData {
    rowKey?: string | null
    counts: Record<string, number>
    total?: number
    unknown: number
    isUnknown?: true
    isTotal?: true
    isPercentage?: true
    firstCellContents?: string
    className?: string
    data?: StatsData // Only on totals?

    // TODO refactor - specific to event stats
    meetingCount?: number
    uniqueVisitorCount?: number
}

const getKeys = <T,>(distType: DistType<T>, uniqueKeys: Record<string, true>) => {
    let keys = distType.orderedKeys

    if (!keys) {
        keys = Object.keys(uniqueKeys)

        let compareFunc: undefined | ((value1: string, value2: string) => number)
        const { getSortKey } = distType

        if (getSortKey) {
            compareFunc = (key1, key2) => {
                const sortKey1 = getSortKey(key1)
                const sortKey2 = getSortKey(key2)

                if (typeof sortKey1 === 'number' && typeof sortKey2 === 'number') {
                    return sortKey1 - sortKey2
                } else {
                    return (sortKey1 as string).localeCompare(sortKey2 as string, 'et')
                }
            }
        }

        keys.sort(compareFunc)
    }

    return keys
}

const getKeyName = <T,>(key: string, distType: DistType<T>) => {
    return distType.getKeyName ? distType.getKeyName(key) : key
}

class StatsTable<T, U> extends Component<Props<T, U>, State> {
    state: State = {
        // TODO: persist after choosing another event?
        colDistTypeId: this.props.initialColDist,
        rowDistTypeId: this.props.initialRowDist,
        filters: [],
    }

    getColDistType() {
        const distTypeId = this.state.colDistTypeId
        return this.props.distTypes[distTypeId]!
    }

    getRowDistType() {
        const distTypeId = this.state.rowDistTypeId
        return this.props.distTypes[distTypeId]!
    }

    getCustomColumns() {
        const rowDistType = this.getRowDistType()
        const filter = (column: CustomColumn<T>) =>
            column.showIf ? column.showIf(rowDistType) : true

        const customColumns = this.props.getCustomColumns
            ? this.props.getCustomColumns()
            : { before: [], after: [] }

        customColumns.before = customColumns.before.filter(filter)
        customColumns.after = customColumns.after.filter(filter)

        return customColumns
    }

    getData(): StatsData {
        const colDistTypeId = this.state.colDistTypeId
        const colDistType = this.props.distTypes[colDistTypeId]!

        const rowDistTypeId = this.state.rowDistTypeId
        const rowDistType = this.props.distTypes[rowDistTypeId]!

        let invalidConfMessage: string | null = null
        const globalData: Record<string, any> = {}
        const dataByRow: Record<string, RowCounts> = {}
        const unknownRow: RowCounts = { countByKey: {}, unknown: 0, custom: {} }
        const uniqueColKeys: Record<string, true> = {}
        const uniqueRowKeys: Record<string, true> = {}
        const filterKeys: Record<string, Record<string, true>> = {}

        const getKey = (
            data: T,
            distTypeId: string,
        ): string | string[] | Record<string, number> | null => {
            const distType: DistType<T> = this.props.distTypes[distTypeId]!
            const key = distType.getKey ? distType.getKey(data) : (data as any)[distTypeId] // TODO
            return key === undefined ? null : key
        }

        const getRowKey = (data: T) => getKey(data, rowDistTypeId)
        const getColKey = (data: T) => getKey(data, colDistTypeId)

        const getRow = (rowKey: string | null) => {
            if (rowKey === null) {
                return unknownRow
            }

            uniqueRowKeys[rowKey] = true
            return Utils.member(dataByRow, rowKey, {
                countByKey: {},
                unknown: 0,
                custom: {},
            })
        }

        const addByKey: DataCollector<unknown>['addByKey'] = (rowKey, colKey, amount) => {
            if ((typeof amount as any) !== 'number') {
                // TODO
                throw new Error('Expected a number but got ' + amount)
            }

            const row = getRow(rowKey)

            if (colKey === null) {
                row.unknown += amount
            } else {
                Utils.member(row.countByKey, colKey, 0)
                row.countByKey[colKey] += amount
                uniqueColKeys[colKey] = true
            }
        }

        const multiplyKeys = (
            singleKey: string | null,
            multiKey: string[] | Record<string, number>,
            singleName: 'row' | 'col',
            multiName: 'row' | 'col',
            format: DistType<unknown>['keyFormat'],
        ): FullKey[] => {
            if (format === 'map') {
                if (Array.isArray(multiKey)) {
                    throw new Error('Expected map, but got array')
                }

                return Object.keys(multiKey).map((subKey) => {
                    const fullKey: FullKey = {
                        amount: multiKey[subKey],
                        row: null,
                        col: null,
                    }
                    fullKey[singleName] = singleKey
                    fullKey[multiName] = subKey
                    return fullKey
                })
            } else if (format === 'array') {
                if (!Array.isArray(multiKey)) {
                    throw new Error('Expected array')
                }

                return multiKey.map((subKey) => {
                    const fullKey: FullKey = { amount: 1, row: null, col: null }
                    fullKey[singleName] = singleKey
                    fullKey[multiName] = subKey
                    return fullKey
                })
            } else {
                throw new Error('Invalid format: ' + format)
            }
        }

        const multiplyMultiKeys = (rowKeys: string[], colKeys: string[]) => {
            const fullKeys: FullKey[] = []

            for (const rowKey of rowKeys) {
                for (const colKey of colKeys) {
                    fullKeys.push({ row: rowKey, col: colKey, amount: 1 })
                }
            }

            return fullKeys
        }

        const dataCollector: DataCollector<T> = {
            process: (data) => {
                let matchesFilters = true

                for (const filter of this.state.filters) {
                    if (filter.dist !== '') {
                        const key = getKey(data, filter.dist)

                        if (!(filter.dist in filterKeys)) {
                            filterKeys[filter.dist] = {}
                        }

                        const distType = this.props.distTypes[filter.dist]!
                        const isMulti = isMultiKey(distType)

                        let matchesAny = false

                        if (isMulti) {
                            if (!Array.isArray(key)) {
                                throw new Error('Key must be an array')
                            }

                            for (const subKey of key) {
                                filterKeys[filter.dist][subKey] = true

                                if (subKey === filter.value) {
                                    matchesAny = true
                                }
                            }
                        } else if (typeof key === 'string') {
                            filterKeys[filter.dist][key] = true
                            matchesAny = key === filter.value
                        }

                        if (filter.value !== '' && !matchesAny) {
                            matchesFilters = false
                        }
                    }
                }

                if (matchesFilters) {
                    const colIsMulti = isMultiKey(colDistType)
                    const rowIsMulti = isMultiKey(rowDistType)

                    const rowKey = getRowKey(data)
                    const colKey = getColKey(data)
                    let fullKeys: FullKey[] | undefined

                    if (colIsMulti && rowIsMulti) {
                        if (
                            colDistType.keyFormat === 'array' &&
                            rowDistType.keyFormat === 'array'
                        ) {
                            if (!Array.isArray(rowKey)) {
                                throw new Error('rowKey must be an array')
                            }

                            if (!Array.isArray(colKey)) {
                                throw new Error('colKey must be an array')
                            }

                            fullKeys = multiplyMultiKeys(rowKey, colKey)
                        } else {
                            invalidConfMessage = 'Valitud jaotuste kombinatsioon ei ole lubatud.'
                        }
                    } else if (colIsMulti) {
                        if (rowKey !== null && typeof rowKey !== 'string') {
                            throw new Error('Expected string or null')
                        }

                        if (typeof colKey !== 'object' || !colKey) {
                            throw new Error('Expected array or object')
                        }

                        fullKeys = multiplyKeys(rowKey, colKey, 'row', 'col', colDistType.keyFormat)
                    } else if (rowIsMulti) {
                        if (colKey !== null && typeof colKey !== 'string') {
                            throw new Error('Expected string or null')
                        }

                        if (typeof rowKey !== 'object' || !rowKey) {
                            throw new Error('Expected array or object')
                        }

                        fullKeys = multiplyKeys(colKey, rowKey, 'col', 'row', rowDistType.keyFormat)
                    } else {
                        if (rowKey !== null && typeof rowKey !== 'string') {
                            throw new Error('Expected string or null')
                        }

                        if (colKey !== null && typeof colKey !== 'string') {
                            throw new Error('Expected string or null')
                        }

                        fullKeys = [{ row: rowKey, col: colKey, amount: 1 }]
                    }

                    if (fullKeys) {
                        for (const fullKey of fullKeys) {
                            addByKey(fullKey.row, fullKey.col, fullKey.amount)
                        }
                    }
                }
            },

            addByKey,

            incCustom(rowKey, fieldName) {
                const row = getRow(rowKey)
                const value = Utils.member(row.custom, fieldName, 0)

                if (typeof value === 'number') {
                    row.custom[fieldName] = value + 1
                } else {
                    throw new Error('Mixed custom types at ' + fieldName)
                }
            },

            uniqueCustom(data, fieldName, string) {
                const rowKey = getRowKey(data)

                if (rowKey !== null && typeof rowKey !== 'string') {
                    throw new Error('Expected string or null for ' + fieldName)
                }

                const row = getRow(rowKey)
                let value = row.custom[fieldName]

                if (typeof value === 'number') {
                    throw new Error('Mixed custom types at ' + fieldName)
                } else if (!value) {
                    value = {}
                    row.custom[fieldName] = value
                }

                value[string] = true
            },

            setGlobal(fieldName, value) {
                globalData[fieldName] = value
            },

            initUniqueGlobal(fieldName) {
                Utils.member(globalData, fieldName, {}) // TODO use Set<string>?
            },

            uniqueGlobal(fieldName, value) {
                globalData[fieldName][value] = true
            },

            setInvalid(message) {
                invalidConfMessage = message
            },
        }

        if (this.props.initData) {
            this.props.initData(dataCollector)
        }

        this.props.collectData(dataCollector, colDistType, rowDistType)

        return {
            invalidConfMessage,
            global: globalData,
            byRow: dataByRow,
            unknownRow,
            uniqueColKeys,
            uniqueRowKeys,
            filterKeys,
        }
    }

    hasAnyData(data: StatsData) {
        if (Object.keys(data.byRow).length > 0) {
            return true
        } else if (data.unknownRow.unknown > 0) {
            return true
        } else {
            return Object.keys(data.unknownRow.countByKey).some(
                (key) => data.unknownRow.countByKey[key] > 0,
            )
        }
    }

    getRowData(rawRowData: RowCounts, rowKey: string | null, columnKeys: string[]) {
        let valueFunc: ValueFunc | null = this.props.getValueFunc
            ? this.props.getValueFunc(rawRowData)
            : null

        if (!valueFunc) {
            valueFunc = (value) => value
        }

        const counts: Record<string, number> = {}
        let total = 0

        for (const columnKey of columnKeys) {
            const value = !rawRowData ? 0 : rawRowData.countByKey[columnKey] || 0
            total += value
            counts[columnKey] = valueFunc!(value)
        }

        const unknown = rawRowData ? rawRowData.unknown : 0
        total += unknown

        const rowData: RowData = {
            rowKey,
            counts,
            total: valueFunc(total),
            unknown: valueFunc(unknown),
        }

        if (this.props.addCustomRowData) {
            this.props.addCustomRowData(rowData, rawRowData)
        }

        return rowData
    }

    getUnknownRowData(data: StatsData, columnKeys: string[]) {
        const rowData = this.getRowData(data.unknownRow, null, columnKeys)
        rowData.isUnknown = true
        rowData.firstCellContents = 'Pole teada'
        rowData.className = 'unknown'
        return rowData
    }

    getTotalsRowData(data: StatsData, columnKeys: string[]): RowData {
        const counts: Record<string, number> = {}
        let grand = 0
        let unknown = 0

        for (const columnKey of columnKeys) {
            counts[columnKey] = 0
        }

        const rows = Utils.mapValues(data.byRow)
        rows.push(data.unknownRow)

        for (const rawRowData of rows) {
            for (const columnKey of columnKeys) {
                const value = rawRowData.countByKey[columnKey] || 0
                counts[columnKey] += value
                grand += value
            }

            unknown += rawRowData.unknown
            grand += rawRowData.unknown
        }

        return {
            isTotal: true,
            data,
            counts,
            unknown,
            total: grand,
            className: 'totals',
            firstCellContents: 'Kokku:',
        }
    }

    getPercentage(value: number, total: number) {
        return total === 0 ? 0 : (100 * value) / total
    }

    getPercentageRowData(totals: RowData): RowData {
        const counts: Record<string, number> = {}

        for (const columnKey of Object.keys(totals.counts)) {
            counts[columnKey] = this.getPercentage(totals.counts[columnKey], totals.total!)
        }

        return {
            isPercentage: true,
            counts,
            unknown: this.getPercentage(totals.unknown, totals.total!),
            className: 'percentages',
            firstCellContents: 'Protsentuaalselt:',
        }
    }

    getRowsData(data: StatsData, columnKeys: string[]) {
        const rowKeys = getKeys(this.getRowDistType(), data.uniqueRowKeys)

        return rowKeys.map((rowKey) => {
            const rawRowData = data.byRow[rowKey]

            // rawRowData may be undefined when using a fixed set of row keys
            const rowData = this.getRowData(rawRowData, rowKey, columnKeys)
            rowData.className = 'stats-row'
            return rowData
        })
    }

    anyValueColumns(columnKeys: string[]) {
        const colDistType = this.getColDistType()
        return !colDistType.hideUnknown || columnKeys.length > 0
    }

    async downloadExcelFile() {
        const fileNamePart = this.props.getExcelFileNamePart()
        const fileName = 'Statistika' + (fileNamePart ? ' - ' + fileNamePart : '')

        const rowDistType = this.getRowDistType()
        const colDistType = this.getColDistType()
        const data: StatsData = this.getData()

        const customColumns = this.getCustomColumns()
        const columnKeys = getKeys(this.getColDistType(), data.uniqueColKeys)

        const rowsData = this.getRowsData(data, columnKeys)
        const totalRowData = this.getTotalsRowData(data, columnKeys)

        if (!rowDistType.hideUnknown) {
            const unknownRowData = this.getUnknownRowData(data, columnKeys)
            rowsData.push(unknownRowData)
        }

        if (!rowDistType.hideTotal) {
            rowsData.push(totalRowData)
        }

        if (!this.isPercentageRowHidden()) {
            const percentageRowData = this.getPercentageRowData(totalRowData)
            rowsData.push(percentageRowData)
        }

        const columns: Column<RowData, ExcelValue>[] = []
        const headerCenterStyle: ExcelStyle = {
            ...HEADER_STYLE,
            alignment: { horizontal: 'center' },
        }

        const maybeBold = (row: RowData, value: SimpleExcelValue) => {
            if (row.isTotal || row.isPercentage) {
                return { value, style: { font: ExcelFont.bold } }
            } else {
                return value
            }
        }

        const addCustomColumn = (column: CustomColumn<T>) => {
            columns.push({
                header: { content: column.header },
                getValue: (row) => {
                    let value: SimpleExcelValue

                    if (row.isTotal) {
                        value = column.getTotalsRowContents
                            ? column.getTotalsRowContents(row.data!)
                            : ''
                    } else if (row.isPercentage) {
                        value = ''
                    } else {
                        value = column.getValue(row)
                    }

                    return maybeBold(row, value)
                },
                excelWidth: column.excelWidth,
            })
        }

        let firstColWidth = rowDistType.excelWidth || 20

        if (!this.isPercentageColumnHidden()) {
            firstColWidth = Math.max(firstColWidth, 18)
        }

        columns.push({
            header: { content: rowDistType.name },
            getValue: (row) => {
                const value = row.firstCellContents || getKeyName(row.rowKey!, rowDistType)
                return maybeBold(row, value)
            },
            excelWidth: firstColWidth,
        })

        customColumns.before.forEach(addCustomColumn)

        const valueFirstHeader: ColumnHeader = {
            excelContent: { value: colDistType.name, style: headerCenterStyle },
            span: columnKeys.length + (colDistType.hideUnknown ? 0 : 1),
        }

        const toExcelValue = (value: number, row: RowData): ExcelValue => {
            if (row.isPercentage) {
                return {
                    value: value / 100,
                    style: {
                        font: ExcelFont.bold,
                        numberFormat: ExcelNumberFormat.percentage,
                    },
                }
            } else {
                return maybeBold(row, value)
            }
        }

        columnKeys.forEach((columnKey, index) => {
            const colName = getKeyName(columnKey, colDistType)
            const width = Math.max(7, colName.length * 1.2)

            // TODO: custom style per rowDistType like in table view?
            columns.push({
                header: index > 0 ? undefined : valueFirstHeader,
                secondHeader: {
                    excelContent: { value: colName, style: headerCenterStyle },
                },
                getValue: (row) => toExcelValue(row.counts[columnKey], row),
                excelWidth: width,
            })
        })

        if (!colDistType.hideUnknown) {
            columns.push({
                header: columnKeys.length ? undefined : valueFirstHeader,
                secondHeader: {
                    excelContent: { value: 'Pole teada', style: headerCenterStyle },
                },
                getValue: (row) => toExcelValue(row.unknown, row),
                excelWidth: 12,
            })
        }

        if (!colDistType.hideTotal) {
            columns.push({
                header: { content: 'Kokku' },
                getValue: (row) => maybeBold(row, typeof row.total === 'number' ? row.total : ''),
                excelWidth: 8,
            })
        }

        if (!this.isPercentageColumnHidden()) {
            columns.push({
                header: { content: 'Protsentuaalselt' },
                getValue: (row) => ({
                    value: row.isTotal || row.isPercentage ? '' : row.total! / totalRowData.total!,
                    style: { numberFormat: ExcelNumberFormat.percentage },
                }),
                excelWidth: 16,
            })
        }

        customColumns.after.forEach(addCustomColumn)

        const spec: ExcelSpec<RowData, ExcelValue> = {
            outputName: fileName,
            columns,
            rows: rowsData,
            hasSecondHeader: true,
        }

        await generateExcelFile(spec)
    }

    addFilter() {
        this.setState({
            filters: this.state.filters.concat({ dist: '', value: '' }),
        })
    }

    removeFilter(index: number) {
        const filters = this.state.filters
        filters.splice(index, 1)
        this.setState({ filters })
    }

    isPercentageColumnHidden() {
        return Boolean(!this.props.showPercentages || this.getColDistType().hideTotal)
    }

    isPercentageRowHidden() {
        return Boolean(!this.props.showPercentages || this.getRowDistType().hideTotal)
    }

    renderFilterValue(filter: Filter, data: StatsData) {
        if (filter.dist === '') {
            return <select disabled className="form-control" style={{ width: 200 }} />
        } else {
            const distType = this.props.distTypes[filter.dist]!
            const keys = getKeys(distType, data.filterKeys[filter.dist] || {})

            if (!keys.length && filter.value === '') {
                return '(ühelgi kaardil pole seda välja täidetud)'
            }

            const options: DropdownOption[] = [
                { id: '', label: '(pole filtreeritud)' },
                ...keys.map((key) => ({ id: key, label: getKeyName(key, distType) })),
            ]

            // When changing centre or date filters, the value of this filter can become invalid.
            // If there was no matching option, the first one would be shown which would be incorrect.
            // Therefore we add a greyed out option for the selected value.
            if (filter.value !== '' && keys.indexOf(filter.value) === -1) {
                const label = getKeyName(filter.value, distType)
                options.push({
                    id: filter.value,
                    label,
                    additional: { style: { color: '#ccc' } },
                })
            }

            return renderDropdown({
                options,
                value: filter.value,
                onChange: (value) => {
                    filter.value = value
                    this.forceUpdate()
                },
                additional: { className: 'form-control', style: { minWidth: 200 } },
            })
        }
    }

    renderCurrentFilters(data: StatsData) {
        const filters = this.state.filters

        if (!filters.length) {
            return null
        }

        return (
            <div>
                <div style={{ marginBottom: 5 }}>
                    Statistika on koostatud andmetest, mis vastavad kõigile neile tingimustele:
                </div>
                {filters.map((filter, ix) => {
                    const onRemove = () => this.removeFilter(ix)

                    return (
                        <div key={ix} className="form-inline" style={{ marginBottom: 5 }}>
                            {renderDropdown({
                                options: [
                                    { id: '', label: '' },
                                    ...Object.keys(this.props.distTypes).map((typeId) => {
                                        const distType = this.props.distTypes[typeId]!
                                        return { id: typeId, label: distType.name }
                                    }),
                                ],
                                value: filter.dist,
                                onChange: (value) => {
                                    filter.dist = value
                                    filter.value = ''
                                    this.setState({ filters })
                                },
                                additional: { className: 'form-control' },
                            })}
                            {this.renderFilterValue(filter, data)}{' '}
                            <button onClick={onRemove}>Eemalda filter</button>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderFilterSection(data: StatsData) {
        if (this.props.showFilters) {
            return (
                <div>
                    {this.renderCurrentFilters(data)}
                    <button onClick={() => this.addFilter()}>Lisa filter</button>
                </div>
            )
        } else {
            return null
        }
    }

    renderDropdown(
        id: string,
        stateMember: 'colDistTypeId' | 'rowDistTypeId',
        options: Array<{ value: string; label: string }>,
    ) {
        return renderDropdown({
            options: options.map((option) => ({
                id: option.value,
                label: option.label,
            })),
            value: this.state[stateMember],
            onChange: (value) => {
                const state: Partial<State> = {}
                state[stateMember] = value
                this.setState(state as any) // TODO
            },
            additional: { id, className: 'form-control', style: { width: 250 } },
        })
    }

    renderColDist() {
        const options = Object.keys(this.props.distTypes)
            .filter((typeId) => {
                const distType = this.props.distTypes[typeId]!
                return distType.showIf ? distType.showIf('col') : true
            })
            .map((typeId) => {
                const distType = this.props.distTypes[typeId]!
                return { value: typeId, label: distType.name }
            })

        let choices: ReactNode

        if (this.props.dropdownForCol) {
            choices = this.renderDropdown('col-dist', 'colDistTypeId', options)
        } else {
            choices = renderButtonGroup({
                id: 'col-dist',
                buttons: options,
                active: this.state.colDistTypeId,
                onClick: (value) => this.setState({ colDistTypeId: value }),
                vertical: this.props.verticalColDists,
            })
        }

        return (
            <tr>
                <td>Veergude jaotus:</td>
                <td>{choices}</td>
            </tr>
        )
    }

    renderRowDist() {
        const options = Object.keys(this.props.distTypes)
            .filter((typeId) => {
                const distType = this.props.distTypes[typeId]!
                return distType.showIf ? distType.showIf('row') : true
            })
            .map((typeId) => {
                const distType = this.props.distTypes[typeId]!
                return {
                    className: 'dist-' + typeId,
                    value: typeId,
                    label: distType.name,
                }
            })

        let choices: ReactNode

        if (this.props.dropdownForRow) {
            choices = this.renderDropdown('row-dist', 'rowDistTypeId', options)
        } else {
            choices = renderButtonGroup({
                id: 'row-dist',
                buttons: options,
                active: this.state.rowDistTypeId,
                onClick: (value) => this.setState({ rowDistTypeId: value }),
            })
        }

        return (
            <tr>
                <td>Ridade jaotus:</td>
                <td>{choices}</td>
            </tr>
        )
    }

    renderControls(data: StatsData) {
        const customOptions = this.props.getCustomOptions()

        return (
            <div>
                {this.renderFilterSection(data)}
                <table className="extra-padded">
                    <tbody>
                        {this.renderColDist()}
                        {this.renderRowDist()}
                        {customOptions.map((option) => {
                            let additional: ReactNode = null

                            if (option.renderAdditional) {
                                additional = option.renderAdditional(
                                    this.state.colDistTypeId,
                                    this.state.rowDistTypeId,
                                )
                            }

                            return (
                                <tr key={option.id}>
                                    <td>{option.label}:</td>
                                    <td>
                                        {renderButtonGroup({
                                            buttons: option.buttons,
                                            disabled: option.disabled,
                                            onClick: option.onClick,
                                            active: option.active,
                                        })}
                                        {additional}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderTotalColumnCell(hide: boolean, rowData: RowData) {
        if (hide) {
            return null
        } else {
            const contents = rowData.isPercentage ? '' : Utils.formatDecimal(rowData.total!)
            return <td style={{ textAlign: 'right' }}>{contents}</td>
        }
    }

    renderPercentageColumnCell(hide: boolean, rowData: RowData, grandTotal: number | undefined) {
        if (hide) {
            return null
        }

        let contents = ''

        if (!rowData.isTotal && !rowData.isPercentage) {
            const perc = this.getPercentage(rowData.total!, grandTotal!)
            contents = Utils.formatDecimal(perc) + '%'
        }

        return <td style={{ textAlign: 'right' }}>{contents}</td>
    }

    renderRow(
        rowData: RowData,
        customColumns: CustomColumns<T>,
        columnKeys: string[],
        grandTotal: number | undefined,
    ) {
        const rowDistType = this.getRowDistType()

        if (rowData.isUnknown && rowDistType.hideUnknown) {
            return null
        }

        if (rowData.isTotal && rowDistType.hideTotal) {
            return null
        }

        if (rowData.isPercentage && this.isPercentageRowHidden()) {
            return null
        }

        const colDistType = this.getColDistType()

        const getCustomCell = (column: CustomColumn<T>) => {
            let contents: ReactNode | SimpleExcelValue

            if (rowData.isTotal) {
                contents = column.getTotalsRowContents
                    ? column.getTotalsRowContents(rowData.data!)
                    : ''
            } else if (rowData.isPercentage) {
                contents = ''
            } else {
                contents = column.getTableValue
                    ? column.getTableValue(rowData)
                    : column.getValue(rowData)
            }

            if (isMoment(contents)) {
                throw new Error('Moment cannot be rendered in React')
            }

            return (
                <td key={column.id} style={column.style}>
                    {contents}
                </td>
            )
        }

        const rowProps: Attributes & HTMLAttributes<HTMLTableRowElement> = {
            className: rowData.className,
        }

        if (rowData.rowKey) {
            rowProps.key = rowData.rowKey
        }

        return (
            <tr {...rowProps}>
                <td
                    style={
                        rowDistType.getKeyColumnStyle ? rowDistType.getKeyColumnStyle() : undefined
                    }
                >
                    {rowData.firstCellContents || getKeyName(rowData.rowKey!, rowDistType)}
                </td>
                {customColumns.before.map(getCustomCell)}
                {columnKeys.map((columnKey) => (
                    <td key={columnKey} style={{ textAlign: 'right' }}>
                        {Utils.formatDecimal(rowData.counts[columnKey])}
                        {rowData.isPercentage ? '%' : ''}
                    </td>
                ))}
                {colDistType.hideUnknown ? null : (
                    <td style={{ textAlign: 'right' }}>
                        {Utils.formatDecimal(rowData.unknown)}
                        {rowData.isPercentage ? '%' : ''}
                    </td>
                )}
                {this.renderTotalColumnCell(colDistType.hideTotal || false, rowData)}
                {this.renderPercentageColumnCell(
                    this.isPercentageColumnHidden(),
                    rowData,
                    grandTotal,
                )}
                {customColumns.after.map(getCustomCell)}
            </tr>
        )
    }

    renderTable(data: StatsData) {
        const colDistTypeId = this.state.colDistTypeId

        if (!colDistTypeId) {
            return null
        }

        const rowDistTypeId = this.state.rowDistTypeId

        const colDistType = this.props.distTypes[colDistTypeId]!
        const rowDistType = this.props.distTypes[rowDistTypeId]!
        const columnKeys = getKeys(colDistType, data.uniqueColKeys)
        const customColumns = this.getCustomColumns()

        const rowsData = this.getRowsData(data, columnKeys)
        const unknownRowData = this.getUnknownRowData(data, columnKeys)
        const totalsRowData = this.getTotalsRowData(data, columnKeys)
        const percentageRowData = this.getPercentageRowData(totalsRowData)
        const grandTotal = totalsRowData.total

        const getCustomHeader = (column: CustomColumn<T>) => (
            <th key={column.id} rowSpan={2} style={{ verticalAlign: 'middle' }}>
                {column.header}
            </th>
        )

        const rows = rowsData.map((rowData) => {
            return this.renderRow(rowData, customColumns, columnKeys, grandTotal)
        })

        const colSpan = columnKeys.length + (colDistType.hideUnknown ? 0 : 1)

        return (
            <table id={this.props.id} className="bordered stats" style={{ marginTop: 10 }}>
                <thead>
                    <tr>
                        <th rowSpan={2}>{rowDistType.name}</th>
                        {customColumns.before.map(getCustomHeader)}
                        {!colSpan ? null : (
                            <th colSpan={colSpan} style={{ textAlign: 'center' }}>
                                {colDistType.name}
                            </th>
                        )}
                        {colDistType.hideTotal ? null : (
                            <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                                Kokku
                            </th>
                        )}
                        {this.isPercentageColumnHidden() ? null : (
                            <th rowSpan={2} style={{ verticalAlign: 'middle' }}>
                                Protsentuaalselt
                            </th>
                        )}
                        {customColumns.after.map(getCustomHeader)}
                    </tr>
                    <tr>
                        {columnKeys.map((columnKey) => {
                            const columnName = getKeyName(columnKey, colDistType)
                            return <th key={columnKey}>{columnName}</th>
                        })}
                        {colDistType.hideUnknown ? null : <th>Pole teada</th>}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                    {this.renderRow(unknownRowData, customColumns, columnKeys, grandTotal)}
                    {this.renderRow(totalsRowData, customColumns, columnKeys, grandTotal)}
                    {this.renderRow(percentageRowData, customColumns, columnKeys, grandTotal)}
                </tbody>
            </table>
        )
    }

    renderData(data: StatsData) {
        let message = data.invalidConfMessage
        const messageProps: HTMLAttributes<HTMLDivElement> = {
            style: { margin: '10px 0' },
        }

        if (message) {
            messageProps.style!.color = '#c00'
            messageProps.id = 'invalid-conf-msg'
        } else if (this.hasAnyData(data)) {
            return (
                <div>
                    {this.renderTable(data)}
                    {this.props.getCustomFooter ? this.props.getCustomFooter(data) : null}
                    <p style={{ marginTop: 10 }}>
                        <button onClick={async () => this.downloadExcelFile()}>
                            Lae tabel alla Exceli failina
                        </button>
                    </p>
                </div>
            )
        } else {
            message =
                this.props.isFiltered || this.state.filters.length
                    ? 'Praegustele filtritele vastavaid andmeid ei leidunud.'
                    : 'Andmeid ei leidunud.'
        }

        return <div {...messageProps}>{message}</div>
    }

    render() {
        const data = this.getData()
        return (
            <div>
                {this.renderControls(data)}
                {this.renderData(data)}
            </div>
        )
    }
}

export const renderStatsTable = <T, U>(props: Props<T, U>) => <StatsTable {...props} />
