import { ButtonHTMLAttributes, FC } from 'react'

import { CustomModal, CustomModalProps } from './custom-modal'
import { EditMeetingForm, EditMeetingFormProps } from './edit-meeting-form'
import { Loading } from './loading'

export interface EditMeetingProps {
    button: ButtonHTMLAttributes<HTMLButtonElement>
    modal?: EditMeetingModalProps
}

export interface EditMeetingModalProps {
    outer: CustomModalProps
    form?: EditMeetingFormProps
}

export const EditMeeting: FC<EditMeetingProps> = (props) => (
    <div>
        <button {...props.button} />
        {props.modal && <EditMeetingModal {...props.modal} />}
    </div>
)

const EditMeetingModal: FC<EditMeetingModalProps> = (props) => (
    <CustomModal {...props.outer}>
        {props.form ? <EditMeetingForm {...props.form} /> : <Loading />}
    </CustomModal>
)
