import classnames from 'classnames'
import moment from 'moment'
import { Component, ReactNode } from 'react'

import { GetCentreCards_Card } from '../server/commands/get-centre-cards'
import { EmployeeForCards } from '../server/commands/get-employees-for-cards'
import { VisitorForCards } from '../server/commands/get-visitors-for-cards'
import { CardPhase } from '../util/card-phase'
import { API } from './api'
import { renderButtonGroup } from './button-group'
import { CardFields } from './card-fields'
import { renderDropdown } from './dropdown'
import { Enums } from './enums'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { AppView } from './state'
import { Utils } from './utils'

const rowLimit = 50

const phaseLabels = {
    started2: '2. faas alustatud',
    ended2: '2. faas lõpetatud',
    started3: '3. faas alustatud',
    ended3: '3. faas lõpetatud',
    started4: '4. faas alustatud',
    cancelled: 'Katkestatud',
}

interface CardListProps {
    view: AppView
}

interface State {
    loaded: boolean
    nameFilter: string
    homeFilter: string
    birthYearFilter: string
    phaseFilter: CardPhase | null
    employeeFilter: string
    includeWithResult: boolean
    cards?: Record<string, GetCentreCards_Card>
    visitors?: Record<string, VisitorForCards>
    employees?: Record<string, EmployeeForCards>
    rowLimitDisabled?: boolean
}

interface Wrapped {
    card: GetCentreCards_Card
    visitor: VisitorForCards | null
    name: string
    phase4open: 0 | 1
}

const OPTIONAL_PHASE2_FIELDS = new Set([
    'pohiprogramm',
    'taust-allikas',
    'varasem-taust',
    'tunnus-allikas',
    'eesmark-kirjeldus',
    '4-kuu-jatk',
    '6-kuu-jatk',
])

const phase2Fields = CardFields.getPhase2Fields({ mode: 'all-options' })

const cardHasResult = (card: GetCentreCards_Card) => {
    return card.phase === Enums.cardPhases.started4 && !card.phase4NoResult
}

export class CardList extends Component<CardListProps, State> {
    state: State = {
        loaded: false,
        nameFilter: '',
        homeFilter: '',
        birthYearFilter: '',
        phaseFilter: null,
        employeeFilter: '',
        includeWithResult: false,
    }

    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const response = await API.getCentreCards(view, false)

        // The response may also contain employees who are associated with log entries,
        // but don't manage any cards. Here we only need those who manage cards.
        const employees: Record<string, EmployeeForCards> = {}

        Utils.iterMap(response.cards, (card) => {
            employees[card.employeeId] = response.employees[card.employeeId]
        })

        await Utils.setState(this, {
            loaded: true,
            cards: response.cards,
            visitors: response.visitors,
            employees,
        })

        await EventBus.fire('card-list-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    updateNameFilter(value: string) {
        this.setState({ nameFilter: value })
    }

    updateHomeFilter(value: string) {
        this.setState({ homeFilter: value })
    }

    updateBirthYearFilter(value: string) {
        let strValue = value.trim()
        let numValue = NaN

        if (strValue.length > 4) {
            strValue = strValue.substring(0, 4)
        }

        if (strValue !== '') {
            numValue = Number(strValue)
        }

        if (!isNaN(numValue) || strValue === '') {
            this.setState({ birthYearFilter: strValue })
        }
    }

    updatePhaseFilter(phase: CardPhase | null) {
        this.setState({ phaseFilter: phase })
    }

    updateEmployeeFilter(value: string) {
        this.setState({ employeeFilter: value })
    }

    disableRowLimit() {
        this.setState({ rowLimitDisabled: true })
    }

    isPhase4Open(card: GetCentreCards_Card) {
        if (card.phase !== Enums.cardPhases.ended3) {
            return false
        }

        const phase4date = new Date(card.phase4date!)
        return Utils.getNow() > phase4date
    }

    hasNameFilter() {
        return this.state.nameFilter.trim() !== ''
    }

    hasHomeFilter() {
        return this.state.homeFilter.trim() !== ''
    }

    hasBirthYearFilter() {
        return this.state.birthYearFilter.length === 4
    }

    hasPhaseFilter() {
        return this.state.phaseFilter !== null
    }

    hasEmployeeFilter() {
        return this.state.employeeFilter !== ''
    }

    hasCardWithResult() {
        return Utils.mapSome(this.state.cards!, cardHasResult)
    }

    hasResultFilter() {
        return !this.state.includeWithResult && this.hasCardWithResult()
    }

    hasFilters() {
        return (
            this.hasNameFilter() ||
            this.hasHomeFilter() ||
            this.hasBirthYearFilter() ||
            this.hasPhaseFilter() ||
            this.hasEmployeeFilter() ||
            this.hasResultFilter()
        )
    }

    filterByName(wrapped: Wrapped) {
        return !this.hasNameFilter() || Utils.contains(wrapped.name, this.state.nameFilter)
    }

    filterByHome(wrapped: Wrapped) {
        if (!this.hasHomeFilter()) {
            return true
        }

        const home = wrapped.card.general['elukoht'] || ''
        return Utils.contains(home, this.state.homeFilter)
    }

    filterByBirthYear(wrapped: Wrapped) {
        if (!this.hasBirthYearFilter()) {
            return true
        }

        const card = wrapped.card
        const birthDate = card.unlinked ? card.general['syn'] : wrapped.visitor!.birthDate
        const filterYear = Number(this.state.birthYearFilter)

        if (!birthDate) {
            return false
        }

        if (birthDate.maxAge) {
            const now = Utils.getNow()
            const maxAgeYear = Utils.getMaxAgeYear(now)
            return filterYear <= maxAgeYear
        }

        return birthDate.year === filterYear
    }

    filterByPhase(wrapped: Wrapped) {
        return !this.hasPhaseFilter() || wrapped.card.phase === this.state.phaseFilter
    }

    filterByEmployee(wrapped: Wrapped) {
        return !this.hasEmployeeFilter() || wrapped.card.employeeId === this.state.employeeFilter
    }

    filterByPhase4Result(wrapped: Wrapped) {
        return this.state.includeWithResult || !cardHasResult(wrapped.card)
    }

    filterWrappedCard(wrapped: Wrapped) {
        return (
            this.filterByName(wrapped) &&
            this.filterByHome(wrapped) &&
            this.filterByBirthYear(wrapped) &&
            this.filterByPhase(wrapped) &&
            this.filterByEmployee(wrapped) &&
            this.filterByPhase4Result(wrapped)
        )
    }

    getWrappedCards() {
        const wrappedCards = Utils.mapMap(this.state.cards!, (card): Wrapped => {
            let visitor: VisitorForCards | null
            let visitorName: string

            if (card.unlinked) {
                visitor = null
                visitorName = card.general['nimi'] || '(Anonüümne)'
            } else {
                visitor = this.state.visitors![card.visitorId!]
                visitorName = visitor.firstname + ' ' + visitor.lastname
            }

            const phase4open = this.isPhase4Open(card) ? 0 : 1

            return { card, visitor, name: visitorName, phase4open }
        }).filter((wrapped) => this.filterWrappedCard(wrapped))

        wrappedCards.sort((wrapped1, wrapped2) => {
            let result = wrapped1.phase4open - wrapped2.phase4open

            if (result !== 0) {
                return result
            }

            result = (wrapped1.card.phase4NoResult ? 0 : 1) - (wrapped2.card.phase4NoResult ? 0 : 1)

            if (result !== 0) {
                return result
            }

            result = wrapped1.name.toLowerCase().localeCompare(wrapped2.name.toLowerCase())

            if (result !== 0) {
                return result
            }

            return wrapped1.card._id.toLowerCase().localeCompare(wrapped2.card._id.toLowerCase())
        })

        return wrappedCards
    }

    renderNameFilter() {
        return (
            <tr>
                <td>Nimi:</td>
                <td>
                    <input
                        id="name-filter"
                        value={this.state.nameFilter}
                        onChange={(evt) => this.updateNameFilter(evt.currentTarget.value)}
                    />
                </td>
            </tr>
        )
    }

    renderHomeFilter() {
        return (
            <tr>
                <td>Elukoht:</td>
                <td>
                    <input
                        id="home-filter"
                        value={this.state.homeFilter}
                        onChange={(evt) => this.updateHomeFilter(evt.currentTarget.value)}
                    />
                </td>
            </tr>
        )
    }

    renderBirthYearFilter() {
        return (
            <tr>
                <td>Sünniaasta:</td>
                <td>
                    <input
                        id="birth-year-filter"
                        className={classnames({ bold: this.hasBirthYearFilter() })}
                        size={4}
                        value={this.state.birthYearFilter}
                        onChange={(evt) => this.updateBirthYearFilter(evt.currentTarget.value)}
                    />
                </td>
            </tr>
        )
    }

    renderPhaseFilterButton(phase: CardPhase | null) {
        const label = phase ? Enums.cardPhaseDescriptions[phase] : 'Ära filtreeri'

        return (
            <button
                key={phase || '_'}
                className={classnames('btn btn-default', {
                    active: this.state.phaseFilter === phase,
                })}
                onClick={() => this.updatePhaseFilter(phase)}
            >
                {label}
            </button>
        )
    }

    renderPhaseFilter() {
        return (
            <tr>
                <td>Faas:</td>
                <td>
                    <div id="phase-filter" className="btn-group">
                        {this.renderPhaseFilterButton(null)}
                        {Enums.cardPhases._order.map((phase) =>
                            this.renderPhaseFilterButton(phase),
                        )}
                    </div>
                </td>
            </tr>
        )
    }

    renderEmployeeFilter() {
        const employees = Utils.mapValues(this.state.employees!)

        if (employees.length === 1) {
            return null
        }

        Utils.sortAsStrings(employees, (employee) => employee.name)

        return (
            <tr>
                <td>Vastutav töötaja:</td>
                <td>
                    {renderDropdown({
                        options: [
                            { id: '', label: 'Ära filtreeri' },
                            ...employees.map((employee) => ({
                                id: employee._id,
                                label: employee.name,
                            })),
                        ],
                        value: this.state.employeeFilter,
                        onChange: (value) => this.updateEmployeeFilter(value),
                        additional: {
                            id: 'employee-filter',
                            className: 'form-control',
                        },
                    })}
                </td>
            </tr>
        )
    }

    renderIncludeWithResultFilter() {
        if (!this.hasCardWithResult()) {
            return null
        }

        return (
            <tr>
                <td>Tulemusega kaardid:</td>
                <td>
                    {renderButtonGroup({
                        id: 'result-filter',
                        buttons: [
                            { value: false, label: 'Ära näita' },
                            { value: true, label: 'Näita' },
                        ],
                        active: this.state.includeWithResult,
                        onClick: (value) => this.setState({ includeWithResult: value }),
                    })}
                </td>
            </tr>
        )
    }

    renderFilters() {
        if (Object.keys(this.state.cards!).length) {
            return (
                <div className="bottom-margin">
                    <h2>Otsing</h2>
                    <table className="extra-padded">
                        <tbody>
                            {this.renderNameFilter()}
                            {this.renderHomeFilter()}
                            {this.renderBirthYearFilter()}
                            {this.renderPhaseFilter()}
                            {this.renderEmployeeFilter()}
                            {this.renderIncludeWithResultFilter()}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return null
        }
    }

    renderCardCounts() {
        const numCards = Object.keys(this.state.cards!).length

        if (!numCards) {
            return null
        }

        let wrappedCount: ReactNode = null

        if (this.hasFilters()) {
            const wrappedCards = this.getWrappedCards()

            wrappedCount = (
                <div id="filtered-card-count">
                    {'Filtrile vastavaid juhtumikaarte: '}
                    {wrappedCards.length}
                </div>
            )
        }

        return (
            <div>
                <div id="total-card-count">
                    {'Juhtumikaarte kokku: '}
                    {numCards}
                </div>
                {wrappedCount}
            </div>
        )
    }

    cardPhase2FieldsAreFilled(card: GetCentreCards_Card) {
        return phase2Fields.every((field) => {
            return OPTIONAL_PHASE2_FIELDS.has(field.id) || field.id in card.phase2
        })
    }

    renderStatusWithWarning(text: string, warning: string) {
        return (
            <span>
                {text} <span style={{ color: '#c00' }}>{'(' + warning + ')'}</span>
            </span>
        )
    }

    renderStatus(card: GetCentreCards_Card) {
        const phase = card.phase
        const lastActionDate = new Date(card.log[card.log.length - 1].time)
        const text = phaseLabels[phase] + ' ' + Utils.formatDate(lastActionDate)

        if (!this.cardPhase2FieldsAreFilled(card)) {
            return this.renderStatusWithWarning(text, '2. faasis on täitmata välju')
        }

        if (phase === Enums.cardPhases.ended3) {
            const phase4date = new Date(card.phase4date!)
            const elapsed = Utils.getNow() > phase4date
            const verb = elapsed ? 'avanes' : 'avaneb'

            const end3Text =
                phaseLabels[phase] + ', 4. faas ' + verb + ' ' + Utils.formatDate(phase4date)

            return elapsed ? <span style={{ color: '#c00' }}>{end3Text}</span> : end3Text
        } else {
            if (
                phase === Enums.cardPhases.started2 ||
                phase === Enums.cardPhases.ended2 ||
                phase === Enums.cardPhases.started3
            ) {
                const creationTime = moment(card.log[0].time)
                const now = moment()
                const monthsPassed = now.diff(creationTime, 'months')

                if (monthsPassed >= 4) {
                    const cont4 = card.phase2['4-kuu-jatk']

                    if (!cont4 || cont4.choices.length === 0) {
                        return this.renderStatusWithWarning(
                            text,
                            '4 kuu jätkamise vajadus sisestamata',
                        )
                    }

                    const cont6 = card.phase2['6-kuu-jatk']

                    if (monthsPassed >= 6 && (!cont6 || cont6.choices.length === 0)) {
                        return this.renderStatusWithWarning(
                            text,
                            '6 kuu jätkamise vajadus sisestamata',
                        )
                    }
                }
            }

            if (card.phase4NoResult) {
                return this.renderStatusWithWarning(text, 'tulemus märkimata')
            }

            return text
        }
    }

    renderEmployee(card: GetCentreCards_Card) {
        const { view } = this.props
        const employeeId = card.employeeId
        const employee = this.state.employees![employeeId]

        if (employee) {
            return employee.name
        } else {
            API.logWarning(view, 'Employee not found: ' + employeeId, false)
            return employeeId
        }
    }

    renderRowLimitInfo(exceedsLimit: boolean) {
        if (exceedsLimit) {
            return (
                <div style={{ marginTop: 10 }}>
                    {'Tabelis on näidatud ainult ' + rowLimit + ' juhtumikaarti. '}
                    <button onClick={() => this.disableRowLimit()}>
                        Näita kõiki juhtumikaarte
                    </button>
                </div>
            )
        } else {
            return null
        }
    }

    renderTable(wrappedCards: Wrapped[]) {
        const numCards = Object.keys(this.state.cards!).length

        if (numCards === 0) {
            return (
                <div id="lbl-no-cards" style={{ marginTop: 10 }}>
                    Selles keskuses pole veel ühtegi juhtumikaarti loodud.
                </div>
            )
        }

        if (wrappedCards.length === 0) {
            if (!this.hasFilters()) {
                return (
                    <div id="lbl-no-cards" style={{ marginTop: 10 }}>
                        Selles keskuses pole veel ühtegi juhtumikaarti loodud.
                    </div>
                )
            }

            // renderCardCounts will render necessary information
            return null
        }

        return (
            <table id="tbl-cards" className="bordered" style={{ marginTop: 10 }}>
                <thead>
                    <tr>
                        <th>Nimi</th>
                        <th>Elukoht</th>
                        <th>Sünnikuupäev</th>
                        <th>Seisund</th>
                        <th>Vastutaja</th>
                    </tr>
                </thead>
                <tbody>
                    {wrappedCards.map((wrapped) => {
                        const card = wrapped.card
                        const birthDate = card.unlinked
                            ? card.general['syn']!
                            : wrapped.visitor!.birthDate!

                        return (
                            <tr key={card._id}>
                                <td>
                                    <a href={'#/cards/view/' + card._id}>
                                        {Utils.highlight(wrapped.name, this.state.nameFilter)}
                                    </a>
                                </td>
                                <td>
                                    {Utils.highlight(
                                        card.general['elukoht'] || '',
                                        this.state.homeFilter,
                                    )}
                                </td>
                                <td>{Utils.formatPartialDate(birthDate)}</td>
                                <td>{this.renderStatus(card)}</td>
                                <td>{this.renderEmployee(card)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        let wrappedCards = this.getWrappedCards()

        const exceedsLimit = !this.state.rowLimitDisabled && wrappedCards.length > rowLimit

        if (exceedsLimit) {
            wrappedCards = wrappedCards.slice(0, rowLimit)
        }

        return (
            <div className="main-panel">
                <div className="bottom-margin">
                    <button id="btn-new-card" onClick={() => view.navigate(['cards', 'new'])}>
                        Loo uus juhtumikaart
                    </button>
                </div>
                {this.renderFilters()}
                {this.renderCardCounts()}
                {this.renderTable(wrappedCards)}
                {this.renderRowLimitInfo(exceedsLimit)}
            </div>
        )
    }
}
