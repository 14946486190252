import { FC } from 'react'

import { CardsMenu } from './cards-menu'
import { AppView } from './state'

export interface CardsAdminProps {
    view: AppView // TODO refactor props
}

export const CardsAdmin: FC<CardsAdminProps> = ({ view }) => <CardsMenu view={view} page="" />
