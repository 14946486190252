import { useEffect, useState } from 'react'

import { EventDurationStatsData } from '../../server/commands/get-event-duration-stats-data'
import { EventCategory } from '../../server/types'
import { API } from '../api'
import { AppView } from '../state'

type DurationStatsResult = [EventDurationStatsData | undefined, () => Promise<void>]

type EventCategoriesResult = [EventCategory[] | undefined, () => Promise<void>]

export const useEventDurationStatsData = (view: AppView): DurationStatsResult => {
    const [data, setData] = useState<EventDurationStatsData>()

    const load = async () => {
        const statsData = await API.getEventDurationStatsData(view, null)
        setData(statsData)
    }

    useEffect(() => {
        load()
    }, [])

    return [data, load]
}

export const useCentreEventCategories = (view: AppView): EventCategoriesResult => {
    const [cats, setCats] = useState<EventCategory[]>()

    const load = async () => {
        const evtCats = await API.getCentreEventCategories(view)
        setCats(evtCats)
    }

    useEffect(() => {
        load()
    }, [])

    return [cats, load]
}
