import { FC, Fragment, useEffect } from 'react'

import { API } from './api'
import { CardsMenu } from './cards-menu'
import { handlePromiseRejection } from './common-conf'
import { DropdownOption, renderDropdown } from './dropdown'
import { getInitialCardsEmployeeUpdateState } from './initial-state'
import { LoadingIcon } from './loading-icon'
import { AppView } from './state'
import { Utils } from './utils'

interface CardsEmployeeUpdateProps {
    view: AppView // TODO refactor props
}

export const CardsEmployeeUpdate: FC<CardsEmployeeUpdateProps> = (props) => {
    const { view } = props
    const { state } = view

    useEffect(() => {
        API.getAllCards(view)
            .then(({ centres, cards }) => {
                // TODO check if mounted
                state.cardsEmployeeUpdate.centres = centres
                state.cardsEmployeeUpdate.cards = cards
                view.update()
            })
            .catch((err) => handlePromiseRejection(view, err))

        return () => {
            state.cardsEmployeeUpdate = getInitialCardsEmployeeUpdateState()
            view.update()
        }
    }, [])

    const updateCentreId = async (centreId: string) => {
        const employees = centreId ? await API.getCentreEmployees(view, centreId) : null

        const { cardsEmployeeUpdate } = state
        cardsEmployeeUpdate.employees = employees
        cardsEmployeeUpdate.centreId = centreId
        cardsEmployeeUpdate.origEmployeeId = ''
        cardsEmployeeUpdate.destEmployeeId = ''
        cardsEmployeeUpdate.status = 'notStarted'
        view.update()
    }

    const renderCentreSelect = () => {
        const { centres, centreId } = state.cardsEmployeeUpdate
        const options = Utils.mapMap(centres!, ({ _id, name }) => ({
            id: _id,
            label: name,
        }))
        options.unshift({ id: '', label: 'Vali keskus' })

        return renderDropdown({
            options,
            value: centreId,
            onChange: async (value) => updateCentreId(value),
            additional: { className: 'form-control bottom-margin' },
        })
    }

    const renderOrigEmployeeSelect = () => {
        const { employees, cards, centreId, origEmployeeId, destEmployeeId } =
            state.cardsEmployeeUpdate

        const employeesWithCards = new Set<string>()

        Utils.iterMap(cards!, (card) => {
            if (card.centreId === centreId) {
                employeesWithCards.add(card.employeeId)
            }
        })

        const options = Utils.filterMap(employees!, ({ _id }) => employeesWithCards.has(_id)).map(
            ({ _id, name }) => {
                const option: DropdownOption = { id: _id, label: name }

                if (_id === destEmployeeId) {
                    option.additional = { disabled: true }
                }

                return option
            },
        )

        options.unshift({ id: '', label: 'Algne vastutaja' })

        return renderDropdown({
            options,
            value: origEmployeeId,
            onChange: (value) => {
                state.cardsEmployeeUpdate.origEmployeeId = value
                view.update()
            },
            additional: { className: 'form-control bottom-margin' },
        })
    }

    const renderDestEmployeeSelect = () => {
        const { employees, centreId, origEmployeeId, destEmployeeId } = state.cardsEmployeeUpdate

        const options = Utils.filterMap(employees!, ({ centrePermissions }) => {
            return centrePermissions[centreId] && centrePermissions[centreId].cards
        }).map(({ _id, name }) => {
            const option: DropdownOption = { id: _id, label: name }

            if (_id === origEmployeeId) {
                option.additional = { disabled: true }
            }

            return option
        })

        options.unshift({ id: '', label: 'Uus vastutaja' })

        return renderDropdown({
            options,
            value: destEmployeeId,
            onChange: (value) => {
                state.cardsEmployeeUpdate.destEmployeeId = value
                view.update()
            },
            additional: { className: 'form-control bottom-margin' },
        })
    }

    const renderEmployeeSelects = () => {
        const { centreId, employees } = state.cardsEmployeeUpdate

        if (!centreId) {
            return null
        }

        if (!employees) {
            return <LoadingIcon />
        }

        return (
            <Fragment>
                {renderOrigEmployeeSelect()}
                {renderDestEmployeeSelect()}
            </Fragment>
        )
    }

    const getCardsToUpdate = () => {
        const { origEmployeeId, cards, centreId } = state.cardsEmployeeUpdate

        return Utils.filterMap(cards!, (card) => {
            return card.centreId === centreId && card.employeeId === origEmployeeId
        })
    }

    const renderNumCards = () => {
        const { origEmployeeId } = state.cardsEmployeeUpdate

        if (!origEmployeeId) {
            return null
        }

        const numCards = getCardsToUpdate().length
        return (
            <div className="bottom-margin">
                {'Ülekantavaid kaarte: '}
                {numCards}
            </div>
        )
    }

    const updateCards = async () => {
        const { cardsEmployeeUpdate } = state
        cardsEmployeeUpdate.status = 'processing'
        view.update()

        const cardsToUpdate = getCardsToUpdate()

        try {
            for (const card of cardsToUpdate) {
                await API.updateCardEmployee(view, card._id, cardsEmployeeUpdate.destEmployeeId)
            }
        } catch (error) {
            cardsEmployeeUpdate.status = 'error'
            view.update()
            throw error
        }

        const { centres, cards } = await API.getAllCards(view)
        cardsEmployeeUpdate.centres = centres
        cardsEmployeeUpdate.cards = cards
        cardsEmployeeUpdate.status = 'success'
        cardsEmployeeUpdate.centreId = ''
        cardsEmployeeUpdate.origEmployeeId = ''
        cardsEmployeeUpdate.destEmployeeId = ''
        view.update()
    }

    const renderUpdateButton = () => {
        const { origEmployeeId, destEmployeeId } = state.cardsEmployeeUpdate

        if (!origEmployeeId || !destEmployeeId) {
            return null
        }

        return <button onClick={async () => updateCards()}>Muuda kaartide vastutajat</button>
    }

    const renderMessage = () => {
        const { status } = state.cardsEmployeeUpdate

        if (status === 'success') {
            return <div className="alert alert-success">Kaartide vastutaja muudetud.</div>
        }

        if (status === 'error') {
            return (
                <div className="alert alert-danger">
                    Kaartide vastutaja muutmisel esinesid probleemid.
                </div>
            )
        }

        return null
    }

    const renderMain = () => {
        const { centres, cards, status } = state.cardsEmployeeUpdate

        if (!centres || !cards || status === 'processing') {
            return <LoadingIcon />
        }

        return (
            <Fragment>
                {renderMessage()}
                {renderCentreSelect()}
                {renderEmployeeSelects()}
                {renderNumCards()}
                {renderUpdateButton()}
            </Fragment>
        )
    }

    return (
        <div>
            <CardsMenu view={view} page="employee-update" />
            <div className="main-panel">{renderMain()}</div>
        </div>
    )
}
