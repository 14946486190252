import { Component, ReactNode } from 'react'
import toastr from 'toastr'

import { API } from './api'
import { bindProps } from './bind-props'
import { AppView } from './state'
import { Utils } from './utils'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

interface EmployeesProps {
    view: AppView // TODO refactor props
}

interface State {
    username: string
    password: string
    validationErrors?: Record<string, ValidationError>
}

export class Employees extends Component<EmployeesProps, State> {
    state: State = { username: '', password: '' }
    unmounted = false

    componentWillUnmount() {
        this.unmounted = true
    }

    async submit() {
        const { view } = this.props
        await clearValidationErrors(view, this)

        try {
            await API.updatePasswordByUsername(
                view,
                this.state.username.trim(),
                this.state.password.trim(),
            )
            toastr.info('Parool muudetud')
            await Utils.setState(this, { username: '', password: '' })
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    render(): ReactNode {
        return (
            <div className="main-panel">
                Siit lehelt on võimalik muuta suvalise töötaja parooli juhuks kui kellelgi
                {' see ära kaob ning ükski keskus tema parooli muuta ei saa.'}
                <table style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
                    <tbody>
                        <tr>
                            <td>
                                <b>Kasutajanimi</b>
                            </td>
                            <td>
                                <input {...bindProps(this, 'username', { type: 'text' })} />
                                {renderValidationError(this.state.validationErrors, 'username', {
                                    invalid: 'Kasutajat ei leitud',
                                })}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Uus parool</b>
                            </td>
                            <td>
                                <input {...bindProps(this, 'password', { type: 'password' })} />
                                {renderValidationError(this.state.validationErrors, 'newPassword')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: 5 }}>
                    <button onClick={async () => this.submit()}>Salvesta</button>
                </div>
            </div>
        )
    }
}
