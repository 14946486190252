import { FC } from 'react'

import { renderButtonGroup } from './button-group'
import { AppView } from './state'

interface Props {
    view: AppView
    page: string
}

export const CardsMenu: FC<Props> = ({ view, page }) => (
    <div style={{ border: '1px solid #ddd', borderTop: 0, padding: 8 }}>
        {renderButtonGroup({
            buttons: [
                { value: 'transfer', label: 'Üle tõstmine' },
                { value: 'employee-update', label: 'Kaartide vastutaja muutmine' },
                { value: 'summary', label: 'Kokkuvõte' },
                { value: 'esf-report', label: 'ESF aruande andmed' },
            ],
            onClick: (value) => view.navigate(['cards', value]),
            active: page,
        })}
    </div>
)
