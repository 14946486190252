import { AppView, MountedElement } from '../state'
import { AlreadyHandledError } from '../utils'

interface InitParams {
    isMounted: () => boolean
    setCleanup: (cleanup: () => void) => void
}

export const initIfNeeded = (
    view: AppView,
    key: string,
    init: (params: InitParams) => Promise<void>,
) => {
    // The cleanup is executed after the next getRootProps where this element is not marked active
    view.activeElementKeys.add(key)

    const isMounted = () => view.mountedElements.has(key)

    if (!isMounted()) {
        const element: MountedElement = {}
        view.mountedElements.set(key, element)

        const params: InitParams = {
            isMounted,
            setCleanup: (cleanup) => (element.cleanup = cleanup),
        }

        // This element was not marked active in the previous getRootProps,
        // so we should initialize it
        init(params).catch((error) => {
            if ((error as AlreadyHandledError).alreadyHandled) {
                return
            }

            console.error(`init ${key} failed:`, error)
        })
    }
}
