import * as Sentry from '@sentry/react'

import { API } from './api'
import { configureErrorHandling } from './common-conf'
import { renderReloadRecommendation } from './reload-recommendation'
import { getDefaultRequestHandler } from './request-handler'
import { initAppView } from './view'

window.addEventListener('load', async () => {
    const view = initAppView()
    API.setRequestHandler(getDefaultRequestHandler(view))
    configureErrorHandling(view)

    const response = await API.init(view) // TODO load response into UiState?
    const { sentryConf } = response

    if (sentryConf.dsn) {
        // By default, Sentry replaces all non-space characters with asterisks.
        // For example: 'This is sensitive data' -> '**** ** ********* ****'.
        // To make troubleshooting easier while still preserving enough privacy, we keep the
        // first character of each alphanumeric sequence and replace the rest with underscores.
        // For example: 'This is sensitive data' -> 'T___ i_ s________ d___'.
        const maskText = (text: string): string => {
            return text.replaceAll(
                /[A-ZÕÄÖÜŠŽa-zõäöüšž0-9]+/g,
                (match) => match[0] + '_'.repeat(match.length - 1),
            )
        }

        Sentry.init({
            dsn: sentryConf.dsn,
            integrations: [
                new Sentry.GlobalHandlers({
                    onunhandledrejection: false,
                }),
                new Sentry.BrowserTracing(),
                new Sentry.Replay({ maskFn: maskText }),
            ],
            tracesSampleRate: sentryConf.tracesRate,
            replaysSessionSampleRate: sentryConf.replayRate,
            replaysOnErrorSampleRate: 1.0,
            ignoreErrors: [
                // Connectivity issues
                'Failed to fetch',
                'Load failed',
                'NetworkError when attempting to fetch resource',

                // Page closed while operation in progress?
                'The operation was aborted',

                // Browser extensions breaking React
                'The node to be removed is not a child of this node',
                'The node before which the new node is to be inserted is not a child of this node',

                // Other browser extensions
                'Cannot redefine property: googletag',
            ],
        })
    }

    view.update()

    renderReloadRecommendation()
})

// Avoid import-is-undefined error from sentryProxyModule
export default undefined
