import { Component } from 'react'

import { CollabStatsCentre } from '../server/commands/get-collaborator-stats-data'
import { Field } from '../server/types'
import { centreFormFields } from '../util/centre-form-fields'
import { API } from './api'
import { CentreDistTypes, getCentreDistTypes } from './centre-dist-types'
import { DistType } from './dist-type'
import { Loading } from './loading'
import { AppView } from './state'
import { CustomOption, DataCollector, renderStatsTable } from './stats-table'
import { Utils } from './utils'

interface Data {
    centre: CollabStatsCentre
}

export interface CollaboratorStatsProps {
    view: AppView // TODO refactor props
    dateFrom?: string
    dateTo?: string
    includeArchived: boolean
    centreIds: string[]
    centreSelectionDesc: string
}

interface State {
    loaded: boolean
    program: string
    collabNames?: Record<string, string>
    programField?: Field
    centres: Record<string, CollabStatsCentre> | null
}

const getDistTypes = (
    collabNames: Record<string, string>,
    centres: Record<string, CollabStatsCentre>,
): CentreDistTypes<Data> => {
    const distTypes = getCentreDistTypes<Data>(centres, false)

    distTypes.collaborator = {
        name: 'Koostööpartner',
        isCollab: true,
        keyFormat: 'map',
        getKey: (data) => data.centre.collaborators || {},
        getKeyName: (collaborator) => collabNames[collaborator],
        excelWidth: 18,
        hideUnknown: true,
        hideTotal: true,
    }

    return distTypes
}

export class CollaboratorStats extends Component<CollaboratorStatsProps, State> {
    state: State = { loaded: false, program: '_', centres: null }
    unmounted = false

    async componentDidMount() {
        const centres = await this.getStatsDataPromise()
        const collabField = Utils.findById(centreFormFields, 'koostoo-vorgustik')!
        const programField = Utils.findById(centreFormFields, 'seotud-programmid')!

        const collabNames: Record<string, string> = {}

        for (const key of collabField.keys!) {
            collabNames[key.id] = key.label!
        }

        this.setState({ loaded: true, collabNames, programField, centres })
    }

    async componentDidUpdate(prevProps: CollaboratorStatsProps, prevState: State) {
        if (
            this.props.dateFrom !== prevProps.dateFrom ||
            this.props.dateTo !== prevProps.dateTo ||
            this.props.includeArchived !== prevProps.includeArchived ||
            !Utils.arraysEqual(this.props.centreIds, prevProps.centreIds) ||
            this.state.program !== prevState.program
        ) {
            await this.reload()
        }
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async getStatsDataPromise() {
        const { view } = this.props
        return API.getCollaboratorStatsData(
            view,
            this.props.centreIds,
            this.props.dateFrom,
            this.props.dateTo,
            this.props.includeArchived,
            this.state.program === '_' ? null : this.state.program,
        )
    }

    async reload() {
        await Utils.setState(this, { loaded: false, centres: null })
        const centres = await this.getStatsDataPromise()
        this.setState({ loaded: true, centres })
    }

    collectData(
        dataCollector: DataCollector<Data>,
        colDistType: DistType<Data>,
        rowDistType: DistType<Data>,
    ) {
        if (!colDistType.isCollab && !rowDistType.isCollab) {
            dataCollector.setInvalid('Üheks jaotuseks tuleb valida Koostööpartner.')
            return
        }

        Utils.iterMap(this.state.centres!, (centre) => dataCollector.process({ centre }))
    }

    getCustomOptions(): CustomOption<string>[] {
        const programButtons = [{ value: '_', label: 'Ära filtreeri' }]

        // TODO: hide filter if no programs selected or no centre form submitted?
        // (When adding advanced features, unduplicate with event-stats)
        for (const key of this.state.programField!.keys!) {
            if (!key.deprecated) {
                programButtons.push({ value: key.id, label: key.label! })
            }
        }

        return [
            {
                id: 'program',
                label: 'Programm',
                buttons: programButtons,
                active: this.state.program,
                onClick: (value) => this.setState({ program: value }),
            },
        ]
    }

    getCustomFooter() {
        return (
            <div style={{ marginTop: 10 }}>
                Numbrid tabelis näitavad kohtumiste arvu, kus vastav koostööpartner on ära märgitud.
            </div>
        )
    }

    getExcelFileNamePart() {
        return this.props.centreSelectionDesc
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div className="main-panel">
                {renderStatsTable({
                    distTypes: {
                        ...getDistTypes(this.state.collabNames!, this.state.centres!),
                    },
                    initialColDist: 'collaborator',
                    initialRowDist: 'county',
                    collectData: (dataCollector, colDistType, rowDistType) => {
                        this.collectData(dataCollector, colDistType, rowDistType)
                    },
                    getCustomOptions: () => this.getCustomOptions(),
                    getCustomFooter: () => this.getCustomFooter(),
                    getExcelFileNamePart: () => this.getExcelFileNamePart(),
                })}
            </div>
        )
    }
}
