import moment from 'moment'
import { Component } from 'react'

import { GetAllCards_Card } from '../server/commands/get-all-cards'
import { VisitorForCards } from '../server/commands/get-visitors-for-cards'
import { BirthDate, Centre, ConditionalValue, Field, MultiCheckboxValue } from '../server/types'
import { API } from './api'
import { CardFields } from './card-fields'
import { DateField } from './date-field'
import { Enums } from './enums'
import { expandValue, renderView } from './form'
import { Loading } from './loading'
import { Column, getTableProps } from './props/table'
import { AppView } from './state'
import { Table } from './table'
import { Utils } from './utils'

export interface CardResultsProps {
    view: AppView // TODO refactor props
    centreIds: string[]
}

interface State {
    loaded: boolean
    cards?: GetAllCards_Card[]
    maxDate: string
    centres?: Record<string, Centre>
    visitors?: Record<string, VisitorForCards>
    phase4Field?: Field
}

interface Row {
    id: string
    location?: string
    result?: ConditionalValue<MultiCheckboxValue>
    gender?: string
    birthDate?: BirthDate
}

export class CardResults extends Component<CardResultsProps, State> {
    state: State = {
        loaded: false,
        maxDate: moment().format('YYYY-MM-DD'),
    }

    async componentDidMount() {
        const { view } = this.props
        const response = await API.getAllCards(view)

        // TODO: could optimize by filtering this on server side
        const cards = Utils.filterMap(response.cards, (card) => {
            return card.phase === Enums.cardPhases.started4
        })

        const phase4Fields = CardFields.getPhase4Fields()

        this.setState({
            loaded: true,
            cards,
            centres: response.centres,
            visitors: response.visitors,
            phase4Field: Utils.findById(phase4Fields, 'faas4')!,
        })
    }

    getColumns(): Column<Row>[] {
        return [
            {
                id: 'age',
                header: 'Vanus',
                getContents: (rowData) => Utils.getAge(rowData.birthDate!),
            },
            { id: 'gender', header: 'Sugu' },
            { id: 'location', header: 'Elukoht' },
            {
                id: 'result',
                header: 'Tulemus',
                getContents: (rowData) => {
                    const field = this.state.phase4Field!
                    const expanded = expandValue(field, rowData.result)
                    return renderView(field, expanded)
                },
            },
        ]
    }

    getRows() {
        const maxDate = moment(this.state.maxDate).endOf('day')
        const rows: Row[] = []

        for (const card of this.state.cards!) {
            if (!Utils.arrayContains(this.props.centreIds, card.centreId)) {
                continue
            }

            let phase4Start: string | undefined

            for (const entry of card.log) {
                if (entry.action === 'start4') {
                    phase4Start = entry.time
                }
            }

            if (!phase4Start) {
                throw new Error('Phase 4 start time not found in log')
            }

            if (moment(phase4Start).isAfter(maxDate)) {
                continue
            }

            const row: Row = {
                id: card._id,
                location: card.general.elukoht,
                result: card.phase4 ? card.phase4.faas4 : undefined,
            }

            if (card.unlinked) {
                const { sugu, syn } = card.general
                row.gender = sugu ? Enums.genderOptions[sugu!.key] : ''
                row.birthDate = syn
            } else {
                const visitor = this.state.visitors![card.visitorId!]
                row.gender = visitor.gender
                row.birthDate = visitor.birthDate
            }

            rows.push(row)
        }

        return rows
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const rows = this.getRows()

        return (
            <div className="main-panel">
                <div className="form-inline" style={{ marginBottom: 5 }}>
                    {'Juhtumikaardid mis olid 4. faasi jõudnud kuupäevaks '}
                    <DateField
                        value={this.state.maxDate}
                        onChange={(value) => this.setState({ maxDate: value })}
                    />
                </div>
                <Table
                    {...getTableProps({
                        className: 'bordered',
                        columns: this.getColumns(),
                        rows,
                    })}
                />
                <div style={{ marginTop: 5 }}>
                    {'Kaarte kokku: '}
                    {rows.length}
                </div>
            </div>
        )
    }
}
