import classnames from 'classnames'

import type { Centre, County } from '../../server/types'
import { keys } from '../../util/keys'
import { API } from '../api'
import type { DropdownProps } from '../dropdown'
import { Enums } from '../enums'
import { EventBus } from '../event-bus'
import { type RegionId, Regions } from '../regions'
import { Session } from '../session'
import type { AppView, StatsState } from '../state'
import type { CentreChoiceProps, StatsProps } from '../stats'
import { Utils } from '../utils'
import { initIfNeeded } from './init-if-needed'
import { getVisitorStatsProps } from './visitor-stats'

export const getStatsProps = (view: AppView, type: string, param?: string): StatsProps => {
    const { state, update } = view
    const { stats } = state

    initIfNeeded(view, 'stats', async ({ setCleanup }) => {
        setCleanup(() => {
            stats.loaded = false
        })

        // TODO more generic solution?
        if (!state.session.loggedIn) {
            view.navigate(['login'])
            return
        }

        if (!Session.isCentreSelected(view)) {
            stats.centres = await API.getCentres(view)
        }

        stats.loaded = true
        update()

        await EventBus.fire('stats-rendered')
    })

    const props: StatsProps = {
        isLoading: !stats.loaded,
    }

    if (props.isLoading) {
        return props
    }

    if (!Session.isCentreSelected(view) && type) {
        props.centreChoice = getCentreChoiceProps(view)
    }

    const isMissingCentreChoice = () => {
        return !Session.isCentreSelected(view) && !stats.centreSelectionType
    }

    if (type && !isMissingCentreChoice() && type !== 'centre-forms' && type !== 'feedback') {
        if (type !== 'card-results') {
            props.dateFilter = {
                from: {
                    value: stats.dateFrom,
                    onChange: (value) => {
                        stats.dateFrom = value
                        update()
                    },
                },
                to: {
                    value: stats.dateTo,
                    onChange: (value) => {
                        stats.dateTo = value
                        update()
                    },
                },
            }
        }

        if (type !== 'new-visitors' && type !== 'cards' && type !== 'card-results') {
            props.archivedFilter = {
                checkbox: {
                    checked: stats.includeArchived,
                    onChange: (checked) => {
                        stats.includeArchived = checked
                        update()
                    },
                },
                label: 'Arvesta sisse arhiveeritud sündmused',
            }
        }
    }

    if (!isMissingCentreChoice()) {
        const dateIsValid = (dateString: string) => {
            return dateString === '' || !isNaN(Date.parse(dateString))
        }

        const datesAreValid = () => {
            return dateIsValid(stats.dateFrom) && dateIsValid(stats.dateTo)
        }

        if (!datesAreValid()) {
            props.dateError = 'Kuupäevade vahemik pole korrektne.'
        } else if (type === 'event-visits') {
            props.eventStats = {
                view,
                dateFrom: stats.dateFrom,
                dateTo: stats.dateTo,
                includeArchived: stats.includeArchived,
                centreIds: getSelectedCentreIds(view),
                allCentres: stats.centres,
            }
        } else if (type === 'event-categories') {
            props.eventCategoryStats = {
                view,
                dateFrom: stats.dateFrom,
                dateTo: stats.dateTo,
                includeArchived: stats.includeArchived,
            }
        } else if (type === 'event-durations') {
            props.eventDurationStats = {
                view,
                dateFrom: stats.dateFrom,
                dateTo: stats.dateTo,
                includeArchived: stats.includeArchived,
                centreIds: getSelectedCentreIds(view),
                allCentres: stats.centres,
            }
        } else if (type === 'cards') {
            props.cardStats = {
                view,
                dateFrom: stats.dateFrom,
                dateTo: stats.dateTo,
                multiCentre: !Session.isCentreSelected(view),
                centreIds: getSelectedCentreIds(view),
                centreSelectionDesc: getCentreSelectionDesc(view) ?? undefined, // TODO
            }
        } else if (type === 'visitor-activity') {
            props.visitorStats = getVisitorStatsProps(view, param)
        } else if (type === 'new-visitors') {
            props.newVisitorStats = {
                view,
                dateFrom: stats.dateFrom,
                dateTo: stats.dateTo,
            }
        } else if (type === 'centre-forms') {
            props.centreStats = {
                view,
                centreIds: getSelectedCentreIds(view)!,
                centreSelectionDesc: getCentreSelectionDesc(view)!,
            }
        } else if (type === 'card-results') {
            props.cardResults = {
                view,
                centreIds: getSelectedCentreIds(view)!,
            }
        } else if (type === 'collaborators') {
            props.collaboratorStats = {
                view,
                dateFrom: stats.dateFrom,
                dateTo: stats.dateTo,
                includeArchived: stats.includeArchived,
                centreIds: getSelectedCentreIds(view)!,
                centreSelectionDesc: getCentreSelectionDesc(view)!,
            }
        } else if (type === 'feedback') {
            props.feedbackStats = {
                view,
                centreIds: getSelectedCentreIds(view)!,
            }
        }
    }

    return props
}

const getCentreChoiceProps = (view: AppView): CentreChoiceProps => {
    const { state, update } = view
    const { stats } = state

    return {
        centre: getCentreSelectProps(view),
        county: getCountySelectProps(view),
        region: getRegionSelectProps(view),
        partition: getPartitionSelectProps(view),
        all: {
            buttons: [{ id: 'all-centres', value: 'all', label: 'Kogu Eesti' }],
            onClick: () => {
                stats.centreSelectionType = 'all'
                update()
            },
            active: stats.centreSelectionType === 'all' ? 'all' : undefined,
        },
    }
}

const getCentreSelectProps = (view: AppView): DropdownProps<string> => {
    const { state, update } = view
    const { stats } = state

    const value = stats.centreSelectionType === 'centre' ? stats.selectedCentre! : ''
    const centres = Utils.mapValues(stats.centres!)
    Utils.sortAsStrings(centres, (centre) => centre.name.toLowerCase())

    return {
        options: [
            { id: '', label: 'Keskuse järgi', additional: { className: 'hint' } },
            ...centres.map((centre) => ({ id: centre._id, label: centre.name })),
        ],
        value,
        onChange: (centreId) => {
            if (centreId) {
                stats.centreSelectionType = 'centre'
                stats.selectedCentre = centreId
                update()
            }
        },
        additional: {
            className: classnames('form-control', { 'hint-selected': !value }),
        },
    }
}

const getCountySelectProps = (view: AppView): DropdownProps<County | ''> => {
    const { state, update } = view
    const { stats } = state

    const value = stats.centreSelectionType === 'county' ? stats.selectedCounty! : ''

    return {
        options: [
            { id: '', label: 'Maakonna järgi', additional: { className: 'hint' } },
            ...keys(Enums.counties).map((county) => ({
                id: county,
                label: Enums.counties[county],
            })),
        ],
        value,
        onChange: (county) => {
            if (county) {
                stats.centreSelectionType = 'county'
                stats.selectedCounty = county
                update()
            }
        },
        additional: {
            className: classnames('form-control', { 'hint-selected': !value }),
        },
    }
}

const getRegionSelectProps = (view: AppView): DropdownProps<RegionId | ''> => {
    const { state, update } = view
    const { stats } = state
    const value = stats.centreSelectionType === 'region' ? stats.selectedRegion! : ''

    return {
        options: [
            { id: '', label: 'Regiooni järgi', additional: { className: 'hint' } },
            ...Regions.array,
        ],
        value,
        onChange: (region) => {
            if (region) {
                stats.centreSelectionType = 'region'
                stats.selectedRegion = region
                update()
            }
        },
        additional: {
            className: classnames('form-control', { 'hint-selected': !value }),
        },
    }
}

const getPartitionSelectProps = (
    view: AppView,
): DropdownProps<Exclude<StatsState['partition'], null> | ''> => {
    const { state, update } = view
    const { stats } = state
    const value = stats.centreSelectionType === 'partition' ? stats.partition! : ''

    return {
        options: [
            { id: '', label: 'Osa järgi', additional: { className: 'hint' } },
            { id: 'tln', label: Enums.partitions.tln },
            { id: 'other', label: Enums.partitions.other },
        ],
        value,
        onChange: (partition) => {
            if (partition === 'tln' || partition === 'other') {
                stats.centreSelectionType = 'partition'
                stats.partition = partition
                update()
            }
        },
        additional: {
            className: classnames('form-control', { 'hint-selected': !value }),
        },
    }
}

const getSelectedCentreIds = (view: AppView): string[] | null => {
    const { state } = view
    const { stats } = state

    const selType = stats.centreSelectionType

    if (Session.isCentreSelected(view) || !selType) {
        return null
    }

    const getId = (centre: Centre) => centre._id

    if (selType === 'centre') {
        return [stats.selectedCentre!]
    } else if (selType === 'county') {
        return Utils.filterMap(stats.centres!, (centre) => {
            return centre.county === stats.selectedCounty
        }).map(getId)
    } else if (selType === 'region') {
        const region = Regions.getById(stats.selectedRegion!)

        if (!region) {
            throw new Error('Invalid region: ' + stats.selectedRegion)
        }

        return Utils.filterMap(stats.centres!, (centre) => {
            return region.counties.indexOf(centre.county) !== -1
        }).map(getId)
    } else if (selType === 'partition') {
        return Utils.filterMap(stats.centres!, (centre) => {
            if (centre.county === 'test') {
                return false
            }

            if (stats.partition === 'tln') {
                // This is a special field used on a specific centre in the production database.
                // The field has been added manually and cannot be edited via the UI.
                // See https://trello.com/c/XkpCBadY/306-statistikavaatesse-valiku-lisamine
                return centre.partition === 'tln'
            } else if (stats.partition === 'other') {
                return centre.partition === undefined
            } else {
                throw new Error('Invalid partition: ' + stats.partition)
            }
        }).map(getId)
    } else if (selType === 'all') {
        return Utils.filterMap(stats.centres!, (centre) => {
            return centre.county !== 'test'
        }).map(getId)
    } else {
        throw new Error('Unexpected selection type: ' + selType)
    }
}

const getCentreSelectionDesc = (view: AppView): string | null => {
    const { state } = view
    const { stats } = state

    const selType = stats.centreSelectionType

    if (!selType) {
        return null
    }

    if (selType === 'centre') {
        return stats.centres![stats.selectedCentre!].name
    } else if (selType === 'county') {
        return Enums.counties[stats.selectedCounty!]
    } else if (selType === 'region') {
        const region = Regions.getById(stats.selectedRegion!)

        if (!region) {
            throw new Error('Invalid region: ' + stats.selectedRegion!)
        }

        return region.label
    } else if (selType === 'partition') {
        const partition = stats.partition!

        if (!Enums.partitions[partition]) {
            throw new Error('Invalid partition: ' + partition)
        }

        return Enums.partitions[partition]
    } else if (selType === 'all') {
        return 'Kogu Eesti'
    } else {
        throw new Error('Unexpected selection type: ' + selType)
    }
}
