import { API } from '../api'
import { AppView } from '../state'

export const reloadEvents = async (view: AppView) => {
    const { state, update } = view

    const [eventCategories, events] = await Promise.all([
        API.getCentreEventCategories(view),
        API.getEvents(view),
    ])

    state.eventList.eventCategories = eventCategories
    state.eventList.events = events
    update()
}
