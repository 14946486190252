import { FC } from 'react'

import { EditMeeting, EditMeetingProps } from './edit-meeting'
import { Link, LinkProps } from './link'

export interface MeetingDetailsProps {
    eventLink?: LinkProps
    eventName?: string
    editMeeting?: EditMeetingProps
    location: string
    meetingName?: string
    time: string
}

export const MeetingDetails: FC<MeetingDetailsProps> = (props) => (
    <table className="vtop" style={{ borderCollapse: 'separate', borderSpacing: 5 }}>
        <tbody>
            <tr>
                <td style={{ fontWeight: 'bold' }}>Sündmus:</td>
                <td>
                    {props.eventLink && <Link {...props.eventLink} />}
                    {props.eventName && props.eventName}
                </td>
                <td rowSpan={3} style={{ verticalAlign: 'middle', paddingLeft: 20 }}>
                    {props.editMeeting && <EditMeeting {...props.editMeeting} />}
                </td>
            </tr>
            <tr>
                <td style={{ fontWeight: 'bold' }}>Asukoht:</td>
                <td>{props.location}</td>
            </tr>
            {props.meetingName && (
                <tr>
                    <td style={{ fontWeight: 'bold' }}>Nimetus:</td>
                    <td>{props.meetingName}</td>
                </tr>
            )}
            <tr>
                <td style={{ fontWeight: 'bold' }}>Aeg:</td>
                <td>{props.time}</td>
            </tr>
        </tbody>
    </table>
)
