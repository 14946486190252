import { CardLogEntry, SubField } from '../server/types'
import { Enums } from './enums'
import { FormUtils } from './form-utils'

const noContactOption = {
    id: 'no-contact',
    label: 'Noorega ei saadud kontakti',
}

const cancelReasonField: SubField = {
    type: 'single-choice',
    innerLabel: 'Põhjus',
    options: [
        noContactOption,
        {
            id: 'other',
            label: 'Muu põhjus',
            details: { type: 'text', multiline: true, required: true },
        },
    ],
}

export const CardUtils = {
    getCancelReasonField() {
        return cancelReasonField
    },

    getReasonText(reason: CardLogEntry['reason']) {
        const fieldTypes = FormUtils.getFieldTypes()
        const expanded = FormUtils.expandValue(fieldTypes, cancelReasonField, reason)

        if (expanded.key === 'no-contact') {
            return noContactOption.label
        } else if (expanded.key === 'other') {
            return expanded.details
        } else {
            throw new Error('Unexpected reason key: ' + expanded.key)
        }
    },

    renderLogInfo(entry: CardLogEntry) {
        const message = Enums.cardLogMessages[entry.action]

        if (entry.action === Enums.cardActions.cancel && entry.reason) {
            const reason = CardUtils.getReasonText(entry.reason)

            return (
                <div>
                    <div>{message}</div>
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {'Põhjus: '}
                        {reason}
                    </div>
                </div>
            )
        }

        return message
    },
}
