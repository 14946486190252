import { DistType } from './dist-type'
import { CustomOption } from './stats-table'

export const isMultiKey = <T>(distType: DistType<T>) => {
    const keyFormat = distType.keyFormat
    return keyFormat === 'map' || keyFormat === 'array'
}

export const getPercentageOption = (
    active: boolean,
    onClick: (value: boolean) => void,
    disabled?: boolean,
): CustomOption<boolean> => ({
    id: 'showPercentages',
    label: 'Protsendid',
    buttons: [
        { value: false, label: 'Ära näita' },
        { value: true, label: 'Näita' },
    ],
    onClick,
    active,
    disabled: Boolean(disabled),
})

// Wrapper for clarifying type
export const wrapCustomOption = <T>(option: CustomOption<T>) => option
