import { FC } from 'react'

import { CustomModal, CustomModalProps } from './custom-modal'
import { EditEventForm, EditEventFormProps } from './edit-event-form'
import { Loading } from './loading'

export interface EditEventProps {
    outer: CustomModalProps
    form?: EditEventFormProps
}

export const EditEvent: FC<EditEventProps> = (props) => (
    <CustomModal {...props.outer}>
        {props.form ? <EditEventForm {...props.form} /> : <Loading />}
    </CustomModal>
)
