import {
    ChangeEvent,
    CSSProperties,
    FC,
    InputHTMLAttributes,
    ReactNode,
    TextareaHTMLAttributes,
} from 'react'

import { Field, FieldType, SubField } from '../server/types'
import { BirthDate } from './birth-date'
import { renderButtonGroup } from './button-group'
import { renderButtonGroupMulti } from './button-group-multi'
import { DateField } from './date-field'
import { renderDropdown } from './dropdown'
import { FieldTypeImpl, FormUtils } from './form-utils'
import { MultiCheckbox } from './multi-checkbox'
import { Utils } from './utils'
import { renderValidationError, ValidationError } from './validation'

interface Props {
    editMode: boolean
    fields: Field[]
    values: Record<string, any> // TODO
    validationErrors?: Record<string, ValidationError>
    validationPrefix?: string
    onChange: (id: string, value: any) => void // TODO
}

let fieldTypes: Record<FieldType, FieldTypeImpl>

// TODO
const renderEditor = (field: SubField, value: any, onChange: (id: string, value: any) => void) => {
    const fieldType = fieldTypes[field.type!]
    return fieldType.renderEditor!(field, value, onChange)
}

const renderView = (field: SubField, value: any) => {
    // TODO
    const fieldType = fieldTypes[field.type!]
    return fieldType.renderView!(field, value)
}

const renderHint = (field: SubField) => {
    const fieldType = fieldTypes[field.type!]
    const hint = field.hint || fieldType.hint
    return hint ? <div className="form-hint">{hint}</div> : null
}

// TODO
const renderDetails = (field: SubField, value: any, onChange: (id: string, value: any) => void) => {
    return (
        <div style={{ marginTop: 5 }}>
            <div
                style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: 3,
                }}
            >
                Lisainfo:
                {renderHint(field)}
            </div>
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {renderEditor(field, value, onChange)}
            </div>
        </div>
    )
}

const expandValue = (field: SubField, value: any) => {
    // TODO
    return FormUtils.expandValue(fieldTypes, field, value)
}

// TODO rename to finalizeValue?
const finalize = (field: SubField, value: any) => {
    // TODO
    return FormUtils.finalize(fieldTypes, field, value)
}

const isMultiline = (field: SubField) => {
    const fieldType = fieldTypes[field.type!]
    return Boolean(fieldType.isMultiline && fieldType.isMultiline(field))
}

fieldTypes = FormUtils.getFieldTypes()

fieldTypes.text.renderEditor = (field, value, onChange) => {
    const TagName = field.multiline ? 'textarea' : 'input'

    const props: InputHTMLAttributes<HTMLInputElement> &
        TextareaHTMLAttributes<HTMLTextAreaElement> = {
        value: value === undefined || value === null ? '' : value,
        onChange: (evt: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
            onChange((field as Field).id, evt.currentTarget.value)
        },
    }

    if (field.multiline) {
        props.rows = field.rows || 4
        props.cols = field.cols || 40
    } else {
        props.style = { width: field.width || '15em' }
    }

    return (
        <span>
            <TagName {...props} />
        </span>
    )
}

fieldTypes.text.renderView = (field, value) => {
    if (field.multiline) {
        return <div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>
    } else {
        return value
    }
}

fieldTypes.conditional.renderEditor = (field, value, onChange) => {
    const onCheckboxChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const newValue = Utils.clone(value)
        newValue.bool = evt.target.checked

        if (!newValue.bool) {
            newValue.value = expandValue(field.valueField!, null)
        } else {
            delete newValue.value
        }

        onChange((field as Field).id, newValue)
    }

    const subOnChange = (_id: string, newSubValue: any) => {
        // TODO
        const newValue = Utils.clone(value)
        newValue.value = newSubValue
        onChange((field as Field).id, newValue)
    }

    let subValue: ReactNode = null

    if (!value.bool) {
        subValue = renderEditor(field.valueField!, value.value, subOnChange)
    }

    return (
        <div>
            <div style={{ marginBottom: 5 }}>
                <input type="checkbox" checked={value.bool} onChange={onCheckboxChange} />{' '}
                {field.checkboxLabel}
            </div>
            {subValue}
        </div>
    )
}

fieldTypes.conditional.renderView = (field, value) => {
    if (value.bool) {
        return field.checkboxLabel
    } else {
        return renderView(field.valueField!, value.value)
    }
}

fieldTypes.number.renderEditor = (field, value, onChange) => {
    return (
        <div>
            <input
                style={{ width: field.width || '7em', textAlign: 'right' }}
                value={value === undefined || value === null ? '' : value}
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    let newValue = evt.currentTarget.value
                    let regex: RegExp

                    if (field.decimal) {
                        newValue = newValue.replace(',', '.')
                        regex = /^((0|[1-9]\d*)(\.\d{0,2})?)?$/
                    } else {
                        regex = /^(0|[1-9]\d*)?$/
                    }

                    if (regex.test(newValue)) {
                        onChange((field as Field).id, newValue)
                    }
                }}
            />{' '}
            {field.unit}
        </div>
    )
}

fieldTypes.number.renderView = (field, value) => {
    return value || value === 0 ? value + (field.unit ? ' ' + field.unit : '') : ''
}

fieldTypes['single-choice'].renderEditor = (field, value, onChange) => {
    const updateValue = (newValues: any) => {
        // TODO
        const newValueObj = Utils.clone(value)

        for (const key of Object.keys(newValues)) {
            newValueObj[key] = newValues[key]
        }

        onChange((field as Field).id, newValueObj)
    }

    const options = field.options!

    const updateChoice = (newValue: string) => {
        let details = null
        const activeOption = FormUtils.findOption(field, newValue, true)

        if (activeOption && activeOption.details) {
            details = expandValue(activeOption.details, null)
        }

        updateValue({ key: newValue, other: null, details })
    }

    let otherElement: ReactNode = null

    if (field.other) {
        otherElement = (
            <span>
                {field.other.label}
                {': '}
                <input
                    style={{ width: field.other.width || '12em' }}
                    value={value.other === undefined || value.other === null ? '' : value.other}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                        updateValue({
                            key: null,
                            other: evt.currentTarget.value,
                            details: null,
                        })
                    }
                />
            </span>
        )
    }

    let selectionElement: ReactNode

    if (field.dropdown) {
        selectionElement = renderDropdown({
            options: [
                { id: '', label: '' },
                ...options.map((option) => ({
                    id: option.id,
                    label: option.label || option.id,
                })),
            ],
            value: value.key === null ? '' : value.key,
            onChange: updateChoice,
            additional: { style: { height: 26 } },
        })
    } else {
        selectionElement = renderButtonGroup({
            vertical: field.vertical,
            buttons: options.map((option) => ({
                value: option.id,
                label: option.label || option.id,
            })),
            active: value.key,
            // Deselect if clicking on selected option
            onClick: (newValue) => updateChoice(value.key === newValue ? '' : newValue),
        })
    }

    if (field.vertical && otherElement) {
        // Put it on a separate line
        otherElement = <div style={{ marginTop: 5 }}>{otherElement}</div>
    }

    let detailsElement: ReactNode = null

    if (value.key) {
        const activeOption = FormUtils.findOption(field, value.key)!

        if (activeOption.details) {
            const onDetailsChange = (_id: string, newDetails: any) => {
                // TODO
                updateValue({ details: newDetails })
            }

            detailsElement = renderDetails(activeOption.details!, value.details, onDetailsChange)
        }
    }

    let innerLabel = field.innerLabel

    if (innerLabel) {
        innerLabel += ': '
    }

    return (
        <div>
            {innerLabel}
            {selectionElement} {otherElement}
            {detailsElement}
        </div>
    )
}

fieldTypes['single-choice'].renderView = (field, value) => {
    if (value.other !== null) {
        return value.other
    } else if (value.key) {
        const option = FormUtils.findOption(field, value.key, true)!

        if (value.details) {
            const details = renderView(option.details!, value.details)
            return (
                <span>
                    {option.label || option.id}
                    {' ('}
                    {details})
                </span>
            )
        } else {
            return option.label || option.id
        }
    } else {
        return ''
    }
}

fieldTypes['multiple-choice'].renderEditor = (field, value, onChange) => {
    return renderButtonGroupMulti({
        vertical: field.vertical,
        buttons: field.options!.map((option) => ({
            value: option.id,
            label: option.label!,
        })),
        active: value,
        onClick: (newValue) => onChange((field as Field).id, newValue),
    })
}

fieldTypes['multiple-choice'].renderView = (field, value) => {
    if (value) {
        return field
            .options!.filter((option) => value.indexOf(option.id) !== -1)
            .map((option) => <div key={option.id}>{option.label}</div>)
    } else {
        return ''
    }
}

fieldTypes['multiple-choice-checkbox'].renderEditor = (field, value, onChange) => {
    return (
        <MultiCheckbox
            value={value}
            onChange={(newValue) => onChange((field as Field).id, newValue)}
            other={field.other}
            groups={field.groups}
            options={field.options!}
            columns={field.columns}
            renderDetails={renderDetails}
            expandValue={expandValue}
        />
    )
}

fieldTypes['multiple-choice-checkbox'].renderView = (field, value) => {
    return (
        <MultiCheckbox
            viewMode
            value={value}
            other={field.other}
            groups={field.groups}
            options={field.options!}
            columns={field.columns}
            renderDetails={renderDetails}
            renderView={renderView}
            expandValue={expandValue}
            isMultiline={isMultiline}
        />
    )
}

fieldTypes.map.renderEditor = (field, value, onChange) => {
    const subOnChange = (key: string, newValue: any) => {
        // TODO
        const newValues = Utils.clone(value)

        if (field.selectByValue && newValue === '') {
            delete newValues[key]
        } else {
            newValues[key] = newValue
        }

        onChange((field as Field).id, newValues)
    }

    let totalRow: ReactNode = null

    if (field.total) {
        let totalValue = 0

        for (const key of Object.keys(value)) {
            totalValue += Number(value[key])
        }

        totalRow = (
            <tr>
                {field.selectByValue ? null : <td />}
                <td>{field.total.label}</td>
                <td style={{ textAlign: 'right' }}>{Utils.formatDecimal(totalValue)}</td>
            </tr>
        )
    }

    return (
        <table className="bordered">
            <thead>
                <tr>
                    {field.selectByValue ? null : <th />}
                    <th>{field.keyLabel}</th>
                    <th>{field.valueLabel}</th>
                </tr>
            </thead>
            <tbody>
                {field.keys!.map((key) => {
                    const isSelected = key.id in value

                    if (key.deprecated) {
                        if (isSelected) {
                            throw new Error('Deprecated key selected: ' + key.id)
                        }

                        return null
                    }

                    const subField: Field = { id: key.id, type: field.valueType }

                    if (field.valueType === 'number') {
                        // TODO: proper type conf?
                        subField.decimal = Boolean(field.valueDecimal)
                    }

                    let style: any = null // TODO
                    let valueInput: ReactNode = null
                    let checkboxCell: ReactNode = null

                    if (isSelected) {
                        style = { backgroundColor: 'hsl(45, 100%, 95%)' }
                    }

                    if (isSelected || field.selectByValue) {
                        valueInput = renderEditor(subField, value[key.id], subOnChange)
                    }

                    if (!field.selectByValue) {
                        checkboxCell = (
                            <td>
                                <input
                                    type="checkbox"
                                    checked={key.id in value}
                                    onChange={(evt) => {
                                        const newValues = Utils.clone(value)

                                        if (evt.target.checked) {
                                            newValues[key.id] = ''
                                        } else {
                                            delete newValues[key.id]
                                        }

                                        onChange((field as Field).id, newValues)
                                    }}
                                />
                            </td>
                        )
                    }

                    return (
                        <tr key={key.id} style={style}>
                            {checkboxCell}
                            <td>{key.label}</td>
                            <td>{valueInput}</td>
                        </tr>
                    )
                })}
                {totalRow}
            </tbody>
        </table>
    )
}

fieldTypes.map.renderView = (field, value) => {
    if (!value) {
        return ''
    }

    let totalLine: ReactNode = null

    if (field.total) {
        let totalValue = 0

        for (const key of Object.keys(value)) {
            totalValue += Number(value[key])
        }

        totalLine = (
            <div>
                {field.total.label}
                {': '}
                {Utils.formatDecimal(totalValue)}
            </div>
        )
    }

    return (
        <div>
            {field.keys!.map((key) => {
                // Note: even if a key is deprecated, it should still be rendered in the view mode.
                // Deprecation should only restrict editing.

                if (key.id in value) {
                    const subValue = value[key.id]
                    let string = key.label

                    if (field.selectByValue) {
                        string += ': ' + subValue
                    } else if (subValue) {
                        string += ' (' + subValue + ')'
                    }

                    return <div key={key.id}>{string}</div>
                } else {
                    return null
                }
            })}
            {totalLine}
        </div>
    )
}

fieldTypes.map.getCustomErrorMessages = (field) => {
    if (field.selectByValue) {
        return { array: 'Vähemalt üks lahter peab olema täidetud' }
    } else {
        return { array: 'Vähemalt üks valik on nõutud' }
    }
}

fieldTypes.date.renderEditor = (field, value, onChange) => {
    return (
        <DateField
            value={value}
            onChange={(newDate) => {
                onChange((field as Field).id, newDate)
            }}
        />
    )
}

fieldTypes.date.renderView = (_field, value) => Utils.formatDateFromString(value)

fieldTypes['birth-date'].renderEditor = (field, value, onChange) => {
    return (
        <BirthDate
            value={value.date}
            onChange={(newDate) => {
                onChange((field as Field).id, {
                    date: newDate,
                    originalDate: value.originalDate,
                })
            }}
            originalValue={value.originalDate}
        />
    )
}

fieldTypes['birth-date'].renderView = (_field, value) => {
    return Utils.formatPartialDate(value.date)
}

fieldTypes['birth-date'].getCustomErrorMessages = () => {
    return {
        'under-min': (_error, errorField) => {
            if (errorField.endsWith('.year')) {
                return 'Aasta on kohustuslik kui päev või kuu on sisestatud'
            } else {
                return null
            }
        },
    }
}

export const Form: FC<Props> = (props) => (
    <div>
        <table className="extra-padded vtop">
            <tbody>
                {props.fields.map((field) => {
                    let valueElement: ReactNode
                    let hint: ReactNode = null
                    let validationError: ReactNode = null

                    if (field.readOnly) {
                        valueElement = field.value
                    } else {
                        const fieldType = fieldTypes[field.type!]

                        if (!fieldType) {
                            throw new Error('Unexpected field type: ' + field.type)
                        }

                        if (props.editMode && (field.hint || fieldType.hint)) {
                            hint = renderHint(field)
                        }

                        if (props.editMode) {
                            valueElement = fieldType.renderEditor!(
                                field,
                                props.values[field.id],
                                props.onChange,
                            )

                            let customErrorMessages = {}

                            if (fieldType.getCustomErrorMessages) {
                                customErrorMessages = fieldType.getCustomErrorMessages(field)
                            }

                            const validationFieldName = (props.validationPrefix || '') + field.id

                            validationError = renderValidationError(
                                props.validationErrors,
                                { childOf: validationFieldName, orSelf: true },
                                customErrorMessages,
                            )
                        } else {
                            valueElement = renderView(field, props.values[field.id])
                        }
                    }

                    const style: CSSProperties = { fontWeight: 'bold', maxWidth: '20em' }

                    if (field.indent) {
                        style.paddingLeft = 40
                    }

                    const extra = field.renderExtra ? field.renderExtra() : null

                    return (
                        <tr key={field.id} className={'formrow-' + field.id}>
                            <td style={style}>
                                {field.label}
                                {hint}
                            </td>
                            <td className={'formcell-' + field.id}>
                                {valueElement}
                                {validationError}
                                {extra}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    </div>
)

export { expandValue, finalize, renderEditor, renderView }

export const expandValues = (fields: Field[], latestValues: any) => {
    // TODO
    FormUtils.expandValues(fieldTypes, fields, latestValues)
}

export const finalizeValues = (fields: Field[], values: any) => {
    // TODO
    return FormUtils.finalizeValues(fieldTypes, fields, values)
}
