import { ReactNode } from 'react'

import { TableCellProps, TableProps, TableRowProps, TdProps } from '../table'

export interface BasicRow {
    id: string | number
}

export interface Column<Row> {
    id: string
    header?: ReactNode
    secondHeader?: ReactNode
    editHeaderProps?: (props: TdProps) => void
    editSecondHeaderProps?: (props: TdProps) => void
    getContents?: (row: Row) => ReactNode
    editCellProps?: (props: TdProps, row: Row) => void
}

export interface TableParams<Row> {
    columns: Column<Row>[]
    rows: Row[]
    omitHeader?: boolean
    id?: string // DOM id
    className?: string
}

export const getTableProps = <Row extends BasicRow>(params: TableParams<Row>): TableProps => {
    const { id, className, columns, rows, omitHeader } = params

    return {
        id,
        className,
        headerRows: omitHeader ? [] : getHeaderRows(columns),
        bodyRows: getBodyRows(columns, rows),
    }
}

const getHeaderRows = <Row extends BasicRow>(columns: Column<Row>[]): TableRowProps[] => {
    const headerRows: TableRowProps[] = []

    headerRows.push({
        reactKey: 'header',
        cells: getHeaderCells(columns),
    })

    const hasSecondHeader = columns.some((column) => column.secondHeader)

    if (hasSecondHeader) {
        headerRows.push({
            reactKey: 'secondHeader',
            cells: getSecondHeaderCells(columns),
        })
    }

    return headerRows
}

const getBodyRows = <Row extends BasicRow>(
    columns: Column<Row>[],
    rows: Row[],
): TableRowProps[] => {
    return rows.map(
        (row): TableRowProps => ({
            reactKey: row.id,
            cells: getBodyCells(columns, row),
        }),
    )
}

const getHeaderCells = <Row>(columns: Column<Row>[]): TableCellProps[] => {
    const cells: TableCellProps[] = []
    let toSkip = 0

    for (const column of columns) {
        if (toSkip) {
            toSkip -= 1
            continue
        }

        const props: TableCellProps = {
            reactKey: column.id,
            isHeader: true,
            contents: column.header,
        }

        if (column.editHeaderProps) {
            const attributes: TdProps = {}
            column.editHeaderProps(attributes)
            props.attributes = attributes

            if (attributes.colSpan) {
                toSkip = attributes.colSpan - 1
            }
        }

        cells.push(props)
    }

    return cells
}

// TODO deduplicate?
const getSecondHeaderCells = <Row>(columns: Column<Row>[]): TableCellProps[] => {
    const cells: TableCellProps[] = []
    let toSkip = 0

    for (const column of columns) {
        if (toSkip) {
            toSkip -= 1
            continue
        }

        const props: TableCellProps = {
            reactKey: column.id,
            isHeader: true,
            contents: column.secondHeader,
        }

        if (column.editSecondHeaderProps) {
            const attributes: TdProps = {}
            column.editSecondHeaderProps(attributes)
            props.attributes = attributes

            if (attributes.colSpan) {
                toSkip = attributes.colSpan - 1
            }
        }

        cells.push(props)
    }

    return cells
}

const getBodyCells = <Row>(columns: Column<Row>[], row: Row): TableCellProps[] => {
    return columns.map((column): TableCellProps => {
        const cell: TableCellProps = {
            reactKey: column.id,
            contents: getCellContents(column, row),
        }

        if (column.editCellProps) {
            const attributes: TdProps = {}
            column.editCellProps(attributes, row)
            cell.attributes = attributes
        }

        return cell
    })
}

const getCellContents = <Row>(column: Column<Row>, row: Row): ReactNode => {
    if (column.getContents) {
        return column.getContents(row)
    } else {
        return String(row[column.id as keyof Row])
    }
}
