import classnames from 'classnames'
import { FC } from 'react'

export interface SearchPanelNewItemProps {
    id?: string
    onClick?: () => void
    label: string
    search?: string
    className?: string
}

export const SearchPanelNewItem: FC<SearchPanelNewItemProps> = (props) => (
    <div
        id={props.id}
        className={classnames(props.className, 'search-panel-item-new')}
        onClick={props.onClick}
    >
        {props.label}
        {props.search ? ': ' : null}
        <b>{props.search}</b>
    </div>
)
