import { County } from '../server/types'
import { DistType } from './dist-type'
import { Enums } from './enums'
import { RegionId, Regions } from './regions'

export interface CentreDistTypes<Data> {
    centre: DistType<Data>
    county: DistType<Data, County>
    region: DistType<Data, RegionId>
    collaborator?: DistType<Data, string, Record<string, number>>
}

interface BaseData {
    centre: { _id: string; name: string; county: County } | null
}

export const getCentreDistTypes = <Data extends BaseData>(
    centres: Record<string, Data['centre']>,
    restrictToRows: boolean,
): CentreDistTypes<Data> => {
    const showIfRow = (rowOrCol: 'row' | 'col') => !restrictToRows || rowOrCol === 'row'

    return {
        centre: {
            name: 'Keskus',
            getKey(data) {
                return data.centre!._id
            },
            getKeyName(key) {
                return centres[key]!.name
            },
            excelWidth: 50,
            showIf: showIfRow,
            hideUnknown: true,
        },
        county: {
            name: 'Maakond',
            getKey(data) {
                return data.centre!.county
            },
            getKeyName(key) {
                return Enums.counties[key]
            },
            excelWidth: 15,
            showIf: showIfRow,
            hideUnknown: true,
        },
        region: {
            name: 'Regioon',
            getKey(data) {
                const { county } = data.centre!

                if (county === 'yle' || county === 'test') {
                    return 'other'
                } else {
                    return Regions.getByCounty(county).id
                }
            },
            getKeyName(key) {
                if (key === 'other') {
                    return 'Muu'
                } else {
                    const region = Regions.getById(key)!
                    return region.label
                }
            },
            getSortKey(key) {
                // 'other' must be last
                return key === 'other' ? '1' : '0-' + key
            },
            excelWidth: 12,
            showIf: showIfRow,
            hideUnknown: true,
        },
    }
}
