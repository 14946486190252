import moment from 'moment'
import { Moment } from 'moment'
import { Component } from 'react'

import { GetAllCards_Card } from '../server/commands/get-all-cards'
import { API } from './api'
import { CardsMenu } from './cards-menu'
import { cardActions, cardPhases } from './enums'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { StatSummary, StatSummaryProps } from './stat-summary'
import { AppView } from './state'
import { Utils } from './utils'

interface CardsSummaryProps {
    view: AppView // TODO refactor props
}

interface State {
    loaded: boolean
    resumedWithoutCancel?: StatSummaryProps
    resumed29DaysAfterCancel?: StatSummaryProps
    phase4NoResult?: StatSummaryProps
}

const initStats = (title: string): StatSummaryProps => ({
    title,
    total: 0,
    centreSums: {},
})

export class CardsSummary extends Component<CardsSummaryProps, State> {
    state: State = { loaded: false }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props
        const response = await API.getAllCards(view)

        const resumedWithoutCancel = initStats('Kaarte, mida on jätkatud ilma katkestamata')

        const resumed29DaysAfterCancel = initStats(
            'Kaarte, mida on jätkatud vähemalt 29 päeva pärast katkestamist',
        )

        const phase4NoResult = initStats(
            'Kaarte, mis on 4. faasis, aga pole märgitud ei tulemust ega vastusest keeldumist',
        )

        const centres = response.centres

        const cards = Utils.filterMap(response.cards, (card) => {
            return centres[card.centreId].county !== 'test'
        })

        for (const card of cards) {
            const centreId = card.centreId
            const centreName = centres[centreId].name

            if (this.cardWasResumedWithoutCancel(card)) {
                this.collectCardStat(resumedWithoutCancel, card, centreName)
            }

            if (this.cardWasResumed29DaysAfterCancel(card)) {
                this.collectCardStat(resumed29DaysAfterCancel, card, centreName)
            }

            if (this.cardIsInPhase4WithoutResult(card)) {
                this.collectCardStat(phase4NoResult, card, centreName)
            }
        }

        await Utils.setState(this, {
            loaded: true,
            resumedWithoutCancel,
            resumed29DaysAfterCancel,
            phase4NoResult,
        })

        await EventBus.fire('cards-summary-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    collectCardStat(stats: StatSummaryProps, card: GetAllCards_Card, centreName: string) {
        const centreId = card.centreId

        if (!(centreId in stats.centreSums)) {
            stats.centreSums[centreId] = {
                id: centreId,
                name: centreName,
                sum: 0,
            }
        }

        stats.centreSums[centreId].sum += 1
        stats.total += 1
    }

    cardWasResumedWithoutCancel(card: GetAllCards_Card) {
        let hasEnd3WithoutCancel = false

        for (const entry of card.log) {
            if (entry.action === cardActions.end3) {
                hasEnd3WithoutCancel = true
            } else if (entry.action === cardActions.cancel) {
                hasEnd3WithoutCancel = false
            } else if (entry.action === cardActions.resume && hasEnd3WithoutCancel) {
                return true
            }
        }

        return false
    }

    cardWasResumed29DaysAfterCancel(card: GetAllCards_Card) {
        let mostRecentCancel: Moment | null = null

        for (const entry of card.log) {
            if (entry.action === cardActions.cancel) {
                mostRecentCancel = moment(entry.time).startOf('day')
            } else if (entry.action === cardActions.resume && mostRecentCancel) {
                const resumeTime = moment(entry.time).startOf('day')
                const daysSinceCancel = resumeTime.diff(mostRecentCancel, 'days')

                if (daysSinceCancel >= 29) {
                    return true
                } else {
                    mostRecentCancel = null
                }
            }
        }

        return false
    }

    cardIsInPhase4WithoutResult(card: GetAllCards_Card) {
        return card.phase === cardPhases.started4 && (!card.phase4 || !card.phase4.faas4)
    }

    renderMain() {
        const { state } = this

        if (!state.loaded) {
            return <Loading />
        }

        return (
            <div className="main-panel">
                <h2>Juhtumikaartide kokkuvõte</h2>
                <StatSummary {...state.resumedWithoutCancel!} />
                <StatSummary {...state.resumed29DaysAfterCancel!} />
                <StatSummary {...state.phase4NoResult!} />
            </div>
        )
    }

    render() {
        const { view } = this.props

        return (
            <div>
                <CardsMenu view={view} page="summary" />
                {this.renderMain()}
            </div>
        )
    }
}
