import { Attributes, FC, ReactNode, TdHTMLAttributes } from 'react'

export type TdProps = Attributes & TdHTMLAttributes<HTMLTableCellElement>

export interface TableProps {
    id?: string
    className?: string
    headerRows: TableRowProps[]
    bodyRows: TableRowProps[]
}

export interface TableRowProps {
    reactKey: string | number
    className?: string
    cells: TableCellProps[]
}

export interface TableCellProps {
    reactKey: string | number
    isHeader?: boolean
    className?: string
    attributes?: TdProps
    contents: ReactNode
}

export const Table: FC<TableProps> = (props) => (
    <table id={props.id} className={props.className}>
        {props.headerRows.length > 0 && (
            <thead>
                {props.headerRows.map((row) => (
                    <TableRow key={row.reactKey} {...row} />
                ))}
            </thead>
        )}
        <tbody>
            {props.bodyRows.map((row) => (
                <TableRow key={row.reactKey} {...row} />
            ))}
        </tbody>
    </table>
)

const TableRow: FC<TableRowProps> = (props) => (
    <tr className={props.className}>
        {props.cells.map((cell) => (
            <TableCell key={cell.reactKey} {...cell} />
        ))}
    </tr>
)

const TableCell: FC<TableCellProps> = (props) => {
    const Tag = props.isHeader ? 'th' : 'td'

    return (
        <Tag className={props.className} {...props.attributes}>
            {props.contents}
        </Tag>
    )
}
