import toastr from 'toastr'

import { MeetingVisitor } from '../../server/commands/get-meeting-visitors'
import { VisitorInput, VisitorUpdate } from '../../server/types'
import { delay } from '../../util/delay'
import { API } from '../api'
import { ButtonProps } from '../button'
import { handlePromiseRejection } from '../common-conf'
import { EditVisitorProps } from '../edit-visitor'
import { EditVisitorFormProps } from '../edit-visitor-form'
import { Enums } from '../enums'
import { EventBus } from '../event-bus'
import { getInitialEditVisitorState } from '../initial-state'
import { RadioButtonProps } from '../radio-button'
import { useCustomHandlerDuring } from '../request-failure-handler'
import { Session } from '../session'
import { AppView } from '../state'
import { Utils } from '../utils'
import { checkValidationErrors, getValErrProps } from '../validation'

export interface EditVisitorParams {
    close: () => Promise<void>
    afterSave: (username: string | null) => Promise<void>
    isNew: boolean
    visitor?: MeetingVisitor
    initialName?: string
}

export const getEditVisitorProps = (view: AppView, params: EditVisitorParams): EditVisitorProps => {
    const { state, update } = view
    const { editVisitor } = state

    const props: EditVisitorProps = {
        effect: () => {
            editVisitor.managedByCurrentCentre = isManagedByCurrentCentre(
                view,
                params.isNew,
                params.visitor,
            )

            if (params.isNew) {
                let firstName = ''
                let lastName = ''
                const initialName = params.initialName || ''

                if (initialName) {
                    const parts = initialName.trim().replace(/ +/g, ' ').split(' ')

                    firstName = Utils.upperCaseFirst(parts[0])

                    if (parts.length > 1) {
                        lastName = Utils.upperCaseFirst(parts[1])
                    }
                }

                editVisitor.firstname = firstName
                editVisitor.lastname = lastName
                editVisitor.username = Utils.cleanName(initialName)
            } else {
                const visitor = params.visitor!
                editVisitor.username = visitor.username
                editVisitor.birthDate = Utils.clone(visitor.birthDate || {})

                if (editVisitor.managedByCurrentCentre) {
                    editVisitor.firstname = visitor.firstname!
                    editVisitor.lastname = visitor.lastname!
                    editVisitor.gender = visitor.gender!
                    editVisitor.lang = visitor.lang!
                    editVisitor.langOther = visitor.langOther
                    editVisitor.schoolType = visitor.schoolType!
                    editVisitor.school = visitor.school!
                    editVisitor.working = visitor.working!
                    editVisitor.email = visitor.email!
                    editVisitor.parentEmail = visitor.parentEmail || ''
                    editVisitor.ownPhone = visitor.ownPhone!
                    editVisitor.country = visitor.country || ''
                    editVisitor.area = visitor.area || ''
                    editVisitor.settlement = visitor.settlement || ''
                    editVisitor.notes = visitor.notes || ''
                }
            }

            editVisitor.ready = true
            update()

            EventBus.fire('visitor-details-rendered').catch((err) =>
                handlePromiseRejection(view, err),
            )

            return () => {
                state.editVisitor = getInitialEditVisitorState()
                update()
            }
        },
        modal: {
            title: 'Osaleja',
            closeModal: params.close,
            dialogClassName: 'edit-visitor',
            buttons: [],
        },
    }

    const cancelButton: ButtonProps = {
        text: 'Tühista',
        onClick: params.close,
    }

    if (isManagedByCurrentCentre(view, params.isNew, params.visitor)) {
        const { processing } = state.editVisitor

        props.modal.buttons.push({
            isLoading: processing,
            id: 'save',
            text: 'Salvesta',
            onClick: async () => {
                try {
                    editVisitor.processing = true
                    update()
                    await save(view, params)
                } finally {
                    editVisitor.processing = false
                    update()
                }

                await params.close()
            },
            className: processing ? 'disabled' : undefined,
            style: { marginRight: 5 },
        })
    } else {
        cancelButton.text = 'Sulge'
    }

    props.modal.buttons.push(cancelButton)

    if (editVisitor.ready) {
        props.form = getFormProps(view, params)
    }

    return props
}

const isManagedByCurrentCentre = (
    view: AppView,
    isNew: boolean,
    visitor: MeetingVisitor | undefined,
) => {
    return isNew || visitor!.managingCentreId === Session.getCentre(view)!._id
}

const getFormProps = (view: AppView, params: EditVisitorParams): EditVisitorFormProps => {
    const { state, update } = view
    const { editVisitor, validationErrors } = state

    const props: EditVisitorFormProps = {}

    if (editVisitor.managedByCurrentCentre) {
        const centre = Session.getCentre(view)!

        props.firstName = {
            label: 'Eesnimi',
            input: {
                value: editVisitor.firstname,
                onChange: (value) => {
                    editVisitor.firstname = value
                    update()
                },
                id: 'firstname',
                className: 'form-control',
            },
            error: getValErrProps(validationErrors, 'firstname'),
        }

        props.lastName = {
            label: 'Perekonnanimi',
            input: {
                value: editVisitor.lastname,
                onChange: (value) => {
                    editVisitor.lastname = value
                    update()
                },
                id: 'lastname',
                className: 'form-control',
            },
            error: getValErrProps(validationErrors, 'lastname'),
        }

        props.username = {
            label: 'Kasutajanimi',
            input: {
                value: editVisitor.username,
                onChange: (value) => {
                    editVisitor.username = value
                    update()
                },
                id: 'username',
                className: 'form-control',
            },
            error: getValErrProps(validationErrors, 'username', {
                unique: 'See kasutajanimi on juba kasutusel. Palun vali uus.',
            }),
            note: 'Sinu kasutajanimi on nähtav ka teistele osalejatele, aga ees- ja perekonnanimi ei ole.',
        }

        props.country = {
            label: 'Elukoha riik',
            input: {
                selectedCountry: editVisitor.country,
                onSelectCountry: (country) => {
                    editVisitor.country = country
                    update()
                },
                onDeselectCountry: async () => {
                    editVisitor.country = ''
                    update()
                    await delay(1)
                    await EventBus.fire('focus-country-input')
                },
            },
            error: getValErrProps(validationErrors, 'country'),
        }

        props.area = {
            label: 'Kohalik omavalitsus',
            input: {
                selectedArea: editVisitor.area,
                onSelectArea: (area) => {
                    editVisitor.area = area
                    update()
                },
                onDeselectArea: async () => {
                    editVisitor.area = ''
                    update()
                    await delay(1)
                    await EventBus.fire('focus-area-input')
                },
            },
            error: getValErrProps(validationErrors, 'area'),
        }

        props.settlement = {
            label: 'Asula või küla',
            input: {
                value: editVisitor.settlement,
                onChange: (value) => {
                    editVisitor.settlement = value
                    update()
                },
                className: 'form-control',
            },
            error: getValErrProps(validationErrors, 'settlement'),
            note: 'Võib jätta tühjaks.',
        }

        props.birthDate = {
            label: 'Sünnikuupäev',
            input: {
                value: editVisitor.birthDate,
                onChange: (newBirthDate) => {
                    editVisitor.birthDate = newBirthDate
                    update()
                },
                originalValue: params.isNew ? {} : params.visitor!.birthDate!,
                validationErrors,
                yearRequired: true,
            },
        }

        props.gender = {
            label: 'Sugu',
            buttons: [
                {
                    reactKey: 'M',
                    label: 'M',
                    checked: editVisitor.gender === 'M',
                    onSelect: () => {
                        editVisitor.gender = 'M'
                        update()
                    },
                    style: { display: 'block' },
                    inputId: 'gender-m',
                },
                {
                    reactKey: 'N',
                    label: 'N',
                    checked: editVisitor.gender === 'N',
                    onSelect: () => {
                        editVisitor.gender = 'N'
                        update()
                    },
                    style: { display: 'block' },
                    inputId: 'gender-n',
                },
            ],
            error: getValErrProps(validationErrors, 'gender'),
        }

        props.lang = {
            label: 'Peamine suhtluskeel',
            buttons: Enums.visitorLanguages._order.map(
                (key): RadioButtonProps => ({
                    reactKey: key,
                    label: Enums.visitorLanguages[key],
                    checked: editVisitor.lang === key,
                    onSelect: () => {
                        editVisitor.lang = key
                        update()
                    },
                    style: { display: 'block' },
                }),
            ),
            otherButton: {
                label: 'muu:',
                checked: editVisitor.lang === 'other',
                onSelect: () => {
                    editVisitor.lang = 'other'
                    update()
                },
                className: 'form-inline',
                style: { display: 'block' },
            },
            otherInput: {
                value: editVisitor.langOther || '',
                onChange: (value) => {
                    editVisitor.langOther = value
                    editVisitor.lang = 'other'
                    update()
                },
                className: 'form-control',
                style: { width: 100 },
            },
            emptyButton: {
                label: 'Ei avalda',
                checked: editVisitor.lang === '',
                onSelect: () => {
                    editVisitor.lang = ''
                    update()
                },
                style: { display: 'block' },
            },
            langError: getValErrProps(validationErrors, 'lang'),
            langOtherError: getValErrProps(validationErrors, 'langOther'),
        }

        props.school = {
            label: 'Õppeasutus',
            yesButton: {
                label: 'Õpin asutuses:',
                checked: editVisitor.schoolType === 'yes',
                onSelect: () => {
                    editVisitor.schoolType = 'yes'
                    update()
                },
                className: 'form-inline',
                style: { display: 'block' },
            },
            schoolInput: {
                selectedSchool: editVisitor.school,
                onSelectSchool: (school) => {
                    editVisitor.school = school
                    update()
                },
                onDeselectSchool: async () => {
                    editVisitor.school = null
                    editVisitor.schoolType = 'no'
                    update()
                    await delay(1)
                    await EventBus.fire('focus-school-input')
                },
                afterChange: (hasValue) => {
                    editVisitor.schoolType = hasValue ? 'yes' : 'no'
                    update()
                },
            },
            typeButtons: [
                {
                    reactKey: 'no',
                    label: 'Ei õpi',
                    checked: editVisitor.schoolType === 'no',
                    onSelect: () => {
                        editVisitor.schoolType = 'no'
                        update()
                    },
                    style: { display: 'block' },
                },
                {
                    reactKey: 'empty',
                    label: 'Ei avalda',
                    checked: editVisitor.schoolType === '',
                    onSelect: () => {
                        editVisitor.schoolType = ''
                        update()
                    },
                    style: { display: 'block' },
                },
            ],
            error: getValErrProps(validationErrors, 'school'),
        }

        props.working = {
            label: 'Töökoht',
            buttons: [
                {
                    reactKey: 'yes',
                    label: 'Töötan',
                    checked: editVisitor.working === 'yes',
                    onSelect: () => {
                        editVisitor.working = 'yes'
                        update()
                    },
                    style: { display: 'block' },
                },
                {
                    reactKey: 'no',
                    label: 'Ei tööta',
                    checked: editVisitor.working === 'no',
                    onSelect: () => {
                        editVisitor.working = 'no'
                        update()
                    },
                    style: { display: 'block' },
                },
                {
                    reactKey: 'empty',
                    label: 'Ei avalda',
                    checked: editVisitor.working === '',
                    onSelect: () => {
                        editVisitor.working = ''
                        update()
                    },
                    style: { display: 'block' },
                },
            ],
            error: getValErrProps(validationErrors, 'working'),
        }

        props.email = {
            label: 'E-mail',
            input: {
                value: editVisitor.email,
                onChange: (value) => {
                    editVisitor.email = value
                    update()
                },
                className: 'form-control',
            },
            note: 'Võib jätta tühjaks.',
            error: getValErrProps(validationErrors, 'email'),
        }

        props.parentEmail = {
            label: 'Lapsevanema e-mail',
            input: {
                value: editVisitor.parentEmail,
                onChange: (value) => {
                    editVisitor.parentEmail = value
                    update()
                },
                className: 'form-control',
            },
            noteLines: [
                'Võib jätta tühjaks.',
                'Täitmisel või muutmisel saadetakse sisestatud aadressile teavitus.',
            ],
            error: getValErrProps(validationErrors, 'parentEmail', {
                invalid: 'Pole korrektne e-maili aadress',
            }),
        }

        props.ownPhone = {
            label: 'Enda telefon',
            input: {
                value: editVisitor.ownPhone,
                onChange: (value) => {
                    editVisitor.ownPhone = value
                    update()
                },
                className: 'form-control',
            },
            error: getValErrProps(validationErrors, 'ownPhone'),
            note: 'Võib jätta tühjaks.',
        }

        props.notes = {
            label: 'Märkmed',
            input: {
                value: editVisitor.notes,
                onChange: (value) => {
                    editVisitor.notes = value
                    update()
                },
                style: { width: '100%', maxWidth: 400, height: 120 },
            },
            note: centre.noteInfo || 'Võib jätta tühjaks.',
            error: getValErrProps(validationErrors, 'notes'),
        }

        if (!params.isNew) {
            props.info = {
                requestInfo: {
                    text: 'Teie keskus on selle külastaja konto haldaja.',
                    moreInfoUrl: '#/transfer/' + params.visitor!._id,
                },
                creationInfo:
                    'Külastaja konto on loodud ' +
                    Utils.formatDateFromString(params.visitor!.createdTime!),
            }
        }
    } else {
        props.username = {
            label: 'Kasutajanimi',
            value: editVisitor.username,
        }

        props.birthYear = {
            label: 'Sünniaasta',
            value: Utils.formatPartialDate(editVisitor.birthDate),
        }

        props.info = {
            requestInfo: {
                text: 'Külastaja isikuandmed on peidetud, kuna teie keskus pole selle konto haldaja.',
                lineBreak: true,
                moreInfoUrl: '#/transfer/' + params.visitor!._id,
            },
        }
    }

    return props
}

const save = async (view: AppView, params: EditVisitorParams) => {
    const { state, update } = view
    const { editVisitor } = state

    if (!editVisitor.managedByCurrentCentre) {
        return
    }

    if (editVisitor.schoolType === 'yes' && !editVisitor.school) {
        toastr.error('Pead valima loendist õppeasutuse või variandi "Ei õpi"')
        throw Utils.getAlreadyHandledError()
    }

    const visitorInput: VisitorInput = {
        firstname: editVisitor.firstname.trim(),
        lastname: editVisitor.lastname.trim(),
        username: editVisitor.username.trim(),
        gender: editVisitor.gender!,
        birthDate: editVisitor.birthDate,
        lang: editVisitor.lang,
        langOther: editVisitor.lang === 'other' ? editVisitor.langOther : null,
        schoolType: editVisitor.schoolType,
        school: editVisitor.schoolType === 'yes' ? editVisitor.school : null,
        working: editVisitor.working,
        email: (editVisitor.email || '').trim(),
        parentEmail: (editVisitor.parentEmail || '').trim(),
        ownPhone: (editVisitor.ownPhone || '').trim(),
        country: (editVisitor.country || '').trim(),
        area: editVisitor.area.trim(),
        settlement: (editVisitor.settlement || '').trim(),
        notes: (editVisitor.notes || '').trim(),
    }

    state.validationErrors = {}
    update()

    try {
        if (params.isNew) {
            await API.addVisitor(view, visitorInput)
            await params.afterSave(visitorInput.username)
        } else {
            const visitorUpdate: VisitorUpdate = {
                ...visitorInput,
                rev: params.visitor!.rev!,
            }

            await useCustomHandlerDuring(Utils.getConcurrentEditErrorHandler('osaleja'), async () =>
                API.updateVisitor(view, params.visitor!._id, visitorUpdate),
            )

            await params.afterSave(null)
        }
    } catch (error) {
        await checkValidationErrors(view, null, error)
    }
}
