import { FC } from 'react'

import { EditActivity, EditActivityProps } from './edit-activity'
import { EmployeeModeMeeting, EmployeeModeMeetingProps } from './employee-mode-meeting'
import { Loading } from './loading'
import { MeetingAnonymous, MeetingAnonymousProps } from './meeting-anonymous'
import { MeetingNotes, MeetingNotesProps } from './meeting-notes'
import { ParticipantModeMeeting, ParticipantModeMeetingProps } from './participant-mode-meeting'
import { VisitorSearchPanel, type VisitorSearchPanelProps } from './visitor-search-panel'

export interface MeetingProps {
    mainPanel?: MainPanelProps
    isLoading?: boolean
    regular?: RegularProps
}

export interface MainPanelProps {
    notFound?: {
        backUrl: string
    }
    onClickBack?: () => void
    anonymous?: MeetingAnonymousProps
    notes?: MeetingNotesProps
}

export interface RegularProps {
    hasTopBorder: boolean
    visitorSearchPanel: VisitorSearchPanelProps
    employeeModeMeeting?: EmployeeModeMeetingProps
    participantModeMeeting?: ParticipantModeMeetingProps
    activityModal?: EditActivityProps
}

export const Meeting: FC<MeetingProps> = (props) => (
    <>
        {props.isLoading && <Loading />}
        {props.regular && <Regular {...props.regular} />}
        {props.mainPanel && <MainPanel {...props.mainPanel} />}
    </>
)

const Regular: FC<RegularProps> = (props) => (
    <div
        style={{
            borderTop: props.hasTopBorder ? '1px solid #ddd' : undefined,
        }}
    >
        <table className="vtop">
            <tbody>
                <tr>
                    <td className="left-panel">
                        <div>
                            <h2>Pane ennast kirja</h2>
                            <VisitorSearchPanel {...props.visitorSearchPanel} />
                        </div>
                    </td>
                    <td className="right-panel">
                        {props.employeeModeMeeting && (
                            <EmployeeModeMeeting {...props.employeeModeMeeting} />
                        )}
                        {props.participantModeMeeting && (
                            <ParticipantModeMeeting {...props.participantModeMeeting} />
                        )}
                    </td>
                </tr>
            </tbody>
        </table>
        {props.activityModal && <EditActivity {...props.activityModal} />}
    </div>
)

const MainPanel: FC<MainPanelProps> = (props) => (
    <div className="main-panel">
        {props.notFound && (
            <>
                Kohtumist, mida proovisite vaadata, ei leitud.
                <br />
                Tõenäoliselt on see ära kustutatud.
                <br />
                {'Vajutage '}
                <a href={props.notFound.backUrl}>siia</a>, et minna tagasi sündmuste nimekirja.
            </>
        )}
        {props.onClickBack && (
            <div style={{ marginBottom: 15 }}>
                <a onClick={props.onClickBack} style={{ cursor: 'pointer' }}>
                    Tagasi kohtumise vaatesse
                </a>
            </div>
        )}
        {props.anonymous && <MeetingAnonymous {...props.anonymous} />}
        {props.notes && <MeetingNotes {...props.notes} />}
    </div>
)
