import classnames from 'classnames'
import { FC } from 'react'

import { Utils } from '../utils'

export interface SearchPanelExistingItemProps {
    reactKey?: string
    primaryName: string
    secondaryName?: string
    search?: string
    onClick: () => void
    desaturated?: boolean
    className?: string
}

const renderSecondaryName = (props: SearchPanelExistingItemProps) => {
    if (props.secondaryName) {
        return (
            <div className="secondary-name" style={{ float: 'right' }}>
                ({Utils.highlight(props.secondaryName, props.search)})
            </div>
        )
    } else {
        return null
    }
}

export const SearchPanelExistingItem: FC<SearchPanelExistingItemProps> = (props) => (
    <div
        className={classnames(
            props.className,
            { faded: props.desaturated },
            'search-panel-item-existing',
            { 'search-panel-item-existing-desaturated': props.desaturated },
        )}
        onClick={props.onClick}
    >
        {renderSecondaryName(props)}
        <span className="primary-name">{Utils.highlight(props.primaryName, props.search)}</span>
    </div>
)
