import { type FC } from 'react'

import { type County } from '../server/types'
import { ButtonGroup, type ButtonGroupProps } from './button-group'
import { CardResults, type CardResultsProps } from './card-results'
import { CardStats, type CardStatsProps } from './card-stats'
import { CentreStats, type CentreStatsProps } from './centre-stats'
import { Checkbox, type CheckboxProps } from './checkbox'
import { CollaboratorStats, type CollaboratorStatsProps } from './collaborator-stats'
import { DateField, type DateFieldProps } from './date-field'
import { Dropdown, type DropdownProps } from './dropdown'
import { EventCategoryStats, type EventCategoryStatsProps } from './event-category-stats'
import { EventDurationStats, type EventDurationStatsProps } from './event-duration-stats'
import { EventStats, type EventStatsProps } from './event-stats'
import { FeedbackStats, type FeedbackStatsProps } from './feedback-stats'
import { Loading } from './loading'
import { NewVisitorStats, type NewVisitorStatsProps } from './new-visitor-stats'
import { type RegionId } from './regions'
import { type StatsState } from './state'
import { VisitorStats, type VisitorStatsProps } from './visitor-stats'

export interface StatsProps {
    isLoading: boolean
    centreChoice?: CentreChoiceProps
    dateFilter?: DateFilterProps
    archivedFilter?: ArchivedFilterProps
    dateError?: string
    eventStats?: EventStatsProps
    eventCategoryStats?: EventCategoryStatsProps
    eventDurationStats?: EventDurationStatsProps
    cardStats?: CardStatsProps
    visitorStats?: VisitorStatsProps
    newVisitorStats?: NewVisitorStatsProps
    centreStats?: CentreStatsProps
    cardResults?: CardResultsProps
    collaboratorStats?: CollaboratorStatsProps
    feedbackStats?: FeedbackStatsProps
}

export interface CentreChoiceProps {
    centre: DropdownProps<string>
    county: DropdownProps<County | ''>
    region: DropdownProps<RegionId | ''>
    partition: DropdownProps<Exclude<StatsState['partition'], null> | ''>
    all: ButtonGroupProps<string>
}

interface DateFilterProps {
    from: DateFieldProps
    to: DateFieldProps
}

interface ArchivedFilterProps {
    checkbox: CheckboxProps
    label: string
}

export const Stats: FC<StatsProps> = (props) => {
    if (props.isLoading) {
        return <Loading />
    }

    return (
        <div>
            <div className="submenu">
                <div>
                    <a href="#/stats">&lt; Tagasi</a>
                </div>
                {props.centreChoice && <CentreChoice {...props.centreChoice} />}
                {(props.dateFilter || props.archivedFilter) && (
                    <div>
                        {props.dateFilter && <DateFilter {...props.dateFilter} />}
                        {props.archivedFilter && <ArchivedFilter {...props.archivedFilter} />}
                    </div>
                )}
            </div>
            {props.dateError && <div className="main-panel">{props.dateError}</div>}
            {props.eventStats && <EventStats {...props.eventStats} />}
            {props.eventCategoryStats && <EventCategoryStats {...props.eventCategoryStats} />}
            {props.eventDurationStats && <EventDurationStats {...props.eventDurationStats} />}
            {props.cardStats && <CardStats {...props.cardStats} />}
            {props.visitorStats && <VisitorStats {...props.visitorStats} />}
            {props.newVisitorStats && <NewVisitorStats {...props.newVisitorStats} />}
            {props.centreStats && <CentreStats {...props.centreStats} />}
            {props.cardResults && <CardResults {...props.cardResults} />}
            {props.collaboratorStats && <CollaboratorStats {...props.collaboratorStats} />}
            {props.feedbackStats && <FeedbackStats {...props.feedbackStats} />}
        </div>
    )
}

const CentreChoice: FC<CentreChoiceProps> = (props) => (
    <div id="centre-choice" className="form-inline" style={{ marginTop: 8 }}>
        {'Vali keskus(ed): '}
        <Dropdown {...props.centre} />
        {' või '}
        <Dropdown {...props.county} />
        {' või '}
        <Dropdown {...props.region} />
        {' või '}
        <Dropdown {...props.partition} />
        {' või '}
        <ButtonGroup {...props.all} />
    </div>
)

const DateFilter: FC<DateFilterProps> = (props) => (
    <div
        className="form-inline"
        id="stats-dates"
        style={{ display: 'inline-block', paddingTop: 10 }}
    >
        {'Näita andmeid vahemikust '}
        <DateField {...props.from} />
        {' kuni '}
        <DateField {...props.to} />
    </div>
)

const ArchivedFilter: FC<ArchivedFilterProps> = (props) => (
    <label style={{ display: 'inline-block', marginLeft: 30 }}>
        <Checkbox {...props.checkbox} /> {props.label}
    </label>
)
