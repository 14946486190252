import { FC } from 'react'

import { ExtendedEventCategory } from '../../server/types'
import { AddEventButton } from '../add-event-button'
import { SearchPanelExistingItem } from '../search-panel/existing-item'
import { AppView } from '../state'
import { Utils } from '../utils'

export interface EventCategorySearchContainerProps {
    view: AppView
    reactKey?: string
    eventCategory: ExtendedEventCategory
    search: string
    onClick: () => void
}

export const EventCategorySearchContainer: FC<EventCategorySearchContainerProps> = (props) => (
    <div className="event-category-container">
        <h3 className="event-category-title clickable" onClick={props.onClick}>
            {Utils.highlight(props.eventCategory.name!, props.search)}
        </h3>
        {props.eventCategory.events.map(({ _id, name, archived }) => (
            <SearchPanelExistingItem
                key={_id}
                primaryName={name}
                desaturated={archived}
                onClick={() => props.view.navigate(['events', _id])}
                search={props.search}
            />
        ))}
        <AddEventButton
            view={props.view}
            search={props.search}
            categoryId={props.eventCategory._id}
        />
    </div>
)
