import classnames from 'classnames'
import { FC, ReactNode, useState } from 'react'

interface Props {
    isOpen?: boolean
    header?: ReactNode
    children?: ReactNode
    className?: string
    headerClassName?: string
    bodyClassName?: string
}

const renderIcon = (isOpen: boolean) => {
    const className = classnames('glyphicon', {
        'glyphicon-chevron-down': isOpen,
        'glyphicon-chevron-right': !isOpen,
    })

    return <span className={className} />
}

const renderHeader = (props: Props, isOpen: boolean, setOpen: (open: boolean) => void) => (
    <div
        className={classnames('clickable', 'panel-heading', props.headerClassName)}
        onClick={() => setOpen(!isOpen)}
    >
        {renderIcon(isOpen)} {props.header}
    </div>
)

const renderBody = (props: Props, isOpen: boolean) => {
    if (!isOpen) {
        return null
    }

    return <div className={classnames('panel-body', props.bodyClassName)}>{props.children}</div>
}

export const Collapsible: FC<Props> = (props) => {
    const [isOpen, setOpen] = useState(props.isOpen || false)

    return (
        <div className={classnames('panel', 'panel-default', props.className)}>
            {renderHeader(props, isOpen, setOpen)}
            {renderBody(props, isOpen)}
        </div>
    )
}
