import { createRoot, Root as ReactRoot } from 'react-dom/client'

import { assert } from '../util/assert'
import { canSeeEvents } from './access'
import { getInitialAppState } from './initial-state'
import { getRootProps } from './props/root'
import { Root } from './root'
import { AppState, AppView } from './state'

declare global {
    interface Window {
        state: AppState
    }
}

let root: ReactRoot | undefined
let stop: (() => void) | undefined

export const initAppView = (): AppView => {
    let isStopped = false
    const container = document.getElementById('main')
    assert(container)

    root = createRoot(container)

    const view: AppView = {
        state: getInitialAppState(parseRoute(window.location.hash)),
        update: () => {
            if (isStopped) {
                return
            }

            if (!view.state.route.length) {
                view.navigate(getDefaultRoute(view))
                return
            }

            view.activeElementKeys = new Set()
            const props = getRootProps(view)
            removeUnmountedElements(view)

            root!.render(<Root {...props} />)
        },
        navigate: (route) => {
            // Causes the hashchange event listener to update the view
            window.location.hash = `/${route.join('/')}`
        },
        mountedElements: new Map(),
        activeElementKeys: new Set(),
    }

    // For troubleshooting in the browser console
    window.state = view.state

    const onHashChange = () => {
        view.state.route = parseRoute(window.location.hash)
        view.update()
    }

    window.addEventListener('hashchange', onHashChange)

    stop = () => {
        isStopped = true
        window.removeEventListener('hashchange', onHashChange)
        root?.unmount()
        root = undefined
    }

    return view
}

export const stopAppView = () => {
    stop?.()
    stop = undefined
}

export const getReactRoot = () => root!

const removeUnmountedElements = (view: AppView) => {
    for (const [key, element] of view.mountedElements) {
        if (!view.activeElementKeys.has(key)) {
            element.cleanup?.()
            view.mountedElements.delete(key)
        }
    }
}

const parseRoute = (route: string): string[] => {
    return route.split('/').filter((segment) => segment !== '' && segment !== '#')
}

const getDefaultRoute = (view: AppView): string[] => {
    const { session } = view.state

    if (!session.loggedIn) {
        return ['login']
    }

    if (!session.centre) {
        return ['select-centre']
    }

    if (canSeeEvents(session.employee, session.centre._id)) {
        return ['events']
    }

    return ['stats']
}
