import { FC } from 'react'

export const EventPlaceholder: FC = () => (
    <div>
        <h2>Tere!</h2>
        <p>Palun vali vasakult sündmus või kategooria, millega soovid tegeleda.</p>
        <p>
            Alusta sündmuse nime trükkimist, et see hõlpsamini leida
            {' või selleks, et lisada süsteemi uus sündmus või kategooria.'}
        </p>
    </div>
)
