import { Component, ReactNode } from 'react'
import toastr from 'toastr'

import { API } from './api'
import { bindProps } from './bind-props'
import { LoadingButton } from './loading-button'
import { Session } from './session'
import { AppView } from './state'

interface EmployeeProps {
    view: AppView // TODO refactor props
}

interface State {
    email: string
}

export class Employee extends Component<EmployeeProps, State> {
    constructor(props: EmployeeProps) {
        super(props)

        const employee = Session.getEmployee(props.view)
        this.state = { email: employee.email || '' }
    }

    async save() {
        const { view } = this.props
        await API.updateSelf(view, this.state.email)
        toastr.info('Muudatused salvestatud')
    }

    render(): ReactNode {
        const { view } = this.props
        const employee = Session.getEmployee(view)

        return (
            <div className="main-panel">
                <h2>
                    {'Töötaja konto: '}
                    {employee.name}
                </h2>
                <p>
                    Siin lehel saate hallata lisainfot, mis on seotud teie töötaja kontoga
                    {' (kasutajanimi '}
                    <b>{employee.username}</b>
                    ).
                    <br />
                    Hetkel piirdub see e-mailiga, kuid tulevikus võib siia lisanduda ka teisi
                    andmeid.
                </p>
                <div>E-mail:</div>
                <input {...bindProps(this, 'email')} />
                <div style={{ marginTop: 10 }}>
                    <LoadingButton text="Salvesta" getPromise={async () => this.save()} />
                </div>
            </div>
        )
    }
}
