import { FC } from 'react'

import { GetLogEntries_Response } from '../server/commands/get-log-entries'
import { LimitedEmployee } from '../server/remove-employee-fields'
import { API } from './api'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'

export interface MeetingLogProps {
    view: AppView // TODO refactor props
    entries: GetLogEntries_Response | undefined
    employees: Record<string, LimitedEmployee>
    extendedEmployees: Record<string, LimitedEmployee>
}

export const MeetingLog: FC<MeetingLogProps> = ({
    view,
    entries,
    employees,
    extendedEmployees,
}) => {
    if (!entries || !Object.keys(entries).length) {
        return <div>Logi on tühi.</div>
    }

    const headerRow = (
        <tr>
            <th>Aeg</th>
            <th>Kasutaja</th>
            <th>Info</th>
        </tr>
    )

    const entryRows = Utils.mapMap(entries, (entry) => {
        let employeeName: string | null = null

        if (entry.employee) {
            const employee = employees[entry.employee] || extendedEmployees[entry.employee]

            if (!employee) {
                const currentEmployee = Session.getEmployee(view)

                if (currentEmployee._id === entry.employee) {
                    employeeName = currentEmployee.name
                } else {
                    API.logWarning(
                        view,
                        'Employee ' + entry.employee + ' for log entry not found',
                        false,
                    )
                    employeeName = 'Error'
                }
            }
        }

        return (
            <tr key={entry._id}>
                <td>{Utils.formatDateTime(new Date(entry.time))}</td>
                <td>{employeeName || '(osaleja režiim)'}</td>
                <td>{entry.info}</td>
            </tr>
        )
    })

    return (
        <table id="meeting-log" className="bordered">
            <thead>{headerRow}</thead>
            <tbody>{entryRows}</tbody>
        </table>
    )
}
