import {
    AppState,
    CardsEmployeeUpdateState,
    EditVisitorState,
    EsfReportState,
    EventFormState,
    EventListState,
    EventState,
    LoginState,
    MeetingFormState,
    MeetingState,
    ParentNotificationState,
    type StatsState,
    type VisitorSearchState,
    type VisitorStatsState,
} from './state'

export const getInitialEditVisitorState = (): EditVisitorState => ({
    newModalVisible: false,
    editModalVisible: false,
    processing: false,
    ready: false,
    firstname: '',
    lastname: '',
    username: '',
    gender: null,
    birthDate: { day: 0, month: 0, year: 0 },
    lang: '',
    langOther: '',
    school: null,
    schoolType: '',
    working: '',
    email: '',
    parentEmail: '',
    ownPhone: '',
    country: '',
    area: '',
    settlement: '',
    notes: '',
    managedByCurrentCentre: false,
})

export const getInitialLoginState = (): LoginState => ({
    username: '',
    password: '',
    processing: false,
    passwordInfoVisible: false,
})

export const getInitialEventListState = (): EventListState => ({
    loaded: false,
    eventCategories: [],
    events: {},
    employees: {},
    extendedEmployees: {},
    search: '',
    showArchived: false,
})

export const getInitialEventState = (): EventState => ({
    isSaving: false,
    loaded: false,
    meetings: {},
    hasMore: false,
})

export const getInitialEventFormState = (): EventFormState => ({
    loaded: false,
    name: '',
    type: 'open',
    employees: [],
    description: '',
    timesNeeded: false,
    activityConf: { mode: 'none' },
    activityChoiceText: '',
})

export const getInitialMeetingState = (): MeetingState => ({
    modalVisible: false,
    isSaving: false,
    password: '',
    loaded: false,
    mode: 'regular',
    activityForm: {
        options: new Set(),
    },
    anonymous: { data: [], newGroupName: '' },
    queriesRunning: 0,
})

export const getInitialMeetingFormState = (): MeetingFormState => ({
    loaded: false,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    name: '',
    locationType: 'centre',
    externalLocation: '',
    program: '',
    collaborator: [],
    openMode: 'participant',
    activityConf: { mode: 'none' },
    activityChoiceText: '',
})

export const getInitialStatsState = (): StatsState => ({
    loaded: false,
    centreSelectionType: null,
    dateFrom: '',
    dateTo: '',
    includeArchived: false,
    partition: null,
})

export const getInitialEsfReportState = (): EsfReportState => ({
    cards: null,
    centres: null,
    filters: {
        phase3EndDate: '',
        phase2StartDate: '',
        centreId: '',
        partition: '',
        phase2Or3Date: '',
    },
})

export const getInitialCardsEmployeeUpdateState = (): CardsEmployeeUpdateState => ({
    centres: null,
    employees: null,
    cards: null,
    centreId: '',
    origEmployeeId: '',
    destEmployeeId: '',
    status: 'notStarted',
})

export const getInitialParentNotificationState = (): ParentNotificationState => ({
    subject: '',
    textTemplate: '',
})

export const getInitialVisitorSearchState = (): VisitorSearchState => ({
    search: '',
    matches: [],
    moreMatches: false,
})

export const getInitialVisitorStatsState = (): VisitorStatsState => ({
    loaded: false,
    dataLoading: false,
    statsData: null,
})

export const getInitialAppState = (route: string[]): AppState => ({
    route,
    session: {
        loggedIn: false,
    },
    editVisitor: getInitialEditVisitorState(),
    login: getInitialLoginState(),
    eventList: getInitialEventListState(),
    event: getInitialEventState(),
    eventForm: getInitialEventFormState(),
    meeting: getInitialMeetingState(),
    meetingForm: getInitialMeetingFormState(),
    stats: getInitialStatsState(),
    esfReport: getInitialEsfReportState(),
    cardsEmployeeUpdate: getInitialCardsEmployeeUpdateState(),
    parentNotificationState: getInitialParentNotificationState(),
    visitorSearch: getInitialVisitorSearchState(),
    visitorStats: getInitialVisitorStatsState(),
    validationErrors: {},
})
