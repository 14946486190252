import { CollabKey, Event, Meeting, Option, Participation } from '../../server/types'
import { centreFormFields } from '../../util/centre-form-fields'
import { DropdownOption } from '../dropdown'
import { EditMeetingFormProps, MeetingFieldsProps } from '../edit-meeting-form'
import { Session } from '../session'
import { AppView } from '../state'
import { Utils } from '../utils'
import { getValErrProps } from '../validation'
import { getActivityConfigProps } from './activity-config'

type Program = Meeting['program']

// TODO refactor?
export interface EditMeetingFormParams {
    isNew?: boolean
    meeting?: Meeting // Required if isNew = false
    event: Event
    afterUpdate?: () => void // Not required if isNew,
}

export const getEditMeetingFormProps = (view: AppView, params: EditMeetingFormParams) => {
    const { state, update } = view
    const { meetingForm } = state
    const { event: evt } = params

    const props: EditMeetingFormProps = {
        fields: getFieldsProps(view, params),
    }

    if (evt.activityConf.mode === 'meeting') {
        let inUse = false
        let usedOptions: string[] | undefined

        if (!params.isNew) {
            inUse = params.meeting!.participations.length > 0

            if (meetingForm.activityConf!.mode === 'choice') {
                usedOptions = getUsedOptions(params.meeting!.participations)
            }
        }

        props.activityConfig = getActivityConfigProps(view, {
            inUse: !params.isNew && inUse,
            usedOptions,
            activityConf: meetingForm.activityConf!,
            afterConfChange: update,
            showMeetingOption: false,
            validationErrors: state.validationErrors,
            validationPrefix: 'meeting',
            choiceText: meetingForm.activityChoiceText,
            onChoiceTextChange: (value) => {
                meetingForm.activityChoiceText = value
                update()
            },
        })
    }

    return props
}

const getFieldsProps = (view: AppView, params: EditMeetingFormParams) => {
    const { state, update } = view
    const { meetingForm, validationErrors } = state

    const centre = Session.getCentre(view)!
    const evt = params.event

    const props: MeetingFieldsProps = {
        eventName: evt.name,
        locationType: {
            label: 'Asukoht',
            buttons: {
                centre: {
                    checked: meetingForm.locationType === 'centre',
                    onSelect: () => {
                        meetingForm.locationType = 'centre'
                        update()
                    },
                    label: `Noortekeskus (${centre.name})`,
                },
                external: {
                    checked: meetingForm.locationType === 'external',
                    onSelect: () => {
                        meetingForm.locationType = 'external'
                        update()
                    },
                    label: 'Muu:',
                },
            },
            other: {
                value: meetingForm.externalLocation,
                onChange: (value) => {
                    meetingForm.externalLocation = value
                    meetingForm.locationType = 'external'
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'meeting.externalLocation'),
        },
        name: {
            label: 'Kohtumise nimetus',
            input: {
                value: meetingForm.name,
                onChange: (value) => {
                    meetingForm.name = value
                    update()
                },
            },
        },
        startDate: {
            input: {
                value: meetingForm.startDate,
                onChange: (value) => {
                    meetingForm.startDate = value
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'meeting.startDate'),
        },
        startTime: {
            input: {
                value: meetingForm.startTime,
                onChange: (value) => {
                    meetingForm.startTime = value
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'meeting.startTime'),
        },
        endDate: {
            input: {
                value: meetingForm.endDate,
                onChange: (value) => {
                    meetingForm.endDate = value
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'meeting.endDate'),
        },
        endTime: {
            input: {
                value: meetingForm.endTime,
                onChange: (value) => {
                    meetingForm.endTime = value
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'meeting.endTime'),
        },
    }

    const fieldId = 'seotud-programmid'
    const fieldValue = (meetingForm.formValues || {})[fieldId]

    if (fieldValue && Object.keys(fieldValue).length) {
        const field = Utils.findById(centreFormFields, fieldId)!
        const existingValue = params.isNew ? null : params.meeting!.program

        const options: DropdownOption<Program>[] = [
            { id: '', label: '' },
            ...field
                .keys!.filter((key) => key.id in fieldValue || key.id === existingValue)
                .map((key) => ({ id: key.id as Program, label: key.label || '' })),
        ]

        props.program = {
            label: 'Programm (pearahastaja)',
            input: {
                options,
                value: meetingForm.program,
                onChange: (program) => {
                    meetingForm.program = program
                    update()
                },
                additional: { style: { height: 26 } }, // TODO
            },
            error: getValErrProps(validationErrors, 'meeting.program'),
        }
    }

    const options = getCollaboratorOptions(view, params)

    if (options.length) {
        const choices = meetingForm.collaborator.map((key) => ({ key }))

        props.collaborator = {
            label: 'Koostööd tehti',
            input: {
                value: { choices, other: null },
                onChange: (newValue) => {
                    const newCollaborators = newValue.choices.map((choice) => choice.key)
                    meetingForm.collaborator = newCollaborators as CollabKey[]
                    update()
                },
                options,
                columns: options.length > 5 ? 2 : undefined,
            },
            error: getValErrProps(validationErrors, 'meeting.collaborator'),
        }
    }

    if (params.isNew) {
        props.mode = {
            label: 'Režiim',
            participant: {
                button: {
                    checked: meetingForm.openMode === 'participant',
                    onSelect: () => {
                        meetingForm.openMode = 'participant'
                        update()
                    },
                    label: 'Osaleja režiim',
                },
                note: 'Osalejad saavad ennast ise kirja panna. Isikuandmed on rohkem varjatud ja paljud funktsioonid turvalisuse eesmärgil kättesaamatud. Töötaja režiimi lülitumiseks tuleb sisestada parool uuesti.',
            },
            employee: {
                button: {
                    checked: meetingForm.openMode === 'employee',
                    onSelect: () => {
                        meetingForm.openMode = 'employee'
                        update()
                    },
                    label: 'Töötaja režiim',
                },
                note: 'Kasuta seda režiimi kui arvutile ligipääs on ainult sinul. Näiteks selleks, et tagantjärgi andmeid sisestada.',
            },
        }
    }

    return props
}

const getUsedOptions = (participations: Participation[]) => {
    const usedOptions = new Set<string>()

    for (const { activity } of participations) {
        if (typeof activity === 'object' && activity) {
            for (const option of activity.selected) {
                usedOptions.add(option)
            }
        }
    }

    return [...usedOptions]
}

const getCollaboratorOptions = (view: AppView, params: EditMeetingFormParams) => {
    const { state } = view
    const { meetingForm } = state

    const fieldId = 'koostoo-vorgustik'
    const fieldValue = (meetingForm.formValues || {})[fieldId] // TODO: || {} not needed?

    if (!fieldValue || !Object.keys(fieldValue).length) {
        return []
    }

    const otherKey = 'muu'
    let otherUsed = false

    // TODO create Set<string> from fieldValue instead?
    const combinedValue = Utils.clone(fieldValue)

    if (!params.isNew) {
        // Combine with existing values
        for (const key of params.meeting!.collaborator || []) {
            if (key === otherKey) {
                otherUsed = true
            }

            if (!(key in combinedValue)) {
                combinedValue[key] = ''
            }
        }
    }

    const field = Utils.findById(centreFormFields, fieldId)!
    const options: Option[] = []

    for (const key of field.keys!) {
        if (key.id in combinedValue) {
            let label = key.label

            if (key.id === otherKey) {
                if (fieldValue[otherKey]) {
                    label = fieldValue[otherKey]!
                } else if (!otherUsed) {
                    continue
                }
            }

            options.push({ id: key.id, label })
        }
    }

    return options
}
