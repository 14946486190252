import { FC } from 'react'

import { renderButtonGroup } from './button-group'
import { AppView } from './state'
import { VisitorMerge } from './visitor-merge'

export interface DevPanelProps {
    view: AppView
    tool?: string
}

const renderMenu = (view: AppView, tool?: string) => (
    <div style={{ border: '1px solid #ddd', borderTop: 0, padding: 8 }}>
        {renderButtonGroup({
            buttons: [{ value: 'visitor-merge', label: 'Visitor merge' }],
            onClick: (value) => view.navigate(['dev', value]),
            active: tool,
        })}
    </div>
)

const renderTool = (view: AppView, tool?: string) => {
    if (tool === 'visitor-merge') {
        return <VisitorMerge view={view} />
    } else {
        return null
    }
}

export const DevPanel: FC<DevPanelProps> = ({ view, tool }) => (
    <div>
        {renderMenu(view, tool)}
        {renderTool(view, tool)}
    </div>
)
