import { FC } from 'react'

import { EmployeeInfo, EmployeeInfoProps } from './employee-info'
import { TabMenu, TabMenuProps } from './tab-menu'

export interface HeaderProps {
    employeeInfo: EmployeeInfoProps
    tabMenu: TabMenuProps
}

export const Header: FC<HeaderProps> = (props) => (
    <div>
        <div id="user-info">
            <EmployeeInfo {...props.employeeInfo} />
        </div>
        <TabMenu {...props.tabMenu} />
    </div>
)
