import { FC } from 'react'

export interface CheckboxProps {
    checked: boolean
    onChange: (checked: boolean) => void
}

export const Checkbox: FC<CheckboxProps> = (props) => (
    <input
        type="checkbox"
        checked={props.checked}
        onChange={(evt) => props.onChange(evt.target.checked)}
    />
)
