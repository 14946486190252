import { FC, useEffect, useState } from 'react'

import { API } from '../api'
import { LoadingIcon } from '../loading-icon'
import { AppView } from '../state'

interface Props {
    view: AppView // TODO refactor props
    eventIds: string[]
    startDate?: string
    endDate?: string
}

export const CentreCount: FC<Props> = ({ view, eventIds, startDate, endDate }) => {
    const [centreCount, setCentreCount] = useState<number | undefined>(undefined)

    const countCentres = async () => {
        const count = await API.countVisitedCentres(view, eventIds, startDate, endDate)
        setCentreCount(Number(count))
    }

    useEffect(() => {
        countCentres()
    }, [eventIds, startDate, endDate])

    if (centreCount === undefined) {
        return <LoadingIcon />
    }

    return <div>Statistika arvutatud {centreCount} keskuse andmete põhjal</div>
}
