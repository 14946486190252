import { BirthDate, CentreForm, CentreFormContents, CentreFormRev } from '../server/types'
import { clone } from '../util/clone'
import { keys } from '../util/keys'
import { sortAsStrings } from '../util/sort-as-strings'

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

let now: Date | undefined

const verifyDate = (date: Date) => {
    if (isNaN(date.getTime())) {
        throw new Error('Invalid date')
    }
}

export const iterMap = <T>(
    obj: Record<string, T>,
    elementCallback: (value: T, key: string) => void,
) =>
    Object.keys(obj).forEach((key) => {
        const element = obj[key]
        elementCallback(element, key)
    })

// TODO move members to src/util
export const CommonUtils = {
    clone,

    arrayContains: <T>(array: T[], element: T) => array.indexOf(element) !== -1,

    member: <T>(map: Record<string, T>, key: string, def: T): NonNullable<T> => {
        if (!(key in map)) {
            map[key] = def
        }

        return map[key] as NonNullable<T>
    },

    iterMap,

    filterMap: <T>(obj: Record<string, T>, predicate: (value: T) => boolean) => {
        const result: T[] = []

        for (const key of keys(obj)) {
            const element = obj[key]

            if (predicate(element)) {
                result.push(element)
            }
        }

        return result
    },

    mapMap: <T, U>(obj: Record<string, T>, mapFunc: (value: T, key: string) => U) =>
        Object.keys(obj).map((key) => mapFunc(obj[key], key)),

    mapMapToMap: <T, U>(obj: Record<string, T>, mapFunc: (value: T, key: string) => U) => {
        const result: Record<string, U> = {}

        for (const key of Object.keys(obj)) {
            result[key] = mapFunc(obj[key], key)
        }

        return result
    },

    mapValues: <T>(map: Record<string, T>) => CommonUtils.mapMap(map, CommonUtils.identity),

    mapSome: <T>(obj: Record<string, T>, predicate: (value: T, key: string) => boolean) =>
        Object.keys(obj).some((key) => predicate(obj[key], key)),

    identity: <T>(value: T) => value,

    sortAsStrings,

    findByField: <T, K extends keyof T>(
        map: Record<string, T> | T[],
        fieldName: K,
        expectedValue: T[K],
        noError?: boolean,
    ) => {
        const filter = (element: T) => element[fieldName] === expectedValue
        const matches = Array.isArray(map) ? map.filter(filter) : CommonUtils.filterMap(map, filter)

        if (matches.length !== 1) {
            if (noError) {
                return null
            } else {
                throw new Error(
                    `Single element with ${String(fieldName)} ${expectedValue} not found`,
                )
            }
        }

        return matches[0]
    },

    findById: <T extends { id: string }>(
        map: Record<string, T> | T[],
        expectedId: string,
        noError?: boolean,
    ) => CommonUtils.findByField(map, 'id', expectedId, noError),

    findIndex: <T>(array: T[], predicate: (value: T) => boolean) => {
        for (let i = 0; i < array.length; i++) {
            if (predicate(array[i])) {
                return i
            }
        }

        return -1
    },

    findIndexByField: <T, K extends keyof T>(array: T[], fieldName: K, expectedValue: T[K]) => {
        return CommonUtils.findIndex(array, (element) => element[fieldName] === expectedValue)
    },

    getNow() {
        return now ? new Date(now) : new Date()
    },

    // Only meant to be used in tests
    setNow(nowParam: string) {
        now = new Date(nowParam)
    },

    getCurrentYear(refDateParam?: Date) {
        const refDate = refDateParam || CommonUtils.getNow()

        if (isNaN(refDate.getTime())) {
            throw new Error('Invalid date')
        }

        return refDate.getFullYear()
    },

    maxAge: 27,

    getMaxAgeYear(refDate?: Date) {
        return CommonUtils.getCurrentYear(refDate) - CommonUtils.maxAge - 1
    },

    formatTime(date: Date) {
        verifyDate(date)

        return (
            CommonUtils.twoLastDigits(date.getHours()) +
            ':' +
            CommonUtils.twoLastDigits(date.getMinutes())
        )
    },

    formatTimeWithSeconds(date: Date) {
        verifyDate(date)

        return (
            CommonUtils.twoLastDigits(date.getHours()) +
            ':' +
            CommonUtils.twoLastDigits(date.getMinutes()) +
            ':' +
            CommonUtils.twoLastDigits(date.getSeconds())
        )
    },

    formatDate(date: Date) {
        verifyDate(date)

        return (
            CommonUtils.twoLastDigits(date.getDate()) +
            '.' +
            CommonUtils.twoLastDigits(date.getMonth() + 1) +
            '.' +
            date.getFullYear()
        )
    },

    formatDateYmd(date: Date) {
        verifyDate(date)

        return (
            date.getFullYear() +
            '-' +
            CommonUtils.twoLastDigits(date.getMonth() + 1) +
            '-' +
            CommonUtils.twoLastDigits(date.getDate())
        )
    },

    formatDateTime(date: Date) {
        return CommonUtils.formatDate(date) + ' ' + CommonUtils.formatTime(date)
    },

    formatDateFromString(string: string) {
        return CommonUtils.formatDate(new Date(Date.parse(string)))
    },

    formatDateTimeFromString(string: string) {
        const date = new Date(Date.parse(string))
        return CommonUtils.formatDateTime(date)
    },

    // Param format: { year: int, month: int, day: int, maxAge: bool }
    // Any member may be missing and the param can be null.
    // If maxAge is true, year is ignored and should be missing or empty.
    formatPartialDate(date: BirthDate) {
        let result = ''

        if (date) {
            if (date.maxAge) {
                // We assume that the user's birth year was set to maxAge at the time of refDate.
                const refDate = CommonUtils.getNow()
                result = CommonUtils.getMaxAgeYear(refDate) + ' või varem'
            } else if (date.year) {
                result = String(date.year)

                if (date.month) {
                    result = CommonUtils.twoLastDigits(date.month) + '.' + result

                    if (date.day) {
                        result = CommonUtils.twoLastDigits(date.day) + '.' + result
                    }
                }
            } else if (date.month && date.day) {
                result =
                    CommonUtils.twoLastDigits(date.day) +
                    '.' +
                    CommonUtils.twoLastDigits(date.month)
            }
        }

        return result
    },

    twoLastDigits(num: number) {
        // Zero-pads the number if it's between 0 and 99
        return String(Number(num) + 100).substr(-2)
    },

    getLatestRevNumber(formData: CentreForm) {
        return formData.revisions[formData.revisions.length - 1].rev
    },

    getRevision(revisions: CentreFormRev[], revNumber: number) {
        const values: CentreFormContents = {}

        // Apply diff from each revision up to the requested one
        for (const revision of revisions) {
            if (revision.rev > revNumber) {
                continue
            }

            if (revision.fieldsSet) {
                for (const fieldName of keys(revision.fieldsSet)) {
                    values[fieldName] = revision.fieldsSet![fieldName] as any // TODO
                }
            }

            if (revision.fieldsUnset) {
                for (const fieldName of revision.fieldsUnset) {
                    delete values[fieldName]
                }
            }
        }

        // Avoid sharing any references with input object
        return CommonUtils.clone(values)
    },

    bytesPerKiB: 1024,
    bytesToKiB: (bytes: number) => bytes / CommonUtils.bytesPerKiB,
    bytesPerMiB: 1048576,
    bytesToMiB: (bytes: number) => bytes / CommonUtils.bytesPerMiB,
    bytesPerGiB: 1073741824,
    bytesToGiB: (bytes: number) => bytes / CommonUtils.bytesPerGiB,

    randomString: (strLength: number) => {
        let str = ''

        for (let i = 0; i < strLength; i++) {
            str += chars.charAt(Math.floor(Math.random() * chars.length))
        }

        return str
    },

    minutesSinceMidnight: (time: string) => {
        const match = /([0-2][0-9]):([0-5][0-9])/.exec(time)

        if (!match) {
            throw new Error('Invalid time: ' + time)
        }

        const hours = parseInt(match[1], 10)
        const minutes = parseInt(match[2], 10)

        if (hours > 23) {
            throw new Error('Invalid time: ' + time)
        }

        return hours * 60 + minutes
    },
}
