import { CSSProperties } from 'react'

import { LoadingIcon } from './loading-icon'

export interface LinkProps {
    isLoading?: boolean
    text: string
    onClick?: () => void
    url?: string
    className?: string
    style?: CSSProperties
    id?: string
}

export const Link = (props: LinkProps) =>
    props.isLoading ? (
        <LoadingIcon />
    ) : (
        <a
            onClick={props.onClick}
            href={props.url}
            className={props.className}
            style={props.style}
            id={props.id}
        >
            {props.text}
        </a>
    )
