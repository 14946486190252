import { FC } from 'react'

import { Button, ButtonProps } from './button'
import { EmployeeInfo, EmployeeInfoProps } from './employee-info'
import { LoadingIcon } from './loading-icon'
import { MeetingDetails, MeetingDetailsProps } from './meeting-details'
import { ParticipantTable, ParticipantTableProps } from './participant-table'
import { TextInput, TextInputProps } from './text-input'
import { ValErr, ValErrProps } from './validation'

export interface ParticipantModeMeetingProps {
    employeeInfo: EmployeeInfoProps
    isChangingMode: boolean
    password: {
        label: string
        input: TextInputProps
        error?: ValErrProps
    }
    modeButton: ButtonProps
    meetingDetails?: MeetingDetailsProps
    participantTable: ParticipantTableProps
}

export const ParticipantModeMeeting: FC<ParticipantModeMeetingProps> = (props) => (
    <div>
        <div style={{ float: 'right' }}>
            <EmployeeInfo {...props.employeeInfo} />
            {
                // Wrap in a form to avoid Chrome treating an unrelated text field
                // as the username input linked to the password input.
                <form style={{ marginTop: 10 }}>
                    {props.isChangingMode ? <LoadingIcon /> : null}
                    {props.password.label}
                    {': '}
                    <TextInput {...props.password.input} /> <Button {...props.modeButton} />
                </form>
            }
            {props.password.error && <ValErr {...props.password.error} />}
        </div>
        <h2 className="topmost">Kohtumine</h2>
        {props.meetingDetails ? (
            <MeetingDetails {...props.meetingDetails} />
        ) : (
            <div>Laen kohtumise infot...</div>
        )}
        <h2 style={{ marginBottom: 20 }}>Osalejad</h2>
        <ParticipantTable {...props.participantTable} />
    </div>
)
