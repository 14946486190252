import moment from 'moment'
import { Component } from 'react'

import { GetCentreVisitors_Response } from '../server/commands/get-centre-visitors'
import { API } from './api'
import { DateField } from './date-field'
import { Loading } from './loading'
import { LoadingButton } from './loading-button'
import { ManageCentreMenu } from './manage-centre-menu'
import { Column, getTableProps } from './props/table'
import { AppView } from './state'
import { Table } from './table'
import { Utils } from './utils'

interface CentreVisitorsProps {
    view: AppView // TODO refactor props
}

interface State {
    dateFrom: string
    dateTo: string
    visitors?: GetCentreVisitors_Response
}

interface Row {
    id: string
    username: string
    name: string
}

const columns: Column<Row>[] = [
    {
        id: 'username',
        header: 'Kasutajanimi',
    },
    {
        id: 'name',
        header: 'Nimi',
    },
]

export class CentreVisitors extends Component<CentreVisitorsProps, State> {
    state: State = { dateFrom: '', dateTo: '' }

    async componentDidMount() {
        const { view } = this.props
        const now = moment()
        const dateFrom = now.date(1).format('YYYY-MM-DD')
        const dateTo = Utils.formatDateYmd(Utils.getNow())
        const visitors = await API.getCentreVisitorsForPeriod(view, dateFrom, dateTo)
        this.setState({ dateFrom, dateTo, visitors })
    }

    getRows() {
        const visitorIds = Object.keys(this.state.visitors!)

        return visitorIds.map((visitorId) => {
            const { username, firstname, lastname } = this.state.visitors![visitorId]
            const name = firstname && lastname ? firstname + ' ' + lastname : ''
            return { id: visitorId, username, name }
        })
    }

    async loadVisitors() {
        const { view } = this.props
        this.setState({ visitors: undefined })
        const { dateFrom, dateTo } = this.state
        const visitors = await API.getCentreVisitorsForPeriod(view, dateFrom, dateTo)
        this.setState({ visitors })
    }

    renderDateFilter() {
        return (
            <div
                className="form-inline"
                style={{ display: 'inline-block', paddingTop: 10, paddingBottom: 10 }}
            >
                {'Näita keskuse külastajaid ajavahemikus '}
                <DateField
                    value={this.state.dateFrom}
                    onChange={(value) => this.setState({ dateFrom: value })}
                />
                {' kuni '}
                <DateField
                    value={this.state.dateTo}
                    onChange={(value) => this.setState({ dateTo: value })}
                />{' '}
                <LoadingButton text="Uuenda" getPromise={async () => this.loadVisitors()} />
            </div>
        )
    }

    renderList() {
        if (!this.state.visitors) {
            return <Loading />
        }

        return (
            <Table
                {...getTableProps({
                    className: 'bordered',
                    columns,
                    rows: this.getRows(),
                })}
            />
        )
    }

    render() {
        const { view } = this.props
        return (
            <div>
                <ManageCentreMenu view={view} active="visitors" />
                <div className="main-panel">
                    <h2>Keskuse külastajad</h2>
                    {this.renderDateFilter()}
                    {this.renderList()}
                </div>
            </div>
        )
    }
}
