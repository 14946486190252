import { Component, ReactNode } from 'react'

import { EventCategoryHierarchy } from '../server/commands/get-event-category-hierarchy'
import { Event, ExtendedCentre } from '../server/types'
import { EventStatsCategorySelection } from './event-stats/category-selection'
import { SearchBox } from './search-box'
import { Utils } from './utils'

interface Props {
    eventCategoryHierarchy: EventCategoryHierarchy
    selectedIds: string[]
    onSelectionChange: (eventIds: string[], reload: boolean) => void
    isAdminMode: boolean
}

interface State {
    search: string
}

export class SelectStatsEventPanel extends Component<Props, State> {
    state: State = { search: '' }

    async onSearchChange(search: string) {
        this.setState({ search })
    }

    selectSingle(eventId: string) {
        this.props.onSelectionChange([eventId], true)
    }

    selectAll() {
        const eventIds: string[] = []

        this.props.eventCategoryHierarchy.forEach((centre) => {
            centre.eventCategories.forEach((cat) => {
                cat.events.forEach((evt) => eventIds.push(evt._id))
            })
        })

        this.props.onSelectionChange(eventIds, true)
    }

    selectNone() {
        this.props.onSelectionChange([], true)
    }

    renderCentre(centre: ExtendedCentre) {
        const categoriesProps: {
            key: string
            categoryName?: string
            events: Event[]
        }[] = []
        const searchLower = this.state.search.toLowerCase()
        let hiddenSelectionCount = 0

        for (const cat of centre.eventCategories) {
            const matchingEvents = cat.events.filter((evt) => {
                const matches = evt.name.toLowerCase().indexOf(searchLower) !== -1
                const isSelected = Utils.arrayContains(this.props.selectedIds, evt._id)

                if (!matches && isSelected) {
                    hiddenSelectionCount += 1
                }

                return matches
            })

            if (matchingEvents.length > 0) {
                categoriesProps.push({
                    key: cat._id || ' ',
                    categoryName: cat.name,
                    events: matchingEvents,
                })
            }
        }

        let hiddenSelectionInfo: ReactNode = null

        if (hiddenSelectionCount) {
            hiddenSelectionInfo = (
                <div style={{ color: 'grey', fontSize: '85%', marginBottom: 5 }}>
                    ({Utils.pluralize(hiddenSelectionCount, 'valitud sündmus', 'valitud sündmust')}
                    {' on hetkel filtri tõttu peidetud)'}
                </div>
            )
        }

        return (
            <div key={centre._id} className="event-category-container">
                <div className="bold">{centre.name}</div>
                {categoriesProps.map(({ key, categoryName, events }) => (
                    <EventStatsCategorySelection
                        key={key}
                        categoryName={categoryName}
                        events={events}
                        searchString={searchLower}
                        selectedEventIds={this.props.selectedIds}
                        onSelectionChange={this.props.onSelectionChange}
                    />
                ))}
                {hiddenSelectionInfo}
            </div>
        )
    }

    render() {
        return (
            <div id="select-event">
                <h2>Vali sündmus</h2>
                <div>
                    <a style={{ cursor: 'pointer' }} onClick={() => this.selectAll()}>
                        Vali kõik sündmused
                    </a>
                </div>
                <div>
                    <a style={{ cursor: 'pointer' }} onClick={() => this.selectNone()}>
                        Tühista kõik valikud
                    </a>
                </div>
                <div style={{ fontSize: '85%', color: 'grey', marginTop: 10 }}>
                    Kliki mõne sündmuse nimele, et jätta valikusse ainult see sündmus.
                </div>
                <hr />
                <div style={{ marginBottom: 10 }}>
                    <SearchBox
                        onChange={async (evt) => this.onSearchChange(evt.currentTarget.value)}
                        search={this.state.search}
                    />
                </div>
                {this.props.eventCategoryHierarchy.map((centre) => this.renderCentre(centre))}
            </div>
        )
    }
}
