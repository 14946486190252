import classnames from 'classnames'
import { FC, ReactNode, useEffect } from 'react'

import { Button, ButtonProps } from './button'

let simpleMode = false

export const setSimpleModalMode = () => {
    // For automatic tests, avoids backdrop flicker.
    // Possibly speeds up rendering as well.
    simpleMode = true
}

export interface CustomModalProps {
    children?: ReactNode
    title: string
    closeModal: () => Promise<void> | void
    buttons: ButtonProps[]
    dialogClassName?: string
}

export const CustomModal: FC<CustomModalProps> = (props) => {
    const onKeyUp = async (evt: KeyboardEvent) => {
        if (evt.key === 'Escape' || evt.keyCode === 27) {
            await props.closeModal()
        }
    }

    // TODO refactor?
    useEffect(() => {
        document.addEventListener('keyup', onKeyUp)
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = ''
            document.removeEventListener('keyup', onKeyUp)
        }
    }, [])

    return (
        <div>
            {!simpleMode && <div className="modal-backdrop fade in" />}
            <div className="modal" style={{ display: 'block' }}>
                <div className={classnames('modal-dialog', props.dialogClassName)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={props.closeModal}>
                                ×
                            </button>
                            <h4 className="modal-title">{props.title}</h4>
                        </div>
                        <div className="modal-body">{props.children}</div>
                        <div className="modal-footer">
                            {props.buttons.map((button, index) => (
                                <Button key={index} {...button} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
