import { EmployeeValue, EventFieldsProps } from '../edit-event-form'
import { Session } from '../session'
import { AppView } from '../state'
import { getValErrProps } from '../validation'

export const getEventFieldsProps = (view: AppView, inUse: boolean) => {
    const { state, update } = view
    const { validationErrors } = state

    const centre = Session.getCentre(view)!

    const props: EventFieldsProps = {
        eventName: {
            label: 'Nimi',
            input: {
                value: state.eventForm.name,
                onChange: (value) => {
                    state.eventForm.name = value
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'event.name'),
        },
        centreName: {
            label: 'Noortekeskus',
            value: centre.name,
        },
        category: {
            label: 'Kategooria',
            input: {
                value: state.eventForm.category ?? '',
                onChange: ({ currentTarget }) => {
                    state.eventForm.category = currentTarget.value || undefined
                    update()
                },
            },
            options: [
                {
                    reactKey: '',
                    value: '',
                    children: '- Puudub -',
                },
                ...state.eventList.eventCategories.map(({ _id, name }) => ({
                    reactKey: _id,
                    value: _id,
                    children: name,
                })),
            ],
            error: getValErrProps(validationErrors, 'event.category'),
        },
        type: {
            label: 'Sündmuse tüüp',
            buttons: [
                {
                    reactKey: 'open',
                    checked: state.eventForm.type === 'open',
                    onSelect: () => {
                        state.eventForm.type = 'open'
                        update()
                    },
                    label: 'Avatud osalus',
                },
                {
                    reactKey: 'closed',
                    checked: state.eventForm.type === 'closed',
                    onSelect: () => {
                        state.eventForm.type = 'closed'
                        update()
                    },
                    label: 'Kindel grupp',
                },
            ],
            error: getValErrProps(validationErrors, 'event.type'),
        },
        employees: {
            label: 'Töötajad',
            input: {
                employees: state.eventList.employees,
                selectedEmployees: state.eventForm.employees,
                onAddEmployee: (employee) => {
                    const newEmployees = state.eventForm.employees.slice()
                    newEmployees.push(employee._id)
                    state.eventForm.employees = newEmployees
                    update()
                },
            },
            values: state.eventForm.employees.map((employeeId, index) => {
                const value: EmployeeValue = {
                    reactKey: employeeId,
                    name: '',
                    remove: () => () => {
                        const newEmployees = state.eventForm.employees.slice()
                        newEmployees.splice(index, 1)
                        state.eventForm.employees = newEmployees
                        update()
                    },
                }

                let employee = state.eventList.employees[employeeId]

                if (!employee) {
                    // Employee is no longer linked to the centre, look in extended set
                    employee = state.eventList.extendedEmployees[employeeId]
                    value.isFaded = true
                }

                if (employee) {
                    value.name = employee.name
                }

                return value
            }),
            error: getValErrProps(validationErrors, 'event.employees'),
        },
        description: {
            label: 'Sisu',
            input: {
                style: { width: 300, height: 150 },
                value: state.eventForm.description,
                onChange: ({ currentTarget }) => {
                    state.eventForm.description = currentTarget.value
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'event.description'),
        },
        timesNeeded: {
            input: {
                disabled: inUse,
                checked: state.eventForm.timesNeeded,
                onChange: ({ currentTarget }) => {
                    state.eventForm.timesNeeded = currentTarget.checked
                    update()
                },
            },
            error: getValErrProps(validationErrors, 'event.timesNeeded'),
        },
    }

    return props
}
