import { Employee, LoggedInUserContext } from '../server/types'
import { AppView } from './state'

const navigateIfNotLoggedIn = (view: AppView) => {
    if (!view.state.session.loggedIn) {
        view.navigate(['login'])
    }
}

export const getEmployee = (view: AppView): Omit<Employee, 'authHash'> => {
    navigateIfNotLoggedIn(view)
    return (view.state.session as LoggedInUserContext).employee // TODO
}

export const getCentre = (view: AppView) => {
    navigateIfNotLoggedIn(view)
    return (view.state.session as LoggedInUserContext).centre // TODO
}

export const isEmployeeMode = (view: AppView) => {
    navigateIfNotLoggedIn(view)
    return (view.state.session as LoggedInUserContext).employeeMode // TODO
}

export const isCentreSelected = (view: AppView) => {
    navigateIfNotLoggedIn(view)
    return (view.state.session as LoggedInUserContext).centre !== null // TODO
}

// TODO inline
export const Session = {
    getEmployee,
    getCentre,
    isEmployeeMode,
    isCentreSelected,
}
