import classnames from 'classnames'
import * as Immutable from 'immutable'
import { Component } from 'react'

import { CardDiaryEntryInput, Field } from '../server/types'
import { API } from './api'
import { FileManagement } from './card-diary/file-management'
import { EventBus } from './event-bus'
import { Form } from './form'
import { Loading } from './loading'
import { LoadingButton } from './loading-button'
import { AppView } from './state'
import { Utils } from './utils'
import { checkValidationErrors, clearValidationErrors, ValidationError } from './validation'

export interface CardDiaryProps {
    reactKey?: string
    view: AppView // TODO refactor props
    editMode: boolean
    isNew: boolean
    cardId?: string
    entryId?: string
}

type FieldName = 'date' | 'location' | 'participants' | 'notes'

interface State {
    loaded: boolean
    cardId?: string
    values: Immutable.Map<FieldName, string>
    validationErrors?: Record<string, ValidationError>
    fileOperationInProgress: boolean
}

export class CardDiary extends Component<CardDiaryProps, State> {
    unmounted = false

    constructor(props: CardDiaryProps) {
        super(props)

        const common = {
            values: Immutable.Map<FieldName, string>(),
            fileOperationInProgress: false,
        }

        if (props.isNew) {
            this.state = { ...common, loaded: true, cardId: this.props.cardId }
        } else {
            this.state = { ...common, loaded: false }
        }
    }

    async componentDidMount() {
        await this.load()
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async load() {
        const { view } = this.props

        if (!this.props.isNew) {
            const entryId = this.props.entryId!
            const response = await API.getCardDiaryEntry(view, entryId)

            await Utils.setState(this, {
                loaded: true,
                cardId: response.cardId,
                values: Immutable.Map<FieldName, string>(response.fields),
            })
        }

        const eventName = this.props.editMode
            ? 'card-diary-edit-rendered'
            : 'card-diary-view-rendered'
        await EventBus.fire(eventName)
    }

    async save() {
        const { view } = this.props

        const entry: CardDiaryEntryInput = this.state.values.toObject() as any // TODO
        let entryId: string
        await clearValidationErrors(view, this)

        try {
            if (this.props.isNew) {
                const response = await API.addCardDiaryEntry(view, this.state.cardId!, entry)
                entryId = response.id
            } else {
                entryId = this.props.entryId!
                await API.updateCardDiaryEntry(view, entryId, entry)
            }

            view.navigate(['cards', 'view-diary', entryId])
        } catch (error) {
            if (!this.unmounted) {
                await checkValidationErrors(view, this, error)
            }
        }
    }

    updateFileOperationInProgress(fileOperationInProgress: boolean) {
        if (fileOperationInProgress !== this.state.fileOperationInProgress) {
            this.setState({ fileOperationInProgress })
        }
    }

    renderBackLink() {
        let url: string
        let text: string

        if (this.props.isNew || !this.props.editMode) {
            const cardId = this.props.isNew ? this.props.cardId : this.state.cardId
            url = '#/cards/view/' + cardId
            text = 'Tagasi juhtumikaardile'
        } else {
            url = '#/cards/view-diary/' + this.props.entryId
            text = 'Tagasi sissekannet vaatama'
        }

        return (
            <div className="bottom-margin">
                <a id="back-link" href={url}>
                    {text}
                </a>
            </div>
        )
    }

    renderVisibilityNote() {
        if (this.props.isNew) {
            return (
                <p>
                    Päeviku sissekanded on töötaja põhised. Need on nähtavad ainult teie
                    kasutajakontole aga mitte teistele selle keskuse kasutajatele.
                </p>
            )
        } else {
            return null
        }
    }

    renderFileManagement() {
        const { view } = this.props

        if (this.props.isNew) {
            return null
        }

        const { editMode } = this.props
        const entryId = this.props.entryId!

        return (
            <FileManagement
                view={view}
                editMode={editMode}
                entryId={entryId}
                updateFileOperationInProgress={(inProgress) =>
                    this.updateFileOperationInProgress(inProgress)
                }
            />
        )
    }

    renderSaveButton() {
        return <LoadingButton id="save" text="Salvesta" getPromise={async () => this.save()} />
    }

    renderEditButton() {
        const { view } = this.props
        const entryId = this.props.entryId
        const disabled = this.state.fileOperationInProgress

        return (
            <button
                className={classnames({ disabled })}
                disabled={disabled}
                onClick={() => view.navigate(['cards', 'edit-diary', entryId!])}
            >
                Muuda
            </button>
        )
    }

    renderEditSaveButton() {
        if (this.props.editMode) {
            return this.renderSaveButton()
        } else {
            return this.renderEditButton()
        }
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const fields: Field[] = [
            {
                id: 'date',
                label: 'Kuupäev',
                type: 'date',
            },
            {
                id: 'location',
                label: 'Koht',
                type: 'text',
                width: '20em',
            },
            {
                id: 'participants',
                label: 'Osalejad',
                type: 'text',
                multiline: true,
                rows: 10,
            },
            {
                id: 'notes',
                label: 'Kohtumise märkmed / Kokkuvõte',
                type: 'text',
                multiline: true,
                rows: 20,
                cols: 80, // TODO: fit on small screens
            },
        ]

        return (
            <div className="main-panel">
                {this.renderBackLink()}
                <h3 style={{ marginTop: 10 }}>
                    <b>Päeviku sissekanne</b>
                </h3>
                {this.renderVisibilityNote()}
                <Form
                    editMode={this.props.editMode}
                    fields={fields}
                    values={this.state.values.toObject()}
                    validationErrors={this.state.validationErrors}
                    validationPrefix="entry."
                    onChange={async (fieldId: string, newValue: string) =>
                        Utils.setState(this, {
                            values: this.state.values.set(fieldId as FieldName, newValue),
                        })
                    }
                />
                {this.renderEditSaveButton()}
                {this.renderFileManagement()}
            </div>
        )
    }
}
