import { FC, useEffect } from 'react'

import { CustomModal, CustomModalProps } from './custom-modal'
import { EditVisitorForm, EditVisitorFormProps } from './edit-visitor-form'
import { LoadingIcon } from './loading-icon'

export interface EditVisitorProps {
    effect: () => void // TODO refactor
    modal: CustomModalProps
    form?: EditVisitorFormProps
}

export const EditVisitor: FC<EditVisitorProps> = (props) => {
    useEffect(props.effect, [])

    return (
        <div>
            <CustomModal {...props.modal}>
                {props.form ? <EditVisitorForm {...props.form} /> : <LoadingIcon />}
            </CustomModal>
        </div>
    )
}
