import { FC } from 'react'

import { CardDiary, CardDiaryProps } from './card-diary'
import { CardList } from './card-list'
import { CardView, CardViewProps } from './card-view'
import { CardsAdmin, CardsAdminProps } from './cards-admin'
import { CardsEmployeeUpdate } from './cards-employee-update'
import { CardsSummary } from './cards-summary'
import { CentreEmployees } from './centre-employees'
import { CentreForm } from './centre-form'
import { CentreVisitors } from './centre-visitors'
import { DevPanel, DevPanelProps } from './dev-panel'
import { EditCardDates, EditCardDatesProps } from './edit-card-dates'
import { Employee } from './employee'
import { Employees } from './employees'
import { EsfReport } from './esf-report'
import { EventList, EventListProps } from './event-list'
import { Header, HeaderProps } from './header'
import { LinkCard, LinkCardProps } from './link-card'
import { Login } from './login'
import { ManageCentre } from './manage-centre'
import { Meeting, MeetingProps } from './meeting'
import { NewCard } from './new-card'
import { ParentNotification } from './parent-notification'
import { SelectCentre } from './select-centre'
import { AppView } from './state'
import { Stats, StatsProps } from './stats'
import { StatsTypeChoice, type StatsTypeChoiceProps } from './stats-type-choice'
import { Transfer, TransferProps } from './transfer'
import { TransferCards } from './transfer-cards'
import { TransferCardsSelection, TransferCardsSelectionProps } from './transfer-cards-selection'
import { TransferCardsSuccess, TransferCardsSuccessProps } from './transfer-cards-success'
import { VisitorSearch } from './visitor-search'

export interface RootProps {
    login?: TempProps
    selectCentre?: TempProps
    header?: HeaderProps
    meeting?: MeetingProps
    eventList?: EventListProps
    visitorSearch?: TempProps
    cardList?: TempProps
    cardsAdmin?: CardsAdminProps
    cardView?: CardViewProps
    newCard?: TempProps
    linkCard?: LinkCardProps
    cardDiary?: CardDiaryProps
    editCardDates?: EditCardDatesProps
    transferCardsSuccess?: TransferCardsSuccessProps
    transferCardsSelection?: TransferCardsSelectionProps
    transferCards?: TempProps
    cardsEmployeeUpdate?: TempProps
    cardsSummary?: TempProps
    esfReport?: TempProps
    statsTypeChoice?: StatsTypeChoiceProps
    stats?: StatsProps
    manageCentre?: TempProps
    centreEmployees?: TempProps
    centreForm?: TempProps
    parentNotification?: TempProps
    centreVisitors?: TempProps
    employees?: TempProps
    employee?: TempProps
    transfer?: TransferProps
    devPanel?: DevPanelProps
}

// TODO convert components to not take view via props
interface TempProps {
    view: AppView
}

export const Root: FC<RootProps> = (props) => (
    // TODO div -> Fragment?
    <div>
        {props.login && <Login {...props.login} />}
        {props.selectCentre && <SelectCentre {...props.selectCentre} />}
        {props.header && <Header {...props.header} />}
        {props.meeting && <Meeting {...props.meeting} />}
        {props.eventList && <EventList {...props.eventList} />}
        {props.visitorSearch && <VisitorSearch {...props.visitorSearch} />}
        {props.cardList && <CardList {...props.cardList} />}
        {props.cardsAdmin && <CardsAdmin {...props.cardsAdmin} />}
        {props.cardView && <CardView key={props.cardView.reactKey} {...props.cardView} />}
        {props.newCard && <NewCard {...props.newCard} />}
        {props.linkCard && <LinkCard {...props.linkCard} />}
        {props.cardDiary && <CardDiary key={props.cardDiary.reactKey} {...props.cardDiary} />}
        {props.editCardDates && <EditCardDates {...props.editCardDates} />}
        {props.transferCardsSuccess && <TransferCardsSuccess {...props.transferCardsSuccess} />}
        {props.transferCardsSelection && (
            <TransferCardsSelection {...props.transferCardsSelection} />
        )}
        {props.transferCards && <TransferCards {...props.transferCards} />}
        {props.cardsEmployeeUpdate && <CardsEmployeeUpdate {...props.cardsEmployeeUpdate} />}
        {props.cardsSummary && <CardsSummary {...props.cardsSummary} />}
        {props.esfReport && <EsfReport {...props.esfReport} />}
        {props.statsTypeChoice && <StatsTypeChoice {...props.statsTypeChoice} />}
        {props.stats && <Stats {...props.stats} />}
        {props.manageCentre && <ManageCentre {...props.manageCentre} />}
        {props.centreEmployees && <CentreEmployees {...props.centreEmployees} />}
        {props.centreForm && <CentreForm {...props.centreForm} />}
        {props.parentNotification && <ParentNotification {...props.parentNotification} />}
        {props.centreVisitors && <CentreVisitors {...props.centreVisitors} />}
        {props.employees && <Employees {...props.employees} />}
        {props.employee && <Employee {...props.employee} />}
        {props.transfer && <Transfer key={props.transfer.reactKey} {...props.transfer} />}
        {props.devPanel && <DevPanel {...props.devPanel} />}
    </div>
)
