let queue = Promise.resolve()

/**
 * Ensures that the given promise will only be executed after all
 * previous promises given to this function have finished
 */
export const queuePromise = async <T>(createPromise: () => Promise<T>) => {
    return new Promise<T>((resolve, reject) => {
        queue = queue.then(async () => {
            try {
                resolve(await createPromise())
            } catch (error) {
                reject(error)
            }
        })
    })
}
