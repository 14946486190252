import { Activity, ActivityConf } from '../../server/types'
import { assert } from '../../util/assert'
import { API } from '../api'
import { EditActivityProps } from '../edit-activity'
import { EditActivityFormProps, OptionProps } from '../edit-activity-form'
import { AppView, MeetingState } from '../state'
import { Utils } from '../utils'
import { addParticipation } from './add-participant-panel'
import { initIfNeeded } from './init-if-needed'
import { setActivity } from './meeting'

export const getEditActivityProps = (view: AppView): EditActivityProps => {
    const { state, update } = view
    const meetingState = state.meeting

    const closeModal = () => {
        delete meetingState.activityModalConf
        update()
    }

    const props: EditActivityProps = {
        modal: {
            title: 'Tegevus',
            closeModal,
            dialogClassName: 'edit-activity',
            buttons: [
                {
                    text: 'Vali',
                    onClick: async () => {
                        const { event, meeting, activityModalConf } = meetingState
                        assert(meeting)
                        const activityConf = Utils.getActivityConf(event!, meeting!)
                        const { action } = activityModalConf!
                        const activity = getActivity(view, activityConf)

                        if (action.type === 'add') {
                            await addParticipation(view, meeting._id, action.username, activity)
                        } else if (action.type === 'update') {
                            await setActivity(view, meeting._id, action.participationId, activity)
                        }

                        closeModal()
                    },
                    style: { marginRight: 5 },
                },
                {
                    text: 'Tühista',
                    onClick: closeModal,
                },
            ],
        },
        form: getFormProps(view),
    }

    return props
}

const getFormProps = (view: AppView): EditActivityFormProps => {
    const { state, update } = view
    const meetingState = state.meeting

    const { event, meeting, activityModalConf } = meetingState
    const activityConf = Utils.getActivityConf(event!, meeting!)
    const { activity } = activityModalConf!

    initIfNeeded(view, 'editActivityForm', async () => {
        const mode = activityConf.mode

        if (mode === 'choice') {
            const formState: MeetingState['activityForm'] = {
                options: new Set(),
            }

            if (activity) {
                const activityObj = asActivityObject(activity)

                for (const [index, option] of activityConf.options!.entries()) {
                    if (activityObj.selected.includes(option)) {
                        formState.options.add(index)
                    }
                }

                if (activityConf.other) {
                    formState.other = activityObj.other
                }
            }

            state.meeting.activityForm = formState
        } else if (mode === 'freeform') {
            state.meeting.activityForm = {
                options: new Set(),
                freeform: asActivityString(activity!),
            }
        } else {
            throw new Error('Invalid mode:' + mode)
        }
    })

    const props: EditActivityFormProps = {}

    const formState = meetingState.activityForm
    const { mode } = activityConf

    if (mode === 'choice') {
        props.options = activityConf.options!.map(
            (option, index): OptionProps => ({
                reactKey: String(index),
                checkbox: {
                    checked: formState.options.has(index),
                    onChange: (checked) => {
                        if (checked) {
                            formState.options.add(index)
                        } else {
                            formState.options.delete(index)
                        }

                        update()
                    },
                },
                label: option,
            }),
        )

        if (activityConf.other) {
            props.textInputLabel = 'Muu'

            props.textInput = {
                value: formState.other ?? '',
                onChange: (value) => {
                    formState.other = value
                    update()
                },
            }
        }
    } else if (mode === 'freeform') {
        props.textInput = {
            value: formState.freeform ?? '',
            onChange: (value) => {
                formState.freeform = value
                update()
            },
        }
    } else {
        API.logWarning(view, 'Invalid activity mode: ' + mode, false)
    }

    return props
}

const asActivityObject = (activity: Activity) => {
    if (!activity || typeof activity === 'string') {
        throw new Error('Expected activity object')
    }

    return activity
}

const asActivityString = (activity: Activity) => (typeof activity === 'string' ? activity : '')

const getActivity = (view: AppView, activityConf: ActivityConf): Activity => {
    const { state } = view
    const formState = state.meeting.activityForm
    const mode = activityConf.mode

    if (mode === 'choice') {
        const options: string[] = []

        for (const [index, option] of activityConf.options!.entries()) {
            if (formState.options.has(index)) {
                options.push(option)
            }
        }

        const activity: Activity = { selected: options }

        if (activityConf.other) {
            activity.other = formState.other
        }

        return activity
    } else if (mode === 'freeform') {
        return formState.freeform!
    } else {
        API.logWarning(view, 'Invalid activity mode: ' + mode, false)
        return null
    }
}
