import { Component } from 'react'

import { Centre, CentreForm, CentreFormContents, Option, SingleChoiceValue } from '../server/types'
import { centreFormFields } from '../util/centre-form-fields'
import { API } from './api'
import { getCentreDistTypes } from './centre-dist-types'
import { DistType } from './dist-type'
import { Loading } from './loading'
import { AppView } from './state'
import { DataCollector, renderStatsTable } from './stats-table'
import { getPercentageOption } from './stats-utils'
import { Utils } from './utils'

interface Data {
    centre: Centre
    formValues: CentreFormContents
}

export interface CentreStatsProps {
    view: AppView // TODO refactor props
    centreIds: string[]
    centreSelectionDesc: string
}

interface State {
    loaded: boolean
    showPercentages: boolean
    forms?: Record<string, CentreForm>
    centres?: Record<string, Centre>
}

const getDistTypes = (
    centres: Record<string, Centre>,
): Record<string, DistType<Data, string, string | string[]>> => {
    const optionToString = (option: Option) => option.id
    const distTypes = getCentreDistTypes(centres, true)
    const distTypesAny = distTypes as any // TODO

    centreFormFields
        .filter((field) => field.stats)
        .forEach((field) => {
            const distType: DistType<Data, string, string | string[]> = {
                name: field.label!,
                showIf(rowOrCol) {
                    return rowOrCol === 'col'
                },
                getKey: () => {
                    throw new Error('Placholder')
                },
            }

            if (field.type === 'single-choice') {
                // No option should use this value as its id
                const OTHER = '_other'

                distType.orderedKeys = field.options!.map(optionToString)

                if (field.other) {
                    distType.orderedKeys.push(OTHER)
                }

                distType.getKeyName = (key) => {
                    if (field.other && key === OTHER) {
                        return field.other.label!
                    }

                    const option = Utils.findById(field.options!, key)!
                    return option.label || option.id
                }

                distType.getKey = (data) => {
                    const value: string | SingleChoiceValue<string> = (data.formValues as any)[
                        field.id
                    ] // TODO

                    if (typeof value === 'string') {
                        return value
                    } else {
                        if (field.other && value.other) {
                            return OTHER
                        } else {
                            return value.key
                        }
                    }
                }

                if (field.required) {
                    distType.hideUnknown = true
                }
            } else if (field.type === 'multiple-choice') {
                distType.keyFormat = 'array'

                if (!field.stats!.omitEmptyColumns) {
                    distType.orderedKeys = field.options!.map(optionToString)
                }

                distType.getKeyName = (key) => Utils.findById(field.options!, key)!.label!

                distType.getKey = (data) => {
                    const value = (data.formValues as any)[field.id] // TODO
                    return !value && !field.required ? [] : value
                }

                distType.hideUnknown = true
                distType.hideTotal = true
            } else if (field.type === 'number') {
                const ranges = field.ranges!
                distType.orderedKeys = ranges.map(String)

                distType.getKeyName = (key) => {
                    const largest = String(ranges[ranges.length - 1])

                    if (key === '0') {
                        return '<' + ranges[1]
                    } else if (key === largest) {
                        return largest + '<'
                    } else {
                        const next = ranges[ranges.indexOf(Number(key)) + 1]
                        return key + '-' + next
                    }
                }

                distType.getKey = (data) => {
                    const value = (data.formValues as any)[field.id] // TODO

                    if (isNaN(value)) {
                        return null
                    }

                    let largestMatchingRange = 0

                    for (const rangeStart of ranges) {
                        if (value >= rangeStart) {
                            largestMatchingRange = rangeStart
                        }
                    }

                    return String(largestMatchingRange)
                }

                // Note: if new fields are added that don't have values on already saved forms,
                // we should show the column even if the field is required.
                if (field.required) {
                    distType.hideUnknown = true
                }
            } else if (field.type === 'map') {
                // TODO: hide total column in some cases?

                if (!field.stats!.omitEmptyColumns) {
                    distType.orderedKeys = field.keys!.map((key) => key.id)
                } else {
                    distType.getSortKey = (key) => Utils.findIndex(field.keys!, (k) => k.id === key)
                }

                distType.getKeyName = (key) => Utils.findById(field.keys!, key)!.label!

                if (field.valueType === 'number') {
                    distType.keyFormat = 'map'
                    distType.getKey = (data) => (data.formValues as any)[field.id] // TODO
                } else {
                    distType.keyFormat = 'array'

                    distType.getKey = (data) => {
                        const value = (data.formValues as any)[field.id] // TODO
                        return !value && !field.required ? [] : Object.keys(value)
                    }
                }

                distType.hideUnknown = true
            } else {
                throw new Error('Unsupported field type for stats: ' + field.type)
            }

            distTypesAny[field.id] = distType
        })

    return distTypesAny
}

export class CentreStats extends Component<CentreStatsProps, State> {
    state: State = { loaded: false, showPercentages: false }

    async componentDidMount() {
        const { view } = this.props
        const [forms, centres] = await Promise.all([API.getCentreForms(view), API.getCentres(view)])
        this.setState({ loaded: true, forms, centres })
    }

    collectData(dataCollector: DataCollector<Data>) {
        for (const centreId of this.props.centreIds) {
            if (centreId in this.state.forms!) {
                const form = this.state.forms![centreId]
                const centre = this.state.centres![centreId]

                const latestRev = form.revisions[form.revisions.length - 1].rev
                const latestValues = Utils.getRevision(form.revisions, latestRev)

                dataCollector.process({ centre, formValues: latestValues })
            }
        }
    }

    getExcelFileNamePart() {
        return this.props.centreSelectionDesc
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        const percentageOption = getPercentageOption(this.state.showPercentages, (value) =>
            this.setState({ showPercentages: value }),
        )

        return (
            <div className="main-panel">
                {renderStatsTable<Data, boolean>({
                    distTypes: getDistTypes(this.state.centres!),
                    initialColDist: 'noorte-arv',
                    initialRowDist: 'county',
                    dropdownForCol: true,
                    collectData: (dataCollector) => this.collectData(dataCollector),
                    getCustomOptions: () => [percentageOption],
                    getExcelFileNamePart: () => this.getExcelFileNamePart(),
                    verticalColDists: true,
                    showPercentages: this.state.showPercentages,
                })}
                <div>
                    {`Statistika arvutatud ${this.props.centreIds.length} keskuse andmete põhjal`}
                </div>
            </div>
        )
    }
}
