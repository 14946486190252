import { FC } from 'react'

import { sortAsStrings } from '../util/sort-as-strings'
import { Collapsible } from './collapsible'
import { Column, getTableProps } from './props/table'
import { Table } from './table'
import { Utils } from './utils'

interface CentreSum {
    id: string
    name: string
    sum: number
}

export interface StatSummaryProps {
    title: string
    total: number
    centreSums: Record<string, CentreSum>
}

const getColumns = (): Column<CentreSum>[] => [
    {
        id: 'name',
        getContents: (rowData) => rowData.name + ':',
    },
    {
        id: 'sum',
        editCellProps: (props) => (props.className = 'bold'),
    },
]

export const StatSummary: FC<StatSummaryProps> = ({ total, title, centreSums }) => {
    const header = title + ': ' + total

    if (total === 0) {
        return (
            <div className="panel panel-default">
                <div className="panel-heading bold">{header}</div>
            </div>
        )
    }

    const rows = Utils.mapValues(centreSums)
    sortAsStrings(rows, (centre) => centre.name)

    return (
        <Collapsible header={title + ': ' + total} headerClassName="bold">
            <Table
                {...getTableProps({
                    columns: getColumns(),
                    rows,
                    omitHeader: true,
                    className: 'extra-padded',
                })}
            />
        </Collapsible>
    )
}
