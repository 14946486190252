import { FC, ReactNode, useRef, useState } from 'react'

import { LimitedEmployee } from '../server/remove-employee-fields'
import { Utils } from './utils'

export interface EmployeeInputProps {
    employees: Record<string, LimitedEmployee>
    selectedEmployees: string[]
    onAddEmployee: (employee: LimitedEmployee) => void
}

export const EmployeeInput: FC<EmployeeInputProps> = ({
    employees,
    selectedEmployees,
    onAddEmployee,
}) => {
    const inputRef = useRef<HTMLInputElement | null>()
    const selectedRef = useRef<string[]>()
    selectedRef.current = selectedEmployees

    const [query, setQuery] = useState('')
    const [selectedIndex, setSelectedIndex] = useState(-1)

    const matches = Utils.filterMap(employees, (employee) => {
        const selected = selectedRef.current!.filter(
            (selectedEmployee) => selectedEmployee === employee._id,
        )

        if (selected.length > 0) {
            return false
        }

        return Utils.contains(employee.name, query)
    }).slice(0, 50)

    const newPos = (diff: number) => {
        let newIndex = selectedIndex + diff

        while (newIndex < 0) {
            newIndex += matches.length
        }

        while (newIndex >= matches.length) {
            newIndex -= matches.length
        }

        return newIndex
    }

    let dropdown: ReactNode = null

    if (query) {
        let suggestions: ReactNode = matches.map((country, index) => {
            let className = 'autocomplete__option'

            if (index === selectedIndex) {
                className += ' autocomplete__option--highlighted'
            }

            let text: ReactNode = ''

            if (Utils.contains(country.name, query)) {
                text = Utils.highlight(country.name, query)
            }

            return (
                <div
                    key={index}
                    className={className}
                    onMouseOver={() => setSelectedIndex(index)}
                    onMouseOut={() => {
                        if (selectedIndex === index) {
                            setSelectedIndex(-1)
                        }
                    }}
                    onClick={async () => {
                        onAddEmployee(matches[index])
                        setQuery('')
                    }}
                >
                    {text}
                </div>
            )
        })

        if (matches.length) {
            dropdown = <div className="autocomplete__options">{suggestions}</div>
        }
    }

    return (
        <div className="autocomplete">
            <input
                type="text"
                ref={(node) => (inputRef.current = node)}
                className="form-control"
                value={query}
                onChange={(evt) => {
                    setQuery(evt.currentTarget.value)
                    setSelectedIndex(-1)
                }}
                onKeyDown={(evt) => {
                    // TODO: scrolling?
                    if (evt.key === 'ArrowUp') {
                        setSelectedIndex(newPos(-1))
                    } else if (evt.key === 'ArrowDown') {
                        setSelectedIndex(newPos(1))
                    } else if (evt.key === 'Enter') {
                        const employee = matches[selectedIndex]

                        if (employee) {
                            onAddEmployee(employee)
                            setQuery('')
                        }
                    }
                }}
            />
            {dropdown}
        </div>
    )
}
