import { FC } from 'react'

import { Link, LinkProps } from './link'

export interface TabMenuProps {
    tabs: TabProps[]
}

export interface TabProps {
    reactKey: string
    className?: string
    link: LinkProps
}

export const TabMenu: FC<TabMenuProps> = ({ tabs }) => (
    <nav style={{ paddingTop: 5 }}>
        <ul id="main-menu" className="nav nav-tabs">
            {tabs.map((tab) => (
                <Tab key={tab.reactKey} {...tab} />
            ))}
        </ul>
    </nav>
)

const Tab: FC<TabProps> = (props) => (
    <li className={props.className}>
        <Link {...props.link} />
    </li>
)
