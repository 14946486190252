export const clone = <T>(obj: T): T => {
    if (Array.isArray(obj)) {
        return (obj as any).map(clone)
    } else if (typeof obj === 'object') {
        if (!obj) {
            return obj
        }

        const objAny = obj as any

        if (objAny.constructor.name === 'ObjectId') {
            // We can't use "new ObjectId(...)" here because clone() is also used
            // from client code that runs in the browser and we can't import the
            // entire mongodb package there.
            return new objAny.constructor(objAny)
        } else if (objAny.constructor !== Object) {
            throw new Error('Cannot clone instance of ' + objAny.constructor.name)
        }

        const clonedObj: Record<string, unknown> = {}

        for (const key of Object.keys(obj)) {
            clonedObj[key] = clone(objAny[key])
        }

        return clonedObj as any
    } else {
        return obj
    }
}
