import { FC } from 'react'

import { BirthDate as TBirthDate } from '../server/types'
import { renderDropdown } from './dropdown'
import { Utils } from './utils'
import { renderValidationError, ValidationError } from './validation'

export interface BirthDateProps {
    value: TBirthDate
    onChange: (value: TBirthDate) => void
    originalValue: TBirthDate
    validationErrors?: Record<string, ValidationError>
    yearRequired?: boolean
}

export const BirthDate: FC<BirthDateProps> = (props) => {
    const onDayChange = (value: string) => {
        const newValue = Utils.clone(props.value)
        newValue.day = Number(value)
        props.onChange(newValue)
    }

    const onMonthChange = (value: string) => {
        const newValue = Utils.clone(props.value)
        newValue.month = Number(value)
        props.onChange(newValue)
    }

    const onYearChange = (value: string) => {
        const newValue = Utils.clone(props.value)

        if (value === 'maxAge') {
            newValue.maxAge = true
            delete newValue.year
        } else {
            newValue.year = Number(value)
            delete newValue.maxAge
        }

        props.onChange(newValue)
    }

    const getMaxAgeValue = () => {
        if (!props.originalValue.maxAge) {
            const originalYear = props.originalValue.year!
            const maxAgeYear = Utils.getMaxAgeYear()

            if (originalYear > 0 && originalYear <= maxAgeYear) {
                return originalYear.toString()
            }
        }

        return 'maxAge'
    }

    const renderNote = () => {
        if (props.yearRequired) {
            return (
                <div className="input-note">
                    Päeva ja kuu võib tühjaks jätta, aasta on kohustuslik.
                </div>
            )
        } else {
            return null
        }
    }

    const birthDate = props.value

    const currentYear = Utils.getCurrentYear()
    const maxAgeYear = Utils.getMaxAgeYear()

    return (
        <div className="form-inline">
            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                {renderDropdown({
                    options: [
                        { id: '', label: '--' },
                        ...Utils.range(1, 31).map((day) => ({
                            id: String(day),
                            label: String(day),
                        })),
                    ],
                    value: String(birthDate.day || ''),
                    onChange: (value) => onDayChange(value),
                    additional: {
                        id: 'day',
                        className: 'form-control',
                        style: { width: 60 },
                    },
                })}
                {renderValidationError(props.validationErrors, 'birthDate.day')}
            </div>{' '}
            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                {renderDropdown({
                    options: [
                        { id: '', label: '--' },
                        ...Utils.range(1, 12).map((monthNum) => {
                            return {
                                id: String(monthNum),
                                label: Utils.getMonthName(monthNum),
                            }
                        }),
                    ],
                    value: String(birthDate.month || ''),
                    onChange: (value) => onMonthChange(value),
                    additional: {
                        id: 'month',
                        className: 'form-control',
                        style: { width: 110 },
                    },
                })}
                {renderValidationError(props.validationErrors, 'birthDate.month')}
            </div>{' '}
            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                {renderDropdown({
                    options: [
                        { id: '', label: '--' },
                        ...Utils.range(maxAgeYear + 1, currentYear)
                            .reverse()
                            .map((year) => {
                                return { id: String(year), label: String(year) }
                            }),
                        { id: getMaxAgeValue(), label: maxAgeYear + ' või varem' },
                    ],
                    value: birthDate.maxAge ? 'maxAge' : String(birthDate.year || ''),
                    onChange: (value) => onYearChange(value),
                    additional: {
                        id: 'birthYear',
                        className: 'form-control',
                        style: { width: 140 },
                    },
                })}
                {renderValidationError(props.validationErrors, 'birthDate.year', {
                    'under-min': 'Kohustuslik väli',
                })}
            </div>
            {renderNote()}
        </div>
    )
}
