import { FC } from 'react'

import { EditEvent, EditEventProps } from './edit-event'
import { EventCategoryContainer, EventCategoryContainerProps } from './event-category/container'
import {
    EventCategorySearchContainer,
    EventCategorySearchContainerProps,
} from './event-category/search-container'
import { SearchBox, SearchBoxProps } from './search-box'
import { SearchPanelExistingItem, SearchPanelExistingItemProps } from './search-panel/existing-item'
import { SearchPanelNewItem, SearchPanelNewItemProps } from './search-panel/new-item'

export interface SelectEventPanelProps {
    searchBox: SearchBoxProps
    eventCategoryContainer?: EventCategoryContainerProps
    categorySearchContainers?: EventCategorySearchContainerProps[]
    categories?: SearchPanelExistingItemProps[]
    newCategory?: SearchPanelNewItemProps
    uncategorized?: SearchPanelExistingItemProps[]
    newEvent?: SearchPanelNewItemProps
    showArchived?: {
        checked: boolean
        onChange: (checked: boolean) => void
    }
    editEvent?: EditEventProps
}

// TODO: loading indicator

export const SelectEventPanel: FC<SelectEventPanelProps> = (props) => (
    <div>
        <h2>Vali sündmus</h2>
        <div>
            <SearchBox {...props.searchBox} />
        </div>
        {props.eventCategoryContainer && (
            <EventCategoryContainer {...props.eventCategoryContainer} />
        )}
        {props.categorySearchContainers?.map((container) => (
            <EventCategorySearchContainer key={container.reactKey} {...container} />
        ))}
        {props.categories && (
            <>
                <div className="top-margin bottom-margin">Kategooriad:</div>
                {props.categories.map((item) => (
                    <SearchPanelExistingItem key={item.reactKey} {...item} />
                ))}
            </>
        )}
        {props.newCategory && <SearchPanelNewItem {...props.newCategory} />}
        {props.uncategorized && (
            <>
                <div className="top-margin bottom-margin">Kategooriata sündmused:</div>
                {props.uncategorized.map((item) => (
                    <SearchPanelExistingItem key={item.reactKey} {...item} />
                ))}
            </>
        )}
        {props.newEvent && <SearchPanelNewItem {...props.newEvent} />}
        {props.showArchived && (
            <div>
                <input
                    type="checkbox"
                    checked={props.showArchived.checked}
                    onChange={(evt) => {
                        props.showArchived!.onChange(evt.target.checked)
                    }}
                />
                {' Näita arhiveeritud sündmusi'}
            </div>
        )}
        {props.editEvent && <EditEvent {...props.editEvent} />}
    </div>
)
