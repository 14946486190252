import { Component, ReactNode } from 'react'

import { CardLogEntry } from '../server/types'
import { API } from './api'
import { CardUtils } from './card-utils'
import { DateField } from './date-field'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { LoadingButton } from './loading-button'
import { getTableProps } from './props/table'
import { AppView } from './state'
import { Table } from './table'
import { Utils } from './utils'
import {
    checkValidationErrors,
    clearValidationErrors,
    renderValidationError,
    ValidationError,
} from './validation'

interface Row {
    id: number
    entry: CardLogEntry
    edited: boolean
    newDate?: string
}

export interface EditCardDatesProps {
    view: AppView // TODO refactor props
    cardId: string
}

interface State {
    loaded: boolean
    rows?: Row[]
    phase4date?: string
    validationErrors?: Record<string, ValidationError>
}

const TIME_SUFFIX = 'T03:00:00+00:00'

export class EditCardDates extends Component<EditCardDatesProps, State> {
    state: State = { loaded: false }
    unmounted = false

    async componentDidMount() {
        const { view } = this.props

        // Employees not really needed here, but it was simpler to use the existing command
        const response = await API.getCardAndEmployees(view, this.props.cardId)

        await Utils.setState(this, {
            loaded: true,
            rows: response.card.log.map(
                (entry, index): Row => ({ id: index, entry, edited: false }),
            ),
            phase4date: response.card.phase4date,
        })

        await EventBus.fire('card-date-editor-rendered')
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async save() {
        const { view } = this.props
        await clearValidationErrors(view, this)

        try {
            const dates = this.state.rows!.map((row) => (row.edited ? row.newDate! : null))
            await API.updateCardDates(view, this.props.cardId, dates)
            view.navigate(['cards', 'view', this.props.cardId])
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    isValidTime(time: string) {
        const date = new Date(time)
        return !isNaN(date.getTime()) && Utils.formatDateYmd(date) === time
    }

    // TODO undup with server
    getNewPhase4date(phase3end: string) {
        const date = new Date(phase3end + TIME_SUFFIX)
        date.setMonth(date.getMonth() + 6)
        return date.toISOString().substr(0, 10) + TIME_SUFFIX
    }

    renderBackLink() {
        return (
            <div style={{ marginBottom: 10 }}>
                <a id="back-link" href={'#/cards/view/' + this.props.cardId}>
                    Tagasi juhtumikaardile
                </a>
            </div>
        )
    }

    renderTable() {
        return (
            <Table
                {...getTableProps({
                    id: 'edit-card-dates',
                    className: 'bordered',
                    columns: [
                        {
                            id: 'action',
                            header: 'Info',
                            getContents: (row) => CardUtils.renderLogInfo(row.entry),
                        },
                        {
                            id: 'time',
                            header: 'Aeg',
                            getContents: (row) => {
                                const valError = renderValidationError(
                                    this.state.validationErrors,
                                    'dates.' + row.id,
                                    { 'under-min': 'Ei tohi olla varasem eelmisest' },
                                )

                                if (row.edited) {
                                    return (
                                        <div>
                                            <DateField
                                                value={row.newDate!}
                                                onChange={(newDate) => {
                                                    row.newDate = newDate
                                                    this.forceUpdate()
                                                }}
                                            />
                                            {valError}
                                        </div>
                                    )
                                } else {
                                    const onClick = () => {
                                        row.edited = true
                                        row.newDate = Utils.formatDateYmd(new Date(row.entry.time))
                                        this.forceUpdate()
                                    }

                                    return (
                                        <div>
                                            <span className="time">
                                                {Utils.formatDateTime(new Date(row.entry.time))}
                                            </span>{' '}
                                            <button onClick={onClick}>Muuda</button>
                                            {
                                                // Validation error must also be rendered on a non-edited date,
                                                // because we may need to show a date order error here.
                                                valError
                                            }
                                        </div>
                                    )
                                }
                            },
                        },
                    ],
                    rows: this.state.rows!,
                })}
            />
        )
    }

    renderPhase4Date() {
        if (this.state.phase4date) {
            let info: ReactNode = null

            const rows = this.state.rows!
            const lastIndex = rows.length - 1
            const lastRow = rows[lastIndex]

            if (lastRow.edited && lastRow.entry.action === 'end3') {
                if (!this.isValidTime(lastRow.newDate!)) {
                    info = '-'
                } else {
                    const newPhase4date = this.getNewPhase4date(lastRow.newDate!)

                    if (newPhase4date !== this.state.phase4date) {
                        info =
                            'oli ' +
                            Utils.formatDateFromString(this.state.phase4date) +
                            ', uueks kuupäevaks saab ' +
                            Utils.formatDateFromString(newPhase4date)
                    }
                }
            }

            if (info === null) {
                info = Utils.formatDateFromString(this.state.phase4date) + ' (ei muutu)'
            }

            return (
                <div id="phase4-date" style={{ marginTop: 10 }}>
                    {'4. faasi algus: '}
                    {info}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div className="main-panel">
                {this.renderBackLink()}
                <h3 style={{ marginTop: 10 }}>
                    <b>Juhtumikaardi kuupäevad</b>
                </h3>
                <p>
                    NB! Kuupäeva muutmisel läheb kellaaeg kaduma, isegi kui jätad kuupäeva samaks.
                </p>
                <p>Vajuta nuppu "Muuda" ainult neil ridadel, kus seda vaja on.</p>
                {this.renderTable()}
                {this.renderPhase4Date()}
                <div style={{ marginTop: 10 }}>
                    <LoadingButton id="save" text="Salvesta" getPromise={async () => this.save()} />
                </div>
            </div>
        )
    }
}
