import { useEffect, useState } from 'react'

import { API, ManagedVisitorExt } from '../api'
import { AppView } from '../state'

type ManagedVisitors = Record<string, ManagedVisitorExt>

export const useManagedVisitors = (
    view: AppView,
): [ManagedVisitors | undefined, () => Promise<void>] => {
    const [visitors, setVisitors] = useState<ManagedVisitors>()

    const loadVisitors = async () => {
        const managedVisitors = await API.getManagedVisitors(view)
        setVisitors(managedVisitors)
    }

    useEffect(() => {
        loadVisitors()
    }, [])

    return [visitors, loadVisitors]
}
