import { Field } from '../server/types'

export const centreFormFields: Field[] = [
    {
        id: 'jur-isik',
        label: 'Juriidiline isik',
        type: 'text',
        required: true,
    },
    {
        id: 'reg-nr',
        label: 'Registrinumber',
        type: 'text',
        required: true,
    },
    {
        id: 'kontaktisik',
        label: 'Kontaktisiku nimi',
        type: 'text',
        required: true,
    },
    {
        id: 'tel',
        label: 'Tel. nr',
        type: 'text',
        required: true,
    },
    {
        id: 'email',
        label: 'E-mail',
        type: 'text',
        required: true,
    },
    {
        id: 'aadress',
        label: 'Noortekeskuse aadress',
        type: 'text',
        required: true,
        multiline: true,
    },
    {
        id: 'koduleht',
        label: 'Kodulehe aadress',
        type: 'text',
        hint: 'Juhul kui on olemas',
        width: '20em',
    },
    {
        id: 'tootajad',
        label: 'Noortekeskuse noorsootöötajad',
        type: 'text',
        required: true,
        multiline: true,
        rows: 8,
        cols: 30,
        hint: 'Igale reale üks nimi',
    },
    {
        id: 'noorte-arv',
        label: 'Noorte arv',
        type: 'number',
        required: true,
        ranges: [0, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000, 5000, 7000, 10000],
        stats: {},
    },
    {
        id: 'tootute-noorte-arv',
        label: 'Töötute noorte arv',
        type: 'number',
        ranges: [0, 10, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200],
        stats: {},
    },
    {
        id: 'neet-noorte-arv',
        label: 'NEET-noorte arv',
        type: 'number',
        ranges: [0, 10, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200],
        stats: {},
    },
    {
        id: 'kov-tugi',
        label: 'KOV tugi',
        type: 'single-choice',
        required: true,
        options: [
            { id: '100%' },
            { id: '90%' },
            { id: '80%' },
            { id: '70%' },
            { id: '60%' },
            { id: '50%' },
            { id: '40%' },
            { id: '30%' },
            { id: '20%' },
            { id: '10%' },
        ],
        stats: {},
    },
    {
        id: 'aasta-eelarve',
        label: 'Aasta eelarve',
        type: 'number',
        required: true,
        unit: '\u20ac',
        ranges: [
            0, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 150000,
            200000, 250000,
        ],
        stats: {},
    },
    {
        id: 'teised-toetajad',
        label: 'Teised toetajad',
        type: 'multiple-choice',
        options: [
            { id: 'eesti-ank', label: 'Eesti ANK' },
            { id: 'hmn', label: 'Hasartmängumaksu Nõukogu' },
            { id: 'kulka', label: 'Kultuurkapital' },
            { id: 'kik', label: 'Keskkonnainvesteeringute Keskus' },
            { id: 'erasmus-sana', label: 'Erasmus+(SANA)' },
            { id: 'ank-pk', label: 'ANK projektikonkurss' },
            { id: 'ovl', label: 'Omavalitsusliit' },
            { id: 'ky-sk-sa', label: 'Kodanikuühiskonna Sihtkapital SA' },
            { id: 'aef', label: 'Avatud Eesti Fond' },
            { id: 'leader-pria', label: 'Leader (Pria)' },
            { id: 'htm', label: 'Haridus- ja Teadusministeerium' },
            { id: 'enyl', label: 'Eesti Noorteühenduste Liit' },
            { id: 'annetused', label: 'Annetused' },
            { id: 'ettev', label: 'Kohalike ettevõtete toetused' },
            { id: 'osalustasud', label: 'Osalustasud' },
            { id: 'teenused', label: 'Teenused (nt ruumide rent)' },
        ],
        vertical: true,
        stats: { omitEmptyColumns: true },
    },
    {
        id: 'asutuse-suurus',
        label: 'Asutuse suurus',
        type: 'number',
        required: true,
        unit: 'ruutmeetrit',
        ranges: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
        stats: {},
    },
    {
        id: 'asub',
        label: 'Noortekeskus asub',
        type: 'single-choice',
        required: true,
        options: [
            { id: 'spets', label: 'Spetsiaalselt noortekeskuseks ehitatud hoones' },
            { id: 'eraldi', label: 'Eraldi hoones' },
            { id: 'teiste', label: 'Teiste asutustega ühes hoones' },
            {
                id: 'teiste-spets',
                label: 'Teiste asutustega ühes hoones, kuid spetsiaalsel noortekeskuseks renoveeritud osas',
            },
        ],
        dropdown: true,
        stats: {},
    },
    {
        id: 'avatud',
        label: 'Avatud',
        type: 'single-choice',
        required: true,
        options: [{ id: 'E-R' }, { id: 'E-N' }, { id: 'T-L' }, { id: 'E-L' }, { id: 'E-P' }],
        other: { label: 'Muu', width: '5em' },
        stats: {},
    },
    {
        id: 'avatud-aeg',
        label: '',
        type: 'number',
        required: true,
        unit: 'tundi nädalas',
    },
    {
        id: 'tootajate-ylevaade',
        label: 'Töötajate ülevaade',
        type: 'map',
        required: true,
        valueType: 'number',
        valueDecimal: true,
        selectByValue: true,
        keyLabel: 'Amet',
        valueLabel: 'Ametikoha määr',
        keys: [
            { id: 'juhataja', label: 'Juhataja/direktor' },
            { id: 'noorsoo', label: 'Noorsootöötaja' },
            { id: 'psyh', label: 'Psühholoog' },
            { id: 'karj', label: 'Karjäärinõustaja' },
            { id: 'neet', label: 'NEET-spetsialist' },
            { id: 'info', label: 'Infotöötaja' },
            { id: 'mono', label: 'MONO töötaja' },
            { id: 'rmp', label: 'Raamatupidaja' },
            { id: 'admin', label: 'Administraator' },
            { id: 'sekr', label: 'Sekretär' },
            { id: 'muu', label: 'Muu' },
        ],
        total: { label: 'Koormus kokku' },
        stats: { omitEmptyColumns: true },
    },
    {
        id: 'tootajate-haridus',
        label: 'Töötajate haridus',
        type: 'map',
        required: true,
        valueType: 'number',
        selectByValue: true,
        keyLabel: 'Haridus',
        valueLabel: 'Isikute arv',
        keys: [
            { id: 'pohi', label: 'Põhiharidus' },
            { id: 'kesk', label: 'Keskharidus' },
            { id: 'noorsoo', label: 'Noorsootöö kõrgem' },
            { id: 'peda', label: 'Pedagoogiline kõrgem' },
            { id: 'sots', label: 'Sotsiaaltöö kõrgem' },
            { id: 'kultuur', label: 'Kultuur kõrgem' },
            { id: 'muu-korgem', label: 'Muu kõrgem' },
            { id: 'muu-kutse', label: 'Muu kutseharidus' },
            { id: 'sh-noorsoo', label: 'sh noorsootöö kutse' },
        ],
        total: { label: 'Kokku' },
        stats: { omitEmptyColumns: true },
    },
    {
        id: 'keskuse-teenused',
        label: 'Keskuse teenused',
        type: 'map',
        required: true,
        valueType: 'text',
        keyLabel: 'Teenus',
        valueLabel: 'Täpsustus',
        keys: [
            { id: 'noorteruum', label: 'Avatud Noorteruum' },
            { id: 'init', label: 'Noorte initsiatiivi ja projektide toetamine' },
            { id: 'info', label: 'Noorteinfo' },
            { id: 'vabatahtlik', label: 'Vabatahtliku tegevuse võimaldamine' },
            { id: 'aktiiv', label: 'Aktiivgruppide ja osaluskogude toetamine' },
            { id: 'mono', label: 'Mobiilne noorsootöö' },
            { id: 'toohoive', label: 'Tööhõivet toetavad tegevused' },
            { id: 'huvi', label: 'Huvitegevus' },
            { id: 'laagrid', label: 'Noortelaagrid' },
            { id: 'koolitused', label: 'Noortekoolitused' },
            { id: 'rahvusvah', label: 'Rahvusvaheline noorsootöö' },
            { id: 'yhis', label: 'Ühistegevused teiste asutustega' },
            { id: 'muu', label: 'Muu' },
            { id: 'res', label: 'Ressursikeskus' },
            { id: 'tugi', label: 'Tugigrupid' },
            { id: 'neet', label: 'NEET-noorte lisatugi' },
        ],
        stats: { omitEmptyColumns: true },
    },
    {
        id: 'seotud-programmid',
        label: 'Seotud programmid',
        type: 'map',
        valueType: 'text',
        keyLabel: 'Programm',
        valueLabel: 'Täpsustus',
        keys: [
            { id: 'ank', label: 'Noorte heaks' },
            { id: 'neet', label: 'NEET-lisatugi' },
            {
                id: 'risk',
                label: 'Riskilapsed ja -noored/Riskilaste...',
                deprecated: true,
            },
            {
                id: 'murde',
                label: 'Riskilapsed ja -noored/Murdepunkt',
                deprecated: true,
            },
            { id: 'kov', label: 'KOV noortekeskuse teenus' },
        ],
        stats: {},
    },
    {
        id: 'koostoo-vorgustik',
        label: 'Koostöövõrgustik',
        type: 'map',
        required: true,
        valueType: 'text',
        keyLabel: 'Partner',
        valueLabel: 'Täpsustus',
        keys: [
            { id: 'alg', label: 'Algkool' },
            { id: 'pohi', label: 'Põhikool' },
            { id: 'kesk', label: 'Keskkool' },
            { id: 'korg', label: 'Kõrgkool' },
            { id: 'kutse', label: 'Kutsekool' },
            { id: 'lasteaed', label: 'Lasteaed' },
            { id: 'kult', label: 'Kultuurikeskus' },
            { id: 'muuseum', label: 'Muuseum' },
            { id: 'politsei', label: 'Politsei' },
            { id: 'ramkogu', label: 'Raamatukogu' },
            { id: 'ny', label: 'Noorteühing' },
            { id: 'rajaleidja', label: 'Rajaleidja' },
            { id: 'programmid', label: 'Programmid' },
            { id: 'huvikool', label: 'Huvikool' },
            { id: 'med', label: 'Meditsiiniasutus' },
            { id: 'seltsid', label: 'Seltsid' },
            { id: 'krimi', label: 'Kriminaalhooldus' },
            { id: 'kov', label: 'KOV' },
            { id: 'op-es', label: 'Õpilasesindus' },
            { id: 'nvol', label: 'Noortevolikogu' },
            { id: 'mk-noor', label: 'MK noortekogu' },
            { id: 'ettev', label: 'Ettevõtted' },
            { id: 'tootu', label: 'Töötukassa' },
            { id: 'ti', label: 'Tööinspektsioon' },
            { id: 'paeva', label: 'Päevakeskus' },
            { id: 'kyla', label: 'Külaseltsid' },
            { id: 'muu', label: 'Muu' },
        ],
        stats: { omitEmptyColumns: true },
    },
    {
        id: 'arengukava',
        label: 'Arengukava olemasolu',
        type: 'single-choice',
        required: true,
        options: [
            { id: 'jah', label: 'Jah' },
            { id: 'ei', label: 'Ei' },
            { id: 'kov', label: 'KOV arengukavas' },
            { id: 'kov-all', label: 'KOV allasutuse arengukavas' },
        ],
        other: { label: 'Muu' },
        stats: {},
    },
    {
        id: 'prioriteet',
        label: 'Mis on keskuse selle aasta peamine prioriteet',
        type: 'text',
        required: true,
        multiline: true,
    },
]
