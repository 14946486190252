import { Component } from 'react'

import { API } from './api'
import { DropdownOption, renderDropdown } from './dropdown'
import { LoadingButton } from './loading-button'
import { AppView } from './state'
import { Utils } from './utils'
import { renderValidationError, ValidationError } from './validation'

interface Props {
    view: AppView // TODO refactor props
    directMode: boolean
    editMode?: boolean
    disabled: boolean
    employees: Record<string, { _id: string; name: string }>
    employeeId: string
    validationErrors?: Record<string, ValidationError>
    openEditMode: () => void
    cancelEditMode: () => Promise<void>
    onSave: (employeeId: string) => Promise<void>
}

interface State {
    employeeId: string
}

export class CardEmployee extends Component<Props, State> {
    state: State = { employeeId: '' }

    async onChange(employeeId: string) {
        if (this.props.directMode) {
            await this.props.onSave(employeeId)
        } else {
            this.setState({ employeeId })
        }
    }

    renderName() {
        const { view } = this.props
        const employeeId = this.props.employeeId
        const employee = this.props.employees[employeeId]

        if (employee) {
            return employee.name
        } else {
            API.logWarning(view, 'Employee not found: ' + employeeId, false)
            return employeeId
        }
    }

    renderSaveButton() {
        return (
            <LoadingButton
                text="Salvesta"
                getPromise={async () => this.props.onSave(this.state.employeeId)}
            />
        )
    }

    renderCancelButton() {
        return <LoadingButton text="Tühista" getPromise={this.props.cancelEditMode} />
    }

    renderButtons() {
        if (!this.props.directMode) {
            return (
                <span>
                    {' '}
                    {this.renderSaveButton()} {this.renderCancelButton()}
                </span>
            )
        } else {
            return null
        }
    }

    render() {
        if (!Object.keys(this.props.employees).length) {
            return (
                <div id="employee">
                    {'Vastutav töötaja: '}
                    <span style={{ color: '#c00' }}>
                        Selles keskuses pole ühtegi töötajat, kellel oleks ligipääs
                        juhtumikaartidele
                    </span>
                </div>
            )
        }

        if (this.props.directMode || this.props.editMode) {
            const options: DropdownOption[] = []
            const employeeId = this.props.directMode ? this.props.employeeId : this.state.employeeId

            if (employeeId === '') {
                options.push({ id: '', label: '' })
            }

            const employees = Utils.mapValues(this.props.employees)
            Utils.sortAsStrings(employees, (employee) => employee.name)

            for (const employee of employees) {
                options.push({ id: employee._id, label: employee.name })
            }

            return (
                <div id="employee">
                    {'Vastutav töötaja: '}
                    {renderDropdown({
                        options,
                        value: employeeId,
                        onChange: async (value) => this.onChange(value),
                    })}
                    {this.renderButtons()}
                    {renderValidationError(this.props.validationErrors, 'card.employeeId')}
                </div>
            )
        } else {
            return (
                <div id="employee">
                    <span className="lbl-employee">
                        {'Vastutav töötaja: '}
                        {this.renderName()}
                    </span>{' '}
                    <LoadingButton
                        text="Muuda"
                        getPromise={async () => {
                            this.setState({ employeeId: this.props.employeeId })
                            this.props.openEditMode()
                        }}
                        disabled={this.props.disabled}
                    />
                </div>
            )
        }
    }
}
