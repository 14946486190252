import { FC } from 'react'

import { Button, ButtonProps } from './button'
import { Checkbox, CheckboxProps } from './checkbox'
import { RadioButton, RadioButtonProps } from './radio-button'
import { TextInput, TextInputProps } from './text-input'
import { ValErr, ValErrProps } from './validation'

export interface ActivityConfigProps {
    modeButtons?: RadioButtonProps[]
    modeText?: string
    choiceConfig?: ChoiceConfigProps
}

export interface ChoiceConfigProps {
    textInput: TextInputProps
    options: ChoiceOptionProps[]
    valErr?: ValErrProps
    other?: {
        checkbox?: CheckboxProps
    }
}

export interface ChoiceOptionProps {
    reactKey: string
    text: string
    deleteButton: ButtonProps
}

export const ActivityConfig: FC<ActivityConfigProps> = (props) => (
    <div>
        Tegevuse sisestamise võimalused:
        {props.modeButtons && (
            <div>
                {props.modeButtons.map((button) => (
                    <RadioButton key={button.reactKey} {...button} />
                ))}
            </div>
        )}
        {props.modeText && <div>{props.modeText}</div>}
        {props.choiceConfig && <ChoiceConfig {...props.choiceConfig} />}
    </div>
)

const ChoiceConfig: FC<ChoiceConfigProps> = (props) => (
    <div style={{ marginLeft: 20 }}>
        <TextInput className="form-control" {...props.textInput} />
        <div className="input-note">Uue valiku lisamiseks trüki see ja vajuta Enter</div>
        {props.options.map((option) => (
            <ChoiceOption key={option.reactKey} {...option} />
        ))}
        {props.valErr && <ValErr {...props.valErr} />}
        {props.other && (
            <div>
                {props.other.checkbox && (
                    <>
                        <Checkbox {...props.other.checkbox} />{' '}
                    </>
                )}
                Lisaks konkreetsetele valikutele võib sisestada vaba tekstina ka muu variandi.
            </div>
        )}
    </div>
)

const ChoiceOption: FC<ChoiceOptionProps> = (props) => (
    <div>
        <Button {...props.deleteButton} /> {props.text}
    </div>
)
