import { Component } from 'react'

import { LimitedEmployee } from '../server/remove-employee-fields'
import { canManage } from './access'
import { API } from './api'
import { EditEmployee, EditEmployeeProps } from './edit-employee'
import { EventBus } from './event-bus'
import { Loading } from './loading'
import { ManageCentreMenu } from './manage-centre-menu'
import { useCustomHandlerDuring } from './request-failure-handler'
import { Session } from './session'
import { AppView } from './state'
import { Utils } from './utils'

interface CentreEmployeesProps {
    view: AppView // TODO refactor props
}

interface State {
    loaded: boolean
    employees?: Record<string, LimitedEmployee>
    editingEmployee: string | null
    addingNew?: boolean
    addingExisting?: boolean
}

export class CentreEmployees extends Component<CentreEmployeesProps, State> {
    state: State = { loaded: false, editingEmployee: null }
    unmounted = false

    async componentDidMount() {
        EventBus.on('user-context-updated', this.afterSelfChange)
        await this.reloadEmployees()
    }

    componentWillUnmount() {
        this.unmounted = true
        EventBus.off('user-context-updated', this.afterSelfChange)
    }

    async reloadEmployees() {
        const { view } = this.props
        const employees = await API.getEmployees(view)

        if (!this.unmounted) {
            await Utils.setState(this, {
                loaded: true,
                employees,
                editingEmployee: null,
                addingNew: false,
                addingExisting: false,
            })

            await EventBus.fire('centre-employees-rendered')
        }
    }

    async removeEmployee(employeeId: string) {
        const { view } = this.props
        const hasCardMessage =
            'Enne selle töötaja eemaldamist tuleb tema juhtumikaartidele määrata uued vastutajad.'

        return useCustomHandlerDuring(
            Utils.getErrorHandler('has-cards', hasCardMessage),
            async () => {
                await API.removeEmployeeFromCentre(view, employeeId)
                await this.afterEmployeeChange(employeeId)
            },
        )
    }

    async afterEmployeeChange(changedEmployeeId: string) {
        const { view } = this.props

        if (changedEmployeeId !== Session.getEmployee(view)._id) {
            return this.reloadEmployees()
        }

        // If employee is self, user-context-updated will fire and trigger afterSelfChange
    }

    afterSelfChange = async () => {
        const { view } = this.props

        if (!view.state.session.loggedIn) {
            return
        }

        const employee = Session.getEmployee(view)
        const centre = Session.getCentre(view)
        const centreId = centre ? centre._id : null

        if (canManage(employee, centreId)) {
            await this.reloadEmployees()
        } else {
            view.navigate([])
        }
    }

    renderEmployeeForm() {
        const { view } = this.props

        const props: EditEmployeeProps = {
            view,
            afterSave: async (employeeId) => this.afterEmployeeChange(employeeId),
        }

        if (this.state.addingNew) {
            props.isNew = true
        } else if (this.state.addingExisting) {
            props.addExisting = true
        } else {
            // Editing existing
            const employeeId = this.state.editingEmployee!
            props.employee = this.state.employees![employeeId]
        }

        return (
            <div>
                <div style={{ padding: 5 }}>
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            this.setState({
                                editingEmployee: null,
                                addingNew: false,
                                addingExisting: false,
                            })
                        }}
                    >
                        &lt; Tagasi nimekirja
                    </a>
                </div>
                <EditEmployee {...props} />
            </div>
        )
    }

    renderEmployeeTable() {
        const { view } = this.props
        const employees = this.state.employees!
        const employeeIds = Object.keys(employees)

        if (employeeIds.length) {
            employeeIds.sort((id1, id2) => {
                return employees[id1].name.localeCompare(employees[id2].name, 'et')
            })

            return (
                <div>
                    <table id="tbl-employees" className="bordered">
                        <thead>
                            <tr>
                                <th rowSpan={2}>Nimi</th>
                                <th rowSpan={2}>Kasutajanimi</th>
                                <th colSpan={3} style={{ textAlign: 'center' }}>
                                    Ligipääs
                                </th>
                                <th rowSpan={2}>Muudatused</th>
                            </tr>
                            <tr>
                                <th>Keskuse haldamine</th>
                                <th>Kõik sündmused</th>
                                <th>Juhtumikaardid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeIds.map((id: string) => {
                                const employee = employees[id]
                                const permissions =
                                    employee.centrePermissions[Session.getCentre(view)!._id]

                                return (
                                    <tr key={employee._id}>
                                        <td>{employee.name}</td>
                                        <td>{employee.username}</td>
                                        <td>{permissions.manage ? 'Jah' : 'Ei'}</td>
                                        <td>{permissions.allEvents ? 'Jah' : 'Ei'}</td>
                                        <td>{permissions.cards ? 'Jah' : 'Ei'}</td>
                                        <td>
                                            <button
                                                onClick={() =>
                                                    this.setState({ editingEmployee: employee._id })
                                                }
                                            >
                                                Muuda
                                            </button>{' '}
                                            <button
                                                onClick={async () =>
                                                    this.removeEmployee(employee._id)
                                                }
                                            >
                                                Eemalda keskusest
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return <div>Sellele keskusele pole veel lisatud ühtegi töötajat.</div>
        }
    }

    render() {
        const { view } = this.props

        if (!this.state.loaded) {
            return <Loading />
        }

        if (this.state.editingEmployee || this.state.addingNew || this.state.addingExisting) {
            return (
                <div>
                    <ManageCentreMenu view={view} active="employees" />
                    <div className="main-panel">{this.renderEmployeeForm()}</div>
                </div>
            )
        } else {
            return (
                <div>
                    <ManageCentreMenu view={view} active="employees" />
                    <div className="main-panel">
                        {this.renderEmployeeTable()}
                        <div style={{ marginTop: 10 }}>
                            <button onClick={() => this.setState({ addingNew: true })}>
                                Loo uus töötaja konto
                            </button>{' '}
                            <button onClick={() => this.setState({ addingExisting: true })}>
                                Lisa keskusesse olemasolev töötaja
                            </button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
