import { Component, CSSProperties } from 'react'

import { CentreFormContents, Field, CentreForm as TCentreForm } from '../server/types'
import { centreFormFields } from '../util/centre-form-fields'
import { canEditCentreForm } from './access'
import { API } from './api'
import { Enums } from './enums'
import { expandValues, finalizeValues, Form } from './form'
import { Loading } from './loading'
import { ManageCentreMenu } from './manage-centre-menu'
import { AppView } from './state'
import { Utils } from './utils'
import { checkValidationErrors, clearValidationErrors, ValidationError } from './validation'

interface CentreFormProps {
    view: AppView // TODO refactor props
}

interface State {
    loaded: boolean
    editMode: boolean
    fields?: Field[]
    formData?: TCentreForm
    activeRevision?: number
    values?: CentreFormContents
    validationErrors?: Record<string, ValidationError>
}

export class CentreForm extends Component<CentreFormProps, State> {
    unmounted = false
    state: State = { loaded: false, editMode: false }

    async componentDidMount() {
        const { view } = this.props
        const formData = await API.getCentreForm(view)
        let activeRevision = -1
        let latestValues: CentreFormContents = {}
        const { session } = view.state

        if (!session.loggedIn) {
            return
        }

        if (formData) {
            activeRevision = Utils.getLatestRevNumber(formData)
            latestValues = Utils.getRevision(formData.revisions, activeRevision)
        }

        const fields: Field[] = [
            {
                id: 'nimi',
                readOnly: true,
                label: 'Noortekeskuse nimi',
                value: session.centre!.name,
            },
            {
                id: 'maakond',
                readOnly: true,
                label: 'Maakond',
                value: Enums.counties[session.centre!.county],
            },
            ...centreFormFields,
        ]

        expandValues(fields, latestValues)

        this.setState({
            loaded: true,
            fields,
            formData,
            activeRevision,
            values: latestValues,
        })
    }

    componentWillUnmount() {
        this.unmounted = true
    }

    async reloadData() {
        const { view } = this.props
        const formData = (await API.getCentreForm(view))!
        const activeRevision = Utils.getLatestRevNumber(formData)
        const latestValues = Utils.getRevision(formData.revisions, activeRevision)
        expandValues(this.state.fields!, latestValues)

        this.setState({
            editMode: false,
            formData,
            activeRevision,
            values: latestValues,
        })
    }

    enterEditMode() {
        const activeRevision = Utils.getLatestRevNumber(this.state.formData!)
        const latestValues = Utils.getRevision(this.state.formData!.revisions, activeRevision)
        expandValues(this.state.fields!, latestValues)
        this.setState({ editMode: true, activeRevision, values: latestValues })
    }

    canEditCentreForm() {
        const { view } = this.props
        const { session } = view.state
        return session.loggedIn && canEditCentreForm(session.employee, session.centre!._id)
    }

    isEditMode() {
        if (this.canEditCentreForm()) {
            return this.state.editMode || !this.state.formData
        } else {
            return false
        }
    }

    async onSave() {
        const { view } = this.props
        const rev = this.state.formData ? this.state.formData.rev : 0
        await clearValidationErrors(view, this)

        try {
            const newValues = finalizeValues(this.state.fields!, this.state.values)
            await API.saveCentreForm(view, rev, newValues)
            await this.reloadData()
        } catch (error) {
            await checkValidationErrors(view, this, error)
        }
    }

    renderVersions() {
        if (!this.isEditMode() && this.state.formData) {
            const versions = this.state.formData.revisions.map((revision) => {
                const isActive = revision.rev === this.state.activeRevision
                const style: CSSProperties = { cursor: 'pointer' }

                if (isActive) {
                    style.fontWeight = 'bold'
                }

                const onClick = () => {
                    const newValues = Utils.getRevision(
                        this.state.formData!.revisions,
                        revision.rev,
                    )
                    expandValues(this.state.fields!, newValues)
                    this.setState({ activeRevision: revision.rev, values: newValues })
                }

                const isoDate = revision.time.replace(' ', 'T')
                const desc =
                    'Versioon ' + revision.rev + ', ' + Utils.formatDateTime(new Date(isoDate))

                let noChangesElement: string | null = null

                if (!revision.fieldsSet && !revision.fieldsUnset) {
                    noChangesElement = ' (muudatusteta)'
                }

                return (
                    <div key={revision.rev}>
                        <a onClick={onClick} style={style}>
                            {desc}
                        </a>
                        {noChangesElement}
                    </div>
                )
            })

            return (
                <div>
                    <h2>Versioonid</h2>
                    {versions}
                </div>
            )
        } else {
            return null
        }
    }

    renderForm() {
        const editMode = this.isEditMode()

        if (editMode || this.state.formData) {
            const validationErrors: Record<string, ValidationError> = {}

            if (this.state.validationErrors) {
                for (const fieldName of Object.keys(this.state.validationErrors)) {
                    if (fieldName.startsWith('values.')) {
                        const unprefixed = fieldName.substr(7)
                        validationErrors[unprefixed] = this.state.validationErrors[fieldName]
                    }
                }
            }

            return (
                <div>
                    <Form
                        editMode={editMode}
                        fields={this.state.fields!}
                        values={this.state.values!}
                        validationErrors={validationErrors}
                        onChange={(fieldId, newValue) => {
                            const newValues = Utils.clone(this.state.values!)
                            newValues[fieldId as keyof CentreFormContents] = newValue
                            this.setState({ values: newValues })
                        }}
                    />
                    {this.renderSaveButton()}
                </div>
            )
        } else {
            return 'Antud keskuses pole veel ankeeti täidetud.'
        }
    }

    renderSaveButton() {
        if (this.isEditMode()) {
            return <button onClick={async () => this.onSave()}>Salvesta</button>
        } else {
            return null
        }
    }

    renderVersionNote() {
        if (this.isEditMode()) {
            return (
                <div style={{ marginTop: 10 }}>
                    NB! Iga salvestuse peale luuakse uus versioon, isegi kui ankeedi sisu pole
                    muutunud.
                </div>
            )
        } else {
            return null
        }
    }

    renderEditButton() {
        if (!this.isEditMode() && this.canEditCentreForm()) {
            return <button onClick={() => this.enterEditMode()}>Muuda andmeid</button>
        } else {
            return null
        }
    }

    render() {
        const { view } = this.props
        if (!this.state.loaded) {
            return <Loading />
        }

        return (
            <div>
                <ManageCentreMenu view={view} active="form" />
                <div className="main-panel">
                    {this.renderVersions()}
                    <h2>Ankeet</h2>
                    {this.renderForm()}
                    {this.renderVersionNote()}
                    {this.renderEditButton()}
                </div>
            </div>
        )
    }
}
